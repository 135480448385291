import { Svg } from "./Svg"

type Props = {
  className?: string
}

export const CopyIcon = ({ className }: Props) => (
  <Svg className={className} viewBox="0 0 8 8">
    <path
      d="M.854 2.646a.5.5 0 0 0-.708.708l2.5 2.5a.5.5 0 0 0 .726-.02l4.5-5a.5.5 0 1 0-.744-.668L2.981 4.774.854 2.646Z"
      fill="#fff"
      fillRule="nonzero"
    />
  </Svg>
)
