import { StyledPagination, StyledPaginationButton } from "./Pagination.styles"

type Props<T> = {
  items: T[]
  value: number
  onChange: (value: number) => void
}

export const Pagination = <T,>({ items, value, onChange }: Props<T>) => {
  const content =
    value >= items.length ? (
      <span>Showing all items</span>
    ) : (
      <>
        <span>
          Showing <b>{value}</b> out of <b>{items.length}</b>
        </span>
        <StyledPaginationButton
          type="button"
          onClick={() => onChange(items.length)}>
          Show all
        </StyledPaginationButton>
      </>
    )

  return <StyledPagination>{content}</StyledPagination>
}
