import styled from "styled-components"

export const StyledContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  p {
    text-align: center;
  }
`

export const StyledTitle = styled.h2`
  font-weight: 700;
`

export const StyledText = styled.p`
  color: var(--text-light-color);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`
