import styled, { keyframes } from "styled-components"

export const StyledItemTitle = styled.p`
  color: var(--text-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 50ms ease-in;
`

export const StyledItemDate = styled.p`
  color: var(--gray-400);
  font-size: 11px;
  transition: color 50ms ease-in;
`

export const StyledContentContainer = styled.div`
  padding: 0.6rem 0.3rem;
  overflow: hidden;
`

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px var(--border-default);
  padding-right: 0.25rem;
`

export const StyledLiveContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`

export const StyledButtonItem = styled.button`
  cursor: pointer;
  font-weight: 600;
  text-align: left;
  user-select: none;
  transition: all 50ms ease-in;
  border-radius: 4px;
  padding: 0;

  &:hover {
    background: var(--primary-accent);
  }

  &:hover ${StyledItemTitle}, &:hover ${StyledItemDate} {
    color: white;
  }

  &:last-child ${StyledWrapper} {
    border: none;
  }
`

const livePulseAnimation = keyframes`
  0% {box-shadow: 0px 0px 0px 0px var(--red-200);}
  50% {box-shadow: 0px 0px 0px 2px var(--red-200);}
  100% {box-shadow: 0px 0px 0px 0px var(--red-200);}
`

export const StyledLiveIcon = styled.span`
  width: 7px;
  height: 7px;
  min-width: 7px;
  background: var(--red-400);
  border-radius: 50%;
  box-shadow: 0px 0px 0px 0px var(--red-200);
  margin-bottom: 2px;
  animation: ${livePulseAnimation};
  animation-duration: 3s;
  animation-iteration-count: infinite;
`
