import { useContext } from "react"
import { Link } from "react-router-dom"

import { UserContext } from "jsx/user/UserContext"
import { Customer } from "types/customer"

import { Search } from "./Search"
import { Filter } from "./filter/Filter"

import {
  StyledHeader,
  StyledLoadingContainer,
  StyledInviteButton,
  StyledPlusIcon,
} from "./Header.styles"

type Props = {
  customers: Customer[]
  isLoading: boolean
}

export const Header = ({ customers, isLoading }: Props) => {
  const { data } = useContext(UserContext)

  return (
    <StyledHeader>
      <h1>Customers</h1>
      <div>
        <Search />
        {isLoading ? (
          <StyledLoadingContainer />
        ) : (
          <Filter customers={customers} />
        )}

        {data?.superuser && (
          <div>
            <Link to="/admin/customers/create">
              <StyledInviteButton>
                <StyledPlusIcon size="20" />
                Add customer
              </StyledInviteButton>
            </Link>
          </div>
        )}
      </div>
    </StyledHeader>
  )
}
