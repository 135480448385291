import { AthenaInvoice } from "@abios/abios-ts-sdk"
import axios from "axios"
import { useEffect, useState } from "react"
import AthenaAPI from "services/athena"
import { Customer } from "types/customer"

const requestInvoices = async (
  signal: AbortSignal,
): Promise<AthenaInvoice[]> => {
  const {
    data: { invoices },
  } = await AthenaAPI.get(`v1/invoice/open`, {
    signal,
  })

  return invoices
}

const createOverduePaymentsDicByCustomerID = (
  invoices: AthenaInvoice[],
): Map<string, AthenaInvoice[]> => {
  const dic = new Map<string, AthenaInvoice[]>()
  invoices.forEach((invoice: AthenaInvoice) => {
    const item: AthenaInvoice[] | undefined = dic.get(invoice.customer.id)
    if (item) item.push(invoice)
    else dic.set(invoice.customer.id, [invoice])
  })
  return dic
}

const useFilteredInvoices = (
  allInvoices?: AthenaInvoice[],
  filters = "",
): [AthenaInvoice[], { error?: string; isLoading: boolean }] => {
  const [invoices, setInvoices] = useState<AthenaInvoice[]>([])
  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!filters && allInvoices) {
      setInvoices(allInvoices)
      setIsLoading(false)

      return () => null
    }

    const controller = new AbortController()

    const getInvoices = async () => {
      setIsLoading(true)
      setError(undefined)

      try {
        const res = await requestInvoices(controller.signal)
        setInvoices(res)
      } catch (err) {
        if (axios.isCancel(err)) {
          return
        }

        setError(err.message)
      } finally {
        setIsLoading(false)
      }
    }

    getInvoices()

    return () => controller.abort()
  }, [allInvoices, filters])

  return [invoices, { error, isLoading }]
}

export const useInvoices = (
  filters = "",
): {
  allInvoices: AthenaInvoice[]
  invoicesDic: Map<string, AthenaInvoice[]>
  error?: string
  isLoading: boolean
  isFiltering: boolean
} => {
  const [allInvoices, { isLoading, error }] = useFilteredInvoices()
  const [filteredInvoices, { isLoading: isFiltering, error: filterError }] =
    useFilteredInvoices(allInvoices, filters)

  const invoicesDic = createOverduePaymentsDicByCustomerID(filteredInvoices)

  return {
    allInvoices,
    invoicesDic,
    isLoading,
    isFiltering,
    error: error || filterError,
  }
}

// go though the whole invoices to get overdue payments
export const getOverduePayments = (invoices: AthenaInvoice[] | undefined) => {
  if (!invoices) return []
  const currentDate = new Date().getTime()
  return invoices.filter((invoice) => {
    const date = new Date(invoice.due_date).getTime()
    return date < currentDate
  })
}

export const getOverduePaymentsCustomerList = (
  invoicesDic: Map<string, AthenaInvoice[]>,
  customers: Customer[],
): Customer[] => {
  return customers.filter(
    (customer) => getOverduePayments(invoicesDic.get(customer.id)).length,
  )
}
