import styled from "styled-components"

export const StyledSelection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
  font-size: 12px;
  text-align: center;
  color: var(--text-secondary);
  font-weight: 500;
  grid-row-start: 1;
  cursor: default;
  border-bottom: solid 1px white;
  background: var(--gray-50);

  &[data-position="1"] {
    grid-column-start: 1;
  }

  &[data-position="2"] {
    grid-column-start: 2;
  }

  &[data-position="3"] {
    grid-column-start: 3;
  }

  &[data-position="4"] {
    grid-column-start: 4;
  }
`

export const StyledOdds = styled(StyledSelection)`
  background: var(--gray-100);
  transition: background 100ms ease-in-out;
  cursor: pointer;

  &[data-state="open"][data-isselected="false"] {
    &:hover {
      background: var(--gray-200);
    }
  }

  &[data-state="settled"] {
    &[data-wasoutcome="true"] {
      background: var(--green-300);
      border-bottom: none;
    }

    &[data-wasoutcome="false"] {
      background: var(--red-300);
      border-bottom: none;
    }
  }

  &[data-isselected="true"] {
    background: var(--blue-500);

    & > div {
      color: white;
    }
  }
`

export const StyledRow = styled.div`
  display: grid;
  grid-gap: 1px;

  &:not([data-columns]) {
    grid-auto-flow: column;
  }

  &[data-columns="2"] {
    grid-template-columns: repeat(2, 1fr);
  }

  &[data-columns="3"] {
    grid-template-columns: repeat(3, 1fr);
  }

  &[data-columns="4"] {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const StyledWinnerText = styled.p`
  color: var(--green-700);
`
export const StyledLoserText = styled.p`
  color: var(--red-700);
`
