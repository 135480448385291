import { AthenaPackage } from "@abios/abios-ts-sdk"

export type PackageResp = {
  packages: AthenaPackage[]
}

export enum PACKAGES {
  MATCH = "gsmcatqq",
  PBP = "8x330ppi",
  ODDS = "7nxizoo0",
  TRADING = "walzwwn0",
}
