import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react"
import { Customer } from "types/customer"
import AthenaAPI from "services/athena"
import { AthenaAccountManager } from "@abios/abios-ts-sdk"

const requestManagerNames = async (
  signal?: AbortSignal,
): Promise<AthenaAccountManager[]> => {
  const {
    /* eslint-disable-next-line */
    data: { account_managers },
  } = await AthenaAPI.get(`v1/account-manager`, {
    signal,
  })

  return account_managers
}

// Record of account manager {id: name}
type AccountManagers = Record<
  string,
  {
    label: string
    active: boolean
  }
>

type AccountManagerContextType = [AccountManagers, boolean]

const providerlessContextValue: AccountManagerContextType = [{}, false]

/**
 * @provides [AccountManagers, isLoading]
 */
const accountManagerContext = createContext<AccountManagerContextType>(
  providerlessContextValue,
)

export const useAccountManagers = (): AccountManagerContextType => {
  const ctx: AccountManagerContextType = useContext(accountManagerContext)

  if (
    process.env.NODE_ENV !== "production" &&
    (ctx === undefined || ctx === providerlessContextValue)
  ) {
    throw new Error(
      "useAccountManagers must be used within an AccountManagerProvider",
    )
  }

  return ctx
}

type AccountManagerProviderProps = {
  customers: Customer[]
  children: ReactNode
}

export const AccountManagerProvider = ({
  customers,
  children,
}: AccountManagerProviderProps) => {
  const [allManagers, setAllManagers] = useState<AthenaAccountManager[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const controller = new AbortController()

    const fetchNames = async () => {
      setIsLoading(true)
      setAllManagers(await requestManagerNames(controller.signal))
      setIsLoading(false)
    }

    fetchNames()
    return () => {
      controller.abort()
    }
  }, [])

  // If the account manager referenced by `id` has any customers.
  const hasCustomers = (id: string): boolean =>
    customers.some((customer) =>
      customer.account_managers.map((manager) => manager.user.id).includes(id),
    )

  const managers: AccountManagers = Object.fromEntries(
    allManagers
      .filter(({ user: { deleted, id } }) => !deleted || hasCustomers(id))
      .map(({ user: { id, name, email } }) => [
        id,
        {
          // If the user does not have a name, display email
          label: name || email,
          active: hasCustomers(id),
        },
      ]),
  )

  const contextMemo: AccountManagerContextType = useMemo(
    () => [managers, isLoading],
    [managers, isLoading],
  )

  return (
    <accountManagerContext.Provider value={contextMemo}>
      {children}
    </accountManagerContext.Provider>
  )
}
