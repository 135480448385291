import { Svg } from "./Svg"

type Props = {
  className?: string
}

export const CheckIcon = ({ className }: Props) => (
  <Svg className={className} viewBox="0 0 8 6">
    <path
      d="m7.5 6-.058-.003a.5.5 0 0 1-.439-.439L7 5.5V1H2.5L2.442.997a.5.5 0 0 1 0-.994L2.5 0h5l.058.003a.5.5 0 0 1 .439.439L8 .5v5l-.003.058a.5.5 0 0 1-.439.439L7.5 6Zm-7 2-.058-.003a.5.5 0 0 1-.439-.439L0 7.5v-5l.003-.058a.5.5 0 0 1 .382-.429l.057-.01L.5 2h5l.058.003a.5.5 0 0 1 .439.439L6 2.5v5l-.003.058a.5.5 0 0 1-.382.429l-.057.01L5.5 8h-5ZM5 7V3H1v4h4Z"
      fillRule="nonzero"
    />
  </Svg>
)
