import {
  StyledContainer,
  StyledSubtitle,
  StyledTitle,
} from "./NoMarkets.styles"

export const NoMarkets = () => {
  return (
    <StyledContainer>
      <StyledTitle>No markets available</StyledTitle>
      <StyledSubtitle>
        Right now there isn&apos;t any available markets here
      </StyledSubtitle>
    </StyledContainer>
  )
}
