import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import orderBy from "lodash/orderBy"
import PageContent from "jsx/components/PageContent"
import { useAtlasStore } from "pages/trading/stores"
import { Match, Series } from "@abios/abios-ts-sdk"
import { Header } from "./components/Header"
import { MarketsBox } from "./Markets"
import { StyledColumn, StyledGrid } from "./Layout.styles"
import { WidgetSidebar } from "./molecules"
import { BetBuilder } from "./components/BetBuilder/BetBuilder"
import { TAB } from "./types/tab"

type StateData = {
  matches: Match[]
  series: Series | undefined
}

export const Layout = () => {
  const [data, setData] = useState<StateData>({
    matches: [],
    series: undefined,
  })
  const [searchParams] = useSearchParams()

  const { series, getMatchesByIds } = useAtlasStore()
  const params = useParams<{ seriesId: string }>()
  const tab = searchParams.get("tab")

  const serie =
    params.seriesId !== undefined && series[parseInt(params.seriesId)]

  useEffect(() => {
    const getDatafromStore = async () => {
      // Get series and matches from store
      if (serie) {
        const matchIds = serie.matches.map((m) => m.id)
        const matches = await getMatchesByIds(matchIds)

        // Order matches by order
        const matchesSorted = orderBy(matches, ["order"], ["asc"])

        setData({
          matches: matchesSorted,
          series: serie,
        })
      }
    }

    getDatafromStore()
  }, [getMatchesByIds, params.seriesId, serie])

  if (!data.series) {
    return <>No seriesId</>
  }

  const hasBetBuilderOrWidget =
    tab === TAB.BETBUILDER ? (
      <BetBuilder />
    ) : (
      <WidgetSidebar
        seriesId={data.series.id}
        lifecycle={data.series.lifecycle}
      />
    )

  return (
    <PageContent>
      <StyledGrid>
        <StyledColumn>
          <Header matches={data.matches} series={data.series} />
          <MarketsBox matches={data.matches} />
        </StyledColumn>
        {hasBetBuilderOrWidget}
      </StyledGrid>
    </PageContent>
  )
}
