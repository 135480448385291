import moment from "moment"

const ATHENA_SECRET_KEY = "athena_id"

export type AthenaStorage = {
  id: string
  expire: string
}

export const addAthenaSecret = (data: AthenaStorage) => {
  localStorage.setItem(ATHENA_SECRET_KEY, JSON.stringify(data))
}

export const removeAthenaSecret = () => {
  localStorage.removeItem(ATHENA_SECRET_KEY)
}

export const getAthenaSecret = (): string | undefined => {
  const storage = localStorage.getItem(ATHENA_SECRET_KEY)
  if (storage) {
    const storageParsed = JSON.parse(storage)
    // Check expiration time
    const timeNow = moment().toISOString()
    const hasExpired = moment(storageParsed.expire).isBefore(timeNow)
    if (hasExpired) {
      removeAthenaSecret()
      return undefined
    }
    return storageParsed.id
  }
  return undefined
}
