import styled from "styled-components"

type SectionProps = {
  firstChild: boolean
}

export const Section = styled.section<SectionProps>`
  ${({ firstChild }) =>
    !firstChild &&
    `
     padding-top: 56px;
  `}
`
