import { memo } from "react"
import { Spring, useSpring, animated } from "react-spring"
import { usePrevious } from "hooks"
import {
  StyledContainer,
  StyledText,
  StyledIconContent,
  StyledTriangleIcon,
} from "./OddsText.styles"

type Props = {
  readonly odds: number
}

export const OddsText = memo(({ odds }: Props) => {
  const prevOdds = usePrevious(odds)

  // TODO: Dummy as backend still sends out both 1 or 2 decimals
  const oddsWithDecimal = odds.toFixed(2)
  const isOddsHigher = odds > (prevOdds || 0)

  const iconStyles = useSpring({
    to: async (next) => {
      await next({ opacity: 1 })
      await next({ opacity: 0 })
    },
    from: { opacity: 1 },
  })

  return (
    <StyledContainer>
      <StyledIconContent style={iconStyles}>
        <StyledTriangleIcon
          size="8"
          isHigher={isOddsHigher}
          hasPrevOdds={!!prevOdds}
        />
      </StyledIconContent>
      <Spring
        config={{ duration: 200 }}
        from={{ opacity: 0.3 }}
        to={{ opacity: 1 }}
        reset>
        {(styles) => (
          <animated.div style={styles}>
            <StyledText>{oddsWithDecimal}</StyledText>
          </animated.div>
        )}
      </Spring>
    </StyledContainer>
  )
})
