import { EXPIRED_DAYS } from "constants/dates"
import { Customer, CUSTOMER_STATUS } from "types/customer"
import { useTrackedFilterStore } from "./useTrackedFilterStore"

const getIsActive = (now: number, activeUntil: string | null): boolean =>
  activeUntil === null || new Date(activeUntil).getTime() >= now

const getIsExpiring = (
  activeDate: number,
  activeUntil: string | null,
): boolean =>
  activeUntil !== null &&
  new Date(activeUntil).getTime() <= activeDate &&
  new Date().getTime() <= new Date(activeUntil).getTime()

// Converts UNIX time to yyyy-MM-dd
export const serverDateToISO = (date: string) =>
  new Date(date).toISOString().slice(0, 10)

const statusesRules = (customer: Customer) => {
  const now: number = new Date().getTime()
  const activeDate: Date = new Date()
  activeDate.setDate(activeDate.getDate() + EXPIRED_DAYS)

  const isActive: boolean = getIsActive(now, customer.active_until)
  const isOverdue: boolean = getIsExpiring(
    activeDate.getTime(),
    customer.active_until,
  )

  return {
    [CUSTOMER_STATUS.ACTIVE]: isActive,
    [CUSTOMER_STATUS.INACTIVE]: !isActive,
    [CUSTOMER_STATUS.OVERDUE]: isOverdue,
  }
}

export const getCustomerStatuses = (customer: Customer): CUSTOMER_STATUS[] =>
  Object.entries(statusesRules(customer))
    .filter(([, v]) => !!v)
    .reduce(
      (a, [status]) => [...a, +status as CUSTOMER_STATUS],
      [] as CUSTOMER_STATUS[],
    )

type CustomerURLFilter = string
type CustomerPredicate = (customer: Customer) => boolean

const filterSearch = (query?: string) => {
  const q = query?.toLowerCase()

  return (customer: Customer) => {
    const candidates = [
      customer.internal_name,
      customer.external_name,
      customer.id,
    ]

    return !q || candidates.some((v) => v.toLowerCase().includes(q))
  }
}

const filterStatuses = (statuses: number[]) => (customer: Customer) => {
  const customerStatuses = getCustomerStatuses(customer)

  return (
    statuses.length <= 0 ||
    statuses.some((status) => customerStatuses.includes(status))
  )
}

const toUrlParams = (
  obj: Record<string, (string | boolean | undefined)[]>,
): string =>
  Object.entries(obj)
    .map(([k, v]) => v.filter(Boolean).map((g) => `${k}=${g}`))
    .flat()
    .filter(Boolean)
    .join("&")

export const useCustomersFilter = (): [
  CustomerURLFilter,
  CustomerPredicate,
] => {
  const {
    managers,
    statuses,
    search,
    createdFrom,
    createdTo,
    statusDate,
    activeDate,
  } = useTrackedFilterStore()

  const filters = [filterStatuses(statuses), filterSearch(search)]

  return [
    toUrlParams({
      account_manager: managers,
      created_before: [
        ...(createdTo ? [new Date(createdTo).toISOString()] : []),
      ],
      created_after: [
        ...(createdFrom ? [new Date(createdFrom).toISOString()] : []),
      ],
      active_indefinitely: [...(statuses.includes(1) ? [true] : [])],
      active_until_after: [
        ...(statuses.includes(1) ? [activeDate.toISOString()] : []),
      ],
      active_until_before: [
        ...(statuses.includes(0) ? [statusDate.toISOString()] : []),
      ],
      active_until_between_from: [
        ...(statuses.includes(2) ? [statusDate.toISOString()] : []),
      ],
      active_until_between_to: [
        ...(statuses.includes(2) ? [activeDate.toISOString()] : []),
      ],
    }),
    (customer: Customer) => filters.every((filter) => filter(customer)),
  ]
}
