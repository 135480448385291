import { useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthStore } from "stores"
import { Billing, Dashboard, Logo, SignOut, Transaction, User } from "icons"
import { XCustomerContext } from "jsx/contexts/XCustomer"
import { CustomerContext } from "jsx/customer/CustomerContext"
import { UserContext } from "jsx/user/UserContext"
import { useOddsStore, useAtlasStore } from "pages/trading/stores"
import { RowsIcon } from "atoms/icons"
import { useTrackedFilterStore } from "pages/customers/components/utils/useTrackedFilterStore"
import {
  StyledNav,
  SidebarContainer,
  SidebarHeader,
  NavItem,
  NavContainer,
  SidebarFooter,
  LogoutContainer,
  UserContainer,
  Avatar,
  ActiveCustomer,
  CloseIcon,
  ChangeUserContainer,
  SidebarText,
} from "./Sidebar.styles"

export const Sidebar = () => {
  const { isAuthed, signOut, hasTradingAccess, resetTradingAccess } =
    useAuthStore()
  const customer = useContext(CustomerContext)
  const xcustomer = useContext(XCustomerContext)
  const user = useContext(UserContext)
  const navigate = useNavigate()
  const oddsStore = useOddsStore()
  const atlasStore = useAtlasStore()
  const { tab } = useTrackedFilterStore()
  const urlParams = useParams()

  const handleSignOut = () => {
    customer.reset()
    // Clear x-customer store
    xcustomer.Clear()
    // Clear odds store
    oddsStore.clear()
    // Clear atlas store
    atlasStore.clear()
    signOut()
  }

  const handleRemoveSelectedCustomer = async () => {
    resetTradingAccess()
    xcustomer.Clear()
    await customer.FetchCustomer()
    navigate("/")
  }

  if (
    user.isLoading ||
    customer.isLoading ||
    !isAuthed ||
    !user.data ||
    !customer.data
  ) {
    return null
  }

  const fullName =
    `${user.data.first_name || ""} ${user.data.last_name || ""}`.trim() ||
    (user.data.email as string)

  const initials = fullName.split(" ").map((name) => name[0])

  return (
    <StyledNav>
      <SidebarContainer>
        <SidebarHeader>
          <NavItem to="/">
            <Logo />
          </NavItem>
        </SidebarHeader>

        <NavContainer>
          <NavItem to="/api-clients">
            <Dashboard />
            <SidebarText>API Clients</SidebarText>
          </NavItem>
          <NavItem to="/widget">
            <RowsIcon size="20" color="#f6f8fa" />
            <SidebarText>Widgets</SidebarText>
          </NavItem>
          <NavItem
            to="/trading"
            className={!hasTradingAccess ? "disabled" : ""}>
            <Transaction />
            <SidebarText>Trading</SidebarText>
          </NavItem>
        </NavContainer>

        <NavContainer>
          <SidebarText>ACCOUNT</SidebarText>
          <NavItem
            to="/billing"
            className={!customer.data.stripe ? "disabled" : ""}>
            <Billing />
            <SidebarText>Billing</SidebarText>
          </NavItem>
          {user.data.superuser && (
            <NavItem to="/users">
              <User />
              <SidebarText>Users</SidebarText>
            </NavItem>
          )}
        </NavContainer>

        <SidebarFooter>
          <ChangeUserContainer>
            {user.data.superuser && xcustomer.customer?.name && (
              <ActiveCustomer>
                <SidebarText>{xcustomer.customer.name}</SidebarText>
                <CloseIcon size="18" onClick={handleRemoveSelectedCustomer} />
              </ActiveCustomer>
            )}
            {user.data.superuser && (
              // Save selected tab value in URL
              <NavItem to={`/admin/customers/${urlParams.tab ?? tab}`}>
                <SidebarText>Change Customer</SidebarText>
              </NavItem>
            )}
          </ChangeUserContainer>

          <LogoutContainer>
            <UserContainer>
              <Avatar>
                <SidebarText>{initials}</SidebarText>
              </Avatar>
              <SidebarText>{fullName}</SidebarText>
            </UserContainer>
            <SignOut onClick={handleSignOut} />
          </LogoutContainer>
        </SidebarFooter>
      </SidebarContainer>
    </StyledNav>
  )
}
