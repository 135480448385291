import styled, { css } from "styled-components"
import { IndexTable } from "@abios/smorgasbord-components"

export const StyledContent = styled.div`
  margin: 0 auto;
  margin-bottom: 2em;
  max-width: 1280px;
  min-height: calc(100vh - 60px);
  padding: 60px 20px 0;
  width: calc(100% - 40px);
`

export const StyledIndexTableHeader = styled(IndexTable.Header)<{
  $dataColumn: string
}>`
  ${({ $dataColumn }) =>
    $dataColumn === "Status" &&
    css`
      text-indent: 26px;
    `}
`

export const StyledHeader = styled.header`
  margin-bottom: 1.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledTableContent = styled.div`
  margin-bottom: 2.5rem;
`
