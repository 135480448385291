import { MARKET_TYPE } from "@abios/abios-ts-sdk"
import { E_MARKET_TEMPLATE } from "../types/EMarketTemplate"

//  |     [Team]     |     [Team]     |
//  |     [odds]     |     [odds]     |
const twoColumnTeam = [MARKET_TYPE.SERIES_WINNER, MARKET_TYPE.MATCH_WINNER]

//  |     [Team]     |      Draw      |     [Team]     |
//  |     [odds]     |     [odds]     |     [odds]     |
const threeColumnTeam = [
  MARKET_TYPE.MATCH_WINNER_THREE_WAY,
  MARKET_TYPE.SERIES_WINNER_THREE_WAY,
]

//  |  [Selection]   |  [Selection]   |
//  |     [odds]     |     [odds]     |
const twoColumnYesNo = [
  MARKET_TYPE.OVERTIME,
  MARKET_TYPE.ACE_OCCURRED,
  MARKET_TYPE.TOTAL_MATCHES_ODD_EVEN,
  MARKET_TYPE.BOTH_TO_SCORE,
]

const threeColumbTeamYesNo = [MARKET_TYPE.BOTH_TO_SCORE_AND_TEAM_WINS]

//  |                |     [Team]     |     [Team]     |
//  |  [Selection]   |     [odds]     |     [odds]     |
const threeColumnEmptyTeam = [MARKET_TYPE.ROUND_WINNER]

//  |    [Count]     |     [Team]     |     [Team]     |
//  |  [Selection]   |     [odds]     |     [odds]     |
const threeColumnTeamProps = [
  MARKET_TYPE.TEAM_TO_GET_KILL,
  MARKET_TYPE.TEAM_TO_DESTROY_BARRACK,
  MARKET_TYPE.TEAM_TO_DESTROY_INHIBITOR,
  MARKET_TYPE.TEAM_TOTAL_GOALS,
  MARKET_TYPE.TEAM_TO_DESTROY_TOWER,
  MARKET_TYPE.TEAM_TO_DESTROY_TURRET,
  MARKET_TYPE.TEAM_TO_KILL_BARON,
  MARKET_TYPE.TEAM_TO_KILL_DRAGON,
  MARKET_TYPE.TEAM_TO_KILL_ROSHAN,
  MARKET_TYPE.TEAM_TO_KILL_SCUTTLER,
  MARKET_TYPE.FIRST_TEAM_TO_ROUNDS,
  MARKET_TYPE.TEAM_TOTAL_GOALS,
]

//  |    [Count]     |      Over      |      Under     |
//  |  [Selection]   |     [odds]     |     [odds]     |
const threeColumnTotalOverUnder = [
  MARKET_TYPE.TOTAL_MATCHES,
  MARKET_TYPE.TOTAL_ROUNDS,
  MARKET_TYPE.TOTAL_ROUNDS_BY_DEFUSE,
  MARKET_TYPE.TOTAL_ROUNDS_BY_DETONATION,
  MARKET_TYPE.TOTAL_ROUNDS_BY_TIMEOUT,
  MARKET_TYPE.TOTAL_BARRACKS_DESTROYED,
  MARKET_TYPE.TOTAL_KILLS,
  MARKET_TYPE.TOTAL_MINUTES,
  MARKET_TYPE.TOTAL_ROSHANS_SLAIN,
  MARKET_TYPE.TOTAL_TOWERS_DESTROYED,
  MARKET_TYPE.TOTAL_BARONS_SLAIN,
  MARKET_TYPE.TOTAL_DRAGONS_SLAIN,
  MARKET_TYPE.TOTAL_INHIBITORS_DESTROYED,
  MARKET_TYPE.TOTAL_TURRETS_DESTROYED,
  MARKET_TYPE.TOTAL_GOALS,
  MARKET_TYPE.TOTAL_CORNERS,
]

//  |                |      Over      |      Under     |
//  |  [Selection]   |     [odds]     |     [odds]     |
const threeColumnEntityOverUnder = [
  MARKET_TYPE.TOTAL_PLAYER_DEATHS,
  MARKET_TYPE.TOTAL_PLAYER_KILLS,
  MARKET_TYPE.TOTAL_PLAYER_BOMB_PLANTS,
  MARKET_TYPE.TOTAL_PLAYER_BOMB_DEFUSALS,
  MARKET_TYPE.TOTAL_PLAYER_GOALS,
]

//  |             [Team]              |             [Team]              |
//  |       1.5      |     [odds]     |     [odds]     |      -1.5      |
const fourColumnTeam = [MARKET_TYPE.MATCH_HANDICAP, MARKET_TYPE.ROUND_HANDICAP]

//  |     [Team]     |     [Team]     |
//  |  [score]  |  [odds]  | [score]  |
const correctSeriesScore = [MARKET_TYPE.CORRECT_SERIES_SCORE]

export const GetOperatorTemplate = (marketKey: MARKET_TYPE) => {
  if (twoColumnTeam.includes(marketKey))
    return E_MARKET_TEMPLATE.TWO_COLUMN_TEAM

  if (threeColumnTeam.includes(marketKey))
    return E_MARKET_TEMPLATE.THREE_COLUMN_TEAM

  if (twoColumnYesNo.includes(marketKey))
    return E_MARKET_TEMPLATE.TWO_COLUMN_YESNO

  if (threeColumnEmptyTeam.includes(marketKey))
    return E_MARKET_TEMPLATE.THREE_COLUMN_EMPTY_TEAM

  if (threeColumnTotalOverUnder.includes(marketKey))
    return E_MARKET_TEMPLATE.THREE_COLUMN_TOTAL_OVER_UNDER

  if (fourColumnTeam.includes(marketKey))
    return E_MARKET_TEMPLATE.FOUR_COLUMN_TEAM

  if (threeColumnEntityOverUnder.includes(marketKey))
    return E_MARKET_TEMPLATE.THREE_COLUMN_ENTITY_OVER_UNDER

  if (threeColumnTeamProps.includes(marketKey))
    return E_MARKET_TEMPLATE.THREE_COLUMN_TEAM_PROPS

  if (correctSeriesScore.includes(marketKey)) {
    return E_MARKET_TEMPLATE.CORRECT_SERIES_SCORE
  }

  if (threeColumbTeamYesNo.includes(marketKey)) {
    return E_MARKET_TEMPLATE.THREE_COLUMN_TEAM_YES_NO
  }

  return null
}
