const dateFormat = new Intl.DateTimeFormat([], {
  dateStyle: "short",
})

export const getTerminateDate = (date: Date | null) => {
  if (date === null) {
    return ""
  }
  return dateFormat.format(date)
}
