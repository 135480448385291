import { Game } from "types/game"
import { GameResource } from "../single/sidebar/resources"

export const sortGames =
  () =>
  (a: Game, b: Game): number =>
    (a.title.toLowerCase() ?? 0) < (b.title.toLowerCase() ?? 0) ? -1 : 1

export const sortGamesByActive = (games: {
  [key: number]: GameResource
}): GameResource[] => {
  // Sort by name and activity status
  const active: GameResource[] = []
  const inactive: GameResource[] = []
  Object.values(games)
    .sort(sortGames())
    .forEach((game) =>
      game.isActive ? active.push(game) : inactive.push(game),
    )

  return [...active, ...inactive]
}
