import { ChevronIcon } from "atoms/icons"
import { FC } from "react"
import { ChevronIconContent } from "./ExpandIcon.styles"

type Props = {
  isActive: boolean
}

export const ExpandIcon: FC<Props> = ({ isActive }: Props) => (
  <ChevronIconContent data-actives={isActive}>
    <ChevronIcon size="10" />
  </ChevronIconContent>
)
