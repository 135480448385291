import { useCallback, useRef, useState } from "react"
import { useOnClickOutside } from "hooks"
import { Customer } from "types/customer"
import { FilterContainer, FilterButton } from "../Search.styles"
import { StyledFilterIcon } from "./Filter.styles"
import { FilterContent } from "./FilterContent"
import { FilterActivatedBlob } from "./FilterActivatedBlob"

type Props = {
  customers: Customer[]
}

export const Filter = ({ customers }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [active, setActive] = useState<number>(0)

  useOnClickOutside(ref, () => setIsOpen(false))

  const onToggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [setIsOpen, isOpen])

  return (
    <FilterContainer ref={ref}>
      <FilterButton onClick={onToggle}>
        <StyledFilterIcon size="15" />
        Filter
      </FilterButton>
      <FilterActivatedBlob active={active} />
      <FilterContent
        show={isOpen}
        customers={customers}
        setActive={setActive}
      />
    </FilterContainer>
  )
}
