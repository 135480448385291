import styled from "styled-components"

export const FilterBox = styled.div`
  ${({ hidden }) => hidden && `display: none;`}

  background: white;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.625rem;
  border: 1px solid var(--border-default);
  box-shadow: 0 5px 15px -3px rgba(103, 107, 224, 0.2);
  border-radius: 3px;
  min-width: 12rem;
`

export const FilterBoxHeader = styled.div`
  padding: 1rem;
  border-bottom: 1px solid var(--border-default);
`

export const FilterBoxFooter = styled.div`
  padding: 1rem;
  border-bottom: 1px solid var(--border-default);
  display: flex;
  justify-content: space-between;
`

export const FilterBoxButton = styled.button`
  padding: 0;
`
