import { create } from "zustand"
import { State } from "./State"
import { addList } from "./AddList"
import { patchSingle } from "./PatchSingle"
import { getMarketsByTypeAndId } from "./getMarketsByTypeAndId"
import { fetchMarketsByTypeAndIds } from "./FetchMarketsByTypeAndIds"
import { fetchLabels } from "./fetchLabels"
import { clear } from "./clear"
import { onMarketSelect } from "./BetBuilder/onMarketSelect"
import { onRemoveBet } from "./BetBuilder/onRemoveBet"

const useStore = create<State>((set, get) => ({
  label: {},
  markets: {},
  selectedMarkets: {},
  selectedMatchOrder: 0,
  isFetchingResources: false,

  /** Methods */

  addList: (list) => addList(set, get, list),

  // Clear store
  clear: () => clear(set),
  patchSingle: (payload) => patchSingle(get, set, payload),
  // From store
  getMarketsByTypeAndId: (type, id) => getMarketsByTypeAndId(get, type, id),
  // Fetch markets from API
  fetchMarketsByTypeAndIds: (controller, type, ids) =>
    fetchMarketsByTypeAndIds(set, get, controller, type, ids),

  /** Labels */
  fetchLabels: (marketIds) => fetchLabels(set, get, marketIds),

  onMarketSelect: (
    marketId,
    selectionId,
    isSelected,
    selFixtureType,
    selMarketType,
    matchOrder,
  ) =>
    onMarketSelect(
      set,
      get,
      marketId,
      selectionId,
      isSelected,
      selFixtureType,
      selMarketType,
      matchOrder,
    ),

  onRemoveBet: (marketId) => onRemoveBet(set, get, marketId),
  onClearSelectedBets: () => set({ selectedMarkets: {} }),
  onResetMatchOrder: () => set({ selectedMatchOrder: 0 }),

  setFetchingResources: (value) => set({ isFetchingResources: value }),
}))

export const useOddsStore = useStore
