import styled, { css } from "styled-components"
import { STATUS } from "./Types"

type StyledBadgeProps = {
  readonly status: STATUS
}

export const StyledBadge = styled.span<StyledBadgeProps>`
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;

  height: 22px;
  padding: 0 12px;

  ${({ status }) =>
    status === STATUS.DEFAULT &&
    css`
      background-color: var(--inactive-accent);
      color: var(--text-inactive);
    `}

  ${({ status }) =>
    status === STATUS.SUCCESS &&
    css`
      background-color: var(--success-accent);
      color: var(--text-success);
    `}

  ${({ status }) =>
    status === STATUS.WARNING &&
    css`
      background-color: var(--warning-accent);
      color: var(--text-warning);
    `}
`

export const StyledText = styled.p`
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.2px;
`
