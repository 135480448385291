type Props = {
  onClick?: (event: React.MouseEvent<SVGElement>) => void
}

export const SignOut = ({ onClick }: Props) => (
  <svg
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}>
    <title>Logout</title>
    <g
      transform="translate(-192 -16)"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <g strokeWidth="1.5">
        <path d="M203.75 20.75l3.5 3.25-3.5 3.25M207 24h-8.25M203.25 16.75h-8.5a2 2 0 00-2 2v10.5a2 2 0 002 2h8.5" />
      </g>
    </g>
  </svg>
)
