import { StoreApi } from "zustand"
import { applyPatch } from "fast-json-patch"
import cloneDeep from "lodash/cloneDeep"

import { Payload } from "types/websocket/Message"
import { State } from "./State"

// Games we currently have trading support for
const TRADED_GAME_IDS = [1, 2, 5, 19, 20]

export const patchSeries = (
  get: StoreApi<State>["getState"],
  set: StoreApi<State>["setState"],
  payload: Payload,
) => {
  const { series } = get()
  const seriesCloned = cloneDeep(series)

  if (!TRADED_GAME_IDS.includes(payload.state.game.id)) {
    return
  }

  const seriesStoreData = seriesCloned[payload.state.id]

  if (seriesStoreData) {
    const seriesObj = { ...seriesStoreData }
    const seriesPatched = applyPatch(seriesObj, payload.patch).newDocument
    seriesCloned[payload.state.id] = seriesPatched
  } else {
    // Add series to state
    seriesCloned[payload.state.id] = payload.state
  }

  set({ series: seriesCloned })
}
