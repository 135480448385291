import { ReactNode } from "react"
import {
  StyledDropContent,
  StyledDropdown,
  StyledDropdownWrapper,
} from "./DropdownWrapper.styles"

type Props = {
  title: string
  value: string
  children?: ReactNode
}

export const DropdownWrapper = ({ title, value, children }: Props) => {
  return (
    <StyledDropdownWrapper>
      <p>{title}</p>
      <StyledDropdown selectedValue={value} inputHidden>
        <StyledDropContent>{children}</StyledDropContent>
      </StyledDropdown>
    </StyledDropdownWrapper>
  )
}
