import { useEffect } from "react"

import LoadingContainer from "jsx/components/LoadingContainer"
import { ClientList } from "./ClientList"
import { getClientStore } from "./store/getClientStore"

export const ApiList = () => {
  const { clients, isLoading, getClients } = getClientStore()

  // Fetch customers API clients
  useEffect(() => {
    const controller = new AbortController()
    getClients(controller)
    return () => {
      controller.abort()
    }
  }, [getClients])

  if (isLoading) return <LoadingContainer />

  return <ClientList clients={clients} />
}
