import { useState, useContext } from "react"
import styled from "styled-components"
// eslint-disable-next-line import/no-extraneous-dependencies
import { Plus } from "@styled-icons/bootstrap"

import { UserContext } from "jsx/user/UserContext"
import { Invite } from "../../invite/Invite"

const StyledHeader = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
`
const StyledInviteButton = styled.button`
  align-items: center;
  background: var(--primary-accent);
  border-radius: 4px;
  border: none;
  color: var(--text-white);
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.25px;
  outline: none;
  padding: 10px 14px;
  user-select: none;
`
const StyledPlusIcon = styled(Plus)`
  color: #fff;
  margin-right: 4px;
`

type Props = {
  reloadUsers: () => void
}

export const Header = ({ reloadUsers }: Props) => {
  const { data } = useContext(UserContext)
  const [shouldShowModal, setShouldShowModal] = useState(false)

  return (
    <StyledHeader>
      <h2>Users</h2>

      {data?.superuser && (
        <div>
          <StyledInviteButton
            type="button"
            onClick={() => setShouldShowModal(true)}>
            <StyledPlusIcon size="20" />
            Invite user
          </StyledInviteButton>

          {shouldShowModal && (
            <Invite
              hide={() => setShouldShowModal(false)}
              reloadUsers={reloadUsers}
            />
          )}
        </div>
      )}
    </StyledHeader>
  )
}
