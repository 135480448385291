import { CreateCustomerType } from "../../types"

const checkRequiredField = (value: string) => {
  return !value.trim().length
}

export const customerUpdateValidation = (
  customerDetails: CreateCustomerType,
  hasStripe: boolean,
) => {
  // Customer name
  if (checkRequiredField(customerDetails.customerName)) {
    return {
      error: true,
      key: "customerName",
      message: "Customer name cannot be empty",
    }
  }

  // Stripe id
  if (hasStripe && checkRequiredField(customerDetails.stripedId)) {
    return {
      error: true,
      key: "stripedId",
      message: "A customer needs a stripe id",
    }
  }

  // Account managers
  if (!customerDetails.accountManagers.length) {
    return {
      error: true,
      key: "accountManagers",
      message: "A customer needs at least one Account Manager",
    }
  }

  return { error: false, key: "", message: "" }
}
