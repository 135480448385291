import { AthenaAccountManager } from "@abios/abios-ts-sdk"

export const getFormattedAccountManagers = (
  managers: AthenaAccountManager[],
) => {
  return managers.map((manager) => {
    return {
      user: {
        ...manager.user,
        name: manager.user.name || manager.user.email.split("@")[0],
      },
    }
  })
}
