import { SeriesPreview } from "types/series_preview"
import { formatDateCreated } from "pages/api/list/utils/formatDateCreated"
import {
  StyledButtonItem,
  StyledContentContainer,
  StyledItemDate,
  StyledItemTitle,
  StyledLiveContainer,
  StyledLiveIcon,
  StyledWrapper,
} from "./SeriesItem.styles"
import { getSeriesTitle } from "../../utils/getSeriesTitle"

type Props = {
  serie: SeriesPreview
  onSelect: (seriesID: number, lifecycle: string, title: string) => void
}

export const SeriesItem = ({ serie, onSelect }: Props) => {
  const getFormatedStart = () => {
    return formatDateCreated(serie.start)
  }

  return (
    <StyledButtonItem
      type="button"
      onClick={() =>
        onSelect(serie.id, serie.lifecycle, getSeriesTitle(serie))
      }>
      <StyledWrapper>
        <StyledContentContainer>
          <StyledItemTitle>{getSeriesTitle(serie)}</StyledItemTitle>
          <StyledItemDate>{getFormatedStart()}</StyledItemDate>
        </StyledContentContainer>
        {serie.lifecycle === "live" && (
          <StyledLiveContainer>
            <StyledItemTitle>Live</StyledItemTitle>
            <StyledLiveIcon />
          </StyledLiveContainer>
        )}
      </StyledWrapper>
    </StyledButtonItem>
  )
}
