import styled from "styled-components"
import { useGameStore } from "stores"
import { IMAGE_TYPE } from "types/game"
import { GAMEID } from "@abios/abios-ts-sdk"

const StyledFigure = styled.figure`
  padding-right: 13px;
`
const StyledImg = styled.img`
  display: block;
  height: 1.5rem;
  width: 1.5rem;
`

type Props = {
  readonly gameId: GAMEID
}

export const GameIcon = ({ gameId }: Props) => {
  const { getById } = useGameStore()

  let iconUrl = ""
  const game = getById(gameId)

  if (game) {
    const gameImageLength = game.images.length
    for (let i = 0; i < gameImageLength; i += 1) {
      if (game.images[i].type === IMAGE_TYPE.CIRCLE) {
        iconUrl = game.images[i].url
        break
      }
    }
  }

  if (!iconUrl) {
    // Fallback
    iconUrl = "https://img.abiosgaming.com/other/New-Abios-Place-Holder.png"
  }

  return (
    <StyledFigure>
      <StyledImg src={iconUrl} alt="game-icon" />
    </StyledFigure>
  )
}
