import { useMemo } from "react"
import styled from "styled-components"

import LoadingContainer from "jsx/components/LoadingContainer"

import { useAccountManagers } from "../utils/account-manager"
import { FilterCategory, FilterCategoryOption } from "./FilterCategory"

const StyledLoadingContainer = styled(LoadingContainer)`
  width: auto;
  padding: 0 1rem;
`

const GrayedOut = styled.span`
  color: var(--text-secondary);
`

type Props = {
  managers: string[]
  setManagers: (managers: string[]) => void
}

export const FilterAccountManager = ({ managers, setManagers }: Props) => {
  const [accountManagers, isLoading] = useAccountManagers()

  const managerOptions: FilterCategoryOption<string>[] = useMemo(
    () =>
      Object.entries(accountManagers)
        .sort(([, { active: a }], [, { active: b }]) => +b - +a) // Sort by active account managers first
        .map(([id, { label, active }]) => ({
          value: id,
          label: active ? label : <GrayedOut>{label}</GrayedOut>,
        })),
    [accountManagers],
  )

  if (isLoading) return <StyledLoadingContainer />

  return (
    <FilterCategory
      header="Account Manager"
      options={managerOptions}
      values={managers}
      onChange={setManagers}
    />
  )
}
