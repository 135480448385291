import { Client3, SCOPE } from "types/client3"
import { AthenaCreateClientPayload, AthenaPackage } from "@abios/abios-ts-sdk"
import { Unit, UNITS } from "../UnitPicker/UnitPicker"
import { FormState, Package } from "./formReducer"

const getTimeMultiplier = (unit: Unit) => {
  switch (unit) {
    case UNITS.hours:
      return 1
    case UNITS.days:
      return 24
    case UNITS.months:
      return 24 * 31
    case UNITS.years:
      return 24 * 365
    default:
      return 0
  }
}

export const getClientInputObjectFromForm = (
  state: FormState,
): AthenaCreateClientPayload => ({
  name: state.name,
  request_rate_second: state.perSecond ?? 0,
  request_rate_burst: state.burst ?? 0,
  hours_back: state.historical
    ? state.historical * getTimeMultiplier(state.unit)
    : state.historical,
  max_concurrent_hermes_connections: state.concurrent ?? 0,
  active_until: state.activeUntil?.toISOString() ?? null,
  packages: state.packages.flatMap(({ game, products }) =>
    Object.entries(products)
      .filter(([, v]) => v)
      .map(([product]) => ({
        game: {
          id: game,
        },
        product: {
          id: product,
        },
        push_channels: [],
      })),
  ),
})

export const getClientFromForm = (state: FormState): Client3 => ({
  id: "",
  name: state.name,
  customer: {
    id: "",
  },
  active_until: state.activeUntil?.toISOString() || null,
  request_rate_second: state.perSecond || 0,
  request_rate_burst: state.burst || 0,
  max_concurrent_hermes_connections: state.concurrent || 0,
  hours_back:
    state.historical != null
      ? state.historical * getTimeMultiplier(state.unit)
      : null,
  scope: SCOPE.READ,
  packages: [],
  created: "",
  resource_version: 0,
  deleted: "",
})

export const getFormPackagesFromClientPackages = (
  packages: AthenaPackage[],
): Package[] => {
  const gamePackages = Object.entries(
    packages.reduce<Record<number, string[]>>(
      (previousPackage, { game, product }) => ({
        ...previousPackage,
        [game.id]: [...(previousPackage[game.id] || []), product.id],
      }),
      {},
    ),
  )

  return gamePackages.reduce<Package[]>(
    (previousPackage, [game, products]) => [
      ...previousPackage,
      {
        game: +game,
        products: products.reduce(
          (b, product) => ({ ...b, [product]: true }),
          {},
        ),
      },
    ],
    [],
  )
}

export const getFormFromClient = (client: Client3): FormState => {
  return {
    name: client.name,
    historical:
      !!client.hours_back || client.hours_back === 0
        ? client.hours_back
        : undefined,
    unit: UNITS.hours,
    perSecond: client.request_rate_second,
    burst: client.request_rate_burst,
    concurrent: client.max_concurrent_hermes_connections,
    activeUntil: client.active_until
      ? new Date(client.active_until)
      : undefined,
    packages: getFormPackagesFromClientPackages(client.packages),
  }
}
