import styled from "styled-components"

export const WidgetContentContainer = styled.div`
  width: calc(300px + 2rem);
  padding-right: 2rem;
  position: sticky;
  top: 1rem;

  & > span {
    font-size: 0.75rem;
    color: var(--text-secondary);
    font-weight: 600;
  }

  @media (max-width: 1000px) {
    position: relative;
    top: 0;
    width: 100%;
    padding: 0;
  }
`

export const WidgetHeader = styled.div`
  width: 100%;
`

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  & > span {
    margin-left: 0.623rem;
  }
`

export const DescriptionSection = styled.p`
  padding: 2rem;
  padding-left: 0;
  color: var(--text-secondary);
`

export const DetailedSection = styled.div`
  padding-top: 1.5rem;
`

export const Details = styled.dl`
  display: grid;
  grid-template: auto 1fr;
  margin: 0;
`

export const StyledDt = styled.dt`
  grid-column: 1;
  font-size: 0.813rem;
  font-weight: 400;
  color: var(--text-secondary);
  padding: 0.5rem 0;
  padding-bottom: 0;
`

export const StyledDd = styled.dd`
  grid-column: 2;
  font-size: 0.813rem;
  color: var(--text-primary);
  font-weight: 400;
  padding: 0.5rem 0;
  padding-bottom: 0;
  text-align: end;
  @media (max-width: 1000px) {
    margin-inline-start: 0;
  }
`

export const GamesSection = styled.section`
  padding-top: 2rem;
  & > h2 {
    color: var(--text-primary);
  }
`

export const GamesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 8px;
  margin-top: 1rem;
`

export const DomainsSection = styled.div`
  padding-top: 2rem;
  & > h2 {
    padding-bottom: 0.5rem;
  }
`

export const DetailedDomains = styled(Details)`
  margin-left: 0;
`
