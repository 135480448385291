import { useState, useEffect, useRef, useMemo, useCallback } from "react"
import { useOnClickOutside } from "hooks"

import { Game } from "types/game"
import { useGamesWithProducts } from "../util/useGamesWithProducts"
import { FormState } from "../util/formReducer"
import { StyledAddGameWrapper, StyledInput } from "./AddGame.styles"
import { GameDropdown } from "./GameDropdown"

type Props = {
  state: FormState
  onAdd: (game: Game) => void
}

const step = (
  val: number,
  amount: number,
  max: number,
  min = 0,
): number | undefined => {
  if (max < 0) {
    return undefined
  }

  if (val + amount < 0) {
    return undefined
  }

  return Math.max(Math.min(val + amount, max), min)
}

export const AddGame = ({ state: { packages }, onAdd }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [query, setQuery] = useState<string>("")
  const [index, setIndex] = useState<number>()

  const availableGames = useGamesWithProducts()

  const handleClickOutside = useCallback(() => {
    setIsOpen(false)
    setIndex(undefined)
  }, [setIsOpen, setIndex])

  useOnClickOutside(wrapperRef, handleClickOutside)

  const queryFiltered = useMemo(
    () =>
      Object.values(availableGames)
        .map(({ game }) => game)
        .filter(({ title, abbreviation }) =>
          query
            .toLowerCase()
            .split(" ")
            .every((q) =>
              [title, abbreviation].some((v) => v.toLowerCase().includes(q)),
            ),
        ),
    [availableGames, query],
  )

  const filteredGames = useMemo(
    () =>
      queryFiltered.filter(
        ({ id }) => !packages.map(({ game }) => game).includes(id),
      ),
    [queryFiltered, packages],
  )

  const handleAdd = useCallback(
    (game: Game) => {
      if (packages.map(({ game: gameId }) => gameId).includes(game.id)) {
        return
      }

      setQuery("")
      onAdd(game)
      setIndex((i) =>
        i !== undefined ? step(i, 0, filteredGames.length - 2) : 0,
      )
      inputRef.current?.focus()
    },
    [setQuery, onAdd, setIndex, inputRef, filteredGames, packages],
  )

  useEffect(() => {
    if (!inputRef.current) return () => undefined

    // On any of the following key presses, we preventDefault in order
    // to keep the focus on the input element. In any other case, don't preventDefault.
    const onKeydown = (e: KeyboardEvent) => {
      switch (e.key) {
        case "ArrowUp":
          e.preventDefault()
          setIndex((i) =>
            i !== undefined ? step(i, -1, filteredGames.length - 1) : undefined,
          )
          break
        case "ArrowDown":
          e.preventDefault()
          setIndex((i) =>
            i !== undefined ? step(i, 1, filteredGames.length - 1) : 0,
          )
          break
        case "Enter":
          e.preventDefault()
          if (index !== undefined) handleAdd(filteredGames[index])
          break
        case "Escape":
          e.preventDefault()
          handleClickOutside()
          inputRef.current?.blur()
          break
        default:
      }
    }

    const element = inputRef.current
    element.addEventListener("keydown", onKeydown)

    return () => {
      element.removeEventListener("keydown", onKeydown)
    }
  }, [inputRef, setIndex, filteredGames, index, handleAdd, handleClickOutside])

  const list = query ? queryFiltered : filteredGames

  const dropdown = isOpen && (
    <GameDropdown list={list} index={index} handleAdd={handleAdd} />
  )

  return (
    <StyledAddGameWrapper ref={wrapperRef}>
      <StyledInput
        ref={inputRef}
        type="text"
        placeholder="Add a game"
        value={query || ""}
        onChange={(e) => setQuery(e.target.value)}
        onFocus={() => setIsOpen(true)}
      />
      {dropdown}
    </StyledAddGameWrapper>
  )
}
