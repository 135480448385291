// TODO: Clean up and move this to storybook
import { memo } from "react"
import { Game, IMAGE_TYPE } from "types/game"
import {
  StyledGameContent,
  StyledGameImg,
  StyledFigure,
  StyledPill,
  StyledPillText,
} from "./GameGroup.styles"

type Props = {
  games: Game[]
}

export const GameGroup = memo(({ games }: Props) => {
  const gamesTotal = games.length
  if (gamesTotal === 0) {
    return null
  }

  const gameImages: JSX.Element[] = []
  const maxTimesToLoop = gamesTotal > 4 ? 4 : gamesTotal

  for (let i = 0; i < maxTimesToLoop; i += 1) {
    // TODO: Update game images to a dictionary
    const squreIndex = games[i].images.findIndex(
      (img) => img.type === IMAGE_TYPE.SQUARE,
    )

    if (squreIndex > -1) {
      gameImages.push(
        <StyledFigure key={games[i].id.toString()}>
          <StyledGameImg
            src={games[i].images[squreIndex].url}
            alt={games[i].title}
          />
        </StyledFigure>,
      )
    }
  }

  const pillAmount = gamesTotal > 4 ? gamesTotal - 4 : 0

  return (
    <StyledGameContent>
      {gameImages}

      {pillAmount > 0 && (
        <StyledPill>
          <StyledPillText>+{pillAmount}</StyledPillText>
        </StyledPill>
      )}
    </StyledGameContent>
  )
})
