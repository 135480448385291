import styled from "styled-components"

export const StyledOverlay = styled.div`
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
`

export const StyledContent = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 5px 15px -3px rgba(103, 107, 224, 0.2);
  padding: 25px;
  position: absolute;
  top: 8px;
  right: 0px;
  width: 475px;
  z-index: 1;
`

export const StyledLabel = styled.label`
  color: #4a4a57;
  font-size: 13px;
  font-weight: 600;
`

export const StyledInnerContent = styled.div`
  margin: 6px 0 16px;
`

export const StyledInviteButton = styled.button`
  background: #5f96fa;
  border-radius: 4px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.25px;
  outline: none;
  padding: 12px 20px;
  transition: opacity 200ms ease-in-out;

  &:disabled {
    opacity: 0.6;
  }
`

export const StyledFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`

export const StyledInput = styled.input`
  border: 1px solid var(--border-default);
  border-radius: 4px;
  display: block;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  padding: 10px 12px;
  width: 100%;
  outline: none;
`
