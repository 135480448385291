import { StoreApi } from "zustand"
import type { FIXTURE_TYPE, Market as MarketType } from "@abios/abios-ts-sdk"
import { State } from "./State"

export const getMarketsByTypeAndId = (
  get: StoreApi<State>["getState"],
  type: FIXTURE_TYPE,
  id: number,
) => {
  const list: MarketType[] = []
  const { markets } = get()

  Object.values(markets).forEach((m) => {
    if (m.fixture.id === id && m.fixture.type === type) {
      list.push(m)
    }
  })

  return list
}
