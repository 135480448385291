import styled, { css } from "styled-components"

type HeaderProps = {
  $hasContent: boolean
  $hasSubTitle: string | undefined
}

export const Header = styled.header<HeaderProps>`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;

  ${({ $hasContent }) =>
    !$hasContent &&
    css`
      border-bottom: 1px solid #e3e8ee;
    `}

  ${({ $hasSubTitle }) =>
    $hasSubTitle &&
    css`
      grid-template-areas: "title subtitle button";
      grid-template-columns: min-content 1fr min-content;
    `}
`

export const H2 = styled.h2`
  font-weight: 700;
  grid-area: title;
  line-height: 32px;
  letter-spacing: 0.16;
  text-transform: capitalize;
`

export const SubTitle = styled.h3`
  color: var(--text-light-color);
  grid-area: subtitle;
  line-height: 32px;
  margin-left: 12px;
`
