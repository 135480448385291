import styled from "styled-components"

type StyledContainerProps = {
  background: string | undefined
  boxShadow: string | undefined
  height: string | undefined
  width: string | undefined
}

export const StyledContainer = styled.div<StyledContainerProps>`
  align-items: center;
  background-color: ${({ background }) => background || "inherit"};
  box-shadow: ${({ boxShadow }) => boxShadow || "none"};
  display: flex;
  border-radius: 6px;
  justify-content: center;
  height: ${({ height }) => height || "100%"};
  width: ${({ width }) => width || "100%"};
`

export const StyledLoader = styled.div`
  position: relative;
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

type LineProps = {
  size: number | undefined
}

export const StyledLine = styled.div<LineProps>`
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px rgba(95, 150, 250, 0.3);
  display: flex;
  height: ${({ size }) => size || "40"}px;
  justify-content: center;
  width: ${({ size }) => size || "40"}px;
`

type StyledLineMaskProps = {
  size: number | undefined
}

export const StyledLineMask = styled.div<StyledLineMaskProps>`
  animation: rotate 1.2s infinite cubic-bezier(0.3, 0.1, 0.7, 0.9);
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;

  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 1) 30%,
    rgba(0, 0, 0, 1) 70%,
    rgba(0, 0, 0, 0) 90%
  );

  transform-origin: ${({ size }) => (size ? size / 2 : "20")}px
    ${({ size }) => (size ? size / 2 : "20")}px;

  height: ${({ size }) => (size ? size / 2 : "20")}px;
  width: ${({ size }) => (size ? size / 2 : "20")}px;
`

type StyledCircleProps = {
  size: number | undefined
}

export const StyledCircle = styled.div<StyledCircleProps>`
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px rgba(95, 150, 250, 0.1);
  height: ${({ size }) => size || "40"}px;
  width: ${({ size }) => size || "40"}px;
`
