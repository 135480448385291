import styled, { css } from "styled-components"

type FigureProps = {
  isActive: boolean
  src: string
}

export const StyledContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

export const StyledFigure = styled.figure<FigureProps>`
  background: url(${({ src }) => src}) center center / cover no-repeat;
  border-radius: 50%;
  height: 24px;
  margin: 0;
  width: 24px;

  ${({ isActive }) =>
    !isActive &&
    css`
      filter: grayscale(0.6);
      opacity: 0.4;
    `}
`

export const StyledTooltip = styled.p`
  background-color: #242838;
  color: #fff;
  border-radius: 2px;
  position: absolute;
  z-index: 100;
  letter-spacing: 0;
  font-size: 0.688rem;
  font-weight: 600;
  padding: 8px;
  opacity: 0;
  top: 30px;
  transition: opacity 0.3s;
  text-align: center;
  visibility: hidden;
  white-space: nowrap;
  width: auto;

  ${StyledContent}:hover & {
    visibility: visible;
    opacity: 1;
  }

  :after {
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #242838 transparent;
    content: "";
    left: 50%;
    margin-left: -5px;
    position: absolute;
    top: -10px;
  }
`
