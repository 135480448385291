import axios from "axios"
import { StoreApi } from "zustand"
import { Match, PAGE_SIZE } from "@abios/abios-ts-sdk"
import { State } from "./State"
import { generateFilterIdsRequests } from "./Utils"

export const getMatchesByIds = async (
  set: StoreApi<State>["setState"],
  get: StoreApi<State>["getState"],
  matchIds: number[],
) => {
  const { matches } = get()
  const matchList: Match[] = []

  // check if matches exist in state
  const matchIdsLength = matchIds.length
  for (let i = matchIdsLength - 1; i >= 0; i -= 1) {
    if (matches[matchIds[i]] !== undefined) {
      matchList.push(matches[matchIds[i]])
      matchIds.splice(i, 1)
    }
  }

  if (matchIds.length === 0) {
    // We have all matches that we need
    return matchList
  }

  // Fetch series from Atlas API

  const url = `v3/matches?filter=id<=`
  const requests = generateFilterIdsRequests(url, PAGE_SIZE, matchIds)

  const newMatches: { [key: number]: Match } = {}
  try {
    const resp = await axios.all(requests)

    if (Array.isArray(resp)) {
      const respLength = resp.length
      for (let i = 0; i < respLength; i += 1) {
        if (resp[i].data && Array.isArray(resp[i].data)) {
          const respDataLength = resp[i].data.length
          for (let j = 0; j < respDataLength; j += 1) {
            newMatches[resp[i].data[j].id] = resp[i].data[j]

            // Add match to list that we return back
            matchList.push(resp[i].data[j])
          }
        }
      }
    }
  } catch (e) {
    throw new Error(e)
  }

  // Update store with all the new fetched series
  set({ matches: { ...matches, ...newMatches } })

  return matchList
}
