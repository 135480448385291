import { StoreApi } from "zustand"
import oddsAPI from "services/odds"
import { Label } from "pages/trading/types/Label"
import { AxiosResponse } from "axios"
import chunk from "lodash/chunk"
import { PAGE_SIZE } from "@abios/abios-ts-sdk"
import { State } from "./State"

export const fetchLabels = async (
  set: StoreApi<State>["setState"],
  get: StoreApi<State>["getState"],
  marketIds: number[],
) => {
  if (marketIds.length === 0) {
    return
  }

  // Check if label already exist in state
  const { label } = get()
  const idsToFetch: number[] = []

  const marketIdsLen = marketIds.length
  for (let i = 0; i < marketIdsLen; i += 1) {
    const labelState = label[marketIds[i]]
    if (labelState === undefined) {
      idsToFetch.push(marketIds[i])
    }
  }

  if (idsToFetch.length === 0) {
    return
  }

  const idsChunked = chunk(idsToFetch, PAGE_SIZE)

  for (let i = 0; i < idsChunked.length; i += 1) {
    const url = `markets/labels?order=market.id-desc&filter=market.id<={${idsChunked[
      i
    ].join()}}`

    try {
      // eslint-disable-next-line no-await-in-loop
      const resp: AxiosResponse<Label[]> = await oddsAPI.get(url)
      if (resp?.data) {
        const newLabels: { [key: number]: Label } = {}
        resp.data.forEach((obj) => {
          newLabels[obj.market.id] = obj
        })
        set((state) => ({
          ...state,
          label: { ...state.label, ...newLabels },
        }))
        if (resp.data.length < PAGE_SIZE) {
          break
        }
      }
    } catch (err) {
      throw new Error(err)
    }
  }
}
