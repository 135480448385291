import styled from "styled-components"

export const ChevronIconContent = styled.div`
  transition: transform 0.2s;
  transform: rotate(90deg); //point to down

  &[data-actives="false"] {
    //point to up
    transform: rotate(270deg);
  }
`
