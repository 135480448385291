import React, { useEffect, useCallback, useState, createContext } from "react"

import API from "../../../utils/api"

export const BillingContext = createContext()

export const BillingProvider = (props) => {
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState(null)
  const [errorMsg, setErrorMsg] = useState("")

  const GetBilling = useCallback(async () => {
    setIsFetching(true)

    try {
      let data = await API.get(`v1/customer/billing`)
      // Replace all nulls in response data with empty strings
      for (let [key, value] of Object.entries(data)) {
        if (key === "tax") {
          if (data.tax !== null && !data.tax.value) {
            data.tax["value"] = ""
          } else if (data.tax === null) {
            data.tax = {}
            data.tax["value"] = ""
          }
          continue
        }
        if (!value) data[key] = ""
      }
      setData(data)
    } catch (err) {
      setErrorMsg(err.message)
    }

    setIsFetching(false)
  }, [])

  useEffect(() => {
    GetBilling()
  }, [GetBilling])

  const handleInputChange = (e) => {
    let value = e.target.value
    if (!value) value = ""
    if (e.target.name === "vat") {
      setData({
        ...data,
        tax: {
          ...data.tax,
          value: value,
        },
      })
    } else {
      setData({
        ...data,
        [e.target.name]: value,
      })
    }
  }

  return (
    <BillingContext.Provider
      value={{ isFetching, data, errorMsg, handleInputChange }}>
      {props.children}
    </BillingContext.Provider>
  )
}
