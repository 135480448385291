import { Player, Roster, Series, Team } from "@abios/abios-ts-sdk"

// Passing teams and rosters instead of using store to avoid call hook conditionally
export const GetParticipantSide = (
  serie: Series,
  teams: {
    [key: number]: Team
  },
  rosters: {
    [key: number]: Roster
  },
) => {
  const firstSeed = serie.participants.find(
    (participant) => participant.seed === 1,
  )

  const secondSeed = serie.participants.find(
    (participant) => participant.seed === 2,
  )

  if (!firstSeed || !secondSeed) return []

  const firstSeedId = firstSeed.roster.id
  const secondSeedId = secondSeed.roster.id

  const firstSeedTeamId = rosters[firstSeedId]?.team?.id
  const secondSeedTeamId = rosters[secondSeedId]?.team?.id

  if (!firstSeedTeamId || !secondSeedTeamId) return []

  return [teams[firstSeedTeamId], teams[secondSeedTeamId]]
}

// Passing rosters and players instead of using store to avoid call hook conditionally
export const GetPlayerSide = (
  serie: Series,
  rosters: {
    [key: number]: Roster
  },
  players: {
    [key: number]: Player
  },
) => {
  const firstSeed = serie.participants.find(
    (participant) => participant.seed === 1,
  )

  const secondSeed = serie.participants.find(
    (participant) => participant.seed === 2,
  )

  if (!firstSeed || !secondSeed) return []

  const firstSeedId = firstSeed.roster.id
  const secondSeedId = secondSeed.roster.id

  const firstPlayerId = rosters[firstSeedId]?.line_up?.players[0].id
  const secondPlayerId = rosters[secondSeedId]?.line_up?.players[0].id

  if (!firstPlayerId || !secondPlayerId) return []

  const firstPlayer = players[firstPlayerId]
  const secondPlayer = players[secondPlayerId]

  if (!firstPlayer || !secondPlayer) return []

  return [firstPlayer, secondPlayer]
}
