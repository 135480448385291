import { useState, useEffect, useMemo, useCallback, useContext } from "react"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"

import { XCustomerContext } from "jsx/contexts/XCustomer"
import { CustomerContext } from "jsx/customer/CustomerContext"

import LoadingContainer from "jsx/components/LoadingContainer"
import { EmptyFeedback } from "pages/api/list/components"
import { Customer, CUSTOMER_STATUS } from "types/customer"
import { EXPIRED_DAYS } from "constants/dates"
import { Header } from "./components/Header"
import { HEADERS } from "./components/TableRow"
import { StyledTableHeader, StyledContent } from "./List.styles"
import { useCustomersFilter } from "./components/utils/filter"
import { useCustomers } from "./components/utils/customers"
import { AccountManagerProvider } from "./components/utils/account-manager"
import { byField } from "./components/utils/sort"
import {
  TAB,
  useTrackedFilterStore,
} from "./components/utils/useTrackedFilterStore"
import OverPaymentsTabs from "./components/Tabs"
import {
  getOverduePayments,
  getOverduePaymentsCustomerList,
  useInvoices,
} from "./components/utils/invoices"
import { EmptyState } from "./components/EmptyState"
import { CustomerList } from "./components/CustomerList"

const DEFAULT_PAGINATION = 20

const headings = HEADERS.map((header: string, index: number) => {
  const key = header + index // has two empty string headers
  return <StyledTableHeader key={key}>{header}</StyledTableHeader>
})

const ListCustomers = () => {
  const [setSearchParams] = useSearchParams()
  const [customersFilterUrl, customersFilter] = useCustomersFilter()

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)

  const xcustomer = useContext(XCustomerContext)
  const customerCon = useContext(CustomerContext)

  const location = useLocation()
  const navigate = useNavigate()

  const {
    setManagers,
    setCreatedFrom,
    setCreatedTo,
    setStatuses,
    setActiveDate,
    setSearch,
    setAllOverduePayments,
    statuses,
  } = useTrackedFilterStore()

  const { tab } = useParams()

  const {
    allInvoices,
    invoicesDic,
    isLoading: isInvoicesLoading,
    error: errorInvoices,
  } = useInvoices()

  const {
    allCustomers,
    filteredCustomers: customers,
    isLoading: isCustomersLoading,
    isFiltering: isCustomersFiltering,
    error: errorCustomers,
  } = useCustomers(customersFilterUrl)

  const onCustomerChange = useCallback(
    async (customer: Customer) => {
      await xcustomer.SetCustomer({
        ...customer,
        name: customer.external_name,
        public_id: customer.id,
        id: customer.internal_id,
      })
      await customerCon.FetchCustomer()
    },
    [xcustomer, customerCon],
  )

  // Client side filters of already server side filtered customers.
  // Sort the customers as well.
  const filteredCustomers = useMemo(
    () => customers?.filter(customersFilter).sort(byField("internal_name")),
    [customers, customersFilter],
  )

  // categorize customers based on the tabs
  const customersList = useMemo(() => {
    if (tab === TAB.OVERDUE_PAYMENTS) {
      return getOverduePaymentsCustomerList(invoicesDic, filteredCustomers)
    }
    return filteredCustomers
  }, [filteredCustomers, invoicesDic, tab])

  const fetchFilterParamsByUrl = () => {
    const accManagerQuery = setSearchParams.getAll("account_manager")

    // Active status
    const activeQuery = setSearchParams.get("active_indefinitely")
    const activeAfterQuery = setSearchParams.get("active_until_after")

    // Inactive status
    const inactiveQuery = setSearchParams.get("active_until_before")

    // Expired status
    const betweenFrom = setSearchParams.get("active_until_between_from")
    const betweenTo = setSearchParams.get("active_until_between_to")
    let createdBefQuery = setSearchParams.get("created_before")
    let createdAftQuery = setSearchParams.get("created_after")

    if (accManagerQuery.length) setManagers(accManagerQuery)

    if (createdBefQuery) {
      createdBefQuery = createdBefQuery.replace("T00:00:00.000Z", "")
      setCreatedTo(createdBefQuery)
    }

    if (createdAftQuery) {
      createdAftQuery = createdAftQuery.replace("T00:00:00.000Z", "")
      setCreatedFrom(createdAftQuery)
    }

    const statusesArr = [...statuses]
    if (activeQuery === "true" && activeAfterQuery)
      statusesArr.push(CUSTOMER_STATUS.ACTIVE)

    if (inactiveQuery) statusesArr.push(CUSTOMER_STATUS.INACTIVE)

    if (betweenFrom && betweenTo) {
      const activeDate: Date = new Date()
      activeDate.setDate(new Date().getDate() + EXPIRED_DAYS)

      setActiveDate(activeDate)
      statusesArr.push(CUSTOMER_STATUS.OVERDUE)
    }

    if (statusesArr.length) setStatuses(statusesArr)
  }

  useEffect(() => {
    // Run once to check if there are query parameters in the URL
    // and add that potential data into the store
    fetchFilterParamsByUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: customersFilterUrl,
    })
  }, [customersFilterUrl, navigate, location.pathname])

  // If there are new search results, reset the pagination
  useEffect(() => {
    setPagination(DEFAULT_PAGINATION)
  }, [customersList.length, setPagination])

  // update the overduePayments
  useEffect(() => {
    return setAllOverduePayments(getOverduePayments(allInvoices))
  }, [allInvoices, setAllOverduePayments])

  // check all necessary requirements before render.
  const isLoading = useMemo(
    () =>
      isCustomersFiltering ||
      (!customersFilterUrl && isCustomersLoading && isInvoicesLoading),
    [
      customersFilterUrl,
      isCustomersFiltering,
      isCustomersLoading,
      isInvoicesLoading,
    ],
  )

  const clearFilters = () => {
    setManagers([])
    setStatuses([])
    setSearch("")
    setCreatedFrom("")
    setCreatedTo("")
  }

  const gotoCreateCustomer = () => {
    navigate("/admin/customers/create")
  }

  if (errorCustomers) return <EmptyFeedback title={errorCustomers} />
  if (errorInvoices) return <EmptyFeedback title={errorInvoices} />

  customers.slice(0, pagination)

  const content = isLoading ? (
    <LoadingContainer />
  ) : (
    <>
      {!customersList.length && (
        <EmptyState
          clearFilters={clearFilters}
          gotoCreateCustomer={gotoCreateCustomer}
        />
      )}
      {customersList.length && (
        <CustomerList
          headings={headings}
          customersList={customersList}
          pagination={pagination}
          setPagination={setPagination}
          invoicesDic={invoicesDic}
          onCustomerChange={onCustomerChange}
        />
      )}
    </>
  )

  return (
    <AccountManagerProvider customers={allCustomers}>
      <StyledContent>
        <Header customers={allCustomers} isLoading={isCustomersLoading} />
        <OverPaymentsTabs />
        {content}
      </StyledContent>
    </AccountManagerProvider>
  )
}
export default ListCustomers
