/* eslint-disable @typescript-eslint/naming-convention */
import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosRequestHeaders,
} from "axios"
import { getAthenaSecret } from "utils/localStorage"

interface RetryConfig extends AxiosRequestConfig {
  retry: number
  retryDelay: number
}

export const GlobalRetryConfig: RetryConfig = {
  retry: 3,
  retryDelay: 5000,
}

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_ATLAS_API}/`,
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
  timeout: 20000,
})

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const conf = { ...config }

    const athenaId = getAthenaSecret()
    if (athenaId) {
      if (conf.headers === undefined) {
        conf.headers = {} as AxiosRequestHeaders
      }
      conf.headers["Abios-Secret"] = athenaId
    }

    return conf
  },
  (err) => {
    Promise.reject(err)
  },
)

instance.interceptors.response.use(
  (resp: AxiosResponse) => resp,
  async (err) => {
    if (err && err.response) {
      // unauthorized user
      if (err.response.status === 401) {
        localStorage.clear()
        window.location.href = "/login"
        return err
      }

      if (err.response.status === 429) {
        // if we hit a ratelimit from atlas we use the headers from the response
        // to figure out how long we should wait before making the request once again.
        const timeoutDelay =
          err.response.headers["x-ratelimit-reset"] ||
          err.config.retryDelay ||
          1000

        await new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve()
          }, timeoutDelay)
        })
        const rateLimitResponse = await instance(err.config)
        return rateLimitResponse
      }
    } else {
      // TODO: Network error
    }
    return err
  },
)

export default instance
