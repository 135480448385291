import styled, { keyframes } from "styled-components"

const loadingTextAnim = keyframes`
  0% { 
    opacity: 0;
  }

  30%, 70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }

`

export const LoadingText = styled.p<{ duration: number }>`
  color: var(--gray-400);
  animation: ${loadingTextAnim} ${({ duration }) => duration}ms ease-in-out
    infinite;
`
