import React from "react"
import styled from "styled-components"
import moment from "moment"

const Row = styled.tr`
  border-bottom: 1px solid var(--border-default);
  padding: 0 20px;
  line-height: 20px;
`
const Td = styled.td`
  padding: 15px 20px;
`
const Text = styled.p`
  color: var(--text-primary);
  font-size: 0.8125rem;
  font-weight: 400;
`

const TableRow = ({ user }) => (
  <Row>
    <Td>
      <Text>{user.email}</Text>
    </Td>
    <Td>
      <Text>
        {user.last_login_at
          ? moment.unix(user.last_login_at).format("YYYY-MM-DD")
          : "Never"}
      </Text>
    </Td>
    <Td></Td>
  </Row>
)

export default TableRow
