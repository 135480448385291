import { Checkbox, Input } from "@abios/smorgasbord-components"
import styled from "styled-components"

export const CreateCustomerContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 2rem;

  display: flex;
  justify-content: center;
`

export const ContentWrapper = styled.div`
  max-width: 800px;
  flex-grow: 1;
`

export const Title = styled.h1`
  font-size: var(--display-font-size-xs);

  margin-bottom: 2rem;
`

export const ContentRow = styled.div<{ mTop?: number; mBottom?: number }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: ${({ mTop }) => mTop || 0}rem;
  margin-bottom: ${({ mBottom }) => mBottom || 0}rem;

  & > span {
    // Width solution for dropdown which takes up 100% space.
    // Using width 0% makes sure they are split up even width.
    width: 0%;
    flex-grow: 1;
    min-width: 375px;

    margin-top: 1rem;
  }

  & > button {
    flex-grow: 1;
  }

  @media (width < 1109px) {
    & > span:nth-of-type(2) {
      margin-top: 2.5rem;
    }
  }
`

export const DropdownValueText = styled.p`
  color: var(--input-text-color);
  line-height: 20px;
`

export const StyledDropdownItem = styled.div`
  padding: 0.625rem 0.875rem;

  &:first-of-type {
    border-radius: 0.25rem 0.25rem 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 0.25rem 0.25rem;
  }

  &:hover {
    background: var(--gray-100);
  }
`

export const StyledCheckbox = styled(Checkbox)`
  box-sizing: content-box;
`

export const Paragraph = styled.p`
  font-size: var(--text-font-size-sm);
`

export const StyledDatePicker = styled(Input)`
  font-size: var(--text-font-size-sm);
`
