type TickIconProps = {
  color: string
}

export const TickIcon = ({ color }: TickIconProps) => (
  <svg width="8" height="6">
    <path
      d="M6.571.929a.427.427 0 0 0-.303.125L3.286 4.037 1.732 2.483A.427.427 0 0 0 1 2.786c0 .118.048.225.126.303l1.857 1.857a.427.427 0 0 0 .606 0L6.874 1.66a.429.429 0 0 0-.303-.732Z"
      fill={color}
      fillRule="nonzero"
      stroke={color}
      strokeWidth=".5"
    />
  </svg>
)
