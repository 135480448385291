import { useContext } from "react"
import { useGameStore } from "stores"
import { V3ResourcesActions } from "contexts/v3Resources"
import { Game } from "types/game"
import { AthenaProduct } from "@abios/abios-ts-sdk"

/**
 * useGamesWithProducts gets all available games and their products
 * @returns A record of gameIds and an object containing the game object and the available products
 */
export const useGamesWithProducts = (): {
  [gameId: number]: {
    game: Game
    products: AthenaProduct[]
  }
} => {
  const { games } = useGameStore()

  const v3ResActions = useContext(V3ResourcesActions)
  const products = v3ResActions.getProducts()

  const toProduct = (productId: string): AthenaProduct => {
    const find = products.find(({ id }) => id === productId)

    // This should never happen
    if (!find) throw new Error("Product not found")

    return find
  }

  const productGames: {
    [key: string]: number[]
  } = products.reduce(
    (a, { id }) => ({
      ...a,
      [id]: v3ResActions.getProductGamesById(id),
    }),
    {},
  )

  return Object.values(games).reduce(
    (a, game) => ({
      ...a,
      [game.id]: {
        game,
        products: Object.entries(productGames)
          .filter(([, _games]) => _games.includes(game.id))
          .map(([product]) => toProduct(product)),
      },
    }),
    {},
  )
}
