export const getSelectionHeaderTitles: { [title: string]: string } = {
  // Dota
  team_to_destroy_barrack: "Barrack",
  team_to_destroy_tower: "Tower",
  team_to_get_kill: "Kill",
  team_to_kill_roshan: "Roshan",

  // LOL
  team_to_kill_baron: "Baron",
  team_to_kill_dragon: "Dragon",
  team_to_destroy_inhibitor: "Inhibitor",
  team_to_kill_scuttler: "Scuttle Crab",
  team_to_destroy_turret: "Turret",

  // CSGO VALORANT
  total_player_bomb_defusals: "Bomb Defusals",
  total_player_bomb_plants: "Bomb Plants",
}
