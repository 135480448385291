import { MARKET_TYPE, Market } from "@abios/abios-ts-sdk"

export const GetPlayerIds = (markets: Market[]) => {
  const playerIds: number[] = []
  const supportedMarkets = [
    MARKET_TYPE.TOTAL_PLAYER_DEATHS,
    MARKET_TYPE.TOTAL_PLAYER_KILLS,
    MARKET_TYPE.TOTAL_PLAYER_BOMB_PLANTS,
    MARKET_TYPE.TOTAL_PLAYER_BOMB_DEFUSALS,
    MARKET_TYPE.TOTAL_PLAYER_GOALS,
  ]

  // Only find the markets that should contain player ids
  markets.forEach((market) => {
    if (!supportedMarkets.includes(market.type) || !market.context?.player?.id)
      return

    const entityId = market.context.player.id

    if (!playerIds.includes(entityId)) playerIds.push(entityId)
  })

  return playerIds
}
