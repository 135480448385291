import { StoreApi } from "zustand"
import { State } from "./State"

export const clear = (set: StoreApi<State>["setState"]) => {
  set({
    matches: {},
    rosters: {},
    series: {},
    teams: {},
    tournaments: {},
  })
}
