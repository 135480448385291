import React from "react"
import styled from "styled-components"

const Header = styled.header`
  margin-bottom: 50px;
`
const Title = styled.h1`
  margin-bottom: 12px;
`
const Text = styled.p`
  color: #a0aab8;
  font-size: 14px;
  line-height: 1.62em;
  margin-bottom: 20px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
`
const Emphasis = styled.span`
  color: #9292a6;
  font-weight: 600;
`

export const CompHeader = ({ title, text, subText, email }) => (
  <Header>
    <Title>{title}</Title>

    {email && subText ? (
      <>
        <Text>
          {text}
          <br />
          <Emphasis>{email}</Emphasis>
        </Text>

        <Text>{subText}</Text>
      </>
    ) : (
      <Text>{text}</Text>
    )}
  </Header>
)

export default CompHeader
