export enum ENDPOINTS_V1 {
  PRODUCT = "v1/product",
  PUSH_CHANNEL = "v1/push-channel",
  PACKAGE = "v1/package",
  CLIENT3 = "v1/client3",
  TOKEN3 = "v1/token3",
  CUSTOMER = "v1/customer",
  ACCOUNT_MANAGER = "v1/account-manager",
  ABIOS_INVITE = "v1/customer/abiosdev/agent",
  WIDGET_NAMESPACE = "v1/widget-namespace",
  INVOICES_OPEN = "v1/invoice/open",
}

// Urls where the public customer id needs to be added when requesting the API using the Authorization-Type header as Account-Manager
export const ACCOUNT_AGENT_SPECIFIC_URLS: string[] = [
  ENDPOINTS_V1.CLIENT3,
  ENDPOINTS_V1.WIDGET_NAMESPACE,
]

// Urls where Authorization-Type must be set to Account-Manager without any customer id added to the request
export const ACCOUNT_MANAGER_SPECIFIC_URLS: string[] = [
  ENDPOINTS_V1.CUSTOMER,
  ENDPOINTS_V1.ACCOUNT_MANAGER,
  ENDPOINTS_V1.INVOICES_OPEN,
]
