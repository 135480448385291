/* eslint-disable @typescript-eslint/naming-convention */
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios"

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_ATHENA_BACKEND}/v1/customer`,
  headers: {
    "Content-Type": "application/json",
    "Authorization-Type": "Customer-Agent",
  },
  responseType: "json",
  timeout: 20000,
})

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const conf = { ...config }

    if (conf.headers === undefined) {
      conf.headers = {} as AxiosRequestHeaders
    }

    const headers: AxiosRequestHeaders = {}
    headers["Authorization-Type"] = "Customer-Agent"

    // Add bearer token to request headers
    const authStorage = localStorage.getItem("auth_data")
    if (authStorage) {
      headers.Authorization = `Bearer ${JSON.parse(authStorage)}`
    }

    // Overwrite config headers with user-specified headers
    return {
      ...conf,
      headers: {
        ...conf.headers,
        ...headers,
      },
    }
  },
  (err) => {
    Promise.reject(err)
  },
)

export default instance
