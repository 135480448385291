import styled from "styled-components"

const StyledHeader = styled.header`
  align-items: center;
  display: flex;
  height: 80px;
`

export const Header = () => (
  <StyledHeader>
    <h1>Trading</h1>
  </StyledHeader>
)
