import { useState, useEffect } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { useAtlasStore, useOddsStore } from "pages/trading/stores"
import {
  FIXTURE_TYPE,
  GAMEID,
  Match,
  type Market as MarketType,
} from "@abios/abios-ts-sdk"
import { Dropdown } from "@abios/smorgasbord-components"
import { StyledDropdownItem } from "pages/customers/create/CreateCustomer.styles"
import { GroupMarketsByType } from "./utils"
import { MarketTemplate } from "./marketComponents/MarketTemplate"
import { GetParticipantSide, GetPlayerSide } from "./utils/GetParticipantSide"
import { NoMarkets } from "./components/NoMarkets"
import { orderMarkets } from "./utils/OrderMarkets"
import { GetPlayerIds } from "./utils/GetPlayerIds"
import { TAB } from "./types/tab"
import {
  StyledBetBuilderContainer,
  StyledBetBuilderDropdownSelect,
  StyledBetBuilderBetslip,
} from "./Markets.styles"

type Props = {
  matches: Match[]
}

export const MarketsBox = ({ matches }: Props) => {
  const { series, setPlayersByIds, teams, rosters, players } = useAtlasStore()
  const {
    markets,
    getMarketsByTypeAndId,
    selectedMarkets,
    onClearSelectedBets,
    onResetMatchOrder,
  } = useOddsStore()
  const { seriesId: seriesIdString } = useParams<string>()
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get("tab")
  const matchId = searchParams.get("matchid")

  const [groupedMarkets, setGroupedMarkets] = useState<
    Record<string, MarketType[]>
  >({})
  const seriesId = seriesIdString ? parseInt(seriesIdString) : undefined
  let type = FIXTURE_TYPE.SERIES
  let idOfInterest = seriesId

  if (tab === TAB.MATCH && matchId) {
    type = FIXTURE_TYPE.MATCH
    idOfInterest = parseInt(matchId)
  }

  if (tab === TAB.BETBUILDER && matchId) {
    type = FIXTURE_TYPE.MATCH
    idOfInterest = parseInt(matchId)
  }

  useEffect(() => {
    const getMarketIds = () => {
      if (!idOfInterest) return
      const list = getMarketsByTypeAndId(type, idOfInterest)

      // Find all the player ids of the markets
      setPlayersByIds(GetPlayerIds(list))

      const marketsOrdered = orderMarkets(list)
      setGroupedMarkets(GroupMarketsByType(marketsOrdered))
    }

    getMarketIds()
  }, [
    getMarketsByTypeAndId,
    idOfInterest,
    markets,
    setPlayersByIds,
    type,
    selectedMarkets,
  ])

  if (!seriesId) return <>No Series ID</>

  // BetBuilder demo code starts.

  const listOfMatches = matches.map((m) => {
    if (m.deleted_at !== null) {
      return null
    }
    return (
      <StyledDropdownItem
        onClick={() => {
          onClearSelectedBets()
          onResetMatchOrder()
          setSearchParams(
            { tab: TAB.BETBUILDER, matchid: m.id.toString() },
            { replace: true },
          )
          searchParams.delete("matchid")
        }}>
        Match {m.order.toString()}
      </StyledDropdownItem>
    )
  })

  const getSelectedMatch = matches.map(
    (m) =>
      matchId === m.id.toString() && (
        <StyledBetBuilderDropdownSelect>
          Match {m.order.toString()}
        </StyledBetBuilderDropdownSelect>
      ),
  )

  if (tab === TAB.BETBUILDER) {
    const [left, right] = GetParticipantSide(series[seriesId], teams, rosters)
    const allMarkets = Object.entries(groupedMarkets).map(
      ([groupId, grpMarkets]) => (
        <MarketTemplate
          key={groupId}
          markets={grpMarkets}
          leftCompetitor={left}
          rightCompetitor={right}
          isPlayer={false}
        />
      ),
    )

    return (
      <StyledBetBuilderContainer>
        <Dropdown
          title="Select match"
          inputHidden
          selectedValue={getSelectedMatch}>
          {listOfMatches}
        </Dropdown>
        {matchId && (
          <StyledBetBuilderBetslip>{allMarkets}</StyledBetBuilderBetslip>
        )}
      </StyledBetBuilderContainer>
    )
  }

  // BetBuilder demo code over.

  // No markets for this series / match
  if (!Object.keys(groupedMarkets).length) {
    return <NoMarkets />
  }
  const gameId = series[seriesId].game.id

  if (gameId !== GAMEID.ESOCCER) {
    const [left, right] = GetParticipantSide(series[seriesId], teams, rosters)

    const allMarkets = Object.entries(groupedMarkets).map(
      ([groupId, grpMarkets]) => (
        <MarketTemplate
          key={groupId}
          markets={grpMarkets}
          leftCompetitor={left}
          rightCompetitor={right}
          isPlayer={false}
        />
      ),
    )

    return <div>{allMarkets}</div>
  }

  const [left, right] = GetPlayerSide(series[seriesId], rosters, players)

  const allMarkets = Object.entries(groupedMarkets).map(
    ([groupId, grpMarkets]) => (
      <MarketTemplate
        key={groupId}
        markets={grpMarkets}
        leftCompetitor={left}
        rightCompetitor={right}
        isPlayer
      />
    ),
  )

  return <div>{allMarkets}</div>
}
