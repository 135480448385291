import { useToggle } from "@abios/abios-ts-sdk"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import athenaCustomer from "services/athenaCustomer"
import { cErr } from "utils/debug"
import { AccessAgreementDetails } from "../types/accessAgreementForm"
import {
  AccessAgreementCompany,
  AccessAgreementResponse,
  AccessAgreementTemplate,
} from "../types/accessAgreementResponse"
import {
  AccessAgreementErrorType,
  emptyAccessAgreementError,
} from "../types/accessAgreementValidation"
import { accessAgreementValidation } from "../utils/accessAgreementValidation"

const emptyAccessAgreement = {
  companyName: "",
  countryOfReg: "",
  companyNum: "",
  companyAddress: "",

  recipientName: "",
  recipientAddress: "",
  recipientEmail: "",

  signerCompany: "",
  signerName: "",
  signerRole: "",
  signerEmail: "",
}

export const useAccessAgreement = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [agreementDetails, setAgreementDetails] =
    useState<AccessAgreementDetails>(emptyAccessAgreement)

  const [validationErrors, setValidationErrors] =
    useState<AccessAgreementErrorType>(emptyAccessAgreementError)

  const [agreementTemplate, setAgreementTemplate] =
    useState<AccessAgreementTemplate>({} as AccessAgreementTemplate)

  const [isFetchingAgreement, setFetchingAgreement] = useState<boolean>(false)
  const [hasAgreementError, setAgreementError] = useState<boolean>(false)

  // Signing Handling
  const [isSigning, setIsSigning] = useState<boolean>(false)
  const [hasSignError, setSignError] = useState<boolean>(false)

  const { isActive: hasAccepted, toggle } = useToggle(false)

  const updateAgreementDetails = (
    key: keyof AccessAgreementDetails,
    value: string,
  ) => {
    setAgreementDetails((prev) => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  const setAgreementDetailsFromFetch = (company: AccessAgreementCompany) => {
    setAgreementDetails((prev) => {
      return {
        ...prev,
        companyName: company.name,
        companyNum: company.number,
        countryOfReg: company.registered_address.country,
        companyAddress: company.registered_address.address_line_1,
      }
    })
  }

  const fetchTrailAgreement = async () => {
    try {
      setFetchingAgreement(true)
      const trailAgreementURL = `/${id}/trial-agreement/unsigned`
      const { data } = await athenaCustomer.get<AccessAgreementResponse>(
        trailAgreementURL,
      )

      setAgreementDetailsFromFetch(data.agreement.parameters.company)

      setAgreementTemplate(data.agreement.template)
    } catch (e) {
      cErr(e)
      setAgreementError(true)
    } finally {
      setFetchingAgreement(false)
    }
  }

  const createAgreementPayload = () => {
    return {
      recipient: {
        name: agreementDetails.recipientName,
        physical_address: agreementDetails.recipientAddress,
        email_address: agreementDetails.recipientEmail,
      },
      signer: {
        name: agreementDetails.signerName,
        role: agreementDetails.signerRole,
        company_name: agreementDetails.signerCompany,
        email_address: agreementDetails.signerEmail,
      },
    }
  }

  useEffect(() => {
    fetchTrailAgreement()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSignAgreement = async () => {
    setValidationErrors(emptyAccessAgreementError)
    setSignError(false)

    const {
      error: hasError,
      key,
      message,
    } = accessAgreementValidation(agreementDetails)

    if (hasError) {
      setValidationErrors((prev) => {
        return {
          ...prev,
          [key]: message,
        }
      })

      return
    }

    const signAgreementPayload = createAgreementPayload()

    let hasSuccessfulSign = false
    try {
      setIsSigning(true)
      await athenaCustomer.post(
        `/${id}/trial-agreement/sign`,
        signAgreementPayload,
      )

      setAgreementDetails(emptyAccessAgreement)
      hasSuccessfulSign = true
    } catch (err) {
      setSignError(true)
    } finally {
      setIsSigning(false)
    }

    if (hasSuccessfulSign) {
      navigate("/")
    }
  }

  return {
    agreementDetails,
    agreementTemplate,
    updateAgreementDetails,
    onSignAgreement,
    hasAccepted,
    toggle,
    isFetchingAgreement,
    isSigning,
    hasSignError,
    validationErrors,
    hasAgreementError,
  }
}
