import styled from "styled-components"

import { ErrorableInput } from "./ErrorableInput"
import { UnitPickerInput } from "./UnitPicker/UnitPickerInput"

export const StyledCreate = styled.div`
  background: var(--color-white);
  width: 100%;
  height: 100%;
`

export const StyledContent = styled.div`
  display: flex;
  justify-content: center;

  margin: 3.5rem 0;
`

export const StyledContentRight = styled.div`
  padding: 0 3rem;
  h3 {
    margin-top: 2rem;
  }
`

export const StyledContentLeft = styled.div`
  padding: 0 3rem;
  border-right: 1px solid var(--border-default);
`

export const StyledFormRow = styled.div`
  display: flex;
  padding: 1rem 0;
  gap: 2rem;

  h6 {
    color: var(--heading-color);
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
  }
`

export const StyledError = styled.div`
  margin-bottom: 1rem;
  color: var(--error-color);
`

export const StyledForm = styled.form`
  width: 100%;
  max-width: 50rem;

  > h2,
  > h3 {
    flex: 1 1 100%;

    &:not(:first-child) {
      padding-top: 1.5rem;
    }
  }

  h6 {
    color: var(--text-secondary);
    margin: 1rem 0;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }
`

export const ErrorableInputBasis = styled(ErrorableInput)<{ basis?: number }>`
  flex: 1 1 calc(${({ basis = 50 }) => basis}% - 1rem);
  font-size: 0.813rem;

  h6 {
    font-size: 12px;
  }
`

export const UnitPickerInputBasis = styled(UnitPickerInput)<{ basis?: number }>`
  flex: 1 1 calc(${({ basis = 50 }) => basis}% - 1rem);
  font-size: 0.813rem;
`

export const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
`

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;

  padding-top: 0.25rem;
`

export const PreviewText = styled.h2`
  font-size: 12px;
  color: var(--text-secondary);
`
