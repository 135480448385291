import styled from "styled-components"

// eslint-disable-next-line import/no-extraneous-dependencies
import { Plus, Pencil } from "@styled-icons/bootstrap"

export const StyledInviteButton = styled.button`
  cursor: pointer;

  align-items: center;
  background: var(--primary-accent);
  border-radius: 4px;
  box-shadow: 0 5px 15px -3px rgba(103, 107, 224, 0.2);
  color: var(--text-white);
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.25px;
  outline: none;
  padding: 10px 14px;

  text-align: center;
  user-select: none;
`

export const StyledPlusIcon = styled(Plus)`
  color: var(--color-white);
  margin-right: 0.25rem;
`

export const StyledPencilIcon = styled(Pencil)`
  color: var(--color-white);
  margin-right: 0.25rem;
`
