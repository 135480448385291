import styled, { keyframes } from "styled-components"

const spinning = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const StyledModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: #3a3a3ad4;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledModalCard = styled.div`
  min-width: 400px;
  border-radius: 12px;
  background: white;
  padding: 1.5rem;
`

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  padding: 0.75rem 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  &[data-align="center"] {
    justify-content: center;
  }

  &[data-align="between"] {
    justify-content: space-between;
  }

  &[data-direction="column"] {
    flex-direction: column;
  }

  &[data-gap="1"] {
    gap: 1rem;
  }

  & svg[data-isCross="true"] {
    margin-top: calc(32px - 1.5rem);
    margin-right: calc(32px - 1.5rem);
    cursor: pointer;
    user-select: none;
  }
`

export const StyledTitle = styled.h1`
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--text-primary);
`

export const StyledSubtitle = styled.h2`
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--text-secondary);
  padding-top: 0.5rem;
`

export const StyledInput = styled.input`
  width: 100%;
  border: solid 1px var(--border-default);
  border-radius: var(--border-radius-3);
  padding: 0.625rem 0.875rem;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--text-primary);
  &::placeholder {
    color: var(--gray-500);
  }
  &[data-error="true"] {
    border: solid 1px var(--red-300);
  }
`

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-white);
  font-size: 14px;
  font-weight: 500;
  background-color: var(--primary-accent);
  padding: 0.625rem 1.125rem;
  border-radius: var(--border-radius-3);
  line-height: 24px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  width: 100%;

  &[data-type="normal"] {
    background: none;
    border: solid 1px var(--border-default);
    color: var(--text-primary);
  }

  &[data-type="delete"] {
    background-color: var(--red-600);
  }

  &:disabled {
    opacity: 0.7;
  }

  & > svg {
    animation: ${spinning} 2s linear infinite;
  }
`
export const StyledIcon = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--blue-100);
  border: solid 8px var(--blue-50);

  &[data-type="delete"] {
    background: var(--red-100);
    border: solid 8px var(--red-50);
  }
`

export const StyledErrorText = styled.p`
  margin-top: 0.5rem;
  color: var(--error-accent);
  font-size: 0.813rem;
  height: 16px;
`
