import { Dispatch } from "react"

import { Game } from "types/game"
import { CrossIcon } from "atoms/icons"
import { useGamesWithProducts } from "../util/useGamesWithProducts"

import { StateAction, STATE_ACTION } from "../util/formReducer"
import { CrossTd, GameTd, PermissionsTd, Tr } from "./ResourceAccess.styles"

type Props = {
  game: Game
  products: Record<string, boolean>
  dispatch: Dispatch<StateAction>
}

export const ResourceRow = ({
  game: { id, title, images },
  products,
  dispatch,
}: Props) => {
  const availableGames = useGamesWithProducts()

  const toggleableButtons = Object.entries(availableGames)
    .find(([, { game }]) => game.id === id)?.[1]
    .products.map(({ id: product, name }) => (
      <button
        key={product}
        type="button"
        className={products[product] ? "active" : ""}
        onClick={() =>
          dispatch({
            type: STATE_ACTION.TOGGLE_PRODUCT,
            game: id,
            product,
          })
        }>
        {name}
      </button>
    ))

  return (
    <Tr key={id}>
      <GameTd>
        <div>
          {images?.[0] && <img src={images[0].url} alt={title} />}
          <span>{title}</span>
        </div>
      </GameTd>
      <PermissionsTd>
        <div>{toggleableButtons}</div>
      </PermissionsTd>
      <CrossTd>
        <button
          type="button"
          onClick={() =>
            dispatch({ type: STATE_ACTION.DELETE_GAME, game: id })
          }>
          <CrossIcon
            width="0.75rem"
            height="0.75rem"
            color="var(--text-secondary)"
          />
        </button>
      </CrossTd>
    </Tr>
  )
}
