import React, { Component } from "react"

import LineSpinner from "./Spinners/LineSpinner"

class Table extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortingOrder: -1,
      sortingKey: props.sortingKey,
    }
  }

  sortRequestTable(key) {
    let { items } = this.props,
      { sortingOrder, sortingKey } = this.state

    if (key === sortingKey) {
      sortingOrder /= -1 // Invert order
    } else {
      sortingKey = key
      sortingOrder = -1
    }

    let sortedItems = items.sort((a, b) => {
      return (1 - (a[key] <= b[key]) - (a[key] < b[key])) * sortingOrder
    })

    this.setState({
      items: sortedItems,
      sortingOrder: sortingOrder,
      sortingKey: sortingKey,
    })
  }

  paginateTable(direction) {
    const { updateCurrentPage, pageCurrent } = this.props
    let page = pageCurrent

    if (direction === "prev") page--
    else page++

    // Send back current page
    updateCurrentPage(page)
  }

  render() {
    let {
        columns,
        pageSize,
        items,
        listItem,
        isFetching,
        tableFooter,
        pageCurrent,
        tableShowingCountView,
      } = this.props,
      { sortingKey, sortingOrder } = this.state

    let tableList = null
    let pageLast = Math.floor(items.length / pageSize)

    let tableStart = pageCurrent * pageSize,
      tableEnd = tableStart + pageSize

    if (tableEnd > items.length) {
      tableEnd = items.length
    }

    let tableHeader = columns.map((column, i) => {
      // Leave arrow down for latency_ms column
      if (column.sortable) {
        return (
          <div
            key={i}
            className={`table-cell ${column.key}`}
            onClick={this.sortRequestTable.bind(this, column.key)}>
            {column.name}
            <div
              data-active={sortingKey === column.key}
              data-order={sortingOrder}>
              {" "}
            </div>
          </div>
        )
      } else {
        return (
          <div key={i} className={`table-cell ${column.key}`}>
            {column.name}
          </div>
        )
      }
    })

    if (isFetching) {
      tableList = (
        <li className="table-row loading">
          <div className="loader-container">
            <LineSpinner />
          </div>
        </li>
      )
    } else if (items.length) {
      tableList = items.slice(tableStart, tableEnd).map((item, i) => {
        return listItem(item, i)
      })
    }

    return (
      <div className="table-container">
        <div className="table-header">{tableHeader}</div>

        {tableList && <ul className="table">{tableList}</ul>}

        <div className="table-footer">
          {tableShowingCountView ? (
            <p>
              Showing{" "}
              <span>
                {items && items.length > 0 && tableStart === 0
                  ? 1
                  : tableStart + 1}
              </span>{" "}
              - <span>{tableEnd}</span> of{" "}
              {items && items.length > 0 ? items.length : 0} {tableFooter}
            </p>
          ) : (
            <p></p>
          )}

          <div className="pagination-group">
            <button
              onClick={
                pageCurrent !== 0 ? this.paginateTable.bind(this, "prev") : null
              }
              data-disable={pageCurrent === 0}>
              Prev
            </button>

            <span>{pageCurrent + 1}</span>

            <button
              onClick={
                pageCurrent !== pageLast
                  ? this.paginateTable.bind(this, "next")
                  : null
              }
              data-disable={pageCurrent === pageLast}>
              Next
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Table
