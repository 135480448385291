import { useRef, useState } from "react"
import { useOnClickOutside } from "hooks"
import styled, { css } from "styled-components"

const StyledWrapper = styled.div`
  position: relative;
  font-size: 10px;
  color: white;

  > div:first-of-type {
    background: var(--primary-accent);
    padding-left: 8px;
    border-radius: 4px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-width: 4.25rem;
    cursor: pointer;
  }
`

const CHEVRON_BASE = css`
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(0.5);
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;

  &::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    left: 4px;
    top: 2px;
  }
`

const ChevronDown = styled.span`
  ${CHEVRON_BASE}

  &::after {
    transform: rotate(45deg);
  }
`

const ChevronUp = styled.span`
  ${CHEVRON_BASE}
  &::after {
    transform: rotate(-135deg);
    top: 6px;
  }
`

const Dropdown = styled.div`
  position: absolute;
  z-index: 99;
  padding: 4px;
  border-radius: 3px;
  background-color: var(--primary-accent);
  top: 100%;
  margin-top: 4px;
  right: 0;
  display: flex;
  flex-direction: column;

  > button {
    font-size: 12px;
    padding: 10px;
    border-radius: 4px;
    user-select: none;
    text-align: left;

    cursor: pointer;
    &:hover {
      background-color: #2563eb;
    }
  }
`

export const UNITS = {
  hours: "hours",
  days: "days",
  months: "months",
  years: "years",
} as const

export type Unit = typeof UNITS[keyof typeof UNITS]

type Props = {
  unit: Unit
  onChange: (unit: Unit) => void
}

export const UnitPicker = ({ unit, onChange }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  useOnClickOutside(ref, () => setIsVisible(false))

  const dropdown = isVisible ? (
    <Dropdown>
      {Object.values(UNITS).map((label) => (
        <button key={label} type="button" onClick={() => onChange(label)}>
          {label}
        </button>
      ))}
    </Dropdown>
  ) : null

  const icon = isVisible ? <ChevronUp /> : <ChevronDown />

  return (
    <StyledWrapper ref={ref} onClick={() => setIsVisible((v) => !v)}>
      <div>
        <span>{unit}</span>
        {icon}
      </div>
      {dropdown}
    </StyledWrapper>
  )
}
