import React, { useState, useEffect, useContext, createContext } from "react"

import API from "../utils/api"

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState(null)
  const [hasFetched, setHasFetched] = useState(false)

  useEffect(() => {
    const fetchUser = async () => {
      setIsFetching(true)
      try {
        const d = await API.get("v1/user")
        setData(d)
      } catch (err) {
        // FIXME:
        console.error("err", err)
      }
      setIsFetching(false)
    }

    fetchUser()
  }, [])

  const Clear = () => setData(null)

  return (
    <UserContext.Provider value={{ isFetching, data, Clear }}>
      {children}
    </UserContext.Provider>
  )
}
