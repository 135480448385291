import {
  Alert,
  ALERT_TYPE,
  Button,
  INFO_PAGE_TYPE,
  Input,
} from "@abios/smorgasbord-components"
import LoadingContainer from "jsx/components/LoadingContainer"
import { LoadingState } from "molecules/LoadingState/LoadingState"
import { useAccessAgreement } from "./hooks/useAccessAgreement"
import { AccessAgreementTemplate } from "./templates/AccessAgreementTemplate"
import {
  AbiosLogo,
  AbiosTitle,
  AccessAgreementEmptyStateError,
  AgreementHeader,
  AnimContainer,
  ContentContainer,
  ContentRow,
  FullPageContainer,
  FullPageWrapper,
  SigningLoadingContainer,
  SigningLoadingText,
  StyledCheckbox,
  TitleSections,
  TrailAgreementContainer,
  TrialFromContainer,
} from "./TrailAgreement.styles"

const loadingOptions = [
  "Slaying Baron Nashor ...",
  "Farming Ancients ...",
  "Waiting for minions to spawn ...",
  "Planting Spike ...",
  "Dying to Tormentor ...",
  "Fighting off Scuttle Crab ...",
  "Running with knife ...",
  "Waiting for respawn ...",
  "Initiating Zerg rush ...",
  "Starting 1 billion eSoccer matches ...",
]

export const TrailAgreement = () => {
  const {
    agreementTemplate,
    agreementDetails,
    updateAgreementDetails,
    hasAccepted,
    toggle,
    isFetchingAgreement,
    onSignAgreement,
    isSigning,
    hasSignError,
    validationErrors,
    hasAgreementError,
  } = useAccessAgreement()

  const errorSignContent = hasSignError && (
    <ContentRow mBottom={1.5}>
      <Alert type={ALERT_TYPE.ERROR}>
        An unexpected error occurred while attempting to sign the Trail Access
        Agreement. Please try again soon, and if the problem persists contact
        your account manager.
      </Alert>
    </ContentRow>
  )

  const agreementErrorContent = hasAgreementError && (
    <AccessAgreementEmptyStateError
      title="Loading Access Trial Agreement"
      text="An unexpected error occurred while attempting to retrieve your Access Trial
      Agreement. Please try to refresh the page soon, and if the problem persists contact
      your account manager."
      type={INFO_PAGE_TYPE.ERROR}
      hideButton
    />
  )

  const signingLoadingContent = isSigning && (
    <SigningLoadingContainer>
      <SigningLoadingText>Signing Access Trial Agreement</SigningLoadingText>
      <LoadingState loadingList={loadingOptions} />
      <LoadingContainer />
    </SigningLoadingContainer>
  )

  const formContent = (
    <TrialFromContainer>
      {errorSignContent}

      <AnimContainer animDelay={50}>
        <AgreementHeader>Company</AgreementHeader>
      </AnimContainer>

      <AnimContainer animDelay={150}>
        <ContentRow mTop={1.5}>
          <Input
            title="Company Name"
            value={agreementDetails.companyName}
            disabled
            onChange={() => {}}
          />
          <Input
            title="Country of Registration"
            value={agreementDetails.countryOfReg}
            disabled
            onChange={() => {}}
          />
        </ContentRow>
      </AnimContainer>

      <AnimContainer animDelay={200}>
        <ContentRow mTop={2} mBottom={2}>
          <Input
            title="Company Number"
            value={agreementDetails.companyNum}
            disabled
            onChange={() => {}}
          />
          <Input
            title="Registered Address"
            value={agreementDetails.companyAddress}
            disabled
            onChange={() => {}}
          />
        </ContentRow>
      </AnimContainer>

      <AnimContainer animDelay={250}>
        <AgreementHeader>Recipient</AgreementHeader>
      </AnimContainer>

      <AnimContainer animDelay={300}>
        <ContentRow mTop={1.5}>
          <Input
            title="Name"
            value={agreementDetails.recipientName}
            hint={validationErrors.recipientName}
            warned={!!validationErrors.recipientName}
            onChange={(e) =>
              updateAgreementDetails("recipientName", e.target.value)
            }
          />
          <Input
            title="Address"
            value={agreementDetails.recipientAddress}
            hint={validationErrors.recipientAddress}
            warned={!!validationErrors.recipientAddress}
            onChange={(e) =>
              updateAgreementDetails("recipientAddress", e.target.value)
            }
          />
        </ContentRow>
      </AnimContainer>

      <AnimContainer animDelay={350}>
        <ContentRow mTop={2} mBottom={2}>
          <Input
            title="Email"
            value={agreementDetails.recipientEmail}
            hint={validationErrors.recipientEmail}
            warned={!!validationErrors.recipientEmail}
            onChange={(e) =>
              updateAgreementDetails("recipientEmail", e.target.value)
            }
          />
        </ContentRow>
      </AnimContainer>

      <AnimContainer animDelay={400}>
        <AgreementHeader>Signer</AgreementHeader>
      </AnimContainer>

      <AnimContainer animDelay={450}>
        <ContentRow mTop={1.5}>
          <Input
            title="Company Name"
            value={agreementDetails.signerCompany}
            hint={validationErrors.signerCompany}
            warned={!!validationErrors.signerCompany}
            onChange={(e) =>
              updateAgreementDetails("signerCompany", e.target.value)
            }
          />
        </ContentRow>
      </AnimContainer>

      <AnimContainer animDelay={500}>
        <ContentRow mTop={2}>
          <Input
            title="Name"
            value={agreementDetails.signerName}
            hint={validationErrors.signerName}
            warned={!!validationErrors.signerName}
            onChange={(e) =>
              updateAgreementDetails("signerName", e.target.value)
            }
          />
          <Input
            title="Role"
            value={agreementDetails.signerRole}
            hint={validationErrors.signerRole}
            warned={!!validationErrors.signerRole}
            onChange={(e) =>
              updateAgreementDetails("signerRole", e.target.value)
            }
          />
        </ContentRow>
      </AnimContainer>

      <AnimContainer animDelay={550}>
        <ContentRow mTop={2}>
          <Input
            title="Email"
            value={agreementDetails.signerEmail}
            hint={validationErrors.signerEmail}
            warned={!!validationErrors.signerEmail}
            onChange={(e) =>
              updateAgreementDetails("signerEmail", e.target.value)
            }
          />
        </ContentRow>
      </AnimContainer>

      <AnimContainer animDelay={600}>
        <ContentRow mTop={2}>
          <StyledCheckbox checked={hasAccepted} onToggle={toggle} />
          <p>I have read and agree to the Terms and Conditions</p>
        </ContentRow>

        <ContentRow mTop={1}>
          <Button onClick={onSignAgreement} disabled={!hasAccepted}>
            Confirm
          </Button>
        </ContentRow>
      </AnimContainer>
    </TrialFromContainer>
  )

  return (
    <FullPageContainer>
      <FullPageWrapper>
        <TitleSections>
          <AbiosLogo
            src="https://img.abiosgaming.com/other/abios-logo-blue-text.png"
            alt="abios logo"
          />
          <AbiosTitle>Access Trial Agreement</AbiosTitle>
        </TitleSections>

        {isFetchingAgreement && <LoadingContainer />}

        {agreementErrorContent}

        {!isSigning && !isFetchingAgreement && !hasAgreementError && (
          <ContentContainer>
            <AnimContainer>
              <TrailAgreementContainer>
                <AccessAgreementTemplate template={agreementTemplate} />
              </TrailAgreementContainer>
            </AnimContainer>

            {formContent}
          </ContentContainer>
        )}

        {signingLoadingContent}
      </FullPageWrapper>
    </FullPageContainer>
  )
}
