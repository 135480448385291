import { create } from "zustand"
import { createTrackedSelector } from "react-tracked"
import { SignOut } from "./Signout"
import { State } from "./State"
import { checkIsAuthed } from "./CheckIsAuthed"
import { signIn } from "./SignIn"
import { getTradingToken } from "./getTradingToken"

const useStore = create<State>((set) => ({
  hasTradingAccess: false,
  isAuthed: false,

  // Methods
  checkIsAuthed: () => checkIsAuthed(set),
  signIn: (token) => signIn(set, token),
  signOut: () => SignOut(set),
  // Renew trading token
  getTradingToken: () => getTradingToken(set),
  resetTradingAccess: () => set({ hasTradingAccess: false }),
}))

export const useAuthStore = createTrackedSelector(useStore)
