import { ChevronIcon } from "atoms/icons"
import {
  StyledAbortButton,
  StyledHeader,
  StyledSubmitButton,
} from "./Header.styles"

type Props = {
  readonly title: string
  /** Methods */
  onAbort: () => void
  onSubmit: () => void
  submitBtnText: string
}

export const CreateHeader = ({
  title,
  onAbort,
  onSubmit,
  submitBtnText,
}: Props) => (
  <StyledHeader>
    <StyledAbortButton onClick={onAbort}>
      <ChevronIcon size="16" />
    </StyledAbortButton>
    <h3>{title}</h3>
    <StyledSubmitButton onClick={onSubmit}>{submitBtnText}</StyledSubmitButton>
  </StyledHeader>
)
