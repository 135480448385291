import { useContext } from "react"

import { V3ResourcesActions } from "contexts/v3Resources"
import { useGameStore } from "stores"

import { Game } from "types/game"
import { GameResource, Resource } from "../../single/sidebar/resources"
import { Package } from "./formReducer"

const packageContainsProduct = ({ products }: Package, id: string): boolean =>
  products[id]

const gameToGameResources = (
  a: Record<number, GameResource>,
  game: Game,
): Record<number, GameResource> => ({
  ...a,
  [game.id]: {
    ...game,
    isActive: true,
  },
})

const resourceHasGames = ({ games }: Resource): boolean =>
  Object.entries(games).length > 0

/**
 * useResourceAccess takes a list of (game, products) tuples and returns a list of resources (product, games) tuples.
 * @param packages a list of (game, product) tuples
 * @returns a list of (product, games) tuples
 * @example
 * const resources = useResourceAccess([{
 *  game: 1,
 *  products: {
 *    "abc": true,
 *    "bca": false,
 *  },
 * },{
 *  game: 2,
 *  products: {
 *    "abc": true,
 *  },
 * }])
 * assert(resources, [{
 *  id: "abc",
 *  title: "...", // The product title for product "abc"
 *  games: {
 *    1: {  } // The game object for game 1
 *    2: {  } // The game object for game 2
 *  }
 * }])
 */
export const useResourceAccess = (packages: Package[]): Resource[] => {
  const { games } = useGameStore()
  const v3ResActions = useContext(V3ResourcesActions)
  const products = v3ResActions.getProducts()

  const packageToGame = ({ game }: Package): Game => games[game]
  const packageToGameResources = (
    productId: string,
  ): Record<number, GameResource> =>
    packages
      .filter((pkg) => packageContainsProduct(pkg, productId)) // Keep if current package contains this product
      .map(packageToGame)
      .reduce(gameToGameResources, {})

  const resources: Resource[] = products
    .map(({ id, name: title }) => ({
      id,
      title,
      games: packageToGameResources(id),
    }))
    .filter(resourceHasGames) // Only show resources that are currently in use

  return resources
}
