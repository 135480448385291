import styled from "styled-components"

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`

export const StyledTitle = styled.h1`
  font-size: 18px;
  color: var(--text-primary);
`

export const StyledSubtitle = styled.p`
  font-size: 14px;
  color: var(--text-secondary);
`
