import styled from "styled-components"

export const NoWidgetFallbackContainer = styled.div`
  padding: 2.5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`

export const WidgetFallbackIcon = styled.div`
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background: var(--red-100);
  border: solid 10px var(--red-50);
  display: flex;
  justify-content: center;
  align-items: center;
`
