import styled from "styled-components"

export const StyledBetBuilderContainer = styled.div`
  flex-direction: row;
  padding-top: 2rem;
`

export const StyledBetBuilderBetslip = styled.div`
  padding-top: 2rem;
`

export const StyledBetBuilderDropdownSelect = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  font-color: var(--text-primary);
`
