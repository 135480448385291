import styled from "styled-components"

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 1rem;
`

export const StyledBetTitle = styled.p`
  font-size: 13px;
  font-weight: bold;
`

export const StyledBetSubtitle = styled(StyledBetTitle)`
  color: var(--text-secondary);
  font-size: 11px;
  font-weight: normal;
`

export const StepLine = styled.div`
  width: 1px;
  height: calc(100% - 12px);
  background: var(--gray-500);
`

export const StyledBet = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;

  &:first-of-type {
    padding-top: 0.25rem;
  }

  &:last-of-type {
    ${StyledContent} {
      padding-bottom: 0;
    }

    ${StepLine} {
      display: none;
    }
  }
`

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StepCircle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: solid 1px var(--gray-500);
`

export const Cross = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  padding: 0.5rem;
  transition: background 100ms ease-in;

  align-self: flex-start;
  position: absolute;
  right: 1rem;

  &:hover {
    background: var(--gray-100);
  }
`

export const BetSpan = styled.span`
  padding: 0.25rem;
  border-radius: 0.25rem;
  background: var(--blue-500);
  color: var(--text-white);
  font-weight: 600;
  margin-right: 0.25rem;
  font-size: 12px;
`
