import styled from "styled-components"

export const WidgetListContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  &:last-child {
    margin-bottom: 2rem;
  }
`

export const WidgetListHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  border-radius: var(--border-radius-1) var(--border-radius-1) 0 0;
  & > p {
    font-size: 0.875rem;
    color: var(--text-secondary);
  }
`
