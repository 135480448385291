import React from "react"

const PageLoader = () => (
  <div className="page-loader">
    <div className="loader">
      <div className="circle"></div>
      <div className="line-mask">
        <div className="line"></div>
      </div>
    </div>
  </div>
)

export default PageLoader
