import styled, { css } from "styled-components"
import { StyledInput as RootStyledInput } from "../Inputs.styles"

export const StyledInput = styled(RootStyledInput)`
  padding: 10px 12px;
  font-size: 12px;
  line-height: 14px;

  &::placeholder {
    color: var(--text-light-color);
  }
`

const GAME_ROW = css`
  display: flex;
  align-items: center;
  padding: 4px 0;

  > img {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    user-select: none;
  }

  > span {
    font-size: 12px;
    color: var(--text-primary-color);
    font-weight: 500;
  }
`

export const StyledAddGameWrapper = styled.div`
  margin-top: 1rem;
`

export const Dropdown = styled.div`
  position: absolute;
  margin-top: 0.25rem;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 5px 15px -3px rgba(103, 107, 224, 0.2);
  border-radius: 0.25rem;
  border: 1px solid #e1e5ea;
  padding: 0.4rem;
  gap: 0.25rem;
  max-height: 300px;
  overflow-y: auto;
  min-width: 240px;

  > button {
    text-align: left;
    border-radius: 2px;
    padding: 0.25rem;
    padding-right: 1rem;
    transition: all 0.1s ease-in-out;

    > div {
      ${GAME_ROW}

      gap: 0.5rem;
    }

    &:not(.included) {
      &:hover,
      &.active {
        background-color: var(--primary-accent);

        > div > span {
          color: white;
        }
      }
    }

    &.included {
      cursor: not-allowed;

      > div > span {
        color: #a0a8b8;
      }
    }
  }
`
