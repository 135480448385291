import { AgreementSectionContainer } from "../atoms/AgreementSection.styles"
import { AgreementSectionNumber } from "../atoms/AgreementSectionNumber.styles"
import { AccessAgreementParties as AccessAgreementPartiesType } from "../types/accessAgreementResponse"

type Props = {
  parties: AccessAgreementPartiesType
}

export const AccessAgreementParties = ({ parties }: Props) => {
  const { abios, customer } = parties

  const renderAbiosBody = abios.body.map((text) => <p key={text}>{text}</p>)

  const renderCustomerBody = customer.body.map((text) => (
    <p key={text}>{text}</p>
  ))

  return (
    <>
      <AgreementSectionContainer>
        <AgreementSectionNumber>{abios.number}</AgreementSectionNumber>
        {renderAbiosBody}
      </AgreementSectionContainer>

      <AgreementSectionContainer>
        <AgreementSectionNumber>{customer.number}</AgreementSectionNumber>
        {renderCustomerBody}
      </AgreementSectionContainer>
    </>
  )
}
