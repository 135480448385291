import React from "react"

const LineSpinner = () => (
  <div className="loader">
    <div className="circle"></div>
    <div className="line-mask">
      <div className="line"></div>
    </div>
  </div>
)

export default LineSpinner
