import { useCallback, ReactNode } from "react"
import xor from "lodash/xor"
import { EXPIRED_DAYS } from "constants/dates"
import { useToggle } from "hooks/useToggle"
import { ExpandIcon } from "atoms/expandIcon"
import {
  FilterRowContent,
  FilterRowElement,
  StyledButtonTitleContent,
} from "./FilterRow"

export type FilterCategoryOption<T> = {
  label: ReactNode
  value: T
}

type Props<T> = {
  header: ReactNode
  options: FilterCategoryOption<T>[]
  values: T[]
  setStatusDate?: (value: Date) => void
  setActiveDate?: (value: Date) => void
  onChange: (v: T[]) => void
}

export const FilterCategory = <T extends string | number>({
  header,
  options,
  values,
  setStatusDate,
  setActiveDate,
  onChange,
}: Props<T>) => {
  const {
    isActive: isOpen,
    open: onOpen,
    toggle: toggleOpen,
  } = useToggle(values.length > 0)

  const toggleAll = useCallback(() => {
    onOpen()

    if (values.length === options.length) {
      onChange([])
      return
    }

    onChange(options.map(({ value }) => value))
  }, [onOpen, values.length, options, onChange])

  const toggleActivated = useCallback(
    (v: T) => {
      if (setStatusDate && setActiveDate) {
        const start: Date = new Date()
        const activeDate: Date = new Date()
        start.setUTCHours(0, 0, 0, 0)
        activeDate.setUTCHours(0, 0, 0, 0)
        activeDate.setDate(activeDate.getDate() + EXPIRED_DAYS)

        setStatusDate(start)
        setActiveDate(activeDate)
      }
      const newVal = xor(values, [v])
      onChange(newVal)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, values],
  )

  const content = isOpen
    ? options.map(({ label, value }) => (
        <FilterRowElement key={value}>
          <label>
            <input
              type="checkbox"
              checked={!!values?.includes(value)}
              onChange={() => toggleActivated(value)}
            />
            {label}
          </label>
        </FilterRowElement>
      ))
    : null

  const isAllChecked = values.length === options.length

  return (
    <FilterRowContent>
      <button type="button" onClick={toggleOpen}>
        <StyledButtonTitleContent>
          <input
            // Without a new unique key, the checkbox is not updated
            key={+isAllChecked}
            type="checkbox"
            checked={isAllChecked}
            onChange={toggleAll}
          />
          {header}
        </StyledButtonTitleContent>
        <ExpandIcon isActive={isOpen} />
      </button>
      {content}
    </FilterRowContent>
  )
}
