import styled, { css } from "styled-components"
import { CopyIcon } from "icons/Copy"
import { CheckIcon } from "icons/Check"

export const StyledContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  position: relative;
  width: 280px;
`

export const StyledInput = styled.input`
  border: 1px solid var(--border-default);
  border-radius: 4px;
  cursor: pointer;
  color: var(--text-primary);
  font-family: var(--font-family-mono);
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  padding: 4px 6px;
  outline: none;
  transition: all 200ms ease-in;
  width: 100%;

  &:disabled {
    background-color: #fff;
    cursor: default;
  }

  &[data-is-copied="true"] {
    border: 1px solid #5f96fa;
    box-shadow: 0 0 0 2px rgba(95, 150, 250, 0.2);
  }

  &[data-blurry-text="true"] {
    border: 1px solid #fff;

    ::placeholder {
      color: transparent;
      text-shadow: 0 0 6px #606870;
      user-select: none;
    }
  }
`

export const StyledRevealButton = styled.button`
  background: #fff;
  border: 1px solid var(--border-default);
  border-radius: 4px;
  color: var(--text-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 8px 16px;
  position: absolute;
  left: 0;

  opacity: 0;
  visibility: hidden;

  &[data-isvisible="true"] {
    opacity: 1;
    visibility: visible;
  }
`

export const StyledInputCheckIcon = styled(CheckIcon)<{ $isVisible: boolean }>`
  cursor: pointer;
  display: block;
  fill: #485065;
  height: 12px;
  right: 8px;
  position: absolute;
  transform: translateY(-55%);
  width: 10px;

  opacity: 0;
  visibility: hidden;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

export const StyledTooltip = styled.div`
  background-color: #242838;
  border: 1px solid #242838;
  border-radius: 4px;
  padding: 4px 6px;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  transition: opacity 0.3s;
  width: auto;
  z-index: 100;

  opacity: 0;
  visibility: hidden;

  top: -32px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  &[data-isvisible="true"] {
    ${StyledContent}:hover & {
      opacity: 1;
      visibility: visible;
    }
  }

  p {
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    padding-left: 6px;
  }
`

export const StyledTooltipCheckIcon = styled(CheckIcon)`
  display: inline-block;
  fill: #fff;
  height: 12px;
  transform: translateY(-10%);
  width: 10px;
`

export const StyledToolipCopyIcon = styled(CopyIcon)`
  display: inline-block;
  height: 10px;
  transform: translateY(10%);
  width: 10px;
`
