export const TOKEN_STORAGE_NAME = "auth_data"

export type State = {
  isAuthed: boolean
  hasTradingAccess: boolean

  /** Methods */
  checkIsAuthed: () => void
  signIn: (token: string) => void
  signOut: () => void
  getTradingToken: () => Promise<void>
  // Reset value for trading access
  resetTradingAccess: () => void
}
