import { ChangeEvent, KeyboardEvent, ReactNode } from "react"
import styled from "styled-components"

import {
  ErrorText,
  StyledInput,
  StyledLabel as RootStyledLabel,
} from "./Inputs.styles"

const dateFormat = new Intl.DateTimeFormat("sv-SE")

const StyledLabel = styled(RootStyledLabel)`
  ${ErrorText} {
    visibility: none;
  }

  min-width: 8rem;

  &[data-error="true"] {
    ${ErrorText} {
      visibility: visible;
    }

    input {
      border: 1px solid var(--error-accent);
    }
  }
`

type Props = {
  label?: ReactNode
  error?: string
  min?: number
  max?: number
  className?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
} & (
  | {
      type: "text"
      value?: string | undefined
    }
  | {
      type: "number"
      value?: number | undefined
    }
  | {
      type: "date"
      value: Date | undefined
    }
)

export const ErrorableInput = ({
  error,
  label,
  type,
  value,
  onChange,
  min,
  max,
  className,
}: Props) => {
  const invalidNumberKeys = ["e", "E", "+", "-"]
  const preventInvalidKeys = (e: KeyboardEvent<HTMLInputElement>) => {
    if (type === "number" && invalidNumberKeys.some((key) => key === e.key)) {
      e.preventDefault()
    }
  }

  const content =
    type === "date" ? (
      <StyledInput
        type={type}
        value={value ? dateFormat.format(value) : ""}
        min={min}
        max={max}
        onChange={onChange}
      />
    ) : (
      <StyledInput
        type={type}
        value={value !== undefined ? value : ""}
        min={min}
        max={max}
        onChange={onChange}
        onKeyDown={(e) => preventInvalidKeys(e)}
      />
    )

  return (
    <StyledLabel data-error={!!error} className={className}>
      <h6>{label}</h6>
      <ErrorText>{error}</ErrorText>
      {content}
    </StyledLabel>
  )
}
