/* eslint-disable @typescript-eslint/naming-convention */

import { useContext } from "react"
import { useNavigate } from "react-router"

import { V3ResourcesProvider } from "contexts/v3Resources"
import { Client3 } from "types/client3"
import * as jsonpatch from "fast-json-patch"

import { AthenaCreateClientPayload } from "@abios/abios-ts-sdk"
import { CreateApiClient } from "../create/Create"
import { client3Context, Client3Provider } from "../single/Client3Context"
import { updateClient } from "../create/util/updateClient"

const getInputFromClient = ({
  name,
  active_until,
  request_rate_burst,
  request_rate_second,
  max_concurrent_hermes_connections,
  hours_back,
  packages,
}: Client3): AthenaCreateClientPayload => ({
  name,
  active_until,
  request_rate_burst,
  request_rate_second,
  max_concurrent_hermes_connections,
  // hours_back can be 0
  hours_back: hours_back !== null ? hours_back : undefined,
  packages,
})

const ApiEditContent = () => {
  const navigate = useNavigate()

  const { client3: client } = useContext(client3Context)

  const onSubmit = async (newClientInput: AthenaCreateClientPayload) => {
    const oldClient = getInputFromClient(client)
    const patch = jsonpatch.compare(oldClient, newClientInput)

    if (patch.length <= 0) return null
    const { data, error } = await updateClient(patch, client)
    if (error) return error.message
    if (data) navigate(`/api-clients/${data.client.id}`)
    return null
  }

  const onAbort = () => navigate(`/api-clients/${client.id}`)

  return (
    <CreateApiClient
      title="Edit API Client"
      submitBtnText="Update"
      client={client}
      onSubmit={onSubmit}
      onAbort={onAbort}
    />
  )
}

export const ApiEdit = () => (
  <V3ResourcesProvider>
    <Client3Provider>
      <ApiEditContent />
    </Client3Provider>
  </V3ResourcesProvider>
)
