import athenaAPI from "services/athena"
import { sample } from "lodash"
import { cErr, clog } from "utils/debug"
import { ENDPOINTS_V1 } from "services/athenaApiEndpoints"
import { Client3APIResponse, Client3 } from "types/client3"
import {
  getAthenaSecret,
  addAthenaSecret,
  AthenaStorage,
} from "utils/localStorage"
import { StoreApi } from "zustand"
import { State } from "./State"

const betBuilderAccessIds = ["lqjoszm5", "mjfqjv42"]

export const getTradingToken = async (set: StoreApi<State>["setState"]) => {
  // Check if token already exist in storage
  const athenaSecret = getAthenaSecret()
  if (athenaSecret !== undefined) {
    // secret exist and has not expired
    set({ hasTradingAccess: true })
    return
  }

  /**
    Create a new trading token and add it to localStorage
  */

  const xCustomer = localStorage.getItem("xcustomer")
  let client3Url = `${ENDPOINTS_V1.CLIENT3}?product=walzwwn0&active=true&deleted=false`
  if (xCustomer) {
    const xCustomerParsed = JSON.parse(xCustomer)
    client3Url += `&customer=${xCustomerParsed.public_id}`
  }

  // Get customer client for trading dashboard
  let client3: Client3 | undefined
  try {
    const resp = await athenaAPI.get<Client3APIResponse>(client3Url)
    if (resp.data?.clients[0]) {
      client3 = { ...resp.data.clients[0] }
      /* 
        Access list of ids of customers who should be able to
        try the bet builder 
      */
      if (betBuilderAccessIds.includes(client3.id)) {
        localStorage.setItem("cDemo", "lqjoszm5")
      }
    }
  } catch (e) {
    cErr("unable to get customer client for trading dashboard", e)
    throw e
  }

  if (client3 === undefined) {
    clog("customer doesn't have any dashboard trading client")
    set({ hasTradingAccess: false })
    return
  }

  const tokenResp = await athenaAPI.get(`${ENDPOINTS_V1.TOKEN3}/name`)
  if (tokenResp.data) {
    const name = sample(tokenResp.data)
    const url = `v1/client3/${client3.id}/token3`
    const resp = await athenaAPI.post(url, { name })
    if (resp.data?.token) {
      clog("new token created for trading dashboard", resp.data)
      const athenaStorage: AthenaStorage = {
        id: resp.data.token.secret,
        expire: resp.data.token.expiration,
      }
      // Add token and expire time to localStorage
      addAthenaSecret(athenaStorage)
      set({ hasTradingAccess: true })
    }
  }
}
