import { useOnClickOutside } from "hooks"
import { useCallback, useEffect, useRef } from "react"
import { StyledModalCard, StyledModalContainer } from "./Modal.styles"

type Props = {
  onClose: () => void
  children: JSX.Element
}

export const Modal = ({ onClose, children }: Props) => {
  const cardRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(cardRef, () => onClose())

  const handleUserKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") onClose()
    },
    [onClose],
  )

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress)
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  return (
    <StyledModalContainer>
      <StyledModalCard ref={cardRef}>{children}</StyledModalCard>
    </StyledModalContainer>
  )
}
