export type AccessAgreementErrorType = {
  recipientName: string
  recipientAddress: string
  recipientEmail: string

  signerCompany: string
  signerName: string
  signerRole: string
  signerEmail: string
}

export const emptyAccessAgreementError = {
  recipientName: "",
  recipientAddress: "",
  recipientEmail: "",

  signerCompany: "",
  signerName: "",
  signerRole: "",
  signerEmail: "",
}
