import styled from "styled-components"
import { NavLink } from "react-router-dom"
import { Close } from "styled-icons/material-rounded"

export const StyledNav = styled.nav`
  min-width: var(--sidebar-width);
`

export const SidebarContainer = styled.div`
  height: 100%;
  min-width: var(--sidebar-width);
  display: flex;
  position: fixed;
  flex-direction: column;
  background: #f6f8fa;
  border-right: 1px solid var(--sidebar-border-color);
`

export const SidebarText = styled.p`
  color: var(--sidebar-color-secondary);
  font-size: 13px;
  font-weight: 600;
`

export const SidebarHeader = styled.header`
  margin: 40px 0 0 35px;
`

export const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;

  > ${SidebarText} {
    margin-left: 26px;
  }

  &.active {
    ${SidebarText} {
      color: var(--primary-accent);
    }
  }
`

export const NavContainer = styled.div`
  margin: 40px 0 0 34px;

  /*  Margin between 'LEGACY' & first menu item below */
  ${SidebarText} + ${NavItem} {
    margin-top: 10px;
  }

  ${NavItem} {
    padding: 14px 0;
    path {
      fill: var(--sidebar-color-secondary);
    }

    &.active {
      path {
        fill: var(--primary-accent);
      }

      ${SidebarText} {
        color: var(--primary-accent);
      }
    }

    &.disabled {
      pointer-events: none;

      ${SidebarText} {
        color: var(--sidebar-color-disabled);
      }

      path {
        fill: var(--sidebar-color-disabled);
      }
    }
  }
`

export const SidebarFooter = styled.div`
  margin-top: auto;

  ${SidebarText} {
    margin-left: 0;
  }
`

export const ChangeUserContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  ${NavItem} {
    margin-bottom: 12px;
  }
`

export const ActiveCustomer = styled.div`
  align-items: center;
  border: 1px solid var(--border-default);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  height: 36px;
  max-width: calc(var(--sidebar-width) - 20px);
  margin-bottom: 18px;
  padding: 0 10px;

  ${SidebarText} {
    color: #4a4a57;
  }
`
export const CloseIcon = styled(Close)`
  cursor: pointer;
  color: #c2c8d1;
  transition: all 200ms ease-in;

  &:hover {
    color: #9292a6;
  }
`

export const LogoutContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 24px;
  margin-right: 32px;

  path {
    stroke: var(--sidebar-color-secondary);
  }

  svg {
    cursor: pointer;
    margin-left: auto;
  }
`

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-accent);
  height: 38px;
  width: 38px;
  border-radius: 19px;
  text-transform: capitalize;

  ${SidebarText} {
    font-size: 13px;
    color: #fff;
  }
`

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 32px;

  > ${SidebarText} {
    color: var(--heading-color);
    margin-left: 16px;
    max-width: calc(var(--sidebar-width) - 150px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`
