import React from "react"
import { useSpring, animated } from "react-spring"

function FormResponseMessage(props) {
  const springProps = useSpring({
    duration: 200,
    to: { color: "#F06E5F", opacity: 1 },
    from: { opacity: 0, color: "#FFF" },
  })
  return (
    <animated.p style={springProps} className="response-msg">
      {props.error}
    </animated.p>
  )
}

export default FormResponseMessage
