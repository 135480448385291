import { useContext, useEffect, useState } from "react"
import orderBy from "lodash/orderBy"
import { Tabs, TabDescriptor, IndexTable } from "@abios/smorgasbord-components"
import { V3ResourcesContext } from "contexts/v3Resources"
import { PushChannel } from "types/push_channels"
import cloneDeep from "lodash/cloneDeep"
import { PushChannelRow } from "./components"
import { client3Context } from "../Client3Context"
import { SectionHeader } from "./components/SectionHeader"
import { Section } from "./Section.styles"
import { StyledTabs, StyledTableContent } from "./PushChannels.styles"
import { useTrackedApiStore } from "../../store"
import { pushTableRowsFilter } from "./utils"

const TABLE_HEADERS = ["Name", "Description", "Access", ""]

export const PushChannels = () => {
  const [selectedTabId, setSelectedTabId] = useState(0)
  const { client3 } = useContext(client3Context)
  const { v3Resources } = useContext(V3ResourcesContext)
  const { packages, pushChannels } = v3Resources
  const [channels, setChannels] = useState<PushChannel[]>([])
  const [tabs, setTabs] = useState<TabDescriptor[]>([])
  const { pushChannelCategories } = useTrackedApiStore()

  // Update state when a user press on a tab
  const onSelectTab = (tabIndex: number) => {
    setSelectedTabId(tabIndex)
  }

  useEffect(() => {
    let didCancel = false

    const formatData = () => {
      // channels the client3 have access to use
      const clientChannels = new Set<string>()

      client3.packages?.forEach((pkg) => {
        // Find index for push channels that belongs to a single product and game id
        const packageIndex = packages.findIndex(
          (p) => p.game.id === pkg.game.id && p.product.id === pkg.product.id,
        )

        // Loop through each package channel and add to clientChannels list if missing
        if (packageIndex >= 0) {
          packages[packageIndex].push_channels?.forEach((chan) => {
            // check if channel name already exist in clientChannels list else add it
            clientChannels.add(chan.name)
          })
        }
      })

      // List of all push channels and if the client3 has access to use it
      const pushChannelsList: PushChannel[] = pushChannels.map(
        (pushChannel) => {
          const channel = { ...pushChannel }
          // Check if client has access to push channel
          channel.hasAccess = clientChannels.has(channel.name)
          return channel
        },
      )

      if (!didCancel) {
        setChannels(
          // Sort channels by name and set available at the top of the list
          orderBy(pushChannelsList, ["hasAccess", "name"], ["desc", "asc"]),
        )
      }
    }

    formatData()
    return () => {
      didCancel = true
    }
  }, [client3.packages, packages, pushChannels])

  useEffect(() => {
    let didCancel = false

    const setupTabs = () => {
      const categoriesTmp = cloneDeep(pushChannelCategories)
      categoriesTmp.unshift({ name: "All" })
      const tabsList: TabDescriptor[] = categoriesTmp.map((p) => {
        return { id: p.name.toLowerCase(), label: p.name }
      })
      if (!didCancel) {
        setTabs(tabsList)
      }
    }

    setupTabs()
    return () => {
      didCancel = true
    }
  }, [pushChannelCategories])

  const headings = TABLE_HEADERS.map((header) => (
    <IndexTable.Header key={header}>{header}</IndexTable.Header>
  ))

  const pushTableRows = channels
    .filter((channel) => pushTableRowsFilter(selectedTabId, channel, tabs))
    .map((channel) => <PushChannelRow key={channel.name} channel={channel} />)

  return (
    <Section firstChild={false}>
      <SectionHeader title="Channels" hasSectionContent />

      <StyledTabs>
        <Tabs selected={selectedTabId} tabs={tabs} onSelect={onSelectTab} />
      </StyledTabs>

      <StyledTableContent>
        <IndexTable headings={headings} rows={pushTableRows} />
      </StyledTableContent>
    </Section>
  )
}
