import { FC, ReactNode } from "react"
import { Header, H2, SubTitle } from "./SectionHeader.styles"

type Props = {
  // If the section doesn't have any content set a border for the header
  children?: ReactNode
  readonly hasSectionContent: boolean
  readonly title: string
  readonly subTitle?: string
}

export const SectionHeader: FC<Props> = ({
  hasSectionContent,
  title,
  subTitle,
  children,
}) => (
  <Header $hasSubTitle={subTitle} $hasContent={hasSectionContent}>
    <H2>{title}</H2>
    <SubTitle>{subTitle}</SubTitle>
    {children}
  </Header>
)
