import AthenaAPI from "services/athena"
import * as jsonpatch from "fast-json-patch"
import { Client3 } from "types/client3"

export const updateClient = async (
  patch: jsonpatch.Operation[],
  client: Client3,
) => {
  let data
  let error = null

  try {
    const resp = await AthenaAPI.patch(
      `/v1/client3/${client.id}`,
      {
        patch,
      },
      {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          "If-Match": client.resource_version,
        },
      },
    )
    data = resp.data
  } catch (e) {
    error = e
  }

  return {
    data,
    error,
  }
}
