import styled from "styled-components"
import { StyledInviteButton } from "pages/customers/components/Header.styles" // Remove this import and break out button as independant style

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem 0;
  height: calc(100% - 2.625rem);
`

export const StyledTitle = styled.h1`
  color: #242830;
  font-size: 20px;
`

export const StyledDescription = styled.h3`
  color: #a6adb8;
  padding: 1rem 0;
  font-weight: 400;
`

export const StyledCreateButton = styled(StyledInviteButton)`
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
  height: 2.75rem;
`
export const StyledClearButton = styled(StyledCreateButton)`
  background: none;
  color: #697990;
  border: 1px solid var(--border-default);
`

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-top: 2rem;
`
