import styled from "styled-components"

export const StyledGameContent = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledFigure = styled.figure`
  height: 22px;
  width: 22px;
`

export const StyledGameImg = styled.img`
  box-shadow: 0px 0px 0px 2px #fff;
  border-radius: 50%;
  height: 100%;
  max-width: 100%;
`

export const StyledPill = styled.div`
  align-items: center;
  background: #6c97f3;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 2px #fff;
  display: flex;
  height: 22px;
  justify-content: center;
  width: 22px;
`

export const StyledPillText = styled.span`
  color: #fff;
  font-size: 8px;
  font-weight: 600;
`
