import { useMemo } from "react"
import { MARKET_TYPE, Player, Team } from "@abios/abios-ts-sdk"
import { E_MARKET_TEMPLATE } from "../types/EMarketTemplate"
import { GetOperatorTemplate } from "../utils"
import { getSelectionHeaderTitles } from "../utils/GetSelectionHeaderTitles"
import {
  StyledSelectionHeader,
  StyledSelectionHeaderItem,
} from "./SelectionHeader.styles"

type Props =
  | {
      marketType: MARKET_TYPE
      left: Player
      right: Player
      isPlayer: true
    }
  | {
      marketType: MARKET_TYPE
      left: Team
      right: Team
      isPlayer: false
    }

export const SelectionHeader = ({
  marketType,
  left,
  right,
  isPlayer,
}: Props) => {
  const getTeamName = (team: Team) => {
    return team?.abbreviation || ""
  }

  const getPlayerName = (player: Player) => {
    return player.nick_name
  }

  const getTitle = useMemo(() => {
    return getSelectionHeaderTitles[marketType] || ""
  }, [marketType])

  const operatorTemplate = useMemo(
    () => GetOperatorTemplate(marketType),
    [marketType],
  )

  const getSelectionHeader = useMemo(() => {
    switch (operatorTemplate) {
      case E_MARKET_TEMPLATE.TWO_COLUMN_TEAM:
      case E_MARKET_TEMPLATE.FOUR_COLUMN_TEAM:
        return isPlayer
          ? [getPlayerName(left), getPlayerName(right)]
          : [getTeamName(left), getTeamName(right)]

      case E_MARKET_TEMPLATE.THREE_COLUMN_TEAM:
        return isPlayer
          ? [getPlayerName(left), "DRAW", getPlayerName(right)]
          : [getTeamName(left), "DRAW", getTeamName(right)]

      case E_MARKET_TEMPLATE.TWO_COLUMN_YESNO: {
        if (marketType === MARKET_TYPE.TOTAL_MATCHES_ODD_EVEN)
          return ["odd", "even"]
        return ["YES", "NO"]
      }

      case E_MARKET_TEMPLATE.THREE_COLUMN_EMPTY_TEAM:
      case E_MARKET_TEMPLATE.THREE_COLUMN_TEAM_PROPS:
        return isPlayer
          ? [getTitle, getPlayerName(left), getPlayerName(right)]
          : [getTitle, getTeamName(left), getTeamName(right)]

      case E_MARKET_TEMPLATE.THREE_COLUMN_TOTAL_OVER_UNDER:
        return ["TOTAL", "OVER", "UNDER"]

      case E_MARKET_TEMPLATE.THREE_COLUMN_ENTITY_OVER_UNDER:
        return [getTitle, "OVER", "UNDER"]

      case E_MARKET_TEMPLATE.CORRECT_SERIES_SCORE:
        return isPlayer
          ? [getPlayerName(left), "", getPlayerName(right)]
          : [getTeamName(left), "", getTeamName(right)]

      case E_MARKET_TEMPLATE.THREE_COLUMN_TEAM_YES_NO:
        return ["TEAM", "YES", "NO"]

      default:
        return []
    }
  }, [operatorTemplate, isPlayer, left, right, getTitle, marketType])
  const headerColumns = getSelectionHeader.map((value) => (
    <StyledSelectionHeaderItem key={value}>{value}</StyledSelectionHeaderItem>
  ))

  return <StyledSelectionHeader>{headerColumns}</StyledSelectionHeader>
}
