import { IndexTable, ROW_SIZE } from "@abios/smorgasbord-components"
import { ClientStatus } from "molecules/ClientStatus"
import { GameGroup } from "pages/api/list/components/GameGroup"
import { getClientStatus } from "pages/api/utils/getClientStatus"
import { getTerminateDate } from "pages/api/utils/getTerminateDate"
import { sortGames } from "pages/api/utils/sortGames"
import { useNavigate } from "react-router"
import { useGameStore } from "stores"
import { CLIENT_STATUS } from "types/client3"
import { Game } from "types/game"
import { Widget } from "types/widget_namespace"
import {
  StyledRow,
  StyledText,
  StyledTextSub,
} from "pages/api/list/components/TableRow.styles"
import { formatDateCreated } from "pages/api/list/utils/formatDateCreated"

type Props = {
  widget: Widget
}

export const WidgetListRow = ({ widget }: Props) => {
  const { games: gamesFromStore } = useGameStore()
  const navigate = useNavigate()

  const getWidgetStatus = (): [string, string, CLIENT_STATUS] => {
    const dateActiveUntil =
      widget.active_until !== null ? new Date(widget.active_until) : null
    const widgetStatus = getClientStatus(dateActiveUntil)

    switch (widgetStatus) {
      case CLIENT_STATUS.WARNING:
        return [
          "Expiring",
          `On ${getTerminateDate(dateActiveUntil)}`,
          CLIENT_STATUS.WARNING,
        ]
      case CLIENT_STATUS.INACTIVE:
        return [
          "Inactive",
          `Since ${getTerminateDate(dateActiveUntil)}`,
          CLIENT_STATUS.INACTIVE,
        ]
      default:
        return ["Active", "Until Terminated", CLIENT_STATUS.ACTIVE]
    }
  }
  const [statusText, statusSub, status] = getWidgetStatus()

  const getGames = (): Game[] => {
    return widget.games.map(({ id }) => gamesFromStore[id]).sort(sortGames())
  }

  const trimDesc = (description: string): string => {
    const DESC_MAX_LENGTH = 50 // Temporary maxlength until we know more
    if (description.length <= DESC_MAX_LENGTH) return description
    return `${description.substring(0, DESC_MAX_LENGTH)}...`
  }

  const gotoWidget = () => {
    navigate(`/widget/${widget.id}`)
  }

  const created = formatDateCreated(widget.created)
  return (
    <StyledRow
      as={IndexTable.Row}
      id={widget.id}
      key={widget.id}
      size={ROW_SIZE.lg}
      onClick={gotoWidget}>
      <IndexTable.Cell>
        <StyledText>{widget.slug}</StyledText>
        <StyledTextSub>{widget.id}</StyledTextSub>
      </IndexTable.Cell>

      <IndexTable.Cell>
        <ClientStatus
          status={status}
          statusText={statusText}
          statusSubText={statusSub}
        />
      </IndexTable.Cell>

      <IndexTable.Cell>
        <GameGroup games={getGames()} />
      </IndexTable.Cell>

      <IndexTable.Cell>
        <StyledTextSub>
          {trimDesc(widget.customer_description || "")}
        </StyledTextSub>
      </IndexTable.Cell>

      <IndexTable.Cell>
        <StyledText data-date="true">{created}</StyledText>
      </IndexTable.Cell>
    </StyledRow>
  )
}
