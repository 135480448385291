import { InfoPage } from "@abios/smorgasbord-components"
import styled from "styled-components"

export const StyledLoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
`

export const StyledErrorPage = styled(InfoPage)`
  min-height: 100vh;

  & > div {
    max-width: 500px;
  }

  & > div > div:nth-child(3) {
    padding-top: 1rem;
  }

  & > div > div > button > span {
    font-weight: 500;
  }

  & button svg {
    padding-top: 0;
    margin-right: 0.25rem;
  }
`
