import { create } from "zustand"
import { createTrackedSelector } from "react-tracked"
import { AthenaInvoice } from "@abios/abios-ts-sdk"

export enum TAB {
  ALL = "all",
  OVERDUE_PAYMENTS = "overdue-payments",
}

type FilterStoreType = {
  managers: string[]
  setManagers: (value: string[]) => void
  statuses: number[]
  setStatuses: (value: number[]) => void
  tab: TAB
  setTab: (value: TAB) => void
  search?: string
  setSearch: (value?: string) => void
  createdFrom?: string
  setCreatedFrom: (value?: string) => void
  createdTo?: string
  setCreatedTo: (value?: string) => void
  statusDate: Date
  setStatusDate: (value: Date) => void
  activeDate: Date
  setActiveDate: (value: Date) => void
  allOverduePayments: AthenaInvoice[]
  setAllOverduePayments: (value: AthenaInvoice[]) => void
}

const useStore = create<FilterStoreType>((set) => ({
  managers: [],
  setManagers: (managers) => set({ managers }),
  statuses: [],
  setStatuses: (statuses) => set({ statuses }),
  tab: TAB.ALL,
  setTab: (tab) => set({ tab }),
  search: undefined,
  setSearch: (search) => set({ search }),
  createdFrom: undefined,
  setCreatedFrom: (createdFrom) => set({ createdFrom }),
  createdTo: undefined,
  setCreatedTo: (createdTo) => set({ createdTo }),
  statusDate: new Date(),
  setStatusDate: (statusDate) => set({ statusDate }),
  activeDate: new Date(),
  setActiveDate: (activeDate) => set({ activeDate }),
  allOverduePayments: [],
  setAllOverduePayments: (allOverduePayments) => set({ allOverduePayments }),
}))

export const useTrackedFilterStore = createTrackedSelector(useStore)
