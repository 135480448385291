/* eslint-disable @typescript-eslint/naming-convention */
import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosRequestHeaders,
} from "axios"
import { getAthenaSecret } from "utils/localStorage"

export const ABIOS_VERSION = "2022-12-24"

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_ODDS_API}/`,
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
  timeout: 20000,
})

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const conf = { ...config }

    if (conf.headers === undefined) {
      conf.headers = {} as AxiosRequestHeaders
    }

    const athenaId = getAthenaSecret()
    if (athenaId) {
      conf.headers["Abios-Secret"] = athenaId
    }

    conf.headers["Abios-Version"] = ABIOS_VERSION

    return conf
  },
  (err) => {
    Promise.reject(err)
  },
)

instance.interceptors.response.use(
  (resp: AxiosResponse) => resp,
  (err) => {
    if (err && err.response) {
      // unauthorized user
      if (err.response.status === 401) {
        localStorage.clear()
        window.location.href = "/login"
        return
      }
    } else {
      // TODO: Network error
    }
    throw err
  },
)

export default instance
