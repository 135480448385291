import { useContext, Dispatch, SetStateAction, useState } from "react"
import { Link } from "react-router-dom"

import { IndexTable } from "@abios/smorgasbord-components"
import { Client3 } from "types/client3"
import { XCustomerContext } from "jsx/contexts/XCustomer"
import { DeleteModal } from "molecules/Modal/ModalTypes/DeleteModal"
import { Modal } from "molecules/Modal/Modal"
import AthenaAPI from "services/athena"
import { TableRow, EmptyFeedback } from "./components"
import {
  StyledContent,
  StyledHeader,
  StyledTableContent,
  StyledIndexTableHeader,
} from "./ClientList.styles"
import { StyledInviteButton, StyledPlusIcon } from "../styles"
import { useModalStore } from "./store/useModalStore"
import { getClientStore } from "./store/getClientStore"

const HEADERS: string[] = [
  "ID",
  "Status",
  "Match",
  "Play by Play",
  "Odds",
  "Trading",
  "Created",
  "",
]

interface Props {
  clients: Client3[]
}

export const ClientList = ({ clients }: Props) => {
  const xcustomer = useContext(XCustomerContext)
  const { isDeleteModalOpen, setIsDeleteModalOpen, targetId, setTargetId } =
    useModalStore()
  const { removeClient } = getClientStore()
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false)

  const headings = HEADERS.map((header) => (
    <StyledIndexTableHeader key={header} $dataColumn={header}>
      {header}
    </StyledIndexTableHeader>
  ))

  const pushTableRows = clients.map((client) => (
    <TableRow key={client.id} client={client} isManager={xcustomer.customer} />
  ))

  const content = (
    <StyledTableContent>
      <IndexTable headings={headings} rows={pushTableRows} />
    </StyledTableContent>
  )

  const createClientButton = xcustomer.customer && (
    <Link to="/api-clients/create">
      <StyledInviteButton>
        <StyledPlusIcon size="20" />
        Create client
      </StyledInviteButton>
    </Link>
  )

  const closeModal = () => {
    setIsDeleteModalOpen(false)
    setTargetId("")
  }

  const DeleteClient = async (setError: Dispatch<SetStateAction<string>>) => {
    if (targetId !== "") {
      try {
        setIsModalLoading(true)
        await AthenaAPI.delete(`v1/client3/${targetId}`, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            "If-Match":
              clients.find((client) => client.id === targetId)
                ?.resource_version ?? 1,
          },
        })
        closeModal()
        removeClient(targetId)
      } catch (err) {
        setError(err)
      } finally {
        setIsModalLoading(false)
      }
    }
  }

  const notManagerText = "Contact your account manager to get started."
  const isManagerText = "Create a client and get started"
  const emptyFeedbackText = xcustomer.customer ? isManagerText : notManagerText
  if (clients.length <= 0) {
    return (
      <StyledContent>
        <EmptyFeedback title="You have no API clients" text={emptyFeedbackText}>
          {createClientButton}
        </EmptyFeedback>
      </StyledContent>
    )
  }

  return (
    <StyledContent>
      <StyledHeader>
        <h1>API Clients</h1>
        {createClientButton}
      </StyledHeader>
      {content}
      {isDeleteModalOpen && (
        <Modal onClose={closeModal}>
          <DeleteModal
            title="Delete client"
            text="Are you sure you want to delete this client?"
            isLoading={isModalLoading}
            onClose={closeModal}
            onSubmit={DeleteClient}
          />
        </Modal>
      )}
    </StyledContent>
  )
}
