import { useState, useEffect } from "react"
import axios from "axios"
import AthenaAPI from "services/athena"

import { Customer } from "types/customer"

const requestCustomers = async (
  signal: AbortSignal,
  filter = "",
): Promise<Customer[]> => {
  const {
    data: { customers },
  } = await AthenaAPI.get(`v1/customer?${filter}`, {
    signal,
  })

  return customers
}

const useFilteredCustomers = (
  allCustomers?: Customer[],
  filters = "",
): [Customer[], { error?: string; isLoading: boolean }] => {
  const [customers, setCustomers] = useState<Customer[]>([])
  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!filters && allCustomers) {
      setCustomers(allCustomers)
      setIsLoading(false)

      return () => undefined
    }

    const controller = new AbortController()

    const getCustomers = async () => {
      setIsLoading(true)

      try {
        setCustomers(await requestCustomers(controller.signal, filters))
        setError(undefined)
      } catch (err) {
        if (axios.isCancel(err)) {
          return
        }

        setError(err.message)
      } finally {
        setIsLoading(false)
      }
    }

    getCustomers()

    return () => controller.abort()
  }, [setCustomers, setIsLoading, setError, filters, allCustomers])

  return [customers, { error, isLoading }]
}

export const useCustomers = (
  filters = "",
): {
  allCustomers: Customer[]
  filteredCustomers: Customer[]
  error?: string
  isLoading: boolean
  isFiltering: boolean
} => {
  const [allCustomers, { isLoading, error }] = useFilteredCustomers()
  const [filteredCustomers, { isLoading: isFiltering, error: filterError }] =
    useFilteredCustomers(allCustomers, filters)

  return {
    allCustomers,
    filteredCustomers,
    isLoading,
    isFiltering,
    error: error || filterError,
  }
}
