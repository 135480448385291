import { LIFECYCLE } from "@abios/abios-ts-sdk"

export const getLifecycleState = (lifecycle: string): LIFECYCLE => {
  switch (lifecycle) {
    case "live":
      return LIFECYCLE.LIVE
    case "over":
      return LIFECYCLE.OVER
    case "over-draw":
      return LIFECYCLE.OVER_DRAW
    case "over-forfeited":
      return LIFECYCLE.OVER_FORFEITED
    case "postponed":
      return LIFECYCLE.POSTPONED
    case "deleted":
      return LIFECYCLE.DELETED
    case "upcoming":
      return LIFECYCLE.UPCOMING
    case "unknown":
    default:
      return LIFECYCLE.UNKNOWN
  }
}
