import { Dispatch } from "react"
import type { Game } from "types"
import { useGamesWithProducts } from "../util/useGamesWithProducts"
import { AddGame } from "../AddGame"
import { FormState, StateAction, STATE_ACTION } from "../util/formReducer"
import { Table, Th, Tr } from "./ResourceAccess.styles"
import { ResourceRow } from "./ResourceRow"

type Props = {
  state: FormState
  dispatch: Dispatch<StateAction>
}

export const ResourceAccess = ({ state, dispatch }: Props) => {
  const availableGames = useGamesWithProducts()

  const onAddGame = (game: Game) => {
    dispatch({ type: STATE_ACTION.ADD_GAME, game: game.id })
  }

  const rows = state.packages
    .map(({ game, products }) => ({
      game: availableGames[game].game,
      products,
    }))
    .sort(({ game: { title: title1 } }, { game: { title: title2 } }) =>
      title1.localeCompare(title2),
    )
    .map(({ game, products }) => (
      <ResourceRow
        key={game.id}
        game={game}
        products={products}
        dispatch={dispatch}
      />
    ))

  return (
    <div>
      <AddGame state={state} onAdd={onAddGame} />
      <Table cellSpacing="0" cellPadding="0">
        <thead>
          <Tr>
            <Th>Title</Th>
            <Th>Permissions</Th>
            <Th />
          </Tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  )
}
