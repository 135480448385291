import styled from "styled-components"

export const StyledOption = styled.div`
  display: inline-flex;
  gap: 0.1875rem;
  position: relative;
  border-radius: 0.1875rem;
  padding: 2px 0.5rem;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover,
  &.open {
    background-color: white;
    border-color: var(--border-default);
  }
`

export const StyledUl = styled.ul`
  position: absolute;
  z-index: 9;
  background: white;
  border: 1px solid var(--border-default);
  border-radius: 0.1875rem;
  top: 100%;
  margin-top: 0.625rem;
  right: 0;
  min-width: 9.375rem;
  cursor: default;
  font-size: 0.75rem;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
`

type StyledLiProps = {
  inactive?: boolean
}

export const StyledLi = styled.li.attrs(({ inactive }: StyledLiProps) => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "data-inactive": inactive || undefined,
}))<StyledLiProps>`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 1rem;
  font-size: 0.813rem;
  text-align: left;
  cursor: pointer;

  &:not([data-inactive]):hover {
    color: #7aa4f7;
    & path {
      stroke: #7aa4f7;
    }
  }

  &[data-inactive] {
    color: #999;
  }

  & path {
    stroke: #4a4a57;
  }
`

export const StyledButton = styled.button`
  padding: 0;
`
