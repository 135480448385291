import React from "react"
import styled from "styled-components"
import { Spring } from "react-spring"

const Box = styled.p`
  background-color: rgba(234, 66, 92, 0.3);
  border-radius: 4px;
  color: #ea425c;
  font-size: 12px;
  font-weight: 600;
  padding: 7px 10px;
`

const ErrorLabel = ({ msg }) => (
  <Spring config={{ duration: 450 }} from={{ opacity: 0 }} to={{ opacity: 1 }}>
    {(props) => (
      <div style={{ marginRight: 12 }}>
        <Box style={props}>{msg}</Box>
      </div>
    )}
  </Spring>
)

export default ErrorLabel
