import React from "react"
import { useSpring, animated } from "react-spring"

function ResponseMessage({ status }) {
  const springProps = useSpring({ opacity: 1, from: { opacity: 0 } })
  return (
    <animated.p
      style={springProps}
      className="status-message"
      data-error={!!status.error}
      data-show={!!status.message}>
      {status.message}
    </animated.p>
  )
}

export default ResponseMessage
