import { StoreApi } from "zustand"
import { Market } from "@abios/abios-ts-sdk"
import { State } from "./State"

export const addList = (
  set: StoreApi<State>["setState"],
  get: StoreApi<State>["getState"],
  list: Market[],
) => {
  const { markets } = get()
  const newMarkets: { [key: number]: Market } = {}

  const listLength = list.length
  for (let i = 0; i < listLength; i += 1) {
    newMarkets[list[i].id] = list[i]
  }

  set({ markets: { ...markets, ...newMarkets } })
}
