import { useOnClickOutside } from "hooks"
import { useRef, useState } from "react"
import {
  FilterContainer,
  FilterButton,
  StyledSearchIcon,
  StyledCrossIcon,
  StyledInputWrapper,
  StyledInput,
} from "./Search.styles"
import { useTrackedFilterStore } from "./utils/useTrackedFilterStore"

export const Search = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { search: query, setSearch: setQuery } = useTrackedFilterStore()
  const [isEditing, setIsEditing] = useState<boolean>(query != null)

  const onClear = () => {
    setQuery(undefined)
    setIsEditing(false)
  }

  const onOpen = () => {
    setIsEditing(true)
  }

  useOnClickOutside(ref, () => {
    if (!query) {
      onClear()
    }
  })

  const shouldShow = query != null || isEditing

  return (
    <FilterContainer>
      <FilterButton onClick={onOpen}>
        <StyledSearchIcon size="15" />
        {!shouldShow && "Search"}
      </FilterButton>
      {shouldShow && (
        <StyledInputWrapper ref={ref}>
          <StyledCrossIcon size="20" onClick={onClear} />
          <StyledInput
            autoFocus
            type="text"
            value={query || ""}
            onChange={(e) => setQuery(e.target.value)}
          />
        </StyledInputWrapper>
      )}
    </FilterContainer>
  )
}
