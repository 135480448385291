export const MARKET_TYPE_PRIORITY: { [key: string]: number } = {
  // Series
  series_winner: 1,
  series_winner_three_way: 1,
  total_matches: 2,
  match_handicap: 3,

  // Match
  match_winner: 4,

  // RTS
  total_kills: 5,
  total_minutes: 6,
  team_to_get_kill: 7,

  // Dota
  team_to_destroy_barrack: 8,
  total_barracks_destroyed: 9,
  team_to_destroy_tower: 10,
  total_towers_destroyed: 11,
  team_to_kill_roshan: 12,
  total_roshans_slain: 13,

  // LOL
  team_to_kill_baron: 8,
  team_to_kill_dragon: 9,
  team_to_kill_scuttler: 10,
  team_to_destroy_turret: 11,
  team_to_destroy_inhibitor: 12,
  total_dragons_slain: 13,
  total_barons_slain: 14,
  total_turrets_destroyed: 15,
  total_inhibitors_destroyed: 16,

  // CSGO / Valorant
  round_handicap: 8,
  total_rounds: 9,
  first_pistol_winner: 10,
  second_pistol_winner: 11,
  round_winner: 12,
  overtime: 13,
  total_rounds_by_timeout: 14,
  total_rounds_by_defuse: 15,
  total_rounds_by_detonation: 16,
  total_player_bomb_defusals: 17,
  total_player_bomb_plants: 18,
  ace_occurred: 19,
  first_team_to_rounds: 20,

  // eSoccer
  total_goals: 8,
  total_team_goals: 9,
  total_corners: 10,

  total_player_kills: 21,
  total_player_deaths: 22,
}
