import { useState, useRef } from "react"
import { cErr } from "utils/debug"
import {
  StyledInputCheckIcon,
  StyledContent,
  StyledInput,
  StyledRevealButton,
  StyledTooltip,
  StyledToolipCopyIcon,
  StyledTooltipCheckIcon,
} from "./SecretKey.styles"

type Props = {
  readonly secretKey: string | undefined

  // Methods
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export const SecretKeyView = ({ secretKey, onClick }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isCopied, setIsCopied] = useState(false)
  const inputValue = secretKey?.replaceAll("-", "") || ""

  const handleMouseLeave = () => {
    if (isCopied) {
      setIsCopied(false)
    }
  }

  const handleClick = async () => {
    if (inputRef?.current && secretKey !== undefined) {
      // Copy input box value to clipboard
      try {
        await navigator.clipboard.writeText(inputValue)
        // If successful, update the isCopied state value
        setIsCopied(true)
      } catch (err) {
        cErr("copy secret to clipboard", err)
        // TODO: show error
      }
    }
  }

  let toolTipInner: JSX.Element | null = null
  if (isCopied) {
    toolTipInner = (
      <>
        <StyledToolipCopyIcon />
        <p>Copied</p>
      </>
    )
  } else {
    toolTipInner = (
      <>
        <StyledTooltipCheckIcon />
        <p>Copy secret</p>
      </>
    )
  }

  return (
    <div>
      <StyledContent onMouseLeave={handleMouseLeave}>
        <StyledTooltip data-isvisible={secretKey !== undefined}>
          {toolTipInner}
        </StyledTooltip>
        <StyledInput
          ref={inputRef}
          type="text"
          value={inputValue}
          placeholder="dummykey-1234-1234-1234"
          data-blurry-text={!secretKey}
          data-is-copied={isCopied}
          onClick={handleClick}
          disabled={secretKey === undefined}
          readOnly
        />

        <div
          tabIndex={0}
          role="button"
          onClick={handleClick}
          onKeyDown={handleClick}>
          <StyledInputCheckIcon $isVisible={secretKey !== undefined} />
        </div>

        <StyledRevealButton
          type="button"
          onClick={onClick}
          data-isvisible={secretKey === undefined}>
          Reveal Secret
        </StyledRevealButton>
      </StyledContent>
    </div>
  )
}
