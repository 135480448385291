import { useEffect, useState } from "react"
import { LoadingText } from "./LoadingState.styles"

type Props = {
  loadingList: string[]
  duration?: number
}

export const LoadingState = ({ loadingList, duration = 2000 }: Props) => {
  const [text, setText] = useState<string>(loadingList[0])

  useEffect(() => {
    let iterator = 0

    const timer = setInterval(() => {
      iterator = (iterator + 1) % loadingList.length

      setText(loadingList[iterator])
    }, duration)

    return () => {
      clearInterval(timer)
    }
  }, [loadingList, duration])

  return <LoadingText duration={duration}>{text}</LoadingText>
}
