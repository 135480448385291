import axios from "axios"
import { StoreApi } from "zustand"

import { PAGE_SIZE, Team } from "@abios/abios-ts-sdk"
import { State } from "./State"
import { generateFilterIdsRequests } from "./Utils"

export const getTeamsFromAPI = async (
  set: StoreApi<State>["setState"],
  get: StoreApi<State>["getState"],
  teamIds: number[],
) => {
  const { teams } = get()
  const teamsList: Team[] = []

  // Loop through incoming team ids and check if they exist in the current state
  // If they exist we remove the id from the list
  const teamIdsLength = teamIds.length
  for (let i = teamIdsLength - 1; i >= 0; i -= 1) {
    if (teams[teamIds[i]] !== undefined) {
      teamsList.push(teams[teamIds[i]])
      teamIds.splice(i, 1)
    }
  }

  if (teamIds.length === 0) {
    // We have all the teams that we need
    return teamsList
  }

  // Fetch teams from Atlas

  const url = `v3/teams?filter=id<=`
  const requests = generateFilterIdsRequests(url, PAGE_SIZE, teamIds)

  const newTeams: { [key: number]: Team } = {}

  try {
    const resp = await axios.all(requests)
    if (Array.isArray(resp)) {
      // Loop through all teams and add them to newTeams obj

      const respLength = resp.length
      for (let i = 0; i < respLength; i += 1) {
        if (resp[i].data && Array.isArray(resp[i].data)) {
          const respDataLength = resp[i].data.length
          for (let j = 0; j < respDataLength; j += 1) {
            newTeams[resp[i].data[j].id] = resp[i].data[j]

            // Add team to list that we return back
            teamsList.push(resp[i].data[j])
          }
        }
      }
    }
  } catch (e) {
    throw new Error(e)
  }

  // Update store with all the new fetched team(s)
  set({ teams: { ...teams, ...newTeams } })

  return teamsList
}
