import styled from "styled-components"

// eslint-disable-next-line import/no-extraneous-dependencies
import { Plus } from "@styled-icons/bootstrap"

import LoadingContainer from "jsx/components/LoadingContainer"

export const StyledLoadingContainer = styled(LoadingContainer)`
  width: auto;
  padding: 0 1rem;
`

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  > div {
    display: flex;

    > *:not(:last-child) {
      margin-right: 1.25rem;
    }
  }
`

export const StyledInviteButton = styled.div`
  cursor: pointer;
  border: 1px solid #5f96fa;
  box-shadow: 0 5px 15px -3px rgba(103, 107, 224, 0.2);
  border-radius: 4px;
  align-items: center;
  background: var(--primary-accent);
  color: var(--text-white);
  display: flex;
  font-size: 12px;
  justify-content: center;
  letter-spacing: 0.25px;
  outline: none;
  padding: 0.625rem 1.125rem;
  user-select: none;
`

export const StyledPlusIcon = styled(Plus)`
  color: var(--text-white);
  margin-right: 0.25rem;
  margin-top: 0.063rem;
`
