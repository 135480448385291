import { useNavigate } from "react-router-dom"
import { Client3, CLIENT_STATUS } from "types/client3"
import { IndexTable, ROW_SIZE } from "@abios/smorgasbord-components"
import { useGameStore } from "stores"
import type { Game } from "types/game"
import { PACKAGES } from "types/package"
import { ClientStatus } from "molecules/ClientStatus/ClientStatus"
import { sortGames } from "pages/api/utils/sortGames"
import { formatDateCreated } from "../utils/formatDateCreated"
import {
  StyledText,
  StyledTextSub,
  StyledIdContent,
  StyledRow,
} from "./TableRow.styles"
import { getClientStatus } from "../../utils/getClientStatus"
import { GameGroup } from "./GameGroup"
import { getTerminateDate } from "../../utils/getTerminateDate"
import { Options } from "./Options"

type Props = {
  client: Client3
  isManager: boolean
}

export const TableRow = ({ client, isManager }: Props) => {
  const gamesStore = useGameStore()
  const navigate = useNavigate()

  const onClickRow = () => {
    // Redirect user
    navigate(`/api-clients/${client.id}`)
  }

  const created = formatDateCreated(client.created)
  const dateActiveUntil =
    client.active_until !== null ? new Date(client.active_until) : null
  const clientStatus = getClientStatus(dateActiveUntil)

  // Get client status
  let statusText = "Active"
  let statusSubText = "Until Terminated"
  if (clientStatus === CLIENT_STATUS.WARNING) {
    statusText = "Expiring"
    statusSubText = `On ${getTerminateDate(dateActiveUntil)}`
  } else if (clientStatus === CLIENT_STATUS.INACTIVE) {
    statusText = "Inactive"
    statusSubText = `Since ${getTerminateDate(dateActiveUntil)}`
  }

  // Setup games for each package
  const matchPkgGames: Game[] = []
  const pbpPkgGames: Game[] = []
  const oddsPkgGames: Game[] = []
  const tradingPkgGames: Game[] = []

  client.packages.forEach((pkg) => {
    const game = gamesStore.getById(pkg.game.id || 0)
    if (game !== undefined) {
      if (pkg.product.id === PACKAGES.MATCH) {
        matchPkgGames.push(game)
      } else if (pkg.product.id === PACKAGES.PBP) {
        pbpPkgGames.push(game)
      } else if (pkg.product.id === PACKAGES.ODDS) {
        oddsPkgGames.push(game)
      } else if (pkg.product.id === PACKAGES.TRADING) {
        tradingPkgGames.push(game)
      }
    }
  })

  // Returns empty string so last HEADER column isn't empty
  const optionsCell = isManager ? (
    <IndexTable.Cell>
      <Options client={client} />
    </IndexTable.Cell>
  ) : (
    ""
  )

  matchPkgGames.sort(sortGames())
  pbpPkgGames.sort(sortGames())
  oddsPkgGames.sort(sortGames())
  tradingPkgGames.sort(sortGames())

  return (
    <StyledRow
      as={IndexTable.Row}
      id={client.id}
      key={client.id}
      size={ROW_SIZE.lg}
      onClick={onClickRow}>
      <StyledIdContent>
        <StyledText>{client.name}</StyledText>
        <StyledTextSub data-id="true">{client.id}</StyledTextSub>
      </StyledIdContent>

      <IndexTable.Cell>
        <ClientStatus
          status={clientStatus}
          statusText={statusText}
          statusSubText={statusSubText}
        />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <GameGroup games={matchPkgGames} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <GameGroup games={pbpPkgGames} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <GameGroup games={oddsPkgGames} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <GameGroup games={tradingPkgGames} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StyledText data-date="true">{created}</StyledText>
      </IndexTable.Cell>
      {optionsCell}
    </StyledRow>
  )
}
