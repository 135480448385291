import styled from "styled-components"

export const StyledContainer = styled.div`
  grid-area: widget;
  min-width: 350px;
  max-width: 400px;
  background: var(--bg);

  // Specific padding to align Bet Builder with markets

  padding-top: 11.5rem;
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem 0.5rem 0 0;
  filter: var(--drop-shadow-lg);

  & > h3 {
    padding: 0.75rem;
    padding-bottom: 0;
  }
`

export const StyledStickyConatiner = styled.div`
  position: sticky;
  top: 1rem;
`

export const BetContiner = styled.div`
  display: flex;
  flex-direction: column;
`

export const BetPlacementContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`

export const StyledBetButton = styled.button`
  width: 50%;
  padding: 18px 10px;
  background: var(--blue-500);
  color: var(--text-white);
  font-weight: 500;
  cursor: pointer;
  opacity: 0.5;
`

export const StyledComboBetContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
`

export const StyledDivider = styled.section`
  width: 100%;
  height: 1px;
  background: var(--gray-200);
  margin-top: 0.75rem;
`

export const StyledBetError = styled.p`
  font-size: 12px;
  padding: 0 0.75rem;
  text-align: center;
  font-weight: 500;
`
