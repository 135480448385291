import React, { useEffect } from "react"
// import styled from "styled-components";
// import { useLocation } from "react-router";
//import queryString from "query-string";

//import API from "../utils/api";
// import LoadingContainer from "../components/LoadingContainer";

/*
const Content = styled.div`
  align-items: center;
  background: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
*/

const ConfirmSCA = () => {
  //let location = useLocation();
  //const [isLoading, setIsLoading] = useState(true);
  //const [data, setData] = useState(null);

  useEffect(() => {
    window.top.postMessage("3DS-auth-complete")

    /*
    let didCancel = false;
    const CheckIntentID = async id => {
      try {
        const resp = await API.get(`v1/customer/seti/${id}`);
        if (!didCancel) setData(resp);
      } catch (err) {
        console.error(err.message);
      }
      if (!didCancel) setIsLoading(false);
    };

    const parsed = queryString.parse(location.search);
    CheckIntentID(parsed.setup_intent);
    return () => {
      didCancel = true;
    };
    */
  }, [])

  return <div></div>

  /*
  return (
    <Content>{isLoading ? <LoadingContainer /> : <div>hehe</div>}</Content>
  );
  */
}

export default ConfirmSCA
