import {
  MARKET_TYPE,
  Market,
  MarketContext,
  SelectionContext,
  SelectionEntity,
} from "@abios/abios-ts-sdk"

type MarketObject = {
  type?: MARKET_TYPE
  context?: MarketContext | null
  selection_entity?: { id: number } | null
  selection_context?: SelectionContext | null
}

// Temporary function fix
const flipLine = (
  marketContext: MarketContext | null,
  selectionEntity: SelectionEntity | undefined | null,
) => {
  if (!marketContext?.line || !selectionEntity?.id) return

  const tempMarketContext = { ...marketContext }

  if (
    selectionEntity.id !== marketContext?.team?.id &&
    tempMarketContext.line
  ) {
    tempMarketContext.line *= -1
  }

  // eslint-disable-next-line consistent-return
  return tempMarketContext
}

export const createBetBuilderObjects = (
  markets: { [key: number]: Market },
  selectedMarket: { [key: number]: number },
) => {
  const matches: { id: number; markets: MarketObject[] }[] = []

  Object.entries(selectedMarket).forEach(([key, value]) => {
    const marketObject: MarketObject = {}

    const market = markets[Number(key)]
    const selection = market.selections.find((sel) => {
      return sel.id === value
    })

    const lineFlipped = flipLine(market.context, selection?.entity)
    // Flip the line in market context
    if (!lineFlipped) {
      marketObject.context = market.context || null
    } else {
      marketObject.context = lineFlipped
    }

    marketObject.type = market.type
    marketObject.selection_context = selection?.context || null

    // Competitor id
    if (market.context) {
      if (market.context.player?.id) {
        marketObject.selection_entity = { id: market.context.player.id }
      } else if (market.context.team?.id) {
        marketObject.selection_entity = { id: market.context.team.id }
      }
    }
    // Competitor id inside a selection
    if (selection?.entity?.id) {
      marketObject.selection_entity = { id: selection.entity.id }
    }

    const matchId = market.fixture.id
    const hasMatchId = matches.some((match) => match.id === matchId)

    if (hasMatchId) {
      matches.forEach((match) => {
        if (match.id !== matchId) return

        match.markets.push(marketObject)
      })
    } else {
      matches.push({ id: matchId, markets: [marketObject] })
    }
  })

  return matches
}
