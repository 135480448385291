import {
  StyledContainer,
  StyledTitle,
  StyledDescription,
  StyledButtonContainer,
} from "./NoDataCard.styles"

type Props = {
  title: string
  subText: string
  children?: JSX.Element | JSX.Element[]
}

export const NoDataCard = ({ title, subText, children }: Props) => {
  return (
    <StyledContainer>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{subText}</StyledDescription>
      <StyledButtonContainer>{children}</StyledButtonContainer>
    </StyledContainer>
  )
}
