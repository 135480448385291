import styled from "styled-components"

const StyledFilterActivatedBlob = styled.div`
  border-radius: 100%;
  background: #464656;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  width: 1.125rem;
  height: 1.125rem;
  text-align: center;
  font-size: 0.6875rem;
  line-height: 1.125rem;
  margin-right: -0.3125rem;
  margin-top: -0.3125rem;
`

type Props = {
  active: number
}

export const FilterActivatedBlob = ({ active }: Props) => {
  if (active <= 0) return null

  return <StyledFilterActivatedBlob>{active}</StyledFilterActivatedBlob>
}
