import { useRef, useState } from "react"

import { GAMEID } from "@abios/abios-ts-sdk"
import { GameIcon } from "./TournamentGameIcon"
import {
  StyledContent,
  StyledHeader,
  StyledArrowDown,
  StyledSeries,
  StyledSection,
  StyledAlignText,
} from "./Tournament.styles"

type Props = {
  gameId: GAMEID
  title: string
  series: JSX.Element[]
}

export const Tournament = ({ gameId, title, series }: Props) => {
  const d = useRef<HTMLSelectElement>(null)
  const dInner = useRef<HTMLDivElement>(null)
  const [shouldHide, setShouldHide] = useState(false)

  const setHeightRefs = () => {
    if (!d.current || !dInner.current) {
      return
    }

    d.current.style.height = `${dInner.current.clientHeight}px`
    if (d.current.clientHeight) {
      d.current.style.height = `${0}px`
      setShouldHide(true)
    } else {
      setShouldHide(false)
    }
  }

  return (
    <StyledContent>
      <StyledHeader>
        <StyledAlignText>
          <GameIcon gameId={gameId} />
          <h3>{title}</h3>
        </StyledAlignText>

        <StyledArrowDown
          deg={shouldHide ? "180deg" : "0"}
          onClick={setHeightRefs}
        />
      </StyledHeader>

      <StyledSection ref={d}>
        <div ref={dInner}>
          <StyledSeries>{series}</StyledSeries>
        </div>
      </StyledSection>
    </StyledContent>
  )
}
