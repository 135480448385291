import axios from "axios"
import { useEffect, useState } from "react"
import AthenaAPI from "services/athena"
import { WidgetNamespace } from "types/widget_namespace"

const requestWidgetNamespaces = async (
  signal: AbortSignal,
): Promise<WidgetNamespace[]> => {
  const data = await AthenaAPI.get("v1/widget-namespace", { signal })
  const { widget_namespaces: widgetNamespace } = data.data
  return widgetNamespace
}

export const useWidgetNamespace = (): {
  widgetNamespaces: WidgetNamespace[]
  error?: string
  isLoading: boolean
} => {
  const [widgetNamespace, setWidgetNamespace] = useState<WidgetNamespace[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>()

  useEffect(() => {
    const controller = new AbortController()

    const getWidgetNamespace = async () => {
      setIsLoading(true)
      try {
        setWidgetNamespace(await requestWidgetNamespaces(controller.signal))
        setError(undefined)
      } catch (err) {
        if (axios.isCancel(err)) {
          return
        }
        setError(err.message)
      } finally {
        setIsLoading(false)
      }
    }

    getWidgetNamespace()
    return () => {
      controller.abort()
    }
  }, [])

  return {
    widgetNamespaces: widgetNamespace,
    isLoading,
    error,
  }
}
