import {
  useRef,
  useState,
  useEffect,
  useContext,
  SyntheticEvent,
  FormEvent,
} from "react"
import { AxiosResponse } from "axios"

import AthenaAPI from "services/athena"
import { useOnClickOutside } from "hooks"
import type { CreateCustomerAgent } from "types/athena/CreateCustomerAgent"
import {
  StyledLabel,
  StyledInviteButton,
  StyledInnerContent,
  StyledFooter,
  StyledContent,
  StyledInput,
  StyledOverlay,
} from "./Invite.styles"
import { FormMessage, FormResponse } from "./FormMessage"
import { XCustomerContext } from "../../contexts/XCustomer"

type Props = {
  hide: () => void
  reloadUsers: () => void
}

export const Invite = ({ hide, reloadUsers }: Props) => {
  const ref = useRef(null)
  const xcustomer = useContext(XCustomerContext)
  const [customerId, setCustomerId] = useState("abiosdev")
  const [isPosting, setIsPosting] = useState(false)
  const [email, setEmail] = useState("")
  const [formResponse, setFormResponse] = useState<FormResponse>({
    status: undefined,
    message: undefined,
  })

  useOnClickOutside(ref, () => hide())

  useEffect(() => {
    // Check if xcustomer is used
    if (xcustomer?.customer) {
      setCustomerId(xcustomer.customer.public_id)
    }
  }, [xcustomer])

  const onClickSend = async (event: SyntheticEvent) => {
    event.preventDefault()

    if (isPosting) {
      return
    }
    setIsPosting(true)

    if (formResponse.message) {
      // Clear old form message
      setFormResponse({ status: undefined, message: undefined })
    }

    let createCustomerAgentResp: AxiosResponse<CreateCustomerAgent>
    try {
      createCustomerAgentResp = await AthenaAPI.post<CreateCustomerAgent>(
        `v1/customer/${customerId}/agent`,
        {
          email,
        },
      )
    } catch (e) {
      let errorMsg = "Failed to create user"
      if (e.response.data?.error_type === "invalid_email_address") {
        errorMsg = "Please enter a valid email address"
      }
      setFormResponse({
        status: e.response.status,
        message: errorMsg,
      })
      setIsPosting(false)
      return
    }

    // Send out email invitation
    try {
      await AthenaAPI.post(
        `v1/customer/agent/${createCustomerAgentResp.data.customer_agent.user.id}/invitation`,
        null,
      )
    } catch (e) {
      setFormResponse({
        status: e.response.status,
        message: "Failed to send out invitation",
      })
      setIsPosting(false)
      return
    }

    setFormResponse({
      status: 204,
      message: "User is now invited",
    })
    setIsPosting(false)
    setEmail("")
    reloadUsers()
  }

  const onChangeEmailInput = (e: FormEvent<HTMLInputElement>) => {
    if (formResponse.message) {
      setFormResponse({
        status: undefined,
        message: undefined,
      })
    }
    setEmail(e.currentTarget.value)
  }

  return (
    <StyledOverlay>
      <StyledContent ref={ref}>
        <form onSubmit={onClickSend}>
          <StyledLabel>Invite user</StyledLabel>
          <StyledInnerContent>
            <StyledInput
              value={email}
              onChange={onChangeEmailInput}
              placeholder="Email address"
            />
          </StyledInnerContent>
          <StyledFooter>
            <FormMessage response={formResponse} />
            <StyledInviteButton onClick={onClickSend} disabled={!email}>
              Send
            </StyledInviteButton>
          </StyledFooter>
        </form>
      </StyledContent>
    </StyledOverlay>
  )
}
