import { AthenaCustomer } from "@abios/abios-ts-sdk"

export enum CUSTOMER_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  OVERDUE = 2,
  UNAUTHORIZED = 3,
  NO_CLIENTS = 4,
}

export type Customer = AthenaCustomer & {
  // Temporary for x-customer functionality
  internal_id?: number
}
