import styled from "styled-components"

const StyledContent = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid var(--border-default);
  box-shadow: 0 5px 15px -3px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 230px;
  justify-content: center;
  margin-bottom: 25px;
`

type Props = {
  message: string
}

export const EmptyView = ({ message }: Props) => (
  <StyledContent>
    <h2>{message}</h2>
  </StyledContent>
)
