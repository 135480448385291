import styled from "styled-components"

export const ErrorText = styled.span`
  position: absolute;
  bottom: -18px;
  font-size: 12px;
  color: var(--error-color);
`

export const StyledLabel = styled.label`
  position: relative;
`

export const StyledInput = styled.input`
  padding: 10px 12px;
  border: 1px solid var(--border-default);
  border-radius: 4px;
  width: 100%;
  &:focus {
    outline: none !important;
    border: 1px solid var(--primary-accent);
  }
`
