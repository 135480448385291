import { DeleteIcon } from "atoms/icons"
import { PenIcon } from "atoms/icons/PenIcon"
import { useOnClickOutside } from "hooks"
import {
  SyntheticEvent,
  useCallback,
  useRef,
  useState,
  MouseEvent,
} from "react"
import { useNavigate } from "react-router-dom"
import { Client3 } from "types/client3"
import { Dots } from "../../../customers/components/Dots"
import {
  StyledOption,
  StyledLi,
  StyledUl,
} from "../../../customers/components/Options.styles"
import { useModalStore } from "../store/useModalStore"

type Props = {
  client: Client3
}

type ListProps = Props & {
  closeMenu: () => void
}

const List = ({ client, closeMenu }: ListProps) => {
  const navigate = useNavigate()
  const { setIsDeleteModalOpen, setTargetId } = useModalStore()

  const onMouseDown = (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const onRedirect = (url: string) => {
    navigate(url)
  }

  return (
    <StyledUl onClick={onMouseDown} onMouseDown={onMouseDown}>
      <StyledLi onClick={() => onRedirect(`/api-clients/${client.id}/edit`)}>
        <PenIcon />
        <p>Edit client</p>
      </StyledLi>
      <StyledLi
        onClick={() => {
          setIsDeleteModalOpen(true)
          setTargetId(client.id)
          closeMenu()
        }}>
        <DeleteIcon size="14" />
        <p>Delete client</p>
      </StyledLi>
    </StyledUl>
  )
}

export const Options = ({ client }: Props) => {
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)

  useOnClickOutside(ref, () => setOpen(false))

  const onClick = useCallback(
    (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      e.stopPropagation()
      setOpen(!isOpen)
    },
    [isOpen, setOpen],
  )

  return (
    <StyledOption
      ref={ref}
      className={isOpen ? "open" : ""}
      onClick={(e) => onClick(e)}>
      <Dots />

      {isOpen && <List client={client} closeMenu={() => setOpen(false)} />}
    </StyledOption>
  )
}
