// TODO: Move this to storybook and split out the SVGs!
// TODO: move colors to tokens
import styled, { css } from "styled-components"
import { CLIENT_STATUS } from "types/client3"
import { TickIcon, CrossIcon } from "atoms/icons"

const StyledCircle = styled.div<{ status: CLIENT_STATUS }>`
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 18px;
  width: 18px;

  ${({ status }) => {
    switch (status) {
      case CLIENT_STATUS.ACTIVE:
        return css`
          background: var(--success-accent);
        `
      case CLIENT_STATUS.WARNING:
        return css`
          background: var(--warning-accent);
        `
      default:
        return css`
          background: var(--inactive-accent);
        `
    }
  }};
`

type Props = {
  status: CLIENT_STATUS
}

export const StatusCircle = ({ status }: Props) => {
  let icon: JSX.Element | null = null

  switch (status) {
    case CLIENT_STATUS.ACTIVE:
      icon = <TickIcon color="var(--text-success)" />
      break
    case CLIENT_STATUS.WARNING:
      icon = <TickIcon color="var(--text-warning)" />
      break
    case CLIENT_STATUS.INACTIVE:
      icon = <CrossIcon color="var(--text-inactive)" />
      break
    default:
  }

  return <StyledCircle status={status}>{icon}</StyledCircle>
}
