import { Checkbox, InfoPage } from "@abios/smorgasbord-components"
import styled, { keyframes } from "styled-components"

export const FullPageContainer = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100vh;

  background-color: white;

  padding: 2rem;

  display: flex;
  flex-direction: column;

  align-items: center;
`

export const FullPageWrapper = styled.div`
  width: 100%;
  max-width: 1600px;
`

export const TitleSections = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const AbiosLogo = styled.img`
  height: 40px;
`

export const AbiosTitle = styled.h1`
  margin-top: 3px;
  color: var(--primary-accent);
`

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;

  padding-top: 2rem;
`

const trailAgreementContainerAnim = keyframes`
to { 
  opacity: 1;
  transform: translateY(0px);
}
`

export const AnimContainer = styled.div<{ animDelay?: number }>`
  opacity: 0;
  transform: translateY(100px);

  animation: ${trailAgreementContainerAnim} 500ms ease forwards;

  animation-delay: ${({ animDelay }) => animDelay || 0}ms;
`

export const TrailAgreementContainer = styled.div``

export const TrialFromContainer = styled.div`
  position: sticky;
  top: 2rem;
  align-self: flex-start;
`

export const AgreementHeader = styled.h2`
  font-size: var(--text-font-size-lg);
`

export const ContentRow = styled.div<{ mTop?: number; mBottom?: number }>`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  row-gap: 2rem;
  flex-wrap: wrap;
  margin-top: ${({ mTop }) => mTop || 0}rem;
  margin-bottom: ${({ mBottom }) => mBottom || 0}rem;

  & > span {
    // Width solution for dropdown which takes up 100% space.
    // Using width 0% makes sure they are split up even width.
    width: 0%;
    flex-grow: 1;
    min-width: 375px;

    margin-top: 1rem;
  }

  & > button {
    flex-grow: 1;
    margin-top: 1rem;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  box-sizing: content-box;
`

export const SigningLoadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 1rem;
  margin-top: 4rem;

  color: var(--gray-500);
`

export const SigningLoadingText = styled.p`
  color: var(--gray-600);
  font-size: var(--text-font-size-xl);
`

export const AccessAgreementEmptyStateError = styled(InfoPage)`
  // 100 height - 40px for the Abios logo at the top, and then the whole page is surrounded by 2rem
  // so we remove in total 4rem for top and bottom.
  height: calc(100vh - 40px - 4rem);

  & > div {
    max-width: 800px;
  }

  & > div > h2 {
    font-size: var(--text-font-size-xl);
    line-height: var(--text-line-height-xl);

    margin-bottom: 0.5rem;
  }

  & > div > div {
    font-size: var(--text-font-size-md);
    line-height: var(--text-line-height-sm);
  }
`
