import { StoreApi } from "zustand"
import oddsAPI from "services/odds"
import { cErr } from "utils/debug"
import {
  FIXTURE_TYPE,
  MAX_MARKET_PAGES,
  Market,
  PAGE_SIZE,
} from "@abios/abios-ts-sdk"
import { ALLOWED_STATES_LIST } from "pages/trading/types/State"
import { State } from "./State"
import { addList } from "./AddList"

export const fetchMarketsByTypeAndIds = async (
  set: StoreApi<State>["setState"],
  get: StoreApi<State>["getState"],
  controller: AbortController,
  type: FIXTURE_TYPE,
  ids: number[],
): Promise<number[]> => {
  if (ids.length === 0) {
    return []
  }

  // Fetch markets from the API
  let newMarkets: Market[] = []
  let marketIds: number[] = []

  try {
    for (let i = 0; i < MAX_MARKET_PAGES; i += 1) {
      let url = `markets?order=id-desc&filter=fixture.type=${type},state<={${ALLOWED_STATES_LIST.join()}},fixture.id<={${ids.join()}}`

      if (i > 0) {
        url += `&skip=${i * PAGE_SIZE}`
      }

      // eslint-disable-next-line no-await-in-loop
      const resp = await oddsAPI.get<Market[]>(url, {
        signal: controller.signal,
      })

      if (resp?.data) {
        for (let j = 0; j < resp.data.length; j += 1) {
          newMarkets = newMarkets.concat(resp.data[j])
          marketIds = marketIds.concat(resp.data[j].id)
        }

        if (resp.data.length < PAGE_SIZE) {
          break
        }
      } else {
        break
      }
    }
  } catch (e) {
    // TODO: throw and handle error
    cErr("unable to get markets from odds API", e)
  }

  // Add markets to state
  addList(set, get, newMarkets)
  return marketIds
}
