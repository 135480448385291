export enum E_MARKET_TEMPLATE {
  TWO_COLUMN_TEAM,
  THREE_COLUMN_TEAM,
  TWO_COLUMN_YESNO,
  THREE_COLUMN_TEAM_YES_NO,
  THREE_COLUMN_EMPTY_TEAM,
  THREE_COLUMN_TOTAL_OVER_UNDER,
  THREE_COLUMN_ENTITY_OVER_UNDER,
  FOUR_COLUMN_TEAM,
  THREE_COLUMN_TEAM_PROPS,
  CORRECT_SERIES_SCORE,
}
