import styled from "styled-components"

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  max-width: 1280px;
  padding: 60px 20px 0;
  width: 100%;
`
