import styled from "styled-components"

export const Package = styled.div`
  padding: 10px 0 24px 0;
`

export const H2 = styled.h2`
  line-height: 24px;
  font-weight: 700;
`

export const HermesTitle = styled(H2)`
  padding-bottom: 0.5rem;
`

export const Games = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 16px 4px;
`
