import styled from "styled-components"

export const StyledContent = styled.div`
  padding-right: 16px;
  width: 250px;
`

export const StyledCompetitor = styled.div`
  align-items: center;
  display: flex;
  height: 25px;
  margin-top: 2px;
  &:not(:first-child) {
    margin-top: 3px;
  }
`

export const StyledText = styled.p`
  color: var(--text-primary);
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 18px;
`

export const StyledFigure = styled.figure`
  height: 20px;
  margin-right: 10px;
  width: 20px;
`

export const StyledLogo = styled.img`
  display: block;
  width: 100%;
`

export const StyledIcon = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  // TODO: Move this color to TOKEN so we can reuse between widget and dashboard?
  background-color: #333333;

  &[data-home="true"] {
    background-color: #299b59;
  }
`

export const StyledEmptyIcon = styled.div`
  // TODO: Move icon size to smorgasbord token
  height: 20px;
  width: 20px;
  background-color: transparent;
`
