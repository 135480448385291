import { CLIENT_STATUS } from "types/client3"
import { EXPIRED_DAYS } from "constants/dates"

export const getClientStatus = (activeUntil: Date | null): CLIENT_STATUS => {
  let status: CLIENT_STATUS = CLIENT_STATUS.INACTIVE

  // Expired customers have activeUntil set and should be expiring within 30 days
  const activeDate: Date = new Date()
  activeDate.setDate(activeDate.getDate() + EXPIRED_DAYS)

  if (activeUntil === null || activeUntil.getTime() > activeDate.getTime()) {
    status = CLIENT_STATUS.ACTIVE
  } else if (
    activeUntil !== null &&
    activeUntil.getTime() >= new Date().getTime() &&
    activeUntil.getTime() <= activeDate.getTime()
  ) {
    status = CLIENT_STATUS.WARNING
  } else {
    status = CLIENT_STATUS.INACTIVE
  }
  return status
}
