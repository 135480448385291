/* eslint-disable @typescript-eslint/naming-convention */
import {
  AthenaAccountManager,
  AthenaCustomer,
  AthenaCustomerAccountManager,
  useToggle,
} from "@abios/abios-ts-sdk"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import athena from "services/athena"
import { cErr } from "utils/debug"
import * as jsonpatch from "fast-json-patch"
import moment from "moment"
import {
  CreateCustomerErrorType,
  CreateCustomerType,
  CUSTOMER_AGREEMENT_STATUS,
  emptyCustomerError,
} from "../types"
import { useFetchAccountManagers } from "./useFetchAccountMangers"
import { customerUpdateValidation } from "../update/utils/customerUpdateValidation"

export const emptyCustomer: CreateCustomerType = {
  customerName: "",
  activeUntil: "",
  billingMethod: "Stripe",
  stripedId: "",
  accountManagers: [],
  fullCompanyName: "",
  companyNumber: "",
  country: "",
  address1: "",
  address2: "",
  zipcode: "",
  city: "",
}

export const useUpdateCustomer = () => {
  const params = useParams()
  const navigate = useNavigate()

  const { managers } = useFetchAccountManagers()

  const [currentCustomer, setCurrentCustomer] =
    useState<CreateCustomerType>(emptyCustomer)

  const [previousCustomer, setPreviousCustomer] =
    useState<Partial<AthenaCustomer>>()

  const [validationErrors, setValidationErrors] =
    useState<CreateCustomerErrorType>(emptyCustomerError)

  // Error handling -- Pre patch
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [hasFetchError, setHasFetchError] = useState<boolean>(false)

  // Error handling -- During patch
  const [isSendingPatch, setIsSendingPatch] = useState<boolean>(false)
  const [hasPatchError, setHasPatchError] = useState<boolean>(false)

  // Successful patch handling
  const [hasSuccessfulPatch, setSuccessfulPatch] = useState<boolean>(false)

  const {
    isActive: hasTrailAgreement,
    toggle: onToggleTrailAgreement,
    open: enableTrialAgreement,
  } = useToggle(false)

  const hasStripe = currentCustomer.billingMethod === "Stripe"

  const formatAccountManagers = (fetchedManagers: AthenaAccountManager[]) => {
    return fetchedManagers.map((manager) => {
      let managerName = ""

      managers.forEach((m) => {
        if (m.user.id === manager.user.id) {
          // if the manager doesn't have a name, use the first part of the email
          managerName = m.user.name || m.user.email.split("@")[0]
        }
      })

      return {
        id: manager.user.id,
        name: managerName,
      }
    })
  }

  useEffect(() => {
    if (!managers.length) return

    if (!params.id) {
      navigate("/admin/customers/all")
      return
    }

    const getCustomerDetails = async () => {
      try {
        setIsFetching(true)

        const customerURL = `/v1/customer?customer=${params.id}`
        const customerResp = await athena.get(customerURL)

        const {
          stripe_id,
          active_until,
          external_name,
          account_managers,
          trial_agreement_status,
          resource_version,
        } = customerResp.data.customers[0]

        // Status is either unsigned or signed if true

        const hasTrialAgreementData =
          trial_agreement_status !==
          CUSTOMER_AGREEMENT_STATUS.NO_TRIAL_AGREEMENT

        let customerAgreementData = {}

        if (hasTrialAgreementData) {
          const customerAgreementURL = `/v1/customer/${params.id}/trial-agreement/unsigned`
          const customerAgreementResp = await athena.get(customerAgreementURL)

          const { parameters } = customerAgreementResp.data.agreement

          customerAgreementData = {
            fullCompanyName: parameters.company.name,
            companyNumber: parameters.company.number,
            country: parameters.company.registered_address.country,
            address1: parameters.company.registered_address.address_line_1,
            address2:
              parameters.company.registered_address.address_line_2 || "",
            zipcode: parameters.company.registered_address.zip_code,
            city: parameters.company.registered_address.city,
          }

          enableTrialAgreement()
        }

        const formattedAccountManagers = formatAccountManagers(account_managers)

        setCurrentCustomer((prev) => {
          return {
            ...prev,

            ...customerAgreementData,

            customerName: external_name,

            billingMethod: stripe_id ? "Stripe" : "Other",

            stripedId: stripe_id || "",

            accountManagers: formattedAccountManagers,

            // get the first part of the timestamp, ex: '2023-12-24'
            activeUntil: active_until ? active_until.split("T")[0] : "",
          }
        })

        // sets the current fetched customer data that will be used to
        // json patch diff when updating
        setPreviousCustomer({
          account_managers,
          active_until,
          external_name,
          stripe_id,
          resource_version,
        })
      } catch (e) {
        setHasFetchError(true)
        cErr(e)
      } finally {
        setIsFetching(false)
      }
    }

    getCustomerDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managers, params.id])

  const setCustomerDetails = (key: keyof CreateCustomerType, value: string) => {
    setCurrentCustomer((prev) => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  const onSelectManager = (id: string, name: string) => {
    setCurrentCustomer((prev) => {
      return {
        ...prev,
        accountManagers: [...prev.accountManagers, { id, name }],
      }
    })
  }

  const onRemoveManager = (managerId: string) => {
    setCurrentCustomer((prev) => {
      return {
        ...prev,
        accountManagers: prev.accountManagers.filter((m) => m.id !== managerId),
      }
    })
  }

  const onCustomerUpdate = async () => {
    // If a request has been sent, return
    if (isSendingPatch) return

    /*
     * Validation section
     */
    setValidationErrors(emptyCustomerError)
    // check for validation errors
    const {
      error: hasError,
      key: validationKey,
      message: validationMessage,
    } = customerUpdateValidation(currentCustomer, hasStripe)

    if (hasError) {
      setValidationErrors((prev) => {
        return {
          ...prev,
          [validationKey]: validationMessage,
        }
      })

      return
    }

    /*
     * Format section
     */
    const formattedAccountManagers: AthenaCustomerAccountManager[] =
      currentCustomer.accountManagers.map((m) => {
        return {
          user: {
            id: m.id,
          },
        }
      })

    // When an activeUntil is set in the backend, it is set to expire at the last second
    // of that date. This formats the frontend date to be the same.
    const formattedActiveUntil = currentCustomer.activeUntil
      ? moment
          .utc(currentCustomer.activeUntil, "YYYY-MM-DD")
          .add(0, "hours")
          .add(0, "minutes")
          .add(0, "seconds")
          .toISOString()
          .replace(".000Z", "Z")
      : null

    const shouldAppendStripeId = currentCustomer.billingMethod === "Stripe"

    const newCustomer: Partial<AthenaCustomer> = {
      external_name: currentCustomer.customerName,
      active_until: formattedActiveUntil,
      stripe_id: shouldAppendStripeId ? currentCustomer.stripedId : null,
      account_managers: formattedAccountManagers,
    }

    const resourceVersion = previousCustomer?.resource_version

    // Remove resource version to not add invalid patch
    delete previousCustomer?.resource_version

    const diff = jsonpatch.compare(previousCustomer || {}, newCustomer)

    // No length then there is nothing to update
    if (!diff.length) return

    /*
     * Patch Request section
     */
    const patchPayload = {
      resource_version: resourceVersion,
      patch: diff,
    }

    try {
      setIsSendingPatch(true)
      const patchedCustomerResp = await athena.patch(
        `/v1/customer/${params.id}`,
        patchPayload,
      )

      const patchedCustomerData = patchedCustomerResp.data.customer

      // Update the previous customer with the patched information and resource version
      setPreviousCustomer(() => {
        return {
          external_name: patchedCustomerData.external_name,
          active_until: patchedCustomerData.active_until,
          stripe_id: patchedCustomerData.stripe_id,
          account_managers: patchedCustomerData.account_managers,
          resource_version: patchedCustomerData.resource_version,
        }
      })

      // If the patch has gone from stripe id to null or the other way around
      // we clear or set the stripeId value by adding the patched value
      setCurrentCustomer((prev) => {
        return {
          ...prev,
          stripedId: patchedCustomerData.stripe_id || "",
        }
      })

      setSuccessfulPatch(true)
    } catch (e) {
      setHasPatchError(true)
      cErr(e)
    } finally {
      setIsSendingPatch(false)
    }
  }

  return {
    managers,
    hasFetchError,
    hasStripe,
    isFetching,
    hasPatchError,
    isSendingPatch,
    onRemoveManager,
    onSelectManager,
    currentCustomer,
    onCustomerUpdate,
    validationErrors,
    hasTrailAgreement,
    setCustomerDetails,
    hasSuccessfulPatch,
    onToggleTrailAgreement,
  }
}
