import styled from "styled-components"

export const StyledButton = styled.button`
  cursor: pointer;
  font-weight: 600;
  padding: 0.6rem 0.3rem;
  text-align: left;
  user-select: none;
  transition: all 50ms ease-in;
  border-radius: 4px;

  &:hover {
    background: var(--primary-accent);
    color: var(--text-white);
  }
`
