import { useContext } from "react"

import { client3Context } from "../Client3Context"
import { PreviewClient } from "./PreviewClient"
import { Aside, Span, StyledHeader } from "./Sidebar.styles"

export const Sidebar = () => {
  const client3Con = useContext(client3Context)

  if (!client3Con.client3.id) {
    return null
  }

  const { client3 } = client3Con

  return (
    <Aside>
      <StyledHeader>
        <Span>API Client</Span>
      </StyledHeader>
      <PreviewClient client={client3} />
    </Aside>
  )
}
