import cloneDeep from "lodash/cloneDeep"
import { StoreApi } from "zustand"
import { MARKET_STATE, MARKET_TYPE } from "@abios/abios-ts-sdk"
import { State } from "../State"

const validateSelection = (
  selFixtureType: string,
  selMarketType: MARKET_TYPE,
  marketState: MARKET_STATE,
) => {
  if (selFixtureType === "series") return false

  if (selMarketType === MARKET_TYPE.FIRST_TEAM_TO_ROUNDS) return false

  if (marketState !== MARKET_STATE.OPEN) return false

  return true
}

export const onMarketSelect = (
  set: StoreApi<State>["setState"],
  get: StoreApi<State>["getState"],
  marketId: number,
  selectionId: number,
  isSelected: boolean,
  selFixtureType: string,
  selMarketType: MARKET_TYPE,
  matchOrder: number,
) => {
  const { selectedMarkets, markets, selectedMatchOrder } = get()

  const selectedMarketsCloned = cloneDeep(selectedMarkets)
  const selection = selectedMarketsCloned[marketId]

  if (
    !validateSelection(selFixtureType, selMarketType, markets[marketId].state)
  )
    return

  if (selectedMatchOrder !== matchOrder && selectedMatchOrder !== 0) return
  set({ selectedMatchOrder: matchOrder })

  // Only allow 10 markets and if the market isn't already selected
  // If we are at 10 and you select a market that is selected
  // It should fall through this if case
  if (Object.keys(selectedMarketsCloned).length + 1 > 10 && !isSelected) return

  // Add selection to bet builder
  // Swap selections if the id doesn't match
  // Add it if there isn't a current selection
  if (selectionId !== selection) {
    selectedMarketsCloned[marketId] = selectionId
    set({
      selectedMarkets: selectedMarketsCloned,
    })
    return
  }

  // Remove the selection of the market if the user
  // clicked the same selection again
  delete selectedMarketsCloned[marketId]
  set({ selectedMarkets: { ...selectedMarketsCloned } })

  if (!Object.keys(selectedMarketsCloned).length) {
    set({ selectedMatchOrder: 0 })
  }
}
