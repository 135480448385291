import { useEffect, useState } from "react"
import { useParams, Navigate, useSearchParams } from "react-router-dom"

import { useOddsStore, useAtlasStore } from "pages/trading/stores"
import { FIXTURE_TYPE } from "@abios/abios-ts-sdk"
import { Layout } from "./Layout"
import { TAB } from "./types/tab"

export const Single = () => {
  const params = useParams<{ seriesId: string }>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [hasFetched, setHasFetched] = useState(false)
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const { fetchMarketsByTypeAndIds, onClearSelectedBets, onResetMatchOrder } =
    useOddsStore()
  const { series, getMatchIdsFromSeries, getSeriesByIds, getMatchesByIds } =
    useAtlasStore()

  const seriesId =
    (params.seriesId !== undefined && parseInt(params.seriesId)) || undefined
  const seriesSingle = (seriesId !== undefined && series[seriesId]) || undefined

  // TODO: add cancel token
  // Fetch all relevant data needed for this view
  useEffect(() => {
    let didCancel = false
    const abortController = new AbortController()

    const getData = async () => {
      if (seriesId === undefined) return

      if (seriesSingle === undefined) {
        // Get series from the API
        try {
          const seriesResp = await getSeriesByIds([seriesId])
          if (seriesResp.length === 0) {
            setShouldRedirect(true)
          }

          // If no tab found, set Series as default
          // Note: If no tab found, the market id is meaningless
          if (!searchParams.get("tab")) {
            setSearchParams(
              {
                tab: TAB.SERIES,
              },
              { replace: true },
            )
            searchParams.delete("matchid")
          }

          // If the series is best of 1, redirect to match page. because no series markets exist
          if (seriesResp[0].format.best_of === 1 && !searchParams.get("tab")) {
            setSearchParams(
              {
                tab: TAB.MATCH,
                matchid: seriesResp[0].matches[0].id.toString(),
              },
              { replace: true },
            )
          }
        } catch (e) {
          // TODO: handle error
          setShouldRedirect(true)
        }
        return
      }

      // Get matches and odds markets for the matches
      const matchIds = getMatchIdsFromSeries(seriesId)
      if (matchIds.length > 0) {
        try {
          await Promise.all([
            fetchMarketsByTypeAndIds(abortController, FIXTURE_TYPE.MATCH, [
              ...matchIds,
            ]),
            getMatchesByIds(matchIds),
          ])
        } catch (e) {
          // TODO: handle error
        }
      }

      // Get odds markets for series
      try {
        await fetchMarketsByTypeAndIds(abortController, FIXTURE_TYPE.SERIES, [
          seriesId,
        ])
      } catch (e) {
        // TODO: handle error
      }

      if (!didCancel) {
        setHasFetched(true)
      }
    }

    getData()
    return () => {
      didCancel = true
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesId, seriesSingle])

  useEffect(() => {
    return () => {
      onResetMatchOrder()
      onClearSelectedBets()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (shouldRedirect) {
    return <Navigate to="/trading" />
  }

  if (!hasFetched) {
    // TODO: add loading animation
    return null
  }

  // TODO: check if error exist

  return <Layout />
}
