import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"

import { Formatter as currencyFormatter } from "../helpers/Currency"
import { FormatUnixTimestamp } from "../helpers/Dates"

import API from "../utils/api"
import Table from "./../components/Table"
import LoadingContainer from "../components/LoadingContainer"
import ResponseMsg from "./components/ResponseMsgContainer"

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  height: 55px;
`
const Container = styled.div`
  background-color: ${(props) => props.background || "inherit"};
  border: 1px solid var(--border-default);
  border-radius: 6px;
  display: ${(props) => props.display || "block"};
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height || "auto"};
  width: 100%;
`

function invoiceStatus(dueDate) {
  let due = moment(dueDate)
  const timeNow = moment().format("YYYY-MM-DD")
  if (moment(due).isBefore(timeNow)) return true
  return false
}

function Invoices() {
  let containerBackground = "#fff",
    containerHeight = "150px",
    containerDisplay = "flex"
  const [pageCurrent, setPageCurrent] = useState(0)
  const [isFetching, setIsFetching] = useState(true)
  const [invoices, setInvoices] = useState([])
  const [errorMsg, setErrorMsg] = useState("")

  useEffect(() => {
    let didCancel = false

    const GetInvoices = async () => {
      try {
        const data = await API.get(`v1/customer/invoices`)
        if (!didCancel) setInvoices(data)
      } catch (err) {
        if (!didCancel) setErrorMsg(err.message)
      }
      if (!didCancel) setIsFetching(false)
    }

    GetInvoices()
    return () => {
      didCancel = true
    }
  }, [])

  const UpdateCurrentPage = (page) => setPageCurrent(page)

  const invoiceListItem = invoices
    ? invoices.map((invoice) => {
        let totalAmount = 0
        if (
          invoice.charge.amount !== null &&
          invoice.charge.amount.total !== null
        ) {
          totalAmount = currencyFormatter(
            invoice.charge.amount.currency,
            invoice.charge.amount.total,
          )
        }

        return {
          invoice_id: invoice.number,
          invoice_date: `${FormatUnixTimestamp(invoice.created)}`,
          due_date: FormatUnixTimestamp(invoice.charge.due_date),
          amount: totalAmount,
          status: invoice.status,
          charge_status: invoice.charge.status,
          download: invoice.document.hosted ? (
            <div className="invoice-details">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={invoice.document.hosted}
                data-status={invoice.status}>
                <span className="invoice-view-btn">View</span>
              </a>
            </div>
          ) : (
            ""
          ),
        }
      })
    : []

  let listItem = (item, i) => (
    <li key={i} className="table-row">
      <div className="table-cell invoice_id invoice-id-item">
        {item.invoice_id}
      </div>

      <div className="table-cell invoice_date">
        {item.invoice_date
          ? moment(item.invoice_date).format("D MMMM YYYY")
          : ""}
      </div>
      <div className="table-cell due_date">
        {item.due_date ? moment(item.due_date).format("D MMMM YYYY") : ""}
      </div>

      <div className="table-cell status">
        <div className="statuses">
          {item.status === "paid" ? (
            <span className="label-box paid">Paid</span>
          ) : item.status === "open" ? (
            <span className="label-box open">Open</span>
          ) : item.status === "void" ? (
            <span className="label-box void">Void</span>
          ) : null}

          {item.status === "open" && invoiceStatus(item.due_date) ? (
            <span className="label-box due">Past due</span>
          ) : null}

          {item.charge_status === "refunded" ? (
            <span className="label-box refunded">Refunded</span>
          ) : item.charge_status === "payment-failed" ? (
            <span className="label-box payment-failed">Failed</span>
          ) : null}
        </div>
      </div>
      <div className="table-cell amount">{item.amount}</div>
      <div className="table-cell download">{item.download}</div>
    </li>
  )

  if (!isFetching && !errorMsg && invoiceListItem.length > 0) {
    containerBackground = "transparent"
    containerHeight = "auto"
    containerDisplay = "block"
  }

  return (
    <section id="invoice-section">
      <SectionHeader>
        <h2>Invoices</h2>
      </SectionHeader>

      <Container
        background={containerBackground}
        display={containerDisplay}
        height={containerHeight}>
        {isFetching ? (
          <LoadingContainer />
        ) : errorMsg ? (
          <ResponseMsg msg={errorMsg} />
        ) : invoiceListItem.length === 0 ? (
          <ResponseMsg msg="No invoices found" />
        ) : (
          <Table
            className="invoice-table"
            isFetching={isFetching}
            sortingKey="due_date"
            columns={[
              { key: "invoice_id", name: "Invoice ID", sortable: true },
              { key: "invoice_date", name: "Invoice Date", sortable: true },
              { key: "due_date", name: "Due Date", sortable: true },
              { key: "status", name: "Status", sortable: false },
              { key: "amount", name: "Amount", sortable: true },
              { key: "download", name: "Details", sortable: false },
            ]}
            items={invoiceListItem}
            listItem={listItem}
            pageSize={10}
            tableFooter="invoices"
            enableSorting={true}
            pageCurrent={pageCurrent}
            updateCurrentPage={(page) => UpdateCurrentPage(page)}
            tableShowingCountView={true}
          />
        )}
      </Container>
    </section>
  )
}

export default React.memo(Invoices)
