import React, { useEffect, useRef, useCallback } from "react"
import { useSpring, animated } from "react-spring"

const Dropdown = (props) => {
  const node = useRef()
  const modalProps = useSpring({
    config: { duration: 300 },
    opacity: 1,
    from: { opacity: 0 },
  })

  const handleClick = useCallback(
    (e) => {
      if (node.current.contains(e.target)) return
      props.hide() // outside modal click
    },
    [props],
  )

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.keyCode === 27) props.hide()
    }

    document.addEventListener("mousedown", handleClick)
    window.addEventListener("keydown", handleEsc)
    return () => {
      document.removeEventListener("mousedown", handleClick)
      window.removeEventListener("keydown", handleEsc)
    }
  }, [props, handleClick])

  function handleSetDefault(cardID) {
    if (props.card.is_default || props.card.status !== "valid") return
    props.setDefaultCard(cardID)
  }
  function handleRemove(cardID) {
    if (props.card.is_default && props.cardLength > 1) return
    props.handleDeleteCard(cardID)
  }

  return (
    <animated.div id="payment-method-item-container" style={modalProps}>
      <div id="payment-method-item-dropdown" ref={node}>
        <header>
          <span>actions</span>
        </header>

        <ul>
          {props.card.sca_details &&
          props.card.sca_details.status === "requires_action" &&
          props.card.sca_details.redirect_url ? (
            <li onClick={() => props.onClickConfirmCard(props.card.id)}>
              Confirm card
            </li>
          ) : null}

          <li
            onClick={() => handleSetDefault(props.card.id)}
            item-disabled={
              props.card.is_default || props.card.status !== "valid"
                ? "true"
                : "false"
            }>
            Set as default
          </li>

          <li
            onClick={() => handleRemove(props.card.id)}
            item-disabled={
              props.card.is_default && props.cardLength > 1 ? "true" : "false"
            }>
            Remove
          </li>
        </ul>
      </div>
    </animated.div>
  )
}

export default Dropdown
