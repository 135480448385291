import { useState, ReactNode, useEffect } from "react"
import orderBy from "lodash/orderBy"

import PageContent from "jsx/components/PageContent"
import { useAtlasStore, useOddsStore } from "pages/trading/stores"
import type {
  Tournament as TournamentType,
  Series as SeriesType,
} from "@abios/abios-ts-sdk"
import LoadingContainer from "jsx/components/LoadingContainer"
import { Header } from "./Header"
import { Tournament } from "./Tournament"
import { Item } from "./series/Item"
import { EmptyView } from "./EmptyView"
import { StyledLoadingContainer } from "./ListOverview.styles"
import { getTournamentIdsFromMarkets } from "./utils/TournamentsFromMarkets"

export const ListView = () => {
  const [tourneys, setTourneys] = useState<TournamentType[]>([])
  const [hasFetched, setHasFetched] = useState(false)
  const { markets, isFetchingResources } = useOddsStore()
  const { series, tournaments, getTournamentsByIds, matches } = useAtlasStore()
  useEffect(() => {
    let didCancel = false

    const getTournaments = () => {
      if (isFetchingResources) return

      // helper function to gets all the tournament ids from markets
      // that we are interested in
      const tournamentIds = getTournamentIdsFromMarkets(
        markets,
        series,
        matches,
      )
      if (tournamentIds.length === 0) {
        setHasFetched(true)
        return
      }
      const tournamentList = getTournamentsByIds(tournamentIds)
      // Sort list by title
      const listSorted = orderBy(tournamentList, ["title"], ["asc"])
      if (!didCancel) {
        if (tournamentList.length > 0) {
          setTourneys(listSorted)
        }

        setHasFetched(true)
      }
    }

    getTournaments()
    return () => {
      didCancel = true
    }
  }, [
    isFetchingResources,
    getTournamentsByIds,
    markets,
    series,
    tournaments,
    matches,
  ])

  const list = tourneys.reduce((res: ReactNode[], t) => {
    const seriesList: SeriesType[] = []

    // Get tournament series
    Object.keys(series).forEach((key) => {
      const k = parseInt(key)
      if (
        series[k].tournament.id === t.id &&
        series[k].deleted_at === null &&
        series[k].start !== null &&
        series[k].end === null
      ) {
        seriesList.push(series[k])
      }
    })

    if (seriesList.length === 0) {
      return res
    }

    // Sort series by start time
    const seriesSorted = orderBy(seriesList, ["start"], ["asc"])

    const seriesJSX = seriesSorted.map((s) => (
      <Item key={s.id.toString()} series={s} />
    ))

    res.push(
      <Tournament
        key={t.id.toString()}
        series={seriesJSX}
        title={t.title}
        gameId={t.game.id}
      />,
    )

    return res
  }, [])

  if (!hasFetched) {
    return (
      <StyledLoadingContainer>
        <LoadingContainer
          background="transparent"
          height="calc(100% - 132px)"
        />
      </StyledLoadingContainer>
    )
  }

  let content: ReactNode = list
  if (list.length === 0) {
    content = <EmptyView message="No upcoming series available" />
  }

  return (
    <PageContent>
      <Header />
      {content}
    </PageContent>
  )
}
