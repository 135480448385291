import { memo } from "react"
import { PushChannel } from "types/push_channels"
import { Badge, STATUS } from "atoms/badge"
import { IndexTable, ROW_SIZE } from "@abios/smorgasbord-components"
import {
  StyledLink,
  StyledReference,
  StyledChannelName,
  StyledAccessContent,
  StyledTextDescription,
} from "./PushChannelRow.styles"

const DOCS_FALLBACK_URL =
  "https://abiosgaming.com/docs/en/push-api/introduction/available-channels"

type Props = {
  readonly channel: PushChannel
}

export const PushChannelRow = memo(({ channel }: Props) => {
  let badge = <Badge status={STATUS.SUCCESS}>Active</Badge>
  if (!channel.hasAccess) {
    badge = <Badge status={STATUS.DEFAULT}>Inactive</Badge>
  }

  return (
    <IndexTable.Row id={channel.name} key={channel.name} size={ROW_SIZE.lg}>
      <IndexTable.Cell>
        <StyledChannelName>{channel.name}</StyledChannelName>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StyledTextDescription>{channel.description}</StyledTextDescription>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StyledAccessContent>{badge}</StyledAccessContent>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StyledReference>
          <StyledLink
            href={channel.api_reference || DOCS_FALLBACK_URL}
            target="_blank">
            Channel Reference
          </StyledLink>
        </StyledReference>
      </IndexTable.Cell>
    </IndexTable.Row>
  )
})
