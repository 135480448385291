import {
  FC,
  createContext,
  useEffect,
  useState,
  ReactNode,
  useMemo,
} from "react"
import { useParams } from "react-router-dom"
import { STATUS } from "hooks/usefetch/types"
import { Client3APIResponse, Client3 } from "types/client3"
import athenaAPI from "services/athena"
import { cErr } from "utils/debug"

type Props = {
  status: STATUS
  client3: Client3
}

type PropsClient3Provider = {
  children: ReactNode
}

export const client3Context = createContext<Props>({
  status: STATUS.FETCHING,
  client3: {} as Client3,
})

// TODO: Deprecated
export const Client3Provider: FC<PropsClient3Provider> = ({
  children,
}: PropsClient3Provider) => {
  const { clientId } = useParams<{ clientId: string }>()
  const [status, setStatus] = useState<STATUS>(STATUS.FETCHING)
  const [client, setClient] = useState<Client3>({} as Client3)

  useEffect(() => {
    let didCancel = false

    const getClient3 = async () => {
      try {
        const url = `v1/client3?client=${clientId}`
        const resp = await athenaAPI.get<Client3APIResponse>(url)
        if (resp.data.clients) {
          const clientsLength = resp.data.clients.length

          if (clientsLength === 1 && !didCancel) {
            setClient(resp.data.clients[0])
          }
        }
      } catch (e) {
        // TODO: show error page for user
        cErr("unable to fetch client3 from the API", e)
      }
      if (!didCancel) {
        setStatus(STATUS.FETCHED)
      }
    }

    getClient3()
    return () => {
      didCancel = true
    }
  }, [clientId])

  const contextValues = useMemo(
    () => ({ status, client3: client }),
    [status, client],
  )

  if (status === STATUS.FETCHING) {
    return null
  }

  return (
    <client3Context.Provider value={contextValues}>
      {children}
    </client3Context.Provider>
  )
}
