import { AthenaCreateClientPayload } from "@abios/abios-ts-sdk"
import AthenaAPI from "services/athena"
import { Client3 } from "types/client3"

export const createClient = async (
  client: AthenaCreateClientPayload,
  publicID: string,
) => {
  let data
  let error = null

  try {
    const res = await AthenaAPI.post<{ client: Client3 }>(
      `v1/customer/${publicID}/client3`,
      client,
    )
    data = res.data
  } catch (e) {
    error = e
  }

  return {
    data,
    error,
  }
}
