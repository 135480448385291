import { StyledContent, StyledFigure, StyledTooltip } from "./GameFigure.styles"

type Props = {
  isActive: boolean
  iconUrl: string
  title: string
}

export const GameFigure = ({ isActive, iconUrl, title }: Props) => (
  <StyledContent>
    <StyledTooltip>{title}</StyledTooltip>
    <StyledFigure src={iconUrl} isActive={isActive} />
  </StyledContent>
)
