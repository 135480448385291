import { StyledContent, StyledTitle, StyledText } from "./EmptyFeedback.styles"

type Props = {
  readonly title: string
  readonly text?: string
  children?: JSX.Element | JSX.Element[]
}

export const EmptyFeedback = ({ title, text, children }: Props) => (
  <StyledContent>
    <StyledTitle>{title}</StyledTitle>
    {text && <StyledText>{text}</StyledText>}
    {children}
  </StyledContent>
)
