import styled from "styled-components"

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: odds-data;

  @media (max-width: 1200px) {
    margin-top: 1rem;
  }
`

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "widget" "odds-data";

  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    grid-template-areas: "odds-data widget";
    grid-template-columns: 1fr minmax(288px, 428px);

    margin-bottom: 2.5rem;
  }
`
