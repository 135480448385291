import { createGlobalStyle } from "styled-components"

/* eslint-disable-next-line @typescript-eslint/naming-convention */
export const Theme = createGlobalStyle`
:root {

  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  --font-family-mono: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  --text-font-weight: 400;

  --heading-color: #242830;
  --heading-font-family: var(--font-family);

  --text-primary-color: #484858;
  --text-light-color: #67768E;
  --text-link-color: #5F96FA;
  --text-green-color: #69D2A0;

  --border-default: #E3E8EE;
  --light-gray: #fafafa;

  --error-color: #ca2e16;
  --resource-color: #3565e9;
  --resource-color-light: #5f96fa;

  /** 1.0 Sidebar */

  --sidebar-width: 280px;
  --sidebar-color-secondary: #768A9F;
  --sidebar-color-primary: #5F96FA;
  --sidebar-color-disabled: #CECECE;
  --sidebar-sub-title-color: #949DA4;
  --sidebar-border-color: #EFEFEF;

  /** 2.0 Badge */

  --badge-success-background-color: #B4EEB4;
  --badge-success-color: #285344;
  --badge-default-background-color: #E8EBED;
  --badge-default-color: #4D5156;
  --badge-warning-background-color: #FDE99B;
  --badge-warning-color:#6E5502;

  /** 3.0 Pills */

  --pill-active-background-color: #B4EEB4;
  --pill-active-color: #285344;
  --pill-inactive-background-color: #E8EBED;
  --pill-inactive-color: #4D5156;
  --pill-warning-background-color: #FDE99B;
  --pill-warning-color: #786802;

}
`
