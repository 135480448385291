import styled from "styled-components"

export const StyledContent = styled.div`
  margin-top: 1rem;
`

export const StyledText = styled.p`
  color: #a0a8b8;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`
