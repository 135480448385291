/* eslint-disable @typescript-eslint/naming-convention */
import axios, {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from "axios"
import {
  ACCOUNT_AGENT_SPECIFIC_URLS,
  ACCOUNT_MANAGER_SPECIFIC_URLS,
} from "./athenaApiEndpoints"

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_ATHENA_BACKEND}/`,
  headers: {
    "Content-Type": "application/json",
    "Authorization-Type": "Customer-Agent",
  },
  responseType: "json",
  timeout: 20000,
})

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const conf = { ...config }

    if (conf.headers === undefined) {
      conf.headers = {} as AxiosRequestHeaders
    }

    const headers: AxiosRequestHeaders = {}
    headers["Authorization-Type"] = "Customer-Agent"

    const xCustomerStorage = localStorage.getItem("xcustomer")
    if (xCustomerStorage) {
      // Override the Authorization-Type header
      headers["Authorization-Type"] = "Account-Manager"
      if (conf.url && ACCOUNT_AGENT_SPECIFIC_URLS.includes(conf.url)) {
        // add public customer id to the request url
        const x = JSON.parse(xCustomerStorage)
        if (conf.url?.includes("?")) {
          conf.url += "&"
        } else {
          conf.url += "?"
        }
        conf.url += `customer=${x.public_id}`
      }
    }

    // TODO: Will probably not work that well, need a better way of adding the correct header for AMs
    if (
      conf.url &&
      ACCOUNT_MANAGER_SPECIFIC_URLS.some((path) => conf.url?.includes(path))
    ) {
      headers["Authorization-Type"] = "Account-Manager"
    }

    // Add bearer token to request headers
    const authStorage = localStorage.getItem("auth_data")
    if (authStorage) {
      headers.Authorization = `Bearer ${JSON.parse(authStorage)}`
    }

    // Overwrite config headers with user-specified headers
    return {
      ...conf,
      headers: {
        ...conf.headers,
        ...headers,
      },
    }
  },
  (err) => {
    Promise.reject(err)
  },
)

instance.interceptors.response.use(
  (resp: AxiosResponse) => resp,
  (err) => {
    if (err && err.response) {
      // unauthorized user
      if (err.response.status === 401) {
        localStorage.clear()
        window.location.href = "/login"
        return
      }
    } else {
      // TODO: Network error
    }
    throw err
  },
)

export default instance
