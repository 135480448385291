import styled from "styled-components"
// eslint-disable-next-line import/no-extraneous-dependencies
import { Search } from "@styled-icons/bootstrap"
// eslint-disable-next-line import/no-extraneous-dependencies
import { Cross } from "@styled-icons/entypo"

export const FilterContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  border: 1px solid var(--border-default);
  font-weight: 600;
  font-size: 0.75rem;
  box-shadow: 0 5px 15px -3px rgba(103, 107, 224, 0.2);
  border-radius: 0.1875rem;
`

export const FilterButton = styled.button`
  padding: 0.75rem;
`

export const StyledSearchIcon = styled(Search)`
  color: var(--primary-accent);
  margin-right: 0.625rem;
`

export const StyledCrossIcon = styled(Cross)`
  color: var(--text-secondary);
`

export const StyledInputWrapper = styled.div`
  position: relative;

  > ${StyledCrossIcon} {
    position: absolute;
    margin-right: 0.625rem;
    top: -0.125rem;
    right: 0;
    opacity: 0.8;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
`

export const StyledInput = styled.input`
  height: 100%;
  margin-right: 1.875rem;
  border: none;
  outline: none;
`
