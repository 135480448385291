import { CrossIconNew, DeleteIcon, LoadingIcon } from "atoms/icons"
import { useState, Dispatch, SetStateAction } from "react"
import {
  StyledButton,
  StyledErrorText,
  StyledIcon,
  StyledRow,
  StyledSubtitle,
  StyledTitle,
} from "../Modal.styles"

type Props = {
  title: string
  text: string
  isLoading: boolean
  onSubmit: (setError: Dispatch<SetStateAction<string>>) => void
  onClose: () => void
}

export const DeleteModal = ({
  title,
  text,
  isLoading,
  onClose,
  onSubmit,
}: Props) => {
  const [errorText, setErrorText] = useState<string>("")

  return (
    <>
      <StyledRow data-align="between">
        <StyledIcon data-type="delete">
          <DeleteIcon size="14" color="var(--red-600)" />
        </StyledIcon>
        <button type="button" onClick={onClose}>
          <CrossIconNew color="var(--gray-500)" size="14" />
        </button>
      </StyledRow>

      <StyledRow data-direction="column">
        <StyledTitle>{title}</StyledTitle>
        <StyledSubtitle>{text}</StyledSubtitle>
        <StyledErrorText>{errorText}</StyledErrorText>
      </StyledRow>

      <StyledRow data-gap="1">
        <StyledButton data-type="normal" onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton
          data-type="delete"
          onClick={() => onSubmit(setErrorText)}
          disabled={isLoading}>
          {isLoading ? <LoadingIcon size="20" color="white" /> : "Delete"}
        </StyledButton>
      </StyledRow>
    </>
  )
}
