import { SeriesPreview } from "types/series_preview"

export const getSeriesTitle = (serie: SeriesPreview) => {
  const rosterA = serie.participants[0].roster
  const rosterB = serie.participants[1].roster

  if (rosterA.team && rosterB.team) {
    return `${rosterA.team.name} - ${rosterB.team.name}`
  }

  if (rosterA.line_up.players.length === rosterB.line_up.players.length) {
    return `${rosterA.line_up.players[0].nick_name} - ${rosterB.line_up.players[0].nick_name}`
  }

  return serie.title
}
