import {
  AgreementClauseContainer,
  AgreementSubClauseContainer,
  AgreementSubClauseNumber,
  AgreementSubClauseText,
  AgreementSubClauseWrapper,
  AgreementClauseText,
  AgreementClauseSectionBodyWrapper,
} from "../atoms/AgreementAtoms.styles"
import { AgreementHeaderText } from "../atoms/AgreementHeaderText.styles"
import { AgreementSectionContainer } from "../atoms/AgreementSection.styles"
import { AgreementSectionNumber } from "../atoms/AgreementSectionNumber.styles"
import {
  AccessAgreementClause,
  AccessAgreementClauseSection,
} from "../types/accessAgreementResponse"

type Props = {
  clauses: AccessAgreementClause[]
}

export const AccessAgreementClauses = ({ clauses }: Props) => {
  const renderSubSections = (
    subSections: AccessAgreementClauseSection["sub_clauses"],
    clauseNum: number,
    sectionNum: number,
  ) => {
    if (!subSections) return null

    return subSections.map((subSection) => {
      const renderSubSectionBody = subSection.body.map((subSectionBody) => (
        <AgreementSubClauseText key={subSectionBody}>
          {subSectionBody}
        </AgreementSubClauseText>
      ))

      return (
        <AgreementSubClauseContainer key={subSection.number}>
          <AgreementSubClauseNumber>
            {clauseNum}.{sectionNum}.{subSection.number}
          </AgreementSubClauseNumber>

          <div>{renderSubSectionBody}</div>
        </AgreementSubClauseContainer>
      )
    })
  }

  const renderSections = (
    sections: AccessAgreementClauseSection[],
    clauseNum: number,
  ) => {
    return sections.map((section) => {
      const renderSectionBody = section.body.map((sectionBody) => (
        <AgreementClauseText key={sectionBody}>
          {sectionBody}
        </AgreementClauseText>
      ))

      const renderSubClauses = renderSubSections(
        section.sub_clauses,
        clauseNum,
        section.number,
      )

      return (
        <AgreementClauseContainer key={section.number}>
          <AgreementSectionNumber>
            {clauseNum}.{section.number}
          </AgreementSectionNumber>
          <div>
            <AgreementClauseSectionBodyWrapper>
              {renderSectionBody}
            </AgreementClauseSectionBodyWrapper>

            <AgreementSubClauseWrapper>
              {renderSubClauses}
            </AgreementSubClauseWrapper>
          </div>
        </AgreementClauseContainer>
      )
    })
  }

  const renderClauses = clauses.map((clause) => {
    return (
      <AgreementSectionContainer key={clause.number}>
        <AgreementSectionNumber>{clause.number}</AgreementSectionNumber>
        <div>
          <AgreementHeaderText>{clause.title}</AgreementHeaderText>
          {renderSections(clause.sections, clause.number)}
        </div>
      </AgreementSectionContainer>
    )
  })

  return <div>{renderClauses}</div>
}
