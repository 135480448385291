import { MarketSelection, Player, Team } from "@abios/abios-ts-sdk"

const contextLeftSelOptions = ["over", "yes", "odd"]
const contextRightSelOptions = ["under", "no", "even"]

export const GetSelectionSide = (
  selections: MarketSelection[],
  leftComp: Team | Player,
  rightComp: Team | Player,
) => {
  // If market selections has entities attached to them we can directly figure out
  // which selection should be aligned left or right
  const leftSelection = selections.find(
    (selection) =>
      selection.entity?.id === leftComp.id ||
      contextLeftSelOptions.includes(selection.context?.result || ""),
  )
  const rightSelection = selections.find(
    (selection) =>
      selection.entity?.id === rightComp.id ||
      contextRightSelOptions.includes(selection.context?.result || ""),
  )

  const drawSelection = selections.find(
    (selection) =>
      selection.id !== leftSelection?.id && selection.id !== rightSelection?.id,
  )

  return { leftSelection, rightSelection, drawSelection }
}
