import { memo, useState } from "react"
import { IndexTable, ROW_SIZE } from "@abios/smorgasbord-components"
import { cErr } from "utils/debug"
import athenaAPI from "services/athena"
import { KeyOptions } from "pages/api/list/components/KeyOptions"
import { AthenaKey, AthenaKeySecretResponse } from "@abios/abios-ts-sdk"
import { StyledColumn, StyledText, StyledSubText } from "./KeysRow.styles"
import { SecretKeyView } from "./SecretKey"

type Props = {
  readonly key3: AthenaKey
}

const dateFormat = new Intl.DateTimeFormat([], {
  dateStyle: "medium",
})

export const KeysRow = memo(({ key3 }: Props) => {
  const [secretKey, setSecretKey] = useState<string | undefined>(undefined)

  const onClickShowSecret = async () => {
    // Request secret from Athena
    try {
      const url = `v1/key3/${key3.id}/secret`
      const resp = await athenaAPI.get<AthenaKeySecretResponse>(url)
      if (resp.data) {
        setSecretKey(resp.data.secret.value)
      }
    } catch (err) {
      // TODO: show err
      cErr("unable to fetch client3 secret key ~ err", err)
    }
  }

  const created = dateFormat.format(new Date(key3.created))

  return (
    <IndexTable.Row id={key3.id} key={key3.id} size={ROW_SIZE.lg}>
      <IndexTable.Cell>
        <StyledColumn>
          <StyledText>{key3.name}</StyledText>
          <StyledSubText data-id="true">{key3.id}</StyledSubText>
        </StyledColumn>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <SecretKeyView secretKey={secretKey} onClick={onClickShowSecret} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StyledText data-date="true">{created}</StyledText>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <KeyOptions clientKey={key3} />
      </IndexTable.Cell>
    </IndexTable.Row>
  )
})
