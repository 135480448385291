import styled from "styled-components"

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledText = styled.p`
  color: var(--text-primary);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  &[data-date="true"] {
    color: var(--text-secondary);
  }
`

export const StyledSubText = styled(StyledText)`
  color: var(--text-secondary);
  font-size: 11px;
  font-weight: 400;

  &[data-id="true"] {
    font-family: var(--font-family-mono);
  }
`
