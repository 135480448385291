import { Badge, STATUS } from "atoms/badge"
import { IMAGE_FALLBACK } from "constants/image"
import { GameFigure } from "pages/api/single/sidebar/atoms/GameFigure"
import { useGameStore } from "stores/games"
import { Game } from "types/game"
import { Widget } from "types/widget_namespace"
import { getClientHistoricalAccess } from "pages/api/utils/getClientHistoricalAccess"
import { Fragment } from "react"
import { getClientStatus } from "pages/api/utils/getClientStatus"
import { CLIENT_STATUS } from "types/client3"
import {
  WidgetContentContainer,
  WidgetHeader,
  StyledTitle,
  DescriptionSection,
  StyledDt,
  StyledDd,
  GamesSection,
  GamesContainer,
  DomainsSection,
  DetailedDomains,
  Details,
  DetailedSection,
} from "./WidgetContent.styles"

type Props = {
  widget: Widget
}

const dateFormat = new Intl.DateTimeFormat([], {
  day: "numeric",
  year: "numeric",
  month: "short",
})

export const WidgetContent = ({ widget }: Props) => {
  const { games } = useGameStore()

  const sortGames = (a: [string, Game], b: [string, Game]): number => {
    const [, gameA] = a
    const [, gameB] = b
    return gameA.title.toLowerCase() > gameB.title.toLowerCase() ? 1 : -1
  }

  const getGames = () => {
    const actives: JSX.Element[] = []
    const inactives: JSX.Element[] = []

    Object.entries(games)
      .sort((a, b) => sortGames(a, b))
      .forEach((gameData) => {
        const [, game] = gameData
        const isActive = widget.games.some(({ id }) => game.id === id)

        const gIndex = game.images.findIndex((g) => g.type === "circle")
        const imgUrl = game.images[gIndex]?.url || IMAGE_FALLBACK

        const gameFigure = (
          <GameFigure
            key={game.id}
            title={game.title}
            iconUrl={imgUrl}
            isActive={isActive}
          />
        )
        if (isActive) {
          actives.push(gameFigure)
        } else {
          inactives.push(gameFigure)
        }
      })
    return [...actives, ...inactives]
  }

  const description = widget.customer_description !== null && (
    <DescriptionSection>{widget.customer_description}</DescriptionSection>
  )

  const idDetails = widget.id && (
    <>
      <StyledDt>Id</StyledDt>
      <StyledDd>{widget.id}</StyledDd>
    </>
  )

  const namespaceDetails = widget.widget_namespace.slug && (
    <>
      <StyledDt>Namespace</StyledDt>
      <StyledDd>{widget.widget_namespace.slug}</StyledDd>
    </>
  )

  const created = widget.created
    ? dateFormat.format(new Date(widget.created))
    : null
  const createdRow = created && (
    <>
      <StyledDt>Created</StyledDt>
      <StyledDd>{created}</StyledDd>
    </>
  )

  const deleted = widget.deleted
    ? dateFormat.format(new Date(widget.deleted))
    : null
  const deletedRow = deleted && (
    <>
      <StyledDt>Created</StyledDt>
      <StyledDd>{deleted}</StyledDd>
    </>
  )

  const historical = (
    <>
      <StyledDt>Historical access</StyledDt>
      <StyledDd>{getClientHistoricalAccess(widget.hours_back)}</StyledDd>
    </>
  )

  const domains = widget.domains.map((domain) => (
    <Fragment key={domain.url}>
      <StyledDt>{domain.url}</StyledDt>
      <StyledDd>{dateFormat.format(new Date(domain.created))}</StyledDd>
    </Fragment>
  ))

  const getBadgeStatus = (): [STATUS, string] => {
    const dateActiveUntil =
      widget.active_until !== null ? new Date(widget.active_until) : null
    const widgetStatus = getClientStatus(dateActiveUntil)

    switch (widgetStatus) {
      case CLIENT_STATUS.WARNING:
        return [STATUS.WARNING, "Expiring"]
      case CLIENT_STATUS.INACTIVE:
        return [STATUS.DEFAULT, "Inactive"]
      default:
        return [STATUS.SUCCESS, "Active"]
    }
  }
  const [badgeStatus, badgeText] = getBadgeStatus()

  return (
    <WidgetContentContainer>
      <span>Widget</span>
      <WidgetHeader>
        <StyledTitle>
          <h1>{widget.slug}</h1>
          <Badge status={badgeStatus}>{badgeText}</Badge>
        </StyledTitle>
      </WidgetHeader>

      {description}

      <DetailedSection>
        <Details>
          {idDetails}
          {namespaceDetails}
          {createdRow}
          {deletedRow}
          {historical}
        </Details>
      </DetailedSection>

      <GamesSection>
        <h2>Games</h2>
        <GamesContainer>{getGames()}</GamesContainer>
      </GamesSection>

      <DomainsSection>
        <h2>Domains</h2>
        <DetailedDomains>{domains}</DetailedDomains>
      </DomainsSection>
    </WidgetContentContainer>
  )
}
