export enum STATUS {
  INIT = "init",
  ERROR = "error",
  FETCHED = "fetched",
  FETCHING = "fetching",
}

export enum ACTION_TYPE {
  FAILURE = "failure",
  REQUEST = "request",
  SUCCESS = "success",
}

type InitState = {
  status: STATUS.INIT
  data: undefined
  error: undefined
}

type ErrorState = {
  status: STATUS.ERROR
  data: undefined
  error: string
}

type FetchedState<T> = {
  status: STATUS.FETCHED
  data: T
  error: undefined
}

type FetchingState = {
  status: STATUS.FETCHING
  data: undefined
  error: undefined
}

export type State<T> = InitState | ErrorState | FetchedState<T> | FetchingState

// discriminated union type
export type Action<T> =
  | { type: ACTION_TYPE.REQUEST }
  | { type: ACTION_TYPE.SUCCESS; payload: T }
  | { type: ACTION_TYPE.FAILURE; payload: string }
