import { Styledheader, StyledH5 } from "./Header.styles"

type Props = {
  title: string
}

export const Header = ({ title }: Props) => (
  <Styledheader>
    <StyledH5>{title}</StyledH5>
  </Styledheader>
)
