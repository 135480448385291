import { useEffect } from "react"

import { useAtlasStore, useOddsStore } from "pages/trading/stores"
import { cErr, clog } from "utils/debug"
import { PatchMessage, CHANNELS } from "types/websocket"
import { getAthenaSecret } from "utils/localStorage"
import { useWebsocket } from "./useWebsocketConnection"

const SOCKET_URL: string | undefined = process.env.REACT_APP_SOCKET_URL

export const useSubscribeWebsocket = () => {
  const { patchSeries } = useAtlasStore()
  const { patchSingle } = useOddsStore()

  if (SOCKET_URL === undefined) {
    cErr("missing env for socket-url")
  }

  const secret = getAthenaSecret()
  if (secret === undefined) {
    cErr("missing athena secret for websocket connection")
  }

  const socketData = useWebsocket(
    `${SOCKET_URL}/subscribe?secret=${secret}&channel=series_updates&channel=match_updates&channel=odds_market_updates`,
  ) as PatchMessage

  useEffect(() => {
    if (!socketData) return
    clog("hermes - new message:", socketData)

    switch (socketData.channel) {
      case CHANNELS.SERIES_UPDATES:
        patchSeries(socketData.payload)
        break
      case CHANNELS.MATCH_UPDATES:
        // TODO:
        break
      case CHANNELS.ODDS_MARKET_UPDATES:
        patchSingle(socketData.payload)
        break
      case CHANNELS.SYSTEM:
        break
      default:
        cErr("unsupported socket channel", socketData.channel)
    }
  }, [patchSeries, patchSingle, socketData])
}
