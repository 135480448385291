import styled from "styled-components"

export const StyledRow = styled.tr`
  padding: 0 1.3125rem;
  line-height: 1.3125rem;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--border-default);
  }

  &:hover {
    background-color: #f7fafc;
  }

  .badge_styles {
    min-width: 130px;
    color: white; // backend requirement, considering color blind
  }
`

export const StyledText = styled.p`
  color: var(--text-primary);
  font-size: 0.8125rem;
  font-weight: 400;

  &[data-id="true"] {
    font-family: var(--font-family-mono);
  }
`

export const StyledTextSub = styled.p`
  color: var(--text-secondary);
  font-size: 0.6875rem;
  font-weight: 500;
  letter-spacing: 0.2px;
`

export const StyledTextLight = styled(StyledText)`
  color: var(--text-secondary);
`

export const StyledStatusesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`
