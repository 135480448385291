import { MouseEventHandler } from "react"
import styled from "styled-components"

const StyledButton = styled.button`
  color: var(--text-link-color);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  grid-area: button;
`

type Props = {
  title: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const SectionButton = ({ title, onClick }: Props) => (
  <StyledButton type="button" onClick={onClick}>
    {title}
  </StyledButton>
)
