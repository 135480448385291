import { StyledContent, StyledText } from "./EmptySection.styles"

type Props = {
  readonly text: string
}

export const EmptySection = ({ text }: Props) => (
  <StyledContent>
    <StyledText>{text}</StyledText>
  </StyledContent>
)
