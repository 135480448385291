/* eslint-disable no-case-declarations */
import { CrossIconNew } from "atoms/icons"
import { useAtlasStore, useOddsStore } from "pages/trading/stores"
import { marketTypeTitles } from "@abios/abios-ts-sdk"
import { E_MARKET_TEMPLATE } from "../../types/EMarketTemplate"
import { GetOperatorTemplate } from "../../utils"
import {
  BetSpan,
  Cross,
  StepCircle,
  StepContainer,
  StepLine,
  StyledBet,
  StyledBetSubtitle,
  StyledBetTitle,
  StyledContent,
} from "./Bet.styles"

type Props = {
  marketId: string
}

export const Bet = ({ marketId }: Props) => {
  const { matches, teams, players } = useAtlasStore()
  const { markets, selectedMarkets, onRemoveBet } = useOddsStore()

  const marketIdNum = Number(marketId)
  const marketGlobal = markets[marketIdNum]
  const selectionId = selectedMarkets[marketIdNum]
  const selection = marketGlobal.selections.find(
    (sel) => sel.id === selectionId,
  )

  const removeBet = () => onRemoveBet(marketIdNum)

  // Always set the odds to two decimal points to keep consistency
  const getOdds = () => Number(selection?.odds).toFixed(2) || ""

  const getFixture = () => {
    const market = markets[marketIdNum]

    return market.fixture.type === "series"
      ? "Series"
      : `Match ${matches[market.fixture.id].order}`
  }

  const getTeamNameFromSelection = () => {
    if (!selection?.entity?.id) return ""

    return teams[selection.entity.id].abbreviation
  }

  const getPlayerNameFromSelection = () => {
    if (!marketGlobal.context?.player?.id) return ""

    const playerId = marketGlobal.context.player.id

    return players[playerId].nick_name
  }

  const getSelectionContext = selection?.context?.result ?? ""

  const shouldInvertLine = () => {
    if (!selection?.entity?.id || !marketGlobal.context?.team?.id)
      return undefined

    return selection.entity.id !== marketGlobal.context?.team?.id
  }

  const toUpperCase = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  const GetOutcome = () => {
    switch (GetOperatorTemplate(marketGlobal.type)) {
      case E_MARKET_TEMPLATE.TWO_COLUMN_TEAM:
        return `Winner ${getTeamNameFromSelection()}`

      case E_MARKET_TEMPLATE.TWO_COLUMN_YESNO:
        return toUpperCase(getSelectionContext)

      case E_MARKET_TEMPLATE.THREE_COLUMN_EMPTY_TEAM:
        if (!marketGlobal.context?.round_number) return ""

        return `${getTeamNameFromSelection()} to win Round ${
          marketGlobal.context.round_number
        }`

      case E_MARKET_TEMPLATE.THREE_COLUMN_TOTAL_OVER_UNDER:
        if (!marketGlobal.context?.line) return ""

        return `${marketGlobal.context.line} ${toUpperCase(
          getSelectionContext,
        )}`

      case E_MARKET_TEMPLATE.THREE_COLUMN_ENTITY_OVER_UNDER:
        if (!marketGlobal.context?.line) return ""

        return `${getPlayerNameFromSelection()} ${
          marketGlobal.context.line
        } - ${toUpperCase(getSelectionContext)}`

      case E_MARKET_TEMPLATE.FOUR_COLUMN_TEAM:
        const shouldInvert = shouldInvertLine()
        if (shouldInvert === undefined || !marketGlobal.context?.line) return ""

        return `${getTeamNameFromSelection()} ${
          shouldInvert
            ? marketGlobal.context.line * -1
            : marketGlobal.context.line
        }`

      case E_MARKET_TEMPLATE.THREE_COLUMN_TEAM_PROPS:
        return `${getTeamNameFromSelection()} | ${
          marketGlobal.context?.line || marketGlobal.context?.index || ""
        }`

      // TODO: Add support for THREE_COLUMN_TEAM
      default:
        return ""
    }
  }

  return (
    <StyledBet>
      <StepContainer>
        <StepCircle />
        <StepLine />
      </StepContainer>
      <StyledContent>
        <StyledBetTitle>
          <BetSpan>{getOdds()}</BetSpan> {GetOutcome()}
        </StyledBetTitle>
        <StyledBetSubtitle>
          {getFixture()} | {marketTypeTitles[marketGlobal.type]}
        </StyledBetSubtitle>
      </StyledContent>
      <Cross onClick={removeBet}>
        <CrossIconNew size="12" color="var(--gray-500)" />
      </Cross>
    </StyledBet>
  )
}
