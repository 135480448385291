import { Market, Match, Series } from "@abios/abios-ts-sdk"
import { ALLOWED_STATES_LIST } from "pages/trading/types/State"

// Helper function to get tournament ids from markets
// Markets -> Series -> Tournament Ids
export const getTournamentIdsFromMarkets = (
  markets: {
    [key: number]: Market
  },
  series: {
    [key: number]: Series
  },
  matches: {
    [key: number]: Match
  },
) => {
  const seriesIds: number[] = []
  const tournamentIds: number[] = []
  const matchIds: number[] = []

  // get all series ids from markets that has type `series`
  Object.values(markets).forEach((m) => {
    // Only list state that exist in variable MARKET_STATES_ALLOWED
    const shouldListMarket = ALLOWED_STATES_LIST.includes(m.state)

    if (
      shouldListMarket &&
      m.fixture.type === "series" &&
      !seriesIds.includes(m.fixture.id)
    ) {
      seriesIds.push(m.fixture.id)
    }

    // If it is a best_of 1 series, there is only match winner
    if (
      shouldListMarket &&
      m.fixture.type === "match" &&
      !matchIds.includes(m.fixture.id)
    ) {
      matchIds.push(m.fixture.id)
    }
  })

  // Get the series ids from match using matchIds
  const matchIdsLength = matchIds.length
  for (let i = 0; i < matchIdsLength; i += 1) {
    const m = matches[matchIds[i]]
    if (m) {
      const sId = m.series.id
      if (!seriesIds.includes(sId)) {
        seriesIds.push(sId)
      }
    }
  }

  // Get the tournament ids from series using seriesIds
  const seriesIdsLength = seriesIds.length
  for (let i = 0; i < seriesIdsLength; i += 1) {
    const s = series[seriesIds[i]]
    if (s) {
      const tId = s.tournament.id
      if (!tournamentIds.includes(tId)) {
        tournamentIds.push(tId)
      }
    }
  }

  return tournamentIds
}
