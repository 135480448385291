import styled from "styled-components"

export const StyledTabs = styled.div`
  margin: 6px 0 24px;
  width: 100%;
`

export const StyledTableContent = styled.div`
  margin-bottom: 1.5rem;
`
export const StyledTabContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 5px;

  .badge_styles {
    padding: 0 3px;
    color: white;
  }
`
