import { CreateCustomerType } from "../../types"

const checkRequiredField = (value: string) => {
  return !value.trim().length
}

export const customerCreationValidation = (
  customerDetails: CreateCustomerType,
  hasTrailAccess: boolean,
  hasStripe: boolean,
) => {
  // Customer name
  if (checkRequiredField(customerDetails.customerName)) {
    return {
      error: true,
      key: "customerName",
      message: "Customer name cannot be empty",
    }
  }

  // Stripe id
  if (hasStripe && checkRequiredField(customerDetails.stripedId)) {
    return {
      error: true,
      key: "stripedId",
      message: "A customer needs a stripe id",
    }
  }

  // Account managers
  if (!customerDetails.accountManagers.length) {
    return {
      error: true,
      key: "accountManagers",
      message: "A customer needs at least one Account Manager",
    }
  }

  // Doesn't have trial agreement enabled, meaning the validation
  // of all required fields are done at this point, returning no validation
  // error back.
  if (!hasTrailAccess) return { error: false, key: "", message: "" }

  // Company name
  if (checkRequiredField(customerDetails.fullCompanyName)) {
    return {
      error: true,
      key: "fullCompanyName",
      message: "Company name cannot be empty",
    }
  }

  // Company number
  if (checkRequiredField(customerDetails.companyNumber)) {
    return {
      error: true,
      key: "companyNumber",
      message: "Company number cannot be empty",
    }
  }

  // Country
  if (checkRequiredField(customerDetails.country)) {
    return {
      error: true,
      key: "country",
      message: "Country cannot be empty",
    }
  }

  // Address
  if (checkRequiredField(customerDetails.address1)) {
    return {
      error: true,
      key: "address1",
      message: "Address cannot be empty",
    }
  }

  // Zipcode
  if (checkRequiredField(customerDetails.zipcode)) {
    return {
      error: true,
      key: "zipcode",
      message: "Zipcode cannot be empty",
    }
  }

  // City
  if (checkRequiredField(customerDetails.city)) {
    return {
      error: true,
      key: "city",
      message: "City cannot be empty",
    }
  }

  return { error: false, key: "", message: "" }
}
