import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { sortBy } from "lodash"

import { IndexTable } from "@abios/smorgasbord-components"
import API from "../../utils/api"
import Page from "../../components/PageContent"
import { Header } from "./components/Header"
import TableRow from "./components/TableRow"
import { XCustomerContext } from "../../contexts/XCustomer"

const HEADERS = ["Mail", "Last signed in"]

const ListUsers = () => {
  const xcustomer = useContext(XCustomerContext)
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState([])
  const [errorMsg, setErrorMsg] = useState("")

  useEffect(() => {
    async function getUsers() {
      let customerId = 1
      if (xcustomer.customer && xcustomer.customer.id) {
        customerId = xcustomer.customer.id
      }
      setIsLoading(true)
      try {
        const d = await API.get(`v1/admin/customer/${customerId}/users`)
        if (d && Array.isArray(d)) {
          setList(sortBy(d, [(user) => user.email.toLowerCase()]))
        }
      } catch (err) {
        setErrorMsg(err.message)
      }
      setIsLoading(false)
    }

    getUsers()
  }, [xcustomer.customer, setIsLoading, setErrorMsg, setList])

  const reloadUsers = async () => {
    let customerId = 1
    if (xcustomer.customer && xcustomer.customer.id) {
      customerId = xcustomer.customer.id
    }
    try {
      const d = await API.get(`v1/admin/customer/${customerId}/users`)
      if (d && Array.isArray(d)) {
        setList(sortBy(d, [(user) => user.email.toLowerCase()]))
      }
    } catch (err) {
      setErrorMsg(err.message)
    }
  }

  const headings = HEADERS.map((header) => (
    <IndexTable.Header key={header} $dataColumn={header}>
      {header}
    </IndexTable.Header>
  ))

  const pushTableRows = list.map((user, i) => {
    return <TableRow key={i} user={user} />
  })

  return (
    <Page isLoading={isLoading} errorMsg={errorMsg}>
      <Header reloadUsers={() => reloadUsers()} />
      <IndexTable headings={headings} rows={pushTableRows} />
    </Page>
  )
}

export default ListUsers
