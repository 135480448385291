import axios from "axios"
import { StoreApi } from "zustand"
import { PAGE_SIZE, Tournament } from "@abios/abios-ts-sdk"
import { State } from "./State"
import { generateFilterIdsRequests } from "./Utils"

export const fetchTournamentsByIds = async (
  set: StoreApi<State>["setState"],
  get: StoreApi<State>["getState"],
  tournamentIds: number[],
) => {
  const { tournaments } = get()
  const tourneys: Tournament[] = []

  // check if tournament exist in state
  const tournamentIdsLength = tournamentIds.length
  for (let i = tournamentIdsLength - 1; i >= 0; i -= 1) {
    if (tournaments[tournamentIds[i]] !== undefined) {
      tourneys.push(tournaments[tournamentIds[i]])
      tournamentIds.splice(i, 1)
    }
  }

  if (tournamentIds.length === 0) {
    // We have all the tournaments that we need
    return tourneys
  }

  // Fetch tournaments from Atlas API

  const url = `v3/tournaments?filter=id<=`
  const requests = generateFilterIdsRequests(url, PAGE_SIZE, tournamentIds)

  const newTournaments: { [key: number]: Tournament } = {}

  try {
    const resp = await axios.all(requests)

    if (Array.isArray(resp)) {
      const respLength = resp.length
      for (let i = 0; i < respLength; i += 1) {
        if (resp[i].data && Array.isArray(resp[i].data)) {
          const respDataLength = resp[i].data.length
          for (let j = 0; j < respDataLength; j += 1) {
            newTournaments[resp[i].data[j].id] = resp[i].data[j]

            // Add tournament to list that we return back
            tourneys.push(resp[i].data[j])
          }
        }
      }
    }
  } catch (e) {
    throw new Error(e)
  }

  // Update store with all the new fetched tournaments
  set({ tournaments: { ...tournaments, ...newTournaments } })
  return tourneys
}
