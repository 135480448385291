export const Transaction = () => (
  <svg width="20" height="16" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-2 -4)" fill="none" fillRule="evenodd">
      <path
        d="M17.002 13a1 1 0 01.117 1.993l-.117.007H5.417l3.292 3.293a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.32.083l-.094-.083-5-5c-.603-.602-.22-1.614.593-1.701L3.002 13h14zm-.39-8.79l.095.083 5 5c.603.602.22 1.614-.593 1.701L21 11H7a1 1 0 01-.117-1.993L7 9h11.585l-3.292-3.293a1 1 0 01-.083-1.32l.083-.094a1 1 0 011.32-.083z"
        fillRule="nonzero"
      />
    </g>
  </svg>
)
