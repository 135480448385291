import styled from "styled-components"

export const StyledContent = styled.header`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 24px 0;
`

export const StyledTabs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`

export const StyledLinkBox = styled.div`
  border-radius: 20px;
  cursor: pointer;
  color: #949da4;
  padding: 6px 18px;
  white-space: nowrap;
  transition: background 100ms ease-in-out;

  &:hover {
    background: var(--gray-100);
  }

  &[data-active="true"] {
    color: #fff;
    background-color: var(--gray-700);
  }
`

export const StyledText = styled.h3`
  font-size: 14px;
  text-align: center;
  color: var(--text-secondary);

  &[data-active="true"] {
    color: #fff;
  }
`
