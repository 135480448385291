import styled from "styled-components"

export const AgreementSectionContainer = styled.div`
  display: flex;
  gap: var(--space-gap-2);
  margin-top: 1rem;

  &:first-of-type {
    margin-top: 0;
  }
`
