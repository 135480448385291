import React, { useEffect, useRef, useCallback } from "react"
import ReactDOM from "react-dom"
import { useSpring, animated } from "react-spring"

const Stripe3DSecure = ({ url, hide }) => {
  const node = useRef()
  const modalProps = useSpring({
    config: { duration: 150 },
    opacity: 1,
    from: { opacity: 0 },
  })

  const handleClick = useCallback(
    (e) => {
      if (node.current.contains(e.target)) return
      hide() // outside modal click
    },
    [hide],
  )

  useEffect(() => {
    let didCancel = false
    const handleEsc = (e) => {
      if (e.keyCode === 27) hide(false)
    }

    window.addEventListener(
      "message",
      (ev) => {
        if (ev.data === "3DS-auth-complete") {
          if (!didCancel) hide(true)
        }
      },
      false,
    )

    document.addEventListener("mousedown", handleClick)
    window.addEventListener("keydown", handleEsc)
    return () => {
      didCancel = true
      document.removeEventListener("mousedown", handleClick)
      window.removeEventListener("keydown", handleEsc)
    }
  }, [handleClick, hide])

  return ReactDOM.createPortal(
    <animated.div className="modal-overlay" style={modalProps}>
      <iframe
        ref={node}
        style={{
          border: "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        title="Confirm Card"
        src={url}
        height="650"
        frameBorder="0"
        width="600"
      />
    </animated.div>,
    document.body,
  )
}

export default Stripe3DSecure
