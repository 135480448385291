import React from "react"
import styled from "styled-components"
import { Spring } from "react-spring"

const DefaultCard = styled.p`
  background-color: #d2dfff;
  border-radius: 4px;
  color: #4366a5;
  font-size: 12px;
  font-weight: 600;
  padding: 7px 10px;
`

const CardDefaultLabel = () => (
  <Spring config={{ duration: 450 }} from={{ opacity: 0 }} to={{ opacity: 1 }}>
    {(props) => (
      <div style={{ marginRight: 12 }}>
        <DefaultCard style={props}>Default</DefaultCard>
      </div>
    )}
  </Spring>
)

export default CardDefaultLabel
