import { useState } from "react"
import athena from "services/athena"
import { cErr } from "utils/debug"
import {
  CreateCustomerErrorType,
  CreateCustomerPayload,
  CreateCustomerType,
  emptyCustomer,
  emptyCustomerError,
  TrialAgreementFields,
} from "../types"
import { customerCreationValidation } from "../create/utils/customerCreationValidation"

export const useCreateCustomer = () => {
  const [details, setDetails] = useState<CreateCustomerType>(emptyCustomer)
  const [validationErrors, setValidationErrors] =
    useState<CreateCustomerErrorType>(emptyCustomerError)

  const [hasTrailAgreement, setTrailsAgreement] = useState<boolean>(false)
  const onToggleTrailAgreement = () => {
    setTrailsAgreement((prev) => !prev)
  }

  // create handling
  const [isSendingCreate, setSendingCreate] = useState<boolean>(false)

  // Error create handling
  const [hasCreateError, setCreateError] = useState<boolean>(false)

  // Successful create handling
  const [hasSuccessfulCreate, setSuccessfulCreate] = useState<boolean>(false)

  const hasStripe = details.billingMethod === "Stripe"

  const onSelectAccountManager = (id: string, name: string) => {
    setDetails((prev) => {
      return {
        ...prev,
        accountManagers: [...prev.accountManagers, { id, name }],
      }
    })
  }

  const onRemoveManager = (managerId: string) => {
    setDetails((prev) => {
      return {
        ...prev,
        accountManagers: prev.accountManagers.filter((m) => m.id !== managerId),
      }
    })
  }

  const setCustomerDetails = (value: string, key: string) => {
    setDetails((prev) => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  const onCreateCustomer = async () => {
    if (isSendingCreate) return

    // reset customer error details every time you try to submit
    setValidationErrors(emptyCustomerError)
    setSuccessfulCreate(false)
    setCreateError(false)

    // check for validation errors
    const {
      error: hasError,
      key: validationKey,
      message: validationMessage,
    } = customerCreationValidation(details, hasTrailAgreement, hasStripe)

    // handle errors
    if (hasError) {
      setValidationErrors((prev) => {
        return {
          ...prev,
          [validationKey]: validationMessage,
        }
      })

      // don't send the request if we have errors
      return
    }

    // formatted account managers
    const formattedAccountManagers = details.accountManagers.map((manager) => {
      return {
        user: {
          id: manager.id,
        },
      }
    })

    // create a new customer payload
    const customerPayload: CreateCustomerPayload = {
      name: details.customerName,
      active_until: details.activeUntil
        ? new Date(details.activeUntil).toISOString()
        : null,
      stripe_id: hasStripe ? details.stripedId : null,
      account_managers: formattedAccountManagers,
    }

    // If trial agreement is checked, add the fields to the payload
    if (hasTrailAgreement) {
      const trialAgreementFields: TrialAgreementFields = {
        company: {
          name: details.fullCompanyName,
          number: details.companyNumber,
          registered_address: {
            address_line_1: details.address1,
            address_line_2: details.address2 || null,
            zip_code: details.zipcode,
            city: details.city,
            country: details.country,
          },
        },
      }

      // eslint-disable-next-line @typescript-eslint/dot-notation
      customerPayload["trial_agreement_fields"] = trialAgreementFields
    }

    // send payload
    try {
      setSendingCreate(true)
      await athena.post("/v1/customer", customerPayload)
      setDetails(emptyCustomer)
      setTrailsAgreement(false)
      setSuccessfulCreate(true)
    } catch (e) {
      cErr(e)
      setCreateError(true)
    } finally {
      setSendingCreate(false)
    }
  }

  return {
    details,
    hasStripe,
    hasCreateError,
    onRemoveManager,
    onCreateCustomer,
    validationErrors,
    hasTrailAgreement,
    setCustomerDetails,
    hasSuccessfulCreate,
    onToggleTrailAgreement,
    onSelectAccountManager,
  }
}
