import React from "react"
import styled from "styled-components"

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`
const LogoLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const Logo = styled.img`
  height: 35px;
`

export const PageLogo = () => (
  <Header>
    <LogoLink href="/login">
      <figure className="logo">
        <Logo
          src="https://img.abiosgaming.com/other/abios-logo-blue-text.png"
          alt="logo"
        />
      </figure>
    </LogoLink>
  </Header>
)

export default PageLogo
