import {
  ALERT_TYPE,
  Button,
  BUTTON_SIZE,
  CrossCloseIcon,
  Dropdown,
  Input,
  INPUT_TYPE,
  Tag,
  TAG_SIZE,
} from "@abios/smorgasbord-components"
import {
  ContentRow,
  ContentWrapper,
  CreateCustomerContainer,
  DropdownValueText,
  Paragraph,
  StyledCheckbox,
  StyledDatePicker,
  StyledDropdownItem,
  Title,
} from "./CreateCustomer.styles"
import { useFetchAccountManagers } from "../hooks/useFetchAccountMangers"
import { useCreateCustomer } from "../hooks/useCreateCustomer"
import { TagButton } from "../update/UpdateCustomer.styles"
import { StyledAlert } from "../atoms/StyledAlert.styles"
import { getFormattedAccountManagers } from "../utils/getFormattedAccountManagers"

export const CreateCustomer = () => {
  const { managers } = useFetchAccountManagers()
  const {
    details,
    hasStripe,
    hasCreateError,
    onRemoveManager,
    validationErrors,
    onCreateCustomer,
    hasTrailAgreement,
    setCustomerDetails,
    hasSuccessfulCreate,
    onSelectAccountManager,
    onToggleTrailAgreement,
  } = useCreateCustomer()

  const formattedAccountManagers = getFormattedAccountManagers(managers)

  const getManagerItems = formattedAccountManagers
    .filter(
      (manager) =>
        !details.accountManagers.find((m) => m.id === manager.user.id),
    )
    .map((manager) => (
      <StyledDropdownItem
        key={manager.user.id}
        onClick={() =>
          onSelectAccountManager(manager.user.id, manager.user.name)
        }>
        {manager.user.name}
      </StyledDropdownItem>
    ))

  const getSelectedManagerValues = details.accountManagers.map((m) => (
    <Tag size={TAG_SIZE.S} key={m.id}>
      <>
        {m.name}
        <TagButton type="button" onClick={() => onRemoveManager(m.id)}>
          <CrossCloseIcon size="14" color="var(--text-secondary)" />
        </TagButton>
      </>
    </Tag>
  ))

  const createSuccessfulContent = (
    <ContentRow mBottom={2.5}>
      <StyledAlert title="Success!" type={ALERT_TYPE.SUCCESS}>
        The customer has successfully been created.
      </StyledAlert>
    </ContentRow>
  )

  const createErrorContent = (
    <ContentRow mTop={2} mBottom={2}>
      <StyledAlert type={ALERT_TYPE.ERROR}>
        An unexpected problem occurred while trying to create a new customer.
        Please try again soon, and if the problem persists contact the developer
        team.
      </StyledAlert>
    </ContentRow>
  )

  return (
    <CreateCustomerContainer>
      <ContentWrapper>
        {hasSuccessfulCreate && createSuccessfulContent}
        <Title>Create Customer</Title>
        {hasCreateError && createErrorContent}
        <ContentRow mTop={2.5}>
          <Input
            title="Customer Name"
            hint={validationErrors.customerName}
            warned={!!validationErrors.customerName}
            value={details.customerName}
            onChange={(e) => setCustomerDetails(e.target.value, "customerName")}
          />
          <StyledDatePicker
            title="Terminate (Optional)"
            type={INPUT_TYPE.DATE}
            value={details.activeUntil}
            onChange={(e) => setCustomerDetails(e.target.value, "activeUntil")}
          />
        </ContentRow>

        <ContentRow mTop={2.5}>
          <Dropdown
            title="Billing Method"
            inputHidden
            selectedValue={
              <DropdownValueText>{details.billingMethod}</DropdownValueText>
            }>
            <StyledDropdownItem
              onClick={() => setCustomerDetails("Stripe", "billingMethod")}>
              Stripe
            </StyledDropdownItem>
            <StyledDropdownItem
              onClick={() => setCustomerDetails("Other", "billingMethod")}>
              Other
            </StyledDropdownItem>
          </Dropdown>
          {hasStripe && (
            <Input
              title="Stripe ID"
              value={details.stripedId}
              hint={validationErrors.stripedId}
              warned={!!validationErrors.stripedId}
              onChange={(e) => setCustomerDetails(e.target.value, "stripedId")}
            />
          )}
        </ContentRow>

        <ContentRow mTop={2.5}>
          <Dropdown
            title="Account Managers"
            inputHidden
            warned={!!validationErrors.accountManagers}
            hint={validationErrors.accountManagers}
            selectedValue={getSelectedManagerValues}>
            {getManagerItems}
          </Dropdown>
        </ContentRow>

        <ContentRow mTop={2} mBottom={2}>
          <StyledCheckbox
            checked={hasTrailAgreement}
            onToggle={onToggleTrailAgreement}
          />
          <Paragraph>Require Trial Access Agreement</Paragraph>
        </ContentRow>
        {hasTrailAgreement && (
          <>
            <Title>Company Details</Title>

            <ContentRow mTop={2}>
              <Input
                title="Full Company Name"
                hint={validationErrors.fullCompanyName}
                warned={!!validationErrors.fullCompanyName}
                value={details.fullCompanyName}
                onChange={(e) =>
                  setCustomerDetails(e.target.value, "fullCompanyName")
                }
              />
              <Input
                title="Company Number"
                hint={validationErrors.companyNumber}
                warned={!!validationErrors.companyNumber}
                value={details.companyNumber}
                onChange={(e) =>
                  setCustomerDetails(e.target.value, "companyNumber")
                }
              />
            </ContentRow>

            <ContentRow mTop={2.5}>
              <Input
                title="Country"
                hint={validationErrors.country}
                warned={!!validationErrors.country}
                value={details.country}
                onChange={(e) => setCustomerDetails(e.target.value, "country")}
              />
            </ContentRow>

            <ContentRow mTop={2.5}>
              <Input
                title="Address Line 1"
                hint={validationErrors.address1}
                warned={!!validationErrors.address1}
                value={details.address1}
                onChange={(e) => setCustomerDetails(e.target.value, "address1")}
              />
              <Input
                title="Address Line 2 (Optional)"
                value={details.address2}
                onChange={(e) => setCustomerDetails(e.target.value, "address2")}
              />
            </ContentRow>

            <ContentRow mTop={2.5} mBottom={2.5}>
              <Input
                title="Zipcode"
                hint={validationErrors.zipcode}
                warned={!!validationErrors.zipcode}
                value={details.zipcode}
                onChange={(e) => setCustomerDetails(e.target.value, "zipcode")}
              />
              <Input
                title="City"
                hint={validationErrors.city}
                warned={!!validationErrors.city}
                value={details.city}
                onChange={(e) => setCustomerDetails(e.target.value, "city")}
              />
            </ContentRow>
          </>
        )}

        <ContentRow>
          <Button size={BUTTON_SIZE.XS} onClick={() => onCreateCustomer()}>
            Create
          </Button>
        </ContentRow>
      </ContentWrapper>
    </CreateCustomerContainer>
  )
}
