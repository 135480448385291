import React, { useState, useEffect, useContext, createContext } from "react"

import { useAuthStore } from "../../stores"
import API from "../utils/api"

export const CustomerContext = createContext()

export const CustomerProvider = ({ children }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState(null)
  const { isAuthed } = useAuthStore()

  const FetchCustomer = async () => {
    try {
      const d = await API.get("v1/customer")
      if (d) setData(d)
    } catch (err) {
      // FIXME:
      console.error("err", err)
    }
  }

  useEffect(() => {
    let didCancel = false

    const fetchCustomer = async () => {
      if (!didCancel) setIsFetching(true)
      try {
        const d = await API.get("v1/customer")
        if (!didCancel && d) setData(d)
      } catch (err) {
        // FIXME:
        console.error("err", err)
      }
      if (!didCancel) setIsFetching(false)
    }

    if (isAuthed) {
      fetchCustomer()
    }
    return () => {
      didCancel = true
    }
  }, [])

  const reset = () => {
    setData(null)
  }

  return (
    <CustomerContext.Provider
      value={{ isFetching, data, FetchCustomer, reset }}>
      {children}
    </CustomerContext.Provider>
  )
}
