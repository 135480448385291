import { create } from "zustand"
import { createTrackedSelector } from "react-tracked"
import { Client3, Client3APIResponse } from "types/client3"
import athenaAPI from "services/athena"
import { cErr } from "utils/debug"
import { ByField } from "../utils/sortByField"

type ModalStoreType = {
  clients: Client3[]
  isLoading: boolean
  getClients: (controller: AbortController) => Promise<void>
  addClient: (client: Client3) => void
  removeClient: (clientId: string) => void
}

const useStore = create<ModalStoreType>((set, get) => ({
  clients: [],
  isLoading: false,
  getClients: async (controller: AbortController) => {
    set({ isLoading: true })
    try {
      const resp = await athenaAPI.get<Client3APIResponse>("v1/client3", {
        signal: controller.signal,
        params: {
          deleted: false,
        },
      })

      // Order client by name
      set({
        clients: [...resp.data.clients].sort(ByField("name")),
      })
    } catch (err) {
      // TODO: Show error page
      cErr("unable to fetch api clients.", err)
    } finally {
      set({ isLoading: false })
    }
  },
  addClient: (client) => {
    set((state) => ({
      clients: [...state.clients, client].sort(ByField("name")),
    }))
  },
  removeClient: (clientId) => {
    const clientsTemp: Client3[] = get().clients
    clientsTemp.splice(
      clientsTemp.findIndex((client) => client.id === clientId),
      1,
    )
    set({ clients: clientsTemp })
  },
}))

export const getClientStore = createTrackedSelector(useStore)
