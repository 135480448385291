import athenaAPI from "services/athena"
import type { ListPushChannelCategoryOutput } from "types/push_channels"
import { AxiosResponse } from "axios"
import { StoreApi } from "zustand"
import { ApiStoreTypes } from "./ApiStoreTypes"

export const fetchPushChannelCategories = async (
  set: StoreApi<ApiStoreTypes>["setState"],
) => {
  try {
    const resp: AxiosResponse<ListPushChannelCategoryOutput> =
      await athenaAPI.get("v1/push-channel/category")
    if (resp.data?.push_channel_categories) {
      set({ pushChannelCategories: resp.data.push_channel_categories })
    }
  } catch (e) {
    throw Error(e)
  }
}
