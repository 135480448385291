import { CLIENT_STATUS } from "types/client3"
import { StatusCircle } from "./atoms/StatusCircle"
import {
  StyledFigure,
  StyledStatusGrid,
  StyledText,
  StyledTextSub,
} from "./ClientStatus.styles"

type Props = {
  status: CLIENT_STATUS
  statusText: string
  statusSubText: string
}

export const ClientStatus = ({ status, statusText, statusSubText }: Props) => {
  return (
    <StyledStatusGrid>
      <StyledFigure>
        <StatusCircle status={status} />
      </StyledFigure>
      <div>
        <StyledText>{statusText}</StyledText>
        <StyledTextSub>{statusSubText}</StyledTextSub>
      </div>
    </StyledStatusGrid>
  )
}
