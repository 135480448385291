import { sortGames } from "pages/api/utils/sortGames"
import { useEffect, useRef } from "react"

import { Game } from "types/game"
import { Dropdown } from "./AddGame.styles"

const scrollIntoViewIfNeeded = (target: HTMLButtonElement) => {
  if (target.getBoundingClientRect().bottom > window.innerHeight) {
    target.scrollIntoView(false)
  }

  if (target.getBoundingClientRect().top < 0) {
    target.scrollIntoView()
  }
}

type Props = {
  list: Game[]
  index?: number
  handleAdd: (game: Game) => void
}

export const GameDropdown = ({ list, index, handleAdd }: Props) => {
  const entriesRef = useRef<(HTMLButtonElement | null)[]>([])

  useEffect(() => {
    if (index === undefined) return

    const element = entriesRef.current[index]
    if (element) {
      scrollIntoViewIfNeeded(element)
    }
  }, [entriesRef, index])

  if (list.length <= 0) {
    return null
  }

  const content = list.sort(sortGames()).map((game, i) => (
    <button
      ref={(el) => {
        entriesRef.current[i] = el
      }}
      type="button"
      key={game.id}
      onClick={() => handleAdd(game)}>
      <div>
        {game.images?.[0] && <img src={game.images[0].url} alt={game.title} />}
        <span>{game.title}</span>
      </div>
    </button>
  ))

  return <Dropdown>{content}</Dropdown>
}
