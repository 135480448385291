import React from "react"
import styled from "styled-components"

import Dropdown from "./PaymentItemDropdown"
import CardDefaultLabel from "./DefaultPaymentSource"
import ItemErrorLabel from "./ItemErrorLabel"

const CardIcon = styled.img`
  display: flex;
  align-items: center;
  height: 26px;
  margin-right: 10px;
  border-radius: 2px;
`
const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`
const InfoText = styled.p`
  color: #4a4a57;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
`

const Item = (props) => {
  let imgBrand = ""
  let card = props.card

  if (card.expire_month) {
    card.expire_month = card.expire_month.toString()
    if (card.expire_month.length === 1) {
      card.expire_month = `0${card.expire_month}`
    }
  }

  if (card.brand) {
    switch (card.brand) {
      case "visa":
        imgBrand = "visa.png"
        break
      case "mastercard":
        imgBrand = "mastercard.png"
        break
      case "amex":
        imgBrand = "american-express.png"
        break
      default:
      // FIXME: fallback image?
    }
  }

  return (
    <div
      className="flex-row payment-item-less-info"
      dropdown-active={props.openID === card.id ? "true" : "false"}>
      <div>
        <CardIcon
          src={`${process.env.PUBLIC_URL}/img/cards/${imgBrand}`}
          alt="credit-card-logo"
        />

        <CardInfo>
          <InfoText>{`**** ${card.last_four}`}</InfoText>
          <InfoText>
            {card.expire_month}/{card.expire_year}
          </InfoText>
        </CardInfo>
      </div>

      <div>
        {card.sca_details && card.sca_details.status === "requires_action" ? (
          <ItemErrorLabel msg="Require Attention" />
        ) : card.status === "declined" ? (
          <ItemErrorLabel msg="Card Declined" />
        ) : null}
        {card.is_default ? <CardDefaultLabel /> : null}

        <div
          className="box-border-placeholder"
          onClick={() => props.setOpenID(card.id)}>
          <span
            className="dots"
            active={props.openID === card.id ? "true" : "false"}>
            ...
          </span>
        </div>

        {props.openID === card.id ? (
          <Dropdown
            card={props.card}
            cardLength={props.cardLength}
            hide={() => props.setOpenID(null)}
            onClickConfirmCard={(cardID) => props.handleConfirmCard(cardID)}
            setDefaultCard={(cardID) => props.handleSetDefaultCard(cardID)}
            handleDeleteCard={() => props.handleDeleteCard(props.card.id)}
          />
        ) : null}
      </div>
    </div>
  )
}

export default Item
