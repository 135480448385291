import { useEffect, useMemo, useRef } from "react"
import useWebSocket, { ReadyState } from "react-use-websocket"

type EventData = unknown | null
enum CONNECTION_STATUS {
  CONNECTING = "Connecting",
  OPEN = "Open",
  CLOSING = "Closing",
  CLOSED = "Closed",
  UNINSTANTIATED = "Uninstantiated",
}

export const useWebsocket = (url: string) => {
  const eventData = useRef<EventData>(null)
  const { readyState, lastJsonMessage } = useWebSocket(url, {
    share: false,
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    retryOnError: true,
  })

  const connectionStatus = useMemo(
    () =>
      ({
        [ReadyState.CONNECTING]: CONNECTION_STATUS.CONNECTING,
        [ReadyState.OPEN]: CONNECTION_STATUS.OPEN,
        [ReadyState.CLOSING]: CONNECTION_STATUS.CLOSING,
        [ReadyState.CLOSED]: CONNECTION_STATUS.CLOSED,
        [ReadyState.UNINSTANTIATED]: CONNECTION_STATUS.UNINSTANTIATED,
      }[readyState]),
    [readyState],
  )

  useMemo(() => {
    if (connectionStatus === CONNECTION_STATUS.OPEN) {
      eventData.current = lastJsonMessage
    }
  }, [lastJsonMessage, connectionStatus])

  useEffect(() => {}, [lastJsonMessage])

  return eventData.current
}
