import { create } from "zustand"
import { createTrackedSelector } from "react-tracked"

type ModalStoreType = {
  isDeleteModalOpen: boolean
  setIsDeleteModalOpen: (value: boolean) => void
  isCreateModalOpen: boolean
  setIsCreateModalOpen: (value: boolean) => void
  isEditModalOpen: boolean
  setIsEditModalOpen: (value: boolean) => void
  targetId: string
  setTargetId: (value: string) => void
}

const useStore = create<ModalStoreType>((set) => ({
  isDeleteModalOpen: false,
  setIsDeleteModalOpen: (isDeleteModalOpen) => set({ isDeleteModalOpen }),
  isCreateModalOpen: false,
  setIsCreateModalOpen: (isCreateModalOpen) => set({ isCreateModalOpen }),
  isEditModalOpen: false,
  setIsEditModalOpen: (isEditModalOpen) => set({ isEditModalOpen }),
  targetId: "",
  setTargetId: (targetId) => set({ targetId }),
}))

export const useModalStore = createTrackedSelector(useStore)
