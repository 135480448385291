import { NoDataCard } from "atoms/noDataCard"
import {
  StyledClearButton,
  StyledCreateButton,
} from "atoms/noDataCard/NoDataCard.styles"
import { StyledPlusIcon } from "./Header.styles"

type Props = {
  clearFilters: () => void
  gotoCreateCustomer: () => void
}
export const EmptyState = ({ clearFilters, gotoCreateCustomer }: Props) => {
  return (
    <NoDataCard
      title="No results found"
      subText="There aren't any results for that query.">
      <StyledClearButton onClick={clearFilters}>Clear filter</StyledClearButton>
      <StyledCreateButton onClick={gotoCreateCustomer}>
        <StyledPlusIcon size="25" />
        Add Customer
      </StyledCreateButton>
    </NoDataCard>
  )
}
