import { useAtlasStore } from "pages/trading/stores"
import { useState } from "react"
import { Bet } from "./Bet"
import {
  DisplayMoreText,
  StyledBetBox,
  StyledTitle,
  StyledTitleContainer,
} from "./BetBox.styles"

type Props = {
  readonly seriesId: string
  betMarketIds: string[]
}

export const BetBox = ({ seriesId, betMarketIds }: Props) => {
  const { series, rosters, teams } = useAtlasStore()

  const [shouldShowMore, setShowMore] = useState<boolean>(false)

  const getSeriesCompetitorsTitle = () => {
    const serie = series[Number(seriesId)]

    const firstSeedId = serie.participants.find(
      (participant) => participant.seed === 1,
    )?.roster.id

    const secondSeedId = serie.participants.find(
      (participant) => participant.seed === 2,
    )?.roster.id

    if (!firstSeedId || !secondSeedId) return ""

    const firstSeedTeamId = rosters[firstSeedId].team?.id
    const secondSeedTeamId = rosters[secondSeedId].team?.id

    if (!firstSeedTeamId || !secondSeedTeamId) return ""

    const firstSeedTeam = teams[firstSeedTeamId].abbreviation
    const secondSeedTeam = teams[secondSeedTeamId].abbreviation

    if (!firstSeedTeam || !secondSeedTeam) return ""

    return `${firstSeedTeam} vs ${secondSeedTeam}`
  }

  const getBets = betMarketIds.map((marketId) => (
    <Bet key={marketId} marketId={marketId} />
  ))

  const hasManyBets = getBets.length > 10

  const showMoreLessText = shouldShowMore ? "show less" : "show more"

  const showMoreText = hasManyBets && (
    <DisplayMoreText onClick={() => setShowMore((prev) => !prev)}>
      {showMoreLessText}
    </DisplayMoreText>
  )

  const slicedBets = shouldShowMore ? getBets : getBets.slice(0, 10)

  return (
    <StyledBetBox>
      <StyledTitleContainer>
        <StyledTitle>{getSeriesCompetitorsTitle()}</StyledTitle>
        {showMoreText}
      </StyledTitleContainer>
      {slicedBets}
    </StyledBetBox>
  )
}
