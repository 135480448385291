import { ReactNode, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import LoadingContainer from "jsx/components/LoadingContainer"

import { useAuthStore } from "stores"

type Props = {
  children: ReactNode
}

export const AuthProvider = ({ children }: Props) => {
  const { isAuthed, checkIsAuthed } = useAuthStore()
  const [isCheckingAuth, setIsCheckingAuth] = useState(true)

  useEffect(() => {
    let didCancel = false

    const checkAuth = async () => {
      checkIsAuthed()
      if (!didCancel) setIsCheckingAuth(false)
    }

    // Check if user is authed
    checkAuth()
    return () => {
      didCancel = true
    }
  }, [checkIsAuthed])

  if (isCheckingAuth) {
    return <LoadingContainer />
  }

  // if user is not authed we want to redirect them to '/login'
  if (!isAuthed) {
    return <Navigate to="/login" />
  }

  return children
}
