import axios from "axios"
import { StoreApi } from "zustand"
import { PAGE_SIZE, Series } from "@abios/abios-ts-sdk"
import { State } from "./State"
import { generateFilterIdsRequests } from "./Utils"

export const getSeriesByIds = async (
  set: StoreApi<State>["setState"],
  get: StoreApi<State>["getState"],
  seriesIds: number[],
) => {
  const { series } = get()
  const seriesList: Series[] = []

  // check if series exist in state
  const seriesIdsLength = seriesIds.length
  for (let i = seriesIdsLength - 1; i >= 0; i -= 1) {
    if (series[seriesIds[i]] !== undefined) {
      seriesList.push(series[seriesIds[i]])
      seriesIds.splice(i, 1)
    }
  }

  if (seriesIds.length === 0) {
    // We have all the series that we need
    return seriesList
  }

  // Fetch series from Atlas API

  const url = `v3/series?filter=id<=`
  const requests = generateFilterIdsRequests(url, PAGE_SIZE, seriesIds)

  const newSeries: { [key: number]: Series } = {}

  try {
    const resp = await axios.all(requests)

    if (Array.isArray(resp)) {
      const respLength = resp.length
      for (let i = 0; i < respLength; i += 1) {
        if (resp[i].data && Array.isArray(resp[i].data)) {
          const respDataLength = resp[i].data.length
          for (let j = 0; j < respDataLength; j += 1) {
            newSeries[resp[i].data[j].id] = resp[i].data[j]

            // Add series to list that we return back
            seriesList.push(resp[i].data[j])
          }
        }
      }
    }
  } catch (e) {
    throw new Error(e)
  }

  // Update store with all the new fetched series
  set({ series: { ...series, ...newSeries } })

  return seriesList
}
