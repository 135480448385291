import { useContext } from "react"

import { STATUS } from "hooks/usefetch/types"
import { V3ResourcesProvider } from "contexts/v3Resources"
import { Client3Provider, client3Context } from "./Client3Context"
import { Sidebar } from "./sidebar"
import { MainContent } from "./main"
import { Content } from "./Overview.styles"

export const Overview = () => {
  // Deprecated:
  const client3Con = useContext(client3Context)

  // TODO: show loading animation
  if (client3Con.status === STATUS.FETCHING) return null
  if (client3Con.client3 === undefined) return null

  return (
    <Content>
      <Sidebar />
      <MainContent />
    </Content>
  )
}

export const Client3Overview = () => (
  <V3ResourcesProvider>
    <Client3Provider>
      <Overview />
    </Client3Provider>
  </V3ResourcesProvider>
)
