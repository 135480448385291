import styled from "styled-components"

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.625rem;
  cursor: pointer;
  user-select: none;
  background: var(--gray-700);

  & > h2 {
    font-size: 13px;
    color: var(--text-white);
  }

  & > svg {
    transition: transform 200ms ease-in-out;
  }

  &[data-isopen="true"] > svg {
    transform: rotate(90deg);
  }

  &[data-isopen="false"] > svg {
    transform: rotate(270deg);
  }
`
export const StyledBogger = styled.div`
  overflow: hidden;
  transition: height 200ms ease-in-out;
`
