import styled from "styled-components"
import { Link } from "react-router-dom"

import { Series } from "@abios/abios-ts-sdk"
import { SeriesDate } from "./SeriesDate"
import { Competitors } from "./Competitors"

const StyledSeries = styled(Link)`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 12px 20px;
  transition: all 200ms ease-in;

  &:not(:first-child) {
    border-top: 1px solid rgba(228, 234, 240, 1);
  }
`
const StyledText = styled.p`
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.2px;
`

const StyledSeriesIdContainer = styled.div`
  padding-right: 30px;
`

type Props = {
  series: Series
}

export const Item = ({ series }: Props) => {
  const url =
    series.format.best_of === 1
      ? `/trading/${series.id}?tab=match&matchid=${series.matches[0].id}`
      : `/trading/${series.id}?tab=series`

  // Only show series ID on dev and stage
  const shouldShowSeriesId = process.env.REACT_APP_ENV !== "production"

  return (
    <StyledSeries key={series.id.toString()} to={url}>
      {shouldShowSeriesId && (
        <StyledSeriesIdContainer>
          <StyledText>{series.id}</StyledText>
        </StyledSeriesIdContainer>
      )}
      <SeriesDate start={series.start} end={series.end} />
      <Competitors participants={series.participants} gameId={series.game.id} />
      <div>
        <StyledText>{`Best of ${series.format.best_of}`}</StyledText>
      </div>
    </StyledSeries>
  )
}
