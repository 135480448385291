import { SVGAttributes, FC, ReactNode } from "react"

export type SvgProps = {
  children: ReactNode
  className?: string
  viewBox?: SVGAttributes<SVGSVGElement>["viewBox"]
}

export const Svg: FC<SvgProps> = ({
  className = "",
  children,
  viewBox = "0 0 32 32",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    className={`${className} icon icon-svg`}>
    {children}
  </svg>
)
