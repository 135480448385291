import { LIFECYCLE } from "@abios/abios-ts-sdk"
import { StyledSidebar } from "./WidgetSidebar.styles"
import { Widget } from "../../atoms"

type Props = {
  readonly seriesId: number
  readonly lifecycle: LIFECYCLE
}

export const WidgetSidebar = ({ seriesId, lifecycle }: Props) => {
  const namespace = "abios-dashboard"

  // Show live and stream widget
  if (lifecycle === LIFECYCLE.LIVE) {
    const streamUrl = `${namespace}/broadcasts/${seriesId}`
    const liveWidgetUrl = `${namespace}/live/${seriesId}`

    return (
      <StyledSidebar>
        <Widget id="widget-stream" urlPath={streamUrl} />
        <Widget id="widget-live" urlPath={liveWidgetUrl} />
      </StyledSidebar>
    )
  }

  // Show pre-game stats widget
  if (lifecycle === LIFECYCLE.UPCOMING) {
    const liveWidgetUrl = `${namespace}/pregame/${seriesId}`

    return (
      <StyledSidebar>
        <Widget id="widget-pregame" urlPath={liveWidgetUrl} />
      </StyledSidebar>
    )
  }

  return null
}
