import {
  StyledContainer,
  StyledLoader,
  StyledLine,
  StyledCircle,
  StyledLineMask,
} from "./LoadingContainer.styles"

type Props = {
  background?: string
  height?: string
  width?: string
  boxShadow?: string
  circleSize?: number
  className?: string
}

const LoadingContainer = ({
  background,
  height,
  width,
  boxShadow,
  circleSize,
  className,
}: Props) => (
  <StyledContainer
    className={className}
    background={background}
    height={height}
    boxShadow={boxShadow}
    width={width}>
    <StyledLoader>
      <StyledCircle size={circleSize} />
      <StyledLineMask size={circleSize}>
        <StyledLine size={circleSize} />
      </StyledLineMask>
    </StyledLoader>
  </StyledContainer>
)

export default LoadingContainer
