import {
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react"
import AthenaAPI from "services/athena"
import { cErr } from "utils/debug"
import { IndexTable } from "@abios/smorgasbord-components"
import { ByField } from "pages/api/list/utils/sortByField"
import { Modal } from "molecules/Modal/Modal"
import { CreateModal } from "molecules/Modal/ModalTypes/CreateModal"
import { useModalStore } from "pages/api/list/store/useModalStore"
import { DeleteModal } from "molecules/Modal/ModalTypes/DeleteModal"
import { createClient3Key } from "pages/api/utils/useCreateClientKey"
import { useParams } from "react-router-dom"
import { AthenaKey, AthenaListKeysResponse } from "@abios/abios-ts-sdk"
import { client3Context } from "../Client3Context"
import { Section } from "./Section.styles"
import { SectionButton, SectionHeader } from "./components/SectionHeader"
import { KeysRow, EmptySection } from "./components"

const TABLE_HEADERS = ["ID", "Secret", "Created at", ""]

export const KeysList = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false)
  const [client3Keys, setClient3Keys] = useState<AthenaKey[]>([])
  const client3Con = useContext(client3Context)
  const { clientId } = useParams()

  const {
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    isCreateModalOpen,
    setIsCreateModalOpen,
    isEditModalOpen,
    setIsEditModalOpen,
    targetId,
    setTargetId,
  } = useModalStore()

  useEffect(() => {
    let didCancel = false

    const getClient3Keys = async () => {
      if (!client3Con.client3.id) {
        // TODO: redirect or show error page
        return
      }
      setIsLoading(true)
      try {
        const url = `v1/key3?client=${client3Con.client3.id}`
        const resp = await AthenaAPI.get<AthenaListKeysResponse>(url, {
          params: {
            deleted: false,
          },
        })
        if (resp.data && !didCancel) {
          setClient3Keys(resp.data.keys)
        }
      } catch (e) {
        // TODO: display error message to user
        cErr("unable to fetch client3 keys from API", e)
      } finally {
        setIsLoading(false)
      }
    }

    getClient3Keys()
    return () => {
      didCancel = true
    }
  }, [client3Con.client3.id])

  const onClickCreateKeys = () => {
    setIsCreateModalOpen(true)
  }

  const onCloseCreateModal = () => {
    setIsCreateModalOpen(false)
  }

  const onCloseEditModal = () => {
    setIsEditModalOpen(false)
    setTargetId("")
  }

  const onCloseDeleteModal = () => {
    setIsDeleteModalOpen(false)
    setTargetId("")
  }

  const createClientKey = async (
    keyName: string,
    _initialName: string,
    setHasError: Dispatch<SetStateAction<boolean>>,
    setErrorText: Dispatch<SetStateAction<string>>,
  ) => {
    if (clientId) {
      if (keyName.trim() === "") {
        setErrorText("The key name input field cannot be empty")
        setHasError(true)
        return
      }

      try {
        setIsModalLoading(true)
        const key = await createClient3Key(clientId, keyName)
        setHasError(false)
        setClient3Keys((oldKeys) => [...oldKeys, key])
        onCloseCreateModal()
      } catch (err) {
        setHasError(true)
      } finally {
        setIsModalLoading(false)
      }
    }
  }

  const editClientKey = async (
    keyName: string,
    initialName: string,
    setHasError: Dispatch<SetStateAction<boolean>>,
    setErrorText: Dispatch<SetStateAction<string>>,
  ) => {
    if (clientId && targetId !== "") {
      if (keyName.trim() === "") {
        setErrorText("The key name input field cannot be empty")
        setHasError(true)
        return
      }

      if (keyName === initialName) {
        onCloseEditModal()
        return
      }

      try {
        setIsModalLoading(true)
        const resp = await AthenaAPI.patch(
          `v1/key3/${targetId}`,
          {
            patch: [
              {
                op: "replace",
                path: "/name",
                value: keyName,
              },
            ],
          },
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              "If-Match":
                client3Keys.find((key) => key.id === targetId)
                  ?.resource_version ?? 1,
            },
          },
        )
        const keysTemp = [...client3Keys]
        keysTemp.splice(
          keysTemp.findIndex((key) => key.id === targetId),
          1,
        )
        setClient3Keys([...keysTemp, resp.data.key])
        setHasError(false)
        onCloseEditModal()
      } catch (err) {
        setHasError(true)
      } finally {
        setIsModalLoading(false)
      }
    }
  }

  const deleteClientKey = async (
    setError: Dispatch<SetStateAction<string>>,
  ) => {
    if (targetId !== "") {
      try {
        setIsModalLoading(true)
        await AthenaAPI.delete(`v1/key3/${targetId}`, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            "If-Match":
              client3Keys.find((key) => key.id === targetId)
                ?.resource_version ?? 1,
          },
        })
        const keysTemp = [...client3Keys]
        keysTemp.splice(
          keysTemp.findIndex((key) => key.id === targetId),
          1,
        )
        setClient3Keys(keysTemp)
        onCloseDeleteModal()
      } catch (err) {
        setError(err)
      } finally {
        setIsModalLoading(false)
      }
    }
  }

  let pageContent: JSX.Element | null = null
  const totalClientKeys = client3Keys.length

  if (!isLoading) {
    if (totalClientKeys > 0) {
      const headings = TABLE_HEADERS.map((header) => (
        <IndexTable.Header key={header}>{header}</IndexTable.Header>
      ))

      const rows = client3Keys
        .sort(ByField("name"))
        .map((key3) => <KeysRow key={key3.id} key3={key3} />)

      pageContent = <IndexTable headings={headings} rows={rows} />
    } else {
      pageContent = <EmptySection text="No keys available" />
    }
  }

  return (
    <Section firstChild>
      <SectionHeader title="Keys" hasSectionContent={totalClientKeys > 0}>
        <SectionButton title="Create" onClick={onClickCreateKeys} />
      </SectionHeader>

      {pageContent}
      {isCreateModalOpen && (
        <Modal onClose={onCloseCreateModal}>
          <CreateModal
            title="Create key"
            text="Please enter a name for the key"
            initialText=""
            isLoading={isModalLoading}
            onClose={onCloseCreateModal}
            onSubmit={createClientKey}
          />
        </Modal>
      )}
      {isEditModalOpen && (
        <Modal onClose={onCloseEditModal}>
          <CreateModal
            title="Edit key"
            text="Please enter a new name for the key"
            isLoading={isModalLoading}
            submitButtonText="Save"
            initialText={
              client3Keys.find((key) => key.id === targetId)?.name ?? ""
            }
            onClose={onCloseEditModal}
            onSubmit={editClientKey}
          />
        </Modal>
      )}
      {isDeleteModalOpen && (
        <Modal onClose={onCloseDeleteModal}>
          <DeleteModal
            title="Delete key"
            text="Are you sure you want to delete this key?"
            isLoading={isModalLoading}
            onClose={onCloseDeleteModal}
            onSubmit={deleteClientKey}
          />
        </Modal>
      )}
    </Section>
  )
}
