import { StoreApi } from "zustand"
import { applyPatch } from "fast-json-patch"
import cloneDeep from "lodash/cloneDeep"

import { Payload } from "types/websocket/Message"
import { Market } from "@abios/abios-ts-sdk"
import { ALLOWED_STATES_LIST } from "pages/trading/types/State"
import { State } from "./State"

export const patchSingle = (
  get: StoreApi<State>["getState"],
  set: StoreApi<State>["setState"],
  payload: Payload,
) => {
  const { markets } = get()
  const marketsCloned = cloneDeep(markets)
  const payloadState = payload.state as Market

  // Get market from state
  const marketStore = marketsCloned[payloadState.id]

  // Only store/patch markets that have state OPEN, SUSPEND or CLOSED
  if (!ALLOWED_STATES_LIST.includes(payloadState.state)) {
    // if market already exist in store we need to remove it
    if (marketStore !== undefined) {
      delete marketsCloned[payloadState.id]
      set({ markets: marketsCloned })
    }
    return
  }

  if (marketStore && payload.patch.length > 0) {
    const marketObj = cloneDeep(marketStore)
    const marketPatched = applyPatch(marketObj, payload.patch).newDocument
    marketsCloned[payloadState.id] = marketPatched
  } else if (marketStore === undefined) {
    // Add market to state
    marketsCloned[payloadState.id] = payloadState
  }

  set({ markets: marketsCloned })
}
