import type { PushChannel } from "types/push_channels"
import type { TabDescriptor } from "@abios/smorgasbord-components"

export const pushTableRowsFilter = (
  selectedTabId: number,
  channel: PushChannel,
  tabs: TabDescriptor[],
) => {
  if (selectedTabId === 0) {
    // Selected tab 'All',
    return true
  }
  const selectedChannel = tabs[selectedTabId] || undefined
  if (selectedChannel === undefined) {
    return false
  }
  const channelExists = channel.categories.findIndex(
    (category) => category.name === selectedChannel.label,
  )
  return channelExists > -1
}
