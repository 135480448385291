import { Customer } from "types/customer"

export const getCreatedAtDate = (customer: Customer): string => {
  const date = new Date(customer.created)

  const isThisYear = date.getFullYear() === new Date().getFullYear()
  const year = isThisYear ? undefined : "numeric"

  const dateString = date.toLocaleDateString([], {
    year,
    month: "short",
    day: "numeric",
  })
  const timeString = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })

  return `${dateString}, ${timeString}`
}
