import styled from "styled-components"
import { IndexTable } from "@abios/smorgasbord-components"

const P = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledText = styled(P)`
  color: var(--text-primary);
  font-size: 0.813rem;

  &[data-date="true"] {
    color: var(--text-secondary);
    font-size: 0.813rem;
  }
`

export const StyledTextSub = styled(P)`
  color: var(--text-secondary);
  font-size: 0.688rem;
  font-weight: 500;
  letter-spacing: 0.2px;

  &[data-id="true"] {
    font-family: var(--font-family-mono);
  }
`

export const StyledIdContent = styled(IndexTable.Cell)`
  width: 255px;
`

export const StyledStatusGrid = styled.div`
  display: grid;
  grid-template-columns: 14px 1fr;
  grid-gap: 12px;
`

export const StyledFigure = styled.figure`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledRow = styled.tr`
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:hover {
    background: #f8fafb;
  }
`
