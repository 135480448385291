import { IndexTable } from "@abios/smorgasbord-components"
import { Widget, WidgetNamespace } from "types/widget_namespace"
import { useWidgets } from "./utils/widgets"
import { WidgetListContainer, WidgetListHeader } from "./WidgetContainer.styles"
import { WidgetListRow } from "./WidgetListRow"

type Props = {
  widgetNamespace: WidgetNamespace
}

const sortCollator = new Intl.Collator("en", {
  numeric: true,
  sensitivity: "base",
})

export const WidgetContainer = ({ widgetNamespace }: Props) => {
  const { widgets, isLoading } = useWidgets(
    `?namespace=${widgetNamespace.slug}`,
  )

  const HEADERS: string[] = ["ID", "Status", "Access", "Description", "Created"]
  const headings = HEADERS.map((header: string) => (
    <IndexTable.Header key={header}>{header}</IndexTable.Header>
  ))

  const slugToCapital = (slug: string): string => {
    return slug.charAt(0).toUpperCase() + slug.slice(1)
  }

  const widgetRows = widgets
    .sort((a: Widget, b: Widget) => sortCollator.compare(a.slug, b.slug))
    .map((widget: Widget) => <WidgetListRow key={widget.id} widget={widget} />)

  return (
    <WidgetListContainer>
      <WidgetListHeader>
        <h2>{slugToCapital(widgetNamespace.slug)}</h2>
        <p>{widgetNamespace.customer_description || ""}</p>
      </WidgetListHeader>
      {!isLoading && <IndexTable headings={headings} rows={widgetRows} />}
    </WidgetListContainer>
  )
}
