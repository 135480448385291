import { StoreApi } from "zustand"

import { State, TOKEN_STORAGE_NAME } from "./State"

export const signIn = (set: StoreApi<State>["setState"], token: string) => {
  // Clear x-customer
  localStorage.removeItem("xcustomer")
  // Set token to storage
  localStorage.setItem(TOKEN_STORAGE_NAME, JSON.stringify(token))
  set({ isAuthed: true })
}
