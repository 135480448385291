import { useAuthStore } from "stores"
import { Navigate, useLocation } from "react-router-dom"

interface Props {
  children: React.ReactChildren
}

export const OddsRouteMiddleware = ({ children }: Props) => {
  const location = useLocation()
  const { hasTradingAccess } = useAuthStore()

  if (!hasTradingAccess && location.pathname.startsWith("/trading")) {
    return <Navigate to="/" />
  }

  return children
}
