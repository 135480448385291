import orderBy from "lodash/orderBy"
import get from "lodash/get"

import { useAtlasStore } from "pages/trading/stores"
import { GAMEID, Participant } from "@abios/abios-ts-sdk"
import {
  StyledContent,
  StyledCompetitor,
  StyledText,
  StyledFigure,
  StyledLogo,
  StyledEmptyIcon,
} from "./Competitors.styles"

type Props = {
  participants: Participant[]
  gameId: GAMEID
}

const IMAGE_FALLBACK =
  "https://img.abiosgaming.com/other/New-Abios-Place-Holder.png"

export const Competitors = ({ participants, gameId }: Props) => {
  const { rosters, teams, players } = useAtlasStore()
  // Sort participants by seeds
  const teamsSortedBySeed = orderBy(participants, ["seed"])

  const list = teamsSortedBySeed.map((t) => {
    let name = ""
    let thumbnail = IMAGE_FALLBACK

    const roster = rosters[t.roster.id]

    if (roster) {
      // If it is a esoccer game, show the player name instead of team name
      if (gameId === GAMEID.ESOCCER) {
        if (!roster.line_up) {
          name = ""
        } else {
          name = players[roster.line_up.players[0].id]?.nick_name || ""
        }
      } else if (roster?.team) {
        const team = teams[roster.team.id]
        if (team !== undefined) {
          name = team.name
          const teamThumb = get(team, "images[0].thumbnail")
          if (teamThumb) thumbnail = teamThumb
        }
      }
    }

    const teamLogo =
      gameId === GAMEID.ESOCCER ? (
        <StyledEmptyIcon />
      ) : (
        <StyledLogo src={thumbnail} alt="competitor-logo" />
      )

    return (
      <StyledCompetitor key={t.seed.toString()}>
        <StyledFigure>{teamLogo}</StyledFigure>

        <StyledText>{name}</StyledText>
      </StyledCompetitor>
    )
  })

  return <StyledContent>{list}</StyledContent>
}
