import { ReactNode, useEffect, useState } from "react"
import { AxiosRequestConfig } from "axios"
import AthenaAPI from "services/athena"
import { useGameStore } from "stores"
import { GameAPI } from "types/game"
import { cErr } from "utils/debug"

/*
  Load basic resources that are needed on multiple pages like games,
  v3 products, push-channels, and packages
*/

type Props = {
  children: ReactNode
}

export const LoadResources = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(true)
  const { addGames } = useGameStore()

  useEffect(() => {
    const abortController = new AbortController()

    const getResources = async () => {
      const reqConfig = {
        signal: abortController.signal,
      } as AxiosRequestConfig

      try {
        // TODO: fetch basic product and package details for v3 client
        const resp = await Promise.all([
          AthenaAPI.get<GameAPI>("v1/game", reqConfig),
        ])

        if (!abortController.signal.aborted) {
          if (resp[0]?.data.games) {
            addGames(resp[0].data.games)
          }
        }
      } catch (e) {
        if (!abortController.signal.aborted) {
          // TODO: show error page to user
          cErr("unable to fetch basic resources", e)
        }
      } finally {
        setIsLoading(false)
      }
    }

    getResources()
    return () => {
      abortController.abort()
    }
  }, [addGames])

  // TODO: Show loading animation
  if (isLoading) {
    return null
  }

  return children
}
