import { useEffect, useState } from "react"
import { SeriesPreview } from "types/series_preview"
import AthenaAPI from "services/athena"
import { GAMEID } from "@abios/abios-ts-sdk"

const requestPreviewSeries = async (
  widgetID: string,
  gameID: GAMEID,
  signal: AbortSignal,
): Promise<SeriesPreview[]> => {
  const resp = await AthenaAPI.get(
    `v1/widget/${widgetID}/preview/game/${gameID}`,
    { signal },
  )
  return resp.data.series
}

export const usePreviewSeries = (
  widgetID: string | undefined,
  gameID: GAMEID | undefined,
): {
  series: SeriesPreview[]
  isLoading: boolean
  error?: string
} => {
  const [series, setSeries] = useState<SeriesPreview[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  useEffect(() => {
    const controller = new AbortController()

    const getPreviewSeries = async () => {
      if (!widgetID || !gameID) return
      setIsLoading(true)
      try {
        const data = await requestPreviewSeries(
          widgetID,
          gameID,
          controller.signal,
        )
        // Sanity check, should be removed once backend fixes
        if (data !== null) {
          setSeries(data)
        } else {
          setSeries([])
        }
        setError(undefined)
      } catch (err) {
        setError(err.message)
      } finally {
        setIsLoading(false)
      }
    }

    getPreviewSeries()
    return () => controller.abort()
  }, [gameID, widgetID])

  return {
    series,
    isLoading,
    error,
  }
}
