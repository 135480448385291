import styled from "styled-components"

export const StyledBetBox = styled.div`
  padding: 0.75rem;
`

export const StyledTitle = styled.h1`
  font-size: 14px;
  padding-bottom: 1rem;
`

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DisplayMoreText = styled.p`
  font-size: 12px;
  cursor: pointer;
`
