// GameImages represents the different keys for images a Gamecan contain

import { AthenaGame, AthenaGameImage } from "@abios/abios-ts-sdk"

export enum IMAGE_TYPE {
  CIRCLE = "circle",
  RECTANGLE = "rectangle",
  SQUARE = "square",
}

export type GameImages = AthenaGameImage & {
  id: number
  type: IMAGE_TYPE
}

export enum MATCH_TYPES {
  TEAM = "team",
  PLAYER = "player",
  BRAWL = "brawl",
  BATTLE_ROYALE = "battle_royale",
}

export type Game = Omit<AthenaGame, "images"> & {
  images: GameImages[]
}

// Response back when fetching games from Athena
export type GameAPI = {
  games: Game[]
}
