import styled from "styled-components"

export const WidgetPreviewContainer = styled.div`
  max-width: 950px;
  width: calc(100% - 300px - 2rem);
  height: 100%;
  padding-left: 2rem;
  border-left: solid 1px var(--border-default);

  @media (max-width: 1640px) {
    width: calc(100% - 300px - 2rem);
  }

  @media (max-width: 1000px) {
    border: none;
    width: 100%;
    padding: 0;
    border-top: solid 1px var(--border-default);
    margin-top: 2rem;
  }
`

export const StyledTitle = styled.h1`
  padding: 1rem 0;
`

export const DropdownContainer = styled.div`
  display: flex;
  gap: 1rem;
  padding-top: 1rem;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`
