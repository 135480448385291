import React from "react"
import styled from "styled-components"
import { Spring } from "react-spring"

const MessageContainer = styled.div`
  background-color: ${(props) => props.background || "inherit"};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height || "100%"};
  width: 100%;
`
const Text = styled.p`
  color: #4a4a57;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  line-height: 24px;
`

const ResponseMsg = ({ msg, background, height }) => (
  <Spring config={{ duration: 400 }} from={{ opacity: 0 }} to={{ opacity: 1 }}>
    {(props) => (
      <MessageContainer
        style={props}
        background={background}
        height={height ? height : "100%"}>
        <Text>{msg}</Text>
      </MessageContainer>
    )}
  </Spring>
)

export default ResponseMsg
