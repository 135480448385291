import React, { useState, useContext, useEffect } from "react"
import { Spring, Trail, animated } from "react-spring"
import styled from "styled-components"

import { BillingContext } from "../billing/Contexts"

import API from "../../../utils/api"
import PaymentMethodItem from "./component/Item"
import AddCardModal from "./component/NewMethod"
import Stripe3DSecure from "./component/Stripe3DSecure"
import LoadingContainer from "../../../components/LoadingContainer"

function PaymentMethods() {
  const billing = useContext(BillingContext)

  const [isUpdating, setIsUpdating] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  const [showAddCard, setShowAddCard] = React.useState(false)
  const [stripe3DIFrame, SetStripe3DIframe] = useState("")
  const [cards, setCards] = useState([])
  const [openID, setOpenID] = useState(null) // Used for opening dropdown for diff items
  const [errorMsgCards, setErrorMsgCards] = useState("")

  useEffect(() => {
    let didCancel = false

    const GetCards = async () => {
      try {
        const data = await API.get(`v1/customer/cards`)
        if (!didCancel) setCards(data)
      } catch (err) {
        if (!didCancel) setErrorMsgCards(err.message)
      }
      if (!didCancel) setIsFetching(false)
    }

    GetCards()
    return () => {
      didCancel = true
    }
  }, [])

  const getCards = async () => {
    if (isFetching) return
    setIsFetching(true)
    try {
      const data = await API.get(`v1/customer/cards`)
      setCards(data)
    } catch (err) {
      setErrorMsgCards(err.message)
    }
    setIsFetching(false)
  }

  function handleCloseNewMethod(shouldReload) {
    if (shouldReload) getCards()
    setShowAddCard(false)
  }

  function findCardIndex(cardID) {
    return cards.findIndex((obj) => obj.id === cardID)
  }

  const handleSetDefaultCard = async (cardID) => {
    let list = cards
    if (isUpdating) return
    setIsUpdating(true)
    setOpenID(null) // Close modal

    try {
      await API.patch(`v1/customer/card/${cardID}/default`)
      // Find new index for default card
      let newDefaultCardIndex = findCardIndex(cardID)
      if (newDefaultCardIndex > -1) {
        const oldCardIndex = cards.findIndex((obj) => obj.is_default === true)
        list[newDefaultCardIndex].is_default = true
        if (oldCardIndex > -1) {
          list[oldCardIndex].is_default = false
          setCards(list)
        } else {
          getCards()
        }
      } else {
        getCards()
      }
    } catch (err) {
      // FIXME:
      console.error(err)
    }
    setIsUpdating(false)
  }
  const handleDeleteCard = async (cardID) => {
    if (isUpdating) return
    setIsUpdating(true)

    try {
      await API.delete(`v1/customer/card/${cardID}`)

      let removeIndex = findCardIndex(cardID)
      if (removeIndex > -1) {
        let list = cards
        list.splice(removeIndex, 1)
        setCards(list)
      } else {
        getCards()
      }
      setOpenID(null)
    } catch (err) {
      // FIXME:
      console.error(err)
    }
    setIsUpdating(false)
  }

  const handleConfirmCard = (cardID) => {
    let cardIndex = findCardIndex(cardID)
    if (cardIndex > -1) {
      SetStripe3DIframe(cards[cardIndex].sca_details.redirect_url)
      setOpenID(null) // Close modal
    }
  }
  const handleCloseConfirmCard = () => {
    SetStripe3DIframe("") // Close modal
    getCards() // Reload cards
  }

  return (
    <>
      {stripe3DIFrame ? (
        <Stripe3DSecure
          url={stripe3DIFrame}
          hide={() => handleCloseConfirmCard()}
        />
      ) : null}
      {showAddCard && stripe3DIFrame === "" ? (
        <AddCardModal
          hide={(shouldReload) => handleCloseNewMethod(shouldReload)}
        />
      ) : null}

      <div className="container-right">
        <header className="flex-row header-with-button">
          <h2>Payment Methods</h2>

          <button
            className="box-head-btn"
            onClick={() => setShowAddCard(true)}
            disabled={isFetching || !billing.data || billing.errorMsg}
            btn-disabled={
              isFetching || !billing.data || billing.errorMsg ? "true" : "false"
            }>
            Add
          </button>
        </header>

        <div className="group-right payment-methods">
          {isFetching ? (
            <LoadingContainer />
          ) : errorMsgCards ? (
            <MessageContainer status="error" message={errorMsgCards} />
          ) : cards.length === 0 ? (
            <MessageContainer status="no-cards" message="" />
          ) : (
            <div className="items">
              <Trail
                items={cards}
                keys={(card) => card.id}
                from={{
                  marginLeft: -25,
                  opacity: 0,
                  transform: "translateY(-30px)",
                }}
                to={{
                  marginLeft: 0,
                  opacity: 1,
                  transform: "translateY(0px)",
                }}>
                {(card) => (props) => {
                  // Fixes z-index bug for item dropdown menu
                  if (card.id === openID) {
                    props["zIndex"] = 3
                    props["position"] = "relative"
                  }
                  return (
                    <animated.div style={props}>
                      <PaymentMethodItem
                        card={card}
                        cardLength={cards.length}
                        key={card.number}
                        openID={openID}
                        setOpenID={(cardID) => setOpenID(cardID)}
                        handleConfirmCard={(cardID) =>
                          handleConfirmCard(cardID)
                        }
                        handleSetDefaultCard={(cardID) =>
                          handleSetDefaultCard(cardID)
                        }
                        handleDeleteCard={(cardID) => handleDeleteCard(cardID)}
                      />
                    </animated.div>
                  )
                }}
              </Trail>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const ResponseMsgDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  text-align: center;
`
const ResponseMsg = styled.p`
  color: #4a4a57;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`

const MessageContainer = ({ status, message }) => {
  let msg = null
  if (status === "no-cards") msg = "No payment method exists"
  return (
    <Spring
      config={{ duration: 400 }}
      from={{ opacity: 0, marginTop: -35 }}
      to={{ opacity: 1, marginTop: 0 }}>
      {(props) => (
        <ResponseMsgDiv style={props}>
          <ResponseMsg>{msg || message}</ResponseMsg>
        </ResponseMsgDiv>
      )}
    </Spring>
  )
}

export default React.memo(PaymentMethods)
