import styled from "styled-components"

export const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 2rem;

  margin: 1rem 0;
  height: 2rem;
  border: 1px solid var(--border-default);
  border-radius: 2px;

  > span {
    font-size: 0.75rem;
    color: var(--text-secondary);
  }
`

export const StyledPaginationButton = styled.button`
  background: #fff;
  border: 1px solid var(--border-default);
  border-radius: 4px;
  color: var(--text-primary);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  padding: 0.5rem 1rem;

  &:hover {
    background: var(--light-gray);
  }
`
