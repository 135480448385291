import React, { useEffect, useRef, useState, useCallback } from "react"
import ReactDOM from "react-dom"
import { useSpring, animated } from "react-spring"
import { Trail } from "react-spring"

import { FormatCC } from "../../../../helpers/Card"
import FormResponseMessage from "./FormResponseMessage"
import LineSpinner from "../../../../components/Spinners/LineSpinner"

import API from "../../../../utils/api"

const NewPaymentSource = ({ hide }) => {
  const node = useRef()
  const [iframeURL, setIframeURL] = useState("")
  const [isSaving, setIsSaving] = useState(false)
  const [data, setData] = useState({
    isAdding: false,
    paymentMethod: 1, // TODO: Set default to 1 for now, change this later on when we accept more then 1 payment method
    card: {
      type: "",
      name: "",
      number: "",
      date: "",
      cvc: "",
    },
    errorMsg: "",
  })
  const modalProps = useSpring({
    config: { duration: 150 },
    opacity: 1,
    from: { opacity: 0 },
  })

  let paymentMethods = [
    {
      id: 1,
      subject: "Credit or Debit card",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      disabled: false,
    },
    {
      id: 2,
      subject: "Bank",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      disabled: true,
    },
  ]

  const handleClick = useCallback(
    (e) => {
      if (node.current.contains(e.target) || isSaving) {
        return
      }
      hide() // outside modal click
    },
    [hide, isSaving],
  )

  useEffect(() => {
    let didCancel = false
    const handleEsc = (e) => {
      if (e.keyCode === 27) hide(false)
    }

    window.addEventListener(
      "message",
      (ev) => {
        if (ev.data === "3DS-auth-complete") {
          if (!didCancel) hide(true)
        }
      },
      false,
    )

    document.addEventListener("mousedown", handleClick)
    window.addEventListener("keydown", handleEsc)
    return () => {
      didCancel = true
      document.removeEventListener("mousedown", handleClick)
      window.removeEventListener("keydown", handleEsc)
    }
  }, [handleClick, hide])

  async function onClickSave() {
    if (isSaving) return
    setIsSaving(true)

    let name = null
    // Clear old errorMsg if exists
    if (data.errorMsg) setData({ ...data, errorMsg: "" })

    // Sanity checks
    if (!data.paymentMethod) {
      setData({
        ...data,
        errorMsg: "You need to select a payment method",
      })
      setIsSaving(false)
      return
    }
    if (data.paymentMethod === 1) {
      // Credit/debit method
      if (!data.card.name) {
        setData({
          ...data,
          errorMsg: "Cardholder name is required",
        })
        setIsSaving(false)
        return
      }
    }

    if (data.card.name) {
      name = data.card.name
    }

    let dates = data.card.date.split(" / ")
    const card = {
      number: Number(data.card.number.split(" ").join("")),
      exp_month: dates[0]
        ? dates[0].charAt(0) === "0"
          ? Number(dates[0].substring(1))
          : Number(dates[0])
        : null,
      exp_year: dates[1] ? Number(dates[1]) : null,
      cvc: Number(data.card.cvc),
      cardholder_name: name,
    }

    try {
      const resp = await API.post("/v1/customer/card", card)

      if (
        resp &&
        resp.sca_details &&
        resp.sca_details.status === "requires_action"
      ) {
        setIframeURL(resp.sca_details.redirect_url)
      } else {
        hide(true)
        setIsSaving(false)
      }
    } catch (err) {
      setData({
        ...data,
        errorMsg: err.message,
      })
      setIsSaving(false)
    }
  }

  const handleCardholder = (e) => {
    e.persist()
    setData((prevData) => ({
      ...prevData,
      card: {
        ...prevData.card,
        name: e.target.value,
      },
    }))
  }

  const handleCardNumber = (e) => {
    e.persist()
    let cardType = ""
    if (e.target.value === "") {
      setData((prevData) => ({
        ...prevData,
        card: {
          ...prevData.card,
          type: "",
          number: "",
        },
      }))
      return
    }

    // Remove spaces from string
    let value = e.target.value.split(" ").join("")
    if (isNaN(value)) return

    if (Number(value.substring(0, 1)) === 4) {
      cardType = "visa"
    } else {
      let firstTwoChars = Number(value.substring(0, 2))
      if (firstTwoChars === 34 || firstTwoChars === 37) {
        cardType = "american-express"
      } else if (
        (firstTwoChars >= 51 && firstTwoChars <= 55) ||
        (firstTwoChars >= 22 && firstTwoChars <= 27)
      ) {
        cardType = "mastercard"
      }
    }

    // FIXME: Check if card is VISA/Mastercard or American express
    // FIXME: Format card format after card type

    setData((prevData) => ({
      ...prevData,
      card: {
        ...prevData.card,
        type: cardType,
        number: FormatCC(value),
      },
    }))
  }

  const handleCardDates = (e) => {
    e.persist()
    let newValue = "",
      value = e.target.value,
      valueLength = value.length

    let isAdd = data.card.date.length < valueLength

    // Check if it's a add or remove
    if (isAdd) {
      if (valueLength > 7) {
        return
      }
      if (isNaN(value.substr(-1))) {
        return
      }

      if (valueLength === 1) {
        let firstChar = value.substring(0, 1)
        if (Number(firstChar) !== 1 && Number(firstChar) !== 0) {
          newValue = `0${value} / `
        } else {
          newValue = value
        }
      } else {
        if (valueLength === 2) {
          newValue += `${value} / `
        } else if (valueLength === 3) {
          newValue += `${value.substring(0, 2)} / ${value.substr(-1)}`
        } else {
          newValue = value
        }
      }
    } else {
      if (valueLength === 1) {
        newValue = value
      } else if (valueLength === 5) {
        // Remove " / "
        let v = value.slice(0, -3)
        newValue = v
      } else if (valueLength === 6) {
        newValue = value
      }
    }

    setData((prevData) => ({
      ...prevData,
      card: {
        ...prevData.card,
        date: newValue,
      },
    }))
  }

  return ReactDOM.createPortal(
    <animated.div className="modal-overlay" style={modalProps}>
      {iframeURL ? (
        <iframe
          ref={node}
          style={{
            border: "none",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          title="Confirm card"
          src={iframeURL}
          height="650"
          frameBorder="0"
          width="600"
        />
      ) : (
        <div id="add-card-modal" ref={node}>
          <header>
            <h3>Add payment method</h3>
            <span className="close-icon" onClick={() => hide(false)} />
          </header>

          <div style={{ display: "none" }} className="payment-methods">
            <p>Choose payment method</p>

            <div className="payment-container">
              <Trail
                items={paymentMethods}
                keys={(item) => item.id}
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}>
                {(item) => (props) =>
                  (
                    <div
                      className="item"
                      item-active={
                        item.id === data.paymentMethod ? "true" : "false"
                      }
                      item-disabled={item.disabled.toString()}
                      style={props}
                      onClick={() => {
                        if (item.disabled) return
                        setData({ ...data, paymentMethod: item.id })
                      }}>
                      <div className="content">
                        <h5>{item.subject}</h5>
                        <p>{item.text}</p>
                      </div>
                    </div>
                  )}
              </Trail>
            </div>
          </div>

          <div className="payment-method-card">
            <p className="light-info-text">
              I authorise Abios to send instructions to the financial
              institution that issued my card to take payments from my card
              account in accordance with the terms of my agreement with you.
            </p>

            <div className="card-holder">
              <label htmlFor="name">Cardholder name</label>
              <input
                type="text"
                value={data.card.name}
                onChange={handleCardholder}
                placeholder="Enter the name of the cardholder"
              />
            </div>

            <div className="card">
              <label htmlFor="name">Card number</label>

              <div className="card-info">
                <input
                  type="text"
                  className="card-number"
                  placeholder="Enter your card number"
                  value={data.card.number}
                  onChange={(e) => handleCardNumber(e)}
                />

                <div>
                  <span className="divider">|</span>

                  <input
                    type="text"
                    className="card-dates"
                    placeholder="MM / YY"
                    value={data.card.date}
                    onChange={(e) => handleCardDates(e)}
                  />

                  <input
                    type="text"
                    className="card-cvc"
                    placeholder="CVC"
                    maxLength="4"
                    value={data.card.cvc}
                    onChange={(e) => {
                      e.persist()
                      if (isNaN(e.target.value)) {
                        return
                      }
                      setData((prevData) => ({
                        ...prevData,
                        card: {
                          ...prevData.card,
                          cvc: e.target.value,
                        },
                      }))
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="footer">
            {data.errorMsg ? (
              <FormResponseMessage error={data.errorMsg} />
            ) : null}
            <div
              className="button-loader-container"
              loading={isSaving ? "true" : "false"}>
              <button type="button" onClick={onClickSave} disabled={isSaving}>
                Save payment method
              </button>
              <LineSpinner />
            </div>
          </div>
        </div>
      )}
    </animated.div>,
    document.body,
  )
}

export default NewPaymentSource
