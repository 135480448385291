import React, { useContext, useState, useEffect } from "react"
import { useSpring, animated } from "react-spring"
import { Spring } from "react-spring"

import { BillingContext } from "./Contexts"

import API from "../../../utils/api"
import LoadingContainer from "../../../components/LoadingContainer"
import LineSpinner from "../../../components/Spinners/LineSpinner"

function BillingInformation() {
  const billing = useContext(BillingContext)

  const [isFetchingCountries, setIsFetchingCountries] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)
  const [countries, setCountries] = useState([])
  const [successMsg, setSuccessMsg] = useState("")
  const [billingErrorMsg, setBillingErrorMsg] = useState("")
  const [errorMsg, setErrorMsg] = useState("")

  useEffect(() => {
    let didCancel = false

    const GetCountries = async () => {
      try {
        const data = await API.get(`v1/countries`)
        if (!didCancel) setCountries(data)
      } catch (err) {
        if (!didCancel) setErrorMsg(err.message)
      }
      if (!didCancel) setIsFetchingCountries(false)
    }

    GetCountries()
    return () => {
      didCancel = true
    }
  }, [])

  const updateBilling = async () => {
    if (isUpdating || billing.isFetching || isFetchingCountries) return
    setIsUpdating(true)

    // If response messages already exist - clear them
    if (billingErrorMsg) setBillingErrorMsg("")
    if (successMsg) setSuccessMsg("")

    let b = Object.assign({}, billing.data)
    // Loop through obj and set empty strings as null, if VAT is used get VAT type from countries
    for (let values of Object.entries(b)) {
      let [key, value] = values

      if (key === "tax" && b.tax !== null) {
        if (b.tax.hasOwnProperty("value") && b.tax.value === "") {
          b.tax = null
        } else {
          if (b.hasOwnProperty("b.tax.status")) {
            // Remove status from obj
            delete b.tax.status
          }
          if (b.tax.value) {
            if (!b.country) {
              setBillingErrorMsg(
                "You need to select a country when using VAT number",
              )
              setIsUpdating(false)
              return
            }
            // Set VAT type from countries
            for (let i = 0; i < countries.length; i++) {
              if (countries[i].short_name === b.country) {
                b.tax.type = countries[i].vat_type
                break
              }
            }
            if (!b.tax.type) {
              setBillingErrorMsg("VAT is not supported for your country")
              setIsUpdating(false)
              return
            }
          }
        }
      } else if (!value) {
        b[key] = null
      }
    }

    try {
      await API.patch(`v1/customer/billing`, JSON.stringify(b))
      setSuccessMsg("Saved!")
    } catch (err) {
      setBillingErrorMsg(err.message)
    }
    setIsUpdating(false)
  }

  // Setup country list
  let countryList = []
  countryList.push(
    <option key={0} value="">
      Select country
    </option>,
  )
  countryList.push(<option key={1} value="" disabled></option>)
  for (let i = 0; i < countries.length; i++) {
    countryList.push(
      <option key={i + 2} value={countries[i].short_name}>
        {countries[i].name}
      </option>,
    )
  }

  return (
    <div className="container-left">
      <div className="flex-row header-with-button">
        <h2>Billing Information</h2>

        {successMsg || billingErrorMsg ? (
          <RespMsg success={successMsg} error={billingErrorMsg} />
        ) : null}

        <div
          className="button-loader-container"
          loading={isUpdating ? "true" : "false"}>
          <button
            type="button"
            className="box-head-btn"
            onClick={updateBilling}
            disabled={
              billing.isFetching ||
              billing.errorMsg ||
              isFetchingCountries ||
              isUpdating ||
              errorMsg
            }
            btn-disabled={
              billing.isFetching ||
              isFetchingCountries ||
              errorMsg ||
              billing.errorMsg
                ? "true"
                : "false"
            }>
            Save
          </button>
          <LineSpinner />
        </div>
      </div>

      <div className="group-left billing-details">
        {billing.isFetching || isFetchingCountries ? (
          <LoadingContainer />
        ) : errorMsg || billing.errorMsg ? (
          <ComponentResponseMessage msg={errorMsg || billing.errorMsg} />
        ) : (
          <>
            <div className="flex-column column-left">
              <div className="billing-info_section">
                <h5>Billing email</h5>

                <div className="blueBox">
                  <h5 className="open-sans">
                    <input
                      name="email"
                      className="billing-info_input"
                      placeholder="Billing email"
                      value={billing.data.email}
                      onChange={(e) => billing.handleInputChange(e)}
                    />
                  </h5>
                </div>

                <div className="billing-info_section">
                  <h5 id="vat-number_header">Country</h5>
                  <select
                    name="country"
                    className="styled-input"
                    value={billing.data.country}
                    onChange={(e) => billing.handleInputChange(e)}>
                    {countryList}
                  </select>
                </div>

                <div className="billing-info_section">
                  <h5 id="vat-number_header">VAT number</h5>
                  <div className="blueBox">
                    <h5 className="open-sans">
                      <input
                        name="vat"
                        className="billing-info_input"
                        placeholder="VAT number"
                        value={billing.data.tax.value}
                        onChange={(e) => billing.handleInputChange(e)}
                      />
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ display: true ? "block" : "none" }}
              className="flex-column column-right">
              <div className="billing-info_section billing-details_div">
                <h5>Billing Address</h5>
              </div>

              <div className="blueBox">
                <h5 className="open-sans">
                  <input
                    type="text"
                    name="line1"
                    className="billing-info_input"
                    placeholder="Address line 1"
                    value={billing.data.line1}
                    onChange={(e) => billing.handleInputChange(e)}
                    required
                  />
                </h5>
              </div>

              <div className="blueBox">
                <h5 className="open-sans">
                  <input
                    name="line2"
                    className="billing-info_input"
                    placeholder="Address line 2"
                    value={billing.data.line2}
                    onChange={(e) => billing.handleInputChange(e)}
                  />
                </h5>
              </div>

              <div className="blueBox">
                <h5 className="open-sans">
                  <input
                    name="postal_code"
                    className="billing-info_input"
                    placeholder="Postal code"
                    value={billing.data.postal_code}
                    onChange={(e) => billing.handleInputChange(e)}
                  />
                </h5>
              </div>

              <div className="blueBox">
                <h5 className="open-sans">
                  <input
                    name="city"
                    className="billing-info_input"
                    placeholder="City"
                    value={billing.data.city}
                    onChange={(e) => billing.handleInputChange(e)}
                  />
                </h5>
              </div>

              <div className="blueBox">
                <h5 className="open-sans">
                  <input
                    name="state"
                    className="billing-info_input"
                    placeholder="State"
                    value={billing.data.state}
                    onChange={(e) => billing.handleInputChange(e)}
                  />
                </h5>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const ComponentResponseMessage = ({ msg }) => (
  <Spring
    config={{ duration: 400 }}
    from={{ opacity: 0, marginTop: -35 }}
    to={{ opacity: 1, marginTop: 0 }}>
    {(props) => (
      <div style={props} className="component-box-error">
        <p>{msg}</p>
      </div>
    )}
  </Spring>
)

const RespMsg = (props) => {
  let msg = "",
    textColor = ""
  if (props.error) {
    msg = props.error
    textColor = "#F06E5F"
  } else {
    textColor = "#5AD887"
    msg = props.success
  }
  const springProps = useSpring({
    duration: 180,
    to: { color: textColor, opacity: 1 },
    from: { opacity: 0, color: "#FFF" },
  })
  return (
    <animated.p style={springProps} className="response-msg">
      {msg}
    </animated.p>
  )
}

export default React.memo(BillingInformation)
