type CrossIconProps = {
  color: string
  width?: string
  height?: string
}

export const CrossIcon = ({ color, width, height }: CrossIconProps) => (
  <svg width={width ?? "8px"} height={height ?? "8px"} viewBox="0 0 6 6">
    <path
      d="m3.5 3 1.397-1.398A.352.352 0 0 0 4.647 1a.352.352 0 0 0-.25.103L3 2.501 1.602 1.103a.352.352 0 0 0-.602.25c0 .097.04.186.103.25L2.501 3 1.103 4.398a.352.352 0 0 0 .25.602c.097 0 .186-.04.25-.103L3 3.499l1.398 1.398a.352.352 0 1 0 .499-.5L3.499 3Z"
      fill={color}
      fillRule="nonzero"
      stroke={color}
      strokeWidth=".5"
    />
  </svg>
)
