import { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import axios from "axios"

import { useAuthStore } from "stores"
import { SERVER_TIMEOUT, INTERNAL_ERROR } from "../../constants/common"
import ResponseMessage from "./ResponseMessage"
import PageLogo from "./components/PageLogo"
import CompHeader from "./components/ComponentHeader"
import LineSpinner from "../components/Spinners/LineSpinner"

export const Login = () => {
  const navigate = useNavigate()
  const { isAuthed, checkIsAuthed, signIn } = useAuthStore()

  const [activeTab, setActiveTab] = useState("sign-in")
  const [loginData, setLoginData] = useState({ email: "", password: "" })
  const [isPosting, setIsPosting] = useState(false)
  const [status, setStatus] = useState({ message: "", error: 0 })

  useEffect(() => {
    checkIsAuthed()
  }, [])

  if (isAuthed) {
    // User is already signed in
    return <Navigate to={{ pathname: "/" }} />
  }

  const ClearStatus = () => setStatus({ message: "", error: 0 })

  const handleSignIn = async (e) => {
    e.preventDefault()
    if (status.message) ClearStatus()

    if (!loginData.email || !loginData.password) {
      setStatus({ error: 1, message: "Please enter both email and password" })
      return
    }

    setIsPosting(true)
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_HEIMDALL_API}/v1/jwt`,
        {
          email_address: loginData.email.trim(),
          plain_password: loginData.password,
        },
      )

      // Get JWT token from the response header
      const jwtToken = resp.headers.authorization
      if (jwtToken) {
        const authSplitted = jwtToken.split(" ")
        signIn(authSplitted[1])
        navigate("/")
      } else {
        setStatus({ message: INTERNAL_ERROR, error: 1 })
      }
    } catch (err) {
      let msg = ""
      switch (err.response.status) {
        case 401:
          msg = "You entered an incorrect email, password, or both."
          break
        case 408:
          msg = SERVER_TIMEOUT
          break
        case 500:
          msg = INTERNAL_ERROR
          break
        default:
          msg = INTERNAL_ERROR
      }
      setStatus({ message: msg, error: 1 })
      setIsPosting(false)
    }
  }

  const onClickSendMail = async (e) => {
    e.preventDefault()
    if (status.message) ClearStatus()
    setIsPosting(true)
    const data = { email_address: loginData.email.trim() }
    try {
      await axios.post(
        `${process.env.REACT_APP_HEIMDALL_API}/v1/password/token`,
        data,
      )
      setActiveTab("check-mail")
    } catch (err) {
      setStatus({ message: err.message, error: 1 })
    }
    setIsPosting(false)
  }

  const onClickChangeTab = (tab) => {
    if (status.message) ClearStatus()
    setActiveTab(tab)
  }

  return (
    <div id="login-page" className="site-container auth">
      <div className="page-container" data-fade-in data-fade-out={isAuthed}>
        <PageLogo />

        <div
          className="component-group"
          data-active-tab={activeTab}
          data-error={!!status.message && !!status.error}>
          <div
            id="sign-in-component"
            className="component"
            data-active={activeTab === "sign-in"}>
            <CompHeader
              title="Dashboard"
              text="Enter your account details below"
            />

            <form onSubmit={handleSignIn}>
              <div id="email-input" className="input-text-field">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={loginData.email}
                  data-input-filled={!!loginData.email.length}
                  onChange={(e) => {
                    const val = e.target.value
                    setLoginData((prevState) => {
                      return { ...prevState, email: val }
                    })
                  }}
                />
                <span>Email</span>
              </div>

              <div id="password-input" className="input-text-field">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={loginData.password}
                  data-input-filled={!!loginData.password.length}
                  onChange={(e) => {
                    const val = e.target.value
                    setLoginData((prevState) => {
                      return { ...prevState, password: val }
                    })
                  }}
                />
                <span>Password</span>
              </div>

              <div
                className="submit-button-container"
                data-loading={isPosting && activeTab === "sign-in"}>
                <button
                  id="sign-in-btn"
                  type="submit"
                  onClick={(e) => handleSignIn(e)}
                  disabled={isPosting}>
                  Sign in
                </button>
                <LineSpinner />
              </div>
            </form>

            <p className="form-footer">
              Forgot your password?
              <button
                id="recover-pwd-btn"
                onClick={() => onClickChangeTab("forgot-password")}
                disabled={isPosting}>
                Recover Account
              </button>
            </p>
          </div>

          <div
            id="forgot-password-component"
            className="component"
            data-active={activeTab === "forgot-password"}>
            <CompHeader
              title="Forgot Password"
              text="Enter your email and we will help you create a new password"
            />

            <form onSubmit={onClickSendMail}>
              <div id="email-input" className="input-text-field">
                <input
                  name="email"
                  type="email"
                  value={loginData.email}
                  data-input-filled={!!loginData.email.length}
                  onChange={(e) => {
                    const val = e.target.value
                    setLoginData((prevState) => {
                      return { ...prevState, email: val }
                    })
                  }}
                />
                <span>Email</span>
              </div>

              <div
                className="submit-button-container"
                data-loading={isPosting && activeTab === "forgot-password"}>
                <button
                  type="submit"
                  onClick={(e) => onClickSendMail(e)}
                  disabled={isPosting}>
                  Reset
                </button>
                <LineSpinner />
              </div>
            </form>

            <p className="form-footer">
              Remember your credentials?
              <button onClick={() => onClickChangeTab("sign-in")}>
                Sign In
              </button>
            </p>
          </div>

          <div
            id="check-mail-component"
            className="component"
            data-active={activeTab === "check-mail"}>
            <CompHeader
              title="Check your mail"
              text="An email with instructions to reset your password has been sent
                to:"
              subText="If you haven't received an email within 5 minutes, check your
                spam folder or resend the mail by pressing the button below"
              email={loginData.email}
            />

            <form onSubmit={onClickSendMail}>
              <button
                type="submit"
                onClick={(e) => onClickSendMail(e)}
                disabled={isPosting}>
                Resend Mail
              </button>
            </form>

            <p className="form-footer">
              For any questions or problems email us at
              <br />
              <a href="mailto:info@abios.se">info@abios.se</a>
            </p>
          </div>
        </div>

        {status.message && <ResponseMessage status={status} />}
      </div>
    </div>
  )
}
