import styled from "styled-components"

export const StyledSelectionHeader = styled.div`
  display: grid;
  grid-gap: 1px;
  grid-auto-columns: 1fr;
  background: var(--gray-100);
  border-bottom: solid 1px white;
`

export const StyledSelectionHeaderItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 11px;
  font-weight: 500;
  color: var(--text-secondary);
  padding: 0.375rem;
  grid-row: 1;
  text-transform: uppercase;
`
