type Props = {
  size?: string
  color?: string
}

export const CrossIconNew = ({ size = "14", color = "black" }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none">
      <path
        d="M13 1L1 13M1 1L13 13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
