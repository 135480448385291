import React, { useContext } from "react"
import { Navigate, useLocation } from "react-router-dom"

import { CustomerContext } from "../customer/CustomerContext"
import PageLoader from "../components/PageLoader"

const Customer = ({ children }) => {
  const customer = useContext(CustomerContext)
  const location = useLocation()

  if (customer.isFetching) {
    return <PageLoader />
  }

  if (
    customer.data &&
    !customer.data.stripe &&
    location.pathname === "/billing"
  ) {
    return <Navigate to="/" />
  }

  return <>{children}</>
}

export default Customer
