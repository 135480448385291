import { LockIcon } from "atoms/icons"
import { useMemo } from "react"
import { useOddsStore, useAtlasStore } from "pages/trading/stores"
import {
  MARKET_STATE,
  Market,
  MarketSelection,
  Player,
  Team,
} from "@abios/abios-ts-sdk"
import { useSearchParams } from "react-router-dom"
import { OddsText } from "../market/atoms/OddsText"
import {
  StyledSelection,
  StyledOdds,
  StyledRow,
  StyledWinnerText,
  StyledLoserText,
} from "./SelectionBuilder.styles"
import { GetOperatorTemplate, GetSelectionSide } from "../utils"
import { E_MARKET_TEMPLATE } from "../types/EMarketTemplate"
import { TAB } from "../types/tab"

type Props = {
  market: Market
  leftCompetitor: Team | Player
  rightCompetitor: Team | Player
}

export const SelectionBuilder = ({
  market,
  leftCompetitor,
  rightCompetitor,
}: Props) => {
  const { players, series, matches, rosters } = useAtlasStore()
  const { selectedMarkets, onMarketSelect } = useOddsStore()

  const { leftSelection, rightSelection, drawSelection } = GetSelectionSide(
    market.selections,
    leftCompetitor,
    rightCompetitor,
  )

  const selectionTemplate = useMemo(
    () => GetOperatorTemplate(market.type),
    [market.type],
  )
  const [searchParams] = useSearchParams()
  const tab = searchParams.get("tab")

  const getOddsContent = (selection: MarketSelection) => {
    switch (market.state) {
      case MARKET_STATE.OPEN:
        return <OddsText odds={selection.odds} />

      case MARKET_STATE.SUSPENDED:
        return <LockIcon size="18" color="#374151" />

      case MARKET_STATE.SETTLED:
        return selection.was_outcome ? (
          <StyledWinnerText>Winner</StyledWinnerText>
        ) : (
          <StyledLoserText>Loser</StyledLoserText>
        )

      default:
        return null
    }
  }

  const getGameFromFixture = () => {
    const fixtureId = market.fixture.id

    const fixtureData =
      market.fixture.type === "series" ? series[fixtureId] : matches[fixtureId]

    // If it is CSGO
    return fixtureData.game.id === 5
  }

  const getMatchOrder = () => {
    if (market.fixture.type === "series") return -1

    return matches[market.fixture.id].order
  }

  const onOddsClick = (selectionId: number, isSelected: boolean) => {
    // NOTE: For now we only support CS:GO
    const isValidGame = getGameFromFixture()
    if (tab !== TAB.BETBUILDER) return
    if (!isValidGame) {
      return
    }

    const fixtureType = market.fixture.type
    if (fixtureType === "series") {
      // NOTE: we don't have support for series markets in bet slip
      return
    }

    // Check match is live
    if (fixtureType === "match") {
      const match = matches[market.fixture.id]
      // Get series from match id
      const s = series[match.series.id]
      if (s.lifecycle !== "upcoming") {
        // We only support pre-match betting
        return
      }
    } else {
      // Neither a series nor a match
      return
    }

    // Get type
    const matchOrder = getMatchOrder()

    onMarketSelect(
      market.id,
      selectionId,
      isSelected,
      market.fixture.type,
      market.type,
      matchOrder,
    )
  }

  const isSelectionSelected = (selectionId: number) => {
    return selectedMarkets[market.id] === selectionId
  }

  const getOdds = (
    selection: MarketSelection | undefined,
    position: number,
  ) => {
    if (!selection) return null

    const isSelected = isSelectionSelected(selection.id)

    return (
      <StyledOdds
        data-position={position}
        data-state={market.state}
        data-wasoutcome={selection.was_outcome}
        data-isselected={isSelected}
        onClick={() => onOddsClick(selection.id, isSelected)}>
        {getOddsContent(selection)}
      </StyledOdds>
    )
  }

  const getLine = (position: number, invertLine = false) => {
    if (!market.context?.line) return null

    // Markets like handicap only receives one line
    // When we build a selection row we need to invert
    // the selected value for the away team
    return (
      <StyledSelection data-position={position}>
        {invertLine ? market.context.line * -1 : market.context.line}
      </StyledSelection>
    )
  }

  const getLineIndex = (position: number) => {
    if (!market.context) return null

    const content = market.context.index || market.context.round_number || ""

    return <StyledSelection data-position={position}>{content}</StyledSelection>
  }

  const getScope = (position: number) => {
    if (!market.context?.round_number) return null

    return (
      <StyledSelection data-position={position}>
        {market.context.round_number}
      </StyledSelection>
    )
  }

  const getLineWithEntity = (position: number) => {
    if (!market.context?.player?.id) return null

    const nickname = players[market.context.player.id]?.nick_name || ""

    return (
      <StyledSelection data-position={position}>
        {`${nickname} ${market.context.line}`}
      </StyledSelection>
    )
  }

  const getLineFromRoster = (position: number) => {
    if (!market.context) return null

    // If no roster, return
    if (!market.context.roster) return null

    // get roster id from context and try to find the roster in the store
    // otherwise return
    const rosterId = market.context.roster.id
    const selectedRoster = rosters[rosterId]
    if (!selectedRoster) return null

    // Try to get the first player from the roster lineup
    const selectedLineupPlayers = selectedRoster.line_up?.players
    if (!selectedLineupPlayers || !selectedLineupPlayers.length) return null

    const firstPlayerInRoster = selectedLineupPlayers.at(0)
    if (!firstPlayerInRoster) return null

    // Try to find the player in the player store, otherwise return
    const selectedPlayer = players[firstPlayerInRoster.id]
    if (!selectedPlayer) return null

    return (
      <StyledSelection data-position={position}>
        {selectedPlayer.nick_name}
      </StyledSelection>
    )
  }

  const renderCorrectSeriesScoreRows = () => {
    return market.selections.map((s) => (
      <StyledRow data-columns="3">
        <StyledSelection data-position={1}>
          {s.context?.score?.home}
        </StyledSelection>
        {getOdds(s, 2)}
        <StyledSelection data-position={3}>
          {s.context?.score?.away}
        </StyledSelection>
      </StyledRow>
    ))
  }

  // The numbers represent the position in a grid
  switch (selectionTemplate) {
    case E_MARKET_TEMPLATE.TWO_COLUMN_TEAM:
      return (
        <StyledRow data-columns="2">
          {getOdds(leftSelection, 1)}
          {getOdds(rightSelection, 2)}
        </StyledRow>
      )

    case E_MARKET_TEMPLATE.THREE_COLUMN_TEAM:
      return (
        <StyledRow data-columns="3">
          {getOdds(leftSelection, 1)}
          {getOdds(drawSelection, 2)}
          {getOdds(rightSelection, 3)}
        </StyledRow>
      )

    case E_MARKET_TEMPLATE.TWO_COLUMN_YESNO:
      return (
        <StyledRow data-columns="2">
          {getOdds(leftSelection, 1)}
          {getOdds(rightSelection, 2)}
        </StyledRow>
      )

    case E_MARKET_TEMPLATE.THREE_COLUMN_TOTAL_OVER_UNDER:
      return (
        <StyledRow data-columns="3">
          {getLine(1)}
          {getOdds(leftSelection, 2)}
          {getOdds(rightSelection, 3)}
        </StyledRow>
      )

    case E_MARKET_TEMPLATE.THREE_COLUMN_EMPTY_TEAM:
      return (
        <StyledRow data-columns="3">
          {getScope(1)}
          {getOdds(leftSelection, 2)}
          {getOdds(rightSelection, 3)}
        </StyledRow>
      )

    case E_MARKET_TEMPLATE.THREE_COLUMN_ENTITY_OVER_UNDER:
      return (
        <StyledRow data-columns="3">
          {getLineWithEntity(1)}
          {getOdds(leftSelection, 2)}
          {getOdds(rightSelection, 3)}
        </StyledRow>
      )

    case E_MARKET_TEMPLATE.FOUR_COLUMN_TEAM:
      return (
        <StyledRow data-columns="4">
          {getLine(1)}
          {getOdds(leftSelection, 2)}
          {getOdds(rightSelection, 3)}
          {getLine(4, true)}
        </StyledRow>
      )

    case E_MARKET_TEMPLATE.THREE_COLUMN_TEAM_PROPS:
      return (
        <StyledRow data-columns="3">
          {getLineIndex(1)}
          {getOdds(leftSelection, 2)}
          {getOdds(rightSelection, 3)}
        </StyledRow>
      )

    case E_MARKET_TEMPLATE.CORRECT_SERIES_SCORE:
      return <>{renderCorrectSeriesScoreRows()}</>

    case E_MARKET_TEMPLATE.THREE_COLUMN_TEAM_YES_NO:
      return (
        <StyledRow data-columns="3">
          {getLineFromRoster(1)}
          {getOdds(leftSelection, 2)}
          {getOdds(rightSelection, 3)}
        </StyledRow>
      )

    default:
      return null
  }
}
