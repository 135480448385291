import { useContext } from "react"
import { Badge, STATUS } from "atoms/badge"
import { useGameStore } from "stores"
import { V3ResourcesActions } from "contexts/v3Resources"
import { getClientStatus } from "pages/api/utils/getClientStatus"
import { Client3, CLIENT_STATUS } from "types/client3"
import { getClientHistoricalAccess } from "pages/api/utils/getClientHistoricalAccess"
import { Game } from "types/game"
import { AthenaProduct, GameId } from "@abios/abios-ts-sdk"
import { Resources, Resource, GameResource } from "./resources"
import { H1, Section, StyledDl, StyledDt, StyledDd } from "./Sidebar.styles"
import { HermesTitle } from "./resources/Resources.styles"

const dateFormat = new Intl.DateTimeFormat([], {
  day: "numeric",
  year: "numeric",
  month: "short",
})

const getClientResources = (
  client: Client3,
  products: AthenaProduct[],
  getProductGamesById: (id: string) => GameId[],
  games: { [key: number]: Game },
): Resource[] => {
  // Holds information about what package the client has (match,pbp, odds)
  // and the games for each pkg
  const clientResources: Resource[] = []

  // add all products to clientResources with full games list
  const productsLength = products.length
  for (let i = 0; i < productsLength; i += 1) {
    const productGames = getProductGamesById(products[i].id)
    const res: Resource = {
      id: products[i].id,
      title: products[i].name,
      games: {},
    }
    // Add all games for the product
    productGames.forEach((gameId) => {
      res.games[gameId] = { ...games[gameId], isActive: false } as GameResource
    })
    // Add all games the client3 has access to
    client.packages.forEach((pkg) => {
      if (products[i].id === pkg.product.id) {
        // Set game as active
        res.games[pkg.game.id].isActive = true
      }
    })
    clientResources.push(res)
  }

  return clientResources
}

type Props = {
  client: Client3
  resources?: Resource[]
  hideBadge?: boolean
}

export const PreviewClient = ({ client, resources, hideBadge }: Props) => {
  const v3ResActions = useContext(V3ResourcesActions)
  const products = v3ResActions.getProducts()

  const { games } = useGameStore()

  const clientResources =
    resources ??
    getClientResources(
      client,
      products,
      v3ResActions.getProductGamesById,
      games,
    )

  let clientTerminates = ""
  let clientTerminated = ""

  const created = client.created
    ? dateFormat.format(new Date(client.created))
    : null

  const clientActiveUntil = client.active_until
    ? new Date(client.active_until)
    : null
  const clientStatus = getClientStatus(clientActiveUntil)
  let badgeText = ""
  let badgeStatus = STATUS.DEFAULT
  if (CLIENT_STATUS.ACTIVE === clientStatus) {
    badgeText = "Active"
    badgeStatus = STATUS.SUCCESS
  } else if (CLIENT_STATUS.WARNING === clientStatus) {
    badgeText = "Expiring"
    badgeStatus = STATUS.WARNING

    // Set client terminates date
    if (clientActiveUntil !== null) {
      clientTerminates = dateFormat.format(clientActiveUntil)
    }
  } else if (CLIENT_STATUS.INACTIVE === clientStatus) {
    badgeText = "Inactive"

    // Set client terminated date
    if (clientActiveUntil !== null) {
      clientTerminated = dateFormat.format(clientActiveUntil)
    }
  }

  const historicalAccess = getClientHistoricalAccess(client.hours_back)

  const idRow = client.id && (
    <>
      <StyledDt>Id</StyledDt>
      <StyledDd data-id="true">{client.id}</StyledDd>
    </>
  )

  const createdRow = created && (
    <>
      <StyledDt>Created</StyledDt>
      <StyledDd>{created}</StyledDd>
    </>
  )

  const terminatesRow = clientTerminates && (
    <>
      <StyledDt>Expires on</StyledDt>
      <StyledDd>{clientTerminates}</StyledDd>
    </>
  )

  const terminatedRow = clientTerminated && (
    <>
      <StyledDt>Terminated</StyledDt>
      <StyledDd>{clientTerminated}</StyledDd>
    </>
  )

  return (
    <>
      <H1>
        {client.name}
        {!hideBadge && <Badge status={badgeStatus}>{badgeText}</Badge>}
      </H1>

      <Section>
        <StyledDl>
          {idRow}
          {createdRow}
          {terminatesRow}
          {terminatedRow}

          <StyledDt>Historical access</StyledDt>
          <StyledDd>{historicalAccess}</StyledDd>
          <StyledDt>Requests per second</StyledDt>
          <StyledDd>{client.request_rate_second}</StyledDd>
          <StyledDt>Request burst</StyledDt>
          <StyledDd>{client.request_rate_burst}</StyledDd>
        </StyledDl>
      </Section>

      <Section>
        <HermesTitle>Hermes</HermesTitle>
        <StyledDl>
          <StyledDt>Maximum connections</StyledDt>
          <StyledDd>{client.max_concurrent_hermes_connections}</StyledDd>
        </StyledDl>
      </Section>

      <Section>
        <Resources clientResources={clientResources} />
      </Section>
    </>
  )
}
