import { useSearchParams } from "react-router-dom"
import { Match, Series } from "@abios/abios-ts-sdk"
import { useOddsStore } from "pages/trading/stores"
import {
  StyledContent,
  StyledTabs,
  StyledLinkBox,
  StyledText,
} from "./Header.styles"
import { TAB } from "../types/tab"

type Props = {
  matches: Match[]
  series: Series
}

export const Header = ({ matches, series }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const matchId = parseInt(searchParams.get("matchid") || "0")
  const { onClearSelectedBets, onResetMatchOrder } = useOddsStore()

  const tab = searchParams.get("tab")
  const hasBetBuilderAccess = localStorage.getItem("cDemo")
  const isSeriesActive = tab === TAB.SERIES
  const isBetBuilderActive = tab === TAB.BETBUILDER

  // If the series is best of 1, no need to show the nav tab
  if (series.format.best_of === 1)
    return (
      <StyledContent>
        <StyledTabs>
          <StyledText>Best of One Match</StyledText>
        </StyledTabs>
      </StyledContent>
    )

  const list = matches.map((m) => {
    // Ignore deleted matches
    if (m.deleted_at !== null) {
      return null
    }

    let isActive = false
    if (matchId === m.id && !isBetBuilderActive) {
      isActive = true
    }

    const onSelectMatchTab = () => {
      onClearSelectedBets()
      onResetMatchOrder()
      setSearchParams(
        { tab: TAB.MATCH, matchid: m.id.toString() },
        { replace: true },
      )
    }

    return (
      <StyledLinkBox
        onClick={onSelectMatchTab}
        key={m.order.toString()}
        data-active={isActive.toString()}>
        <StyledText data-active={isActive.toString()}>
          Match {m.order}
        </StyledText>
      </StyledLinkBox>
    )
  })

  const onSelectSeriesTab = () => {
    onClearSelectedBets()
    onResetMatchOrder()
    setSearchParams({ tab: TAB.SERIES }, { replace: true })
    searchParams.delete("matchid")
  }

  const onSelectBetBuilderTab = () => {
    setSearchParams(
      { tab: TAB.BETBUILDER, matchid: matches[0].id.toString() },
      { replace: true },
    )
  }

  const showBetBuilderTab = hasBetBuilderAccess && (
    <StyledLinkBox
      onClick={onSelectBetBuilderTab}
      key={1}
      data-active={isBetBuilderActive}>
      <StyledText data-active={isBetBuilderActive}>Bet Builder</StyledText>
    </StyledLinkBox>
  )

  return (
    <StyledContent>
      <StyledTabs>
        <StyledLinkBox
          onClick={onSelectSeriesTab}
          key={0}
          data-active={isSeriesActive}>
          <StyledText data-active={isSeriesActive}>Series</StyledText>
        </StyledLinkBox>
        {showBetBuilderTab}
        {list}
      </StyledTabs>
    </StyledContent>
  )
}
