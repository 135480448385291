import { useEffect } from "react"

import { useAtlasStore, useOddsStore } from "pages/trading/stores"
import { cErr } from "utils/debug"
import { SELECTION_ENTITY_TYPE, Series } from "@abios/abios-ts-sdk"

/*
  Whenever a change to markets has happened we want to check if
  we have all the resources from Atlas in store

  TODO: this should be splitted and broken out to an SDK
*/

export const GetResources = () => {
  const { markets, setFetchingResources } = useOddsStore()
  const {
    getTeamsFromAPI,
    getSeriesByIds,
    fetchTournamentsByIds,
    getRostersByIds,
    getMatchesByIds,
    setPlayersByIds,
  } = useAtlasStore()

  // Get atlas resources for markets
  useEffect(() => {
    const getAtlasResources = async () => {
      const newSeriesIds: number[] = []
      const newMatchIds: number[] = []
      const teamsIds: number[] = []
      // player list to be fetched, eSoccer market has only players and no teams
      const playerIds: number[] = []
      setFetchingResources(true)
      const checkSeriesAndTeam = async () => {
        // Get all series and team ids from market selections
        Object.keys(markets).forEach(async (k) => {
          const key = parseInt(k)
          // Series id
          if (!newSeriesIds.includes(markets[key].fixture.id)) {
            if (markets[key].fixture.type === "series")
              newSeriesIds.push(markets[key].fixture.id)

            if (markets[key].fixture.type === "match") {
              newMatchIds.push(markets[key].fixture.id)
            }
          }

          // Get team ids from selections
          const selectionsLength = markets[key].selections.length
          for (let j = 0; j < selectionsLength; j += 1) {
            const selection = markets[key].selections[j]
            // If selection's entity is a team
            if (selection.entity?.type === SELECTION_ENTITY_TYPE.TEAM) {
              const teamId: number = selection.entity?.id || 0
              // If it doesn't exist in the list, push it into the list
              if (teamId && !teamsIds.includes(teamId)) {
                teamsIds.push(teamId)
              }
            }
            // If selection's entity is a player
            if (selection.entity?.type === SELECTION_ENTITY_TYPE.PLAYER) {
              const playerId: number = selection.entity?.id || 0
              // If it doesn't exist in the list, push it into the list
              if (playerId && !playerIds.includes(playerId)) {
                playerIds.push(playerId)
              }
            }
          }
        })
      }

      await checkSeriesAndTeam()

      // best_of 1 series only has match markets, so we need to find the series by match id
      if (newMatchIds.length) {
        try {
          const matches = await getMatchesByIds(newMatchIds)
          matches.forEach((match) => {
            if (!newSeriesIds.includes(match.series.id)) {
              newSeriesIds.push(match.series.id)
            }
          })
        } catch (e) {
          cErr("unable to get match from atlas", e)
          // TODO: Show toast or error message
          return
        }
      }

      // Get all series needed from the API
      let series: Series[] = []
      // Don't fetch series if we don't have any new seriesIds
      if (newSeriesIds.length) {
        try {
          series = await getSeriesByIds(newSeriesIds)
        } catch (e) {
          cErr("unable to get series from atlas", e)
          // TODO: Show toast or error message
          return
        }
      }

      // get all list of unique tournament ids
      const tournamentIds: number[] = []
      const rosterIds: number[] = []
      // We need to get player info for esoccer game because they don't play as a team
      const eSoccerRosterIds: number[] = []

      for (let i = 0; i < series.length; i += 1) {
        if (!tournamentIds.includes(series[i].tournament.id)) {
          tournamentIds.push(series[i].tournament.id)
        }

        series[i].participants.forEach((p) => {
          if (!rosterIds.includes(p.roster.id)) {
            rosterIds.push(p.roster.id)
          }
          // If it is a esoccer match
          if (series[i].game.id === 24) {
            eSoccerRosterIds.push(p.roster.id)
          }
        })
      }
      /*
      / If series is empty that means all the api calls below will be
      / unnecessary, so we just return
      */
      if (!series.length) return

      // Get tournaments and teams from API that is missing from the state
      try {
        const [, , rosterList] = await Promise.all([
          fetchTournamentsByIds(tournamentIds),
          getTeamsFromAPI(teamsIds),
          getRostersByIds(rosterIds),
        ])

        rosterList.forEach((r) => {
          // Avoid pushing duplicated id into the list
          if (
            eSoccerRosterIds.includes(r.id) &&
            r.line_up &&
            !playerIds.includes(r.line_up.players[0].id)
          ) {
            playerIds.push(r.line_up.players[0].id)
          }
        })
        setPlayersByIds(playerIds)
      } catch (e) {
        cErr("unable to get resources from atlas", e)
        // TODO: Show toast or error message
      } finally {
        setFetchingResources(false)
      }
    }

    getAtlasResources()
  }, [
    getRostersByIds,
    getSeriesByIds,
    getTeamsFromAPI,
    fetchTournamentsByIds,
    markets,
    setFetchingResources,
    getMatchesByIds,
    setPlayersByIds,
  ])

  return null
}
