import { StoreApi } from "zustand"

import { Game } from "types/game"
import { GAMEID } from "@abios/abios-ts-sdk"
import { State } from "./State"

export const getById = (
  get: StoreApi<State>["getState"],
  gameId: GAMEID,
): Game | undefined => {
  return get().games[gameId]
}
