import React, { useEffect, useState } from "react"
import { Navigate, useParams } from "react-router-dom"
import axios, { CancelToken } from "axios"

import ResponseMessage from "./ResponseMessage"
import PageLogo from "./components/PageLogo"
import CompHeader from "./components/ComponentHeader"
import LineSpinner from "../components/Spinners/LineSpinner"
import BubbleSpinner from "../components/Spinners/BubbleSpinner"

const UpdatePassword = () => {
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [isUpdatingPwd, setIsUpdatingPwd] = useState(false)
  const [activeTab, setActiveTab] = useState("loading")
  const [userData, setUserData] = useState({
    password: "",
    password_repeat: "",
  })
  const [status, setStatus] = useState({ message: "", error: 0 })
  const { token } = useParams()

  useEffect(() => {
    let didCancel = false
    const source = CancelToken.source()

    const validateRecoveryToken = async () => {
      try {
        const d = await axios.get(
          `${process.env.REACT_APP_HEIMDALL_API}/v1/password/token/${token}`,
          {
            cancelToken: source.token,
          },
        )
        if (!didCancel) setActiveTab("reset-password")
      } catch (err) {
        if (!didCancel) setActiveTab("invalid-token")
      }
    }

    validateRecoveryToken()
    return () => {
      didCancel = true
      source.cancel()
    }
  }, [token])

  const onClickUpdatePwd = async (e) => {
    e.preventDefault()
    setIsUpdatingPwd(true)
    // Sanity check passwords as backend don't do this check
    if (userData.password !== userData.password_repeat) {
      setStatus({ message: "Password doesn't match.", error: 1 })
      setIsUpdatingPwd(false)
      return
    }
    try {
      await axios.put(
        `${process.env.REACT_APP_HEIMDALL_API}/v1/password`,
        {
          plain_password: userData.password,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      setShouldRedirect(true)
    } catch (err) {
      setStatus({ message: err.message, error: 1 })
      setIsUpdatingPwd(false)
    }
  }

  if (shouldRedirect) {
    return <Navigate to={{ pathname: "/login" }} />
  }

  return (
    <div id="update-password-page" className="site-container auth">
      <div
        className="page-container"
        data-fade-in={true}
        data-fade-out={shouldRedirect}>
        <PageLogo />

        <div
          className="component-group"
          data-active-tab={activeTab}
          data-error={!!status.message && !!status.error}>
          <div
            id="loading-component"
            className="component"
            data-active={activeTab === "loading"}>
            <BubbleSpinner />
          </div>

          <div
            id="reset-password-component"
            className="component"
            data-active={activeTab === "reset-password"}>
            <CompHeader
              title="Update Password"
              text="Please enter your new password below"
            />

            <form noValidate>
              <div id="email-input" className="input-text-field">
                <input
                  type="password"
                  name="password"
                  value={userData.password}
                  data-input-filled={!!userData.password.length}
                  onChange={(e) => {
                    let v = e.target.value
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        password: v,
                      }
                    })
                  }}
                />
                <span>Password</span>
              </div>

              <div id="email-input" className="input-text-field">
                <input
                  type="password"
                  name="password_repeat"
                  value={userData.password_repeat}
                  data-input-filled={!!userData.password_repeat.length}
                  onChange={(e) => {
                    let v = e.target.value
                    setUserData((prevState) => {
                      return {
                        ...prevState,
                        password_repeat: v,
                      }
                    })
                  }}
                />
                <span>Repeat Password</span>
              </div>

              <div
                className="submit-button-container"
                data-loading={isUpdatingPwd}>
                <button type="submit" onClick={(e) => onClickUpdatePwd(e)}>
                  Update
                </button>
                <LineSpinner />
              </div>
            </form>
          </div>

          <div
            id="invalid-token-component"
            className="component"
            data-active={activeTab === "invalid-token"}>
            <CompHeader
              title="Invalid Token"
              text="If you have already updated your password or want to request a
                new token, press the button below"
            />

            <form>
              <button type="submit" onClick={() => setShouldRedirect(true)}>
                Back to login
              </button>
            </form>

            <p className="form-footer">
              For any questions or problems email us at
              <br />
              <a href="mailto:info@abios.se">
                info@abios.se
              </a>
            </p>
          </div>
        </div>

        {status.message ? <ResponseMessage status={status} /> : null}
      </div>
    </div>
  )
}

export default UpdatePassword
