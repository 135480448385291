import styled from "styled-components"

export const StyledLink = styled.a`
  color: var(--primary-accent);
  font-size: var(--text-xs);
  font-weight: 500;
  line-height: 16px;
`

const StyledP = styled.p`
  font-size: var(--text-xs);
  line-height: 16px;
  word-wrap: break-word;
`

export const StyledChannelName = styled(StyledP)`
  color: var(--text-heading);
  font-family: var(--font-family-mono);
  font-size: 11px;
  font-weight: 400;
  padding-right: 8px;
`

export const StyledTextDescription = styled(StyledP)`
  color: var(--text-secondary);
  padding: 8px 8px 8px 0;
`

export const StyledAccessContent = styled.div`
  width: 130px;
`

export const StyledReference = styled.div`
  text-align: right;
  width: 110px;
`
