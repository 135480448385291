import { ReactNode, useCallback, useMemo } from "react"
import {
  BADGE_SIZE,
  Badge,
  BADGE_STATUS,
  TabDescriptor,
  Tabs,
} from "@abios/smorgasbord-components"
import {
  StyledTabContent,
  StyledTabs,
} from "pages/api/single/main/PushChannels.styles"
import { useNavigate, useParams } from "react-router"
import { AthenaInvoice } from "@abios/abios-ts-sdk"
import { TAB, useTrackedFilterStore } from "./utils/useTrackedFilterStore"

type TabLabelProps = {
  label: "All" | "Overdue Payments"
  overduePaymentsAmount: number
}

const TabLabel = ({ label, overduePaymentsAmount }: TabLabelProps) => (
  <StyledTabContent>
    <span>{label}</span>
    {overduePaymentsAmount !== 0 && (
      <Badge
        className="badge_styles"
        status={BADGE_STATUS.ERROR}
        size={BADGE_SIZE.S}>
        {overduePaymentsAmount}
      </Badge>
    )}
  </StyledTabContent>
)

const createTabsByOverduePayments = (
  allOverduePayments: AthenaInvoice[],
): {
  id: TAB
  label: ReactNode
}[] => {
  return [
    {
      id: TAB.ALL,
      label: <TabLabel label="All" overduePaymentsAmount={0} />,
    },
    {
      id: TAB.OVERDUE_PAYMENTS,
      label: (
        <TabLabel
          label="Overdue Payments"
          overduePaymentsAmount={allOverduePayments.length}
        />
      ),
    },
  ]
}

const OverPaymentsTabs = () => {
  const { tab, setTab, allOverduePayments } = useTrackedFilterStore()
  const tabs = useMemo(() => {
    return createTabsByOverduePayments(allOverduePayments)
  }, [allOverduePayments])

  const navigate = useNavigate()

  const urlParam = useParams()

  const tabIndex = tabs.findIndex(
    (t: TabDescriptor) => t.id === (urlParam.tab ?? tab), // if selected tab value is not in URL, used the default tab value
  )

  const onSelect = useCallback(
    (selectedTab: number) => {
      const selected = tabs[selectedTab]
      setTab(selected.id)
      navigate(`/admin/customers/${selected.id}`) // Save selected tab value in URL
    },
    [navigate, setTab, tabs],
  )

  return (
    <StyledTabs>
      <Tabs selected={tabIndex} tabs={tabs} onSelect={onSelect} />
    </StyledTabs>
  )
}

export default OverPaymentsTabs
