import { StoreApi } from "zustand"
import atlasAPI from "services/atlas"
import { cErr } from "utils/debug"
import { Player } from "@abios/abios-ts-sdk"
import { deepClone } from "fast-json-patch"
import { State } from "../State"

export const setPlayersByIds = async (
  set: StoreApi<State>["setState"],
  get: StoreApi<State>["getState"],
  playerIds: number[],
) => {
  const { players } = get()
  const newPlayers: { [key: number]: Player } = {}
  const idsToFetch: number[] = []

  playerIds.forEach((playerId) => {
    if (!players[playerId] && !idsToFetch.includes(playerId)) {
      idsToFetch.push(playerId)
    }
  })

  if (!idsToFetch.length) return

  const url = `v3/players?filter=id<={${idsToFetch.join()}}`
  try {
    const { data } = await atlasAPI.get<Player[]>(url)
    data.forEach((player) => {
      newPlayers[player.id] = deepClone(player)
    })
    set({ players: { ...players, ...newPlayers } })
  } catch (err) {
    cErr(err)
  }
}
