import styled from "styled-components"
import { ErrorText, StyledLabel as RootStyledLabel } from "../Inputs.styles"
import { Unit, UnitPicker } from "./UnitPicker"

const StyledLabel = styled(RootStyledLabel)`
  ${ErrorText} {
    visibility: none;
  }

  &[data-error="true"] {
    ${ErrorText} {
      visibility: visible;
    }

    > div {
      border: 1px solid #ca2e16;
    }
  }
`

const UnstyledInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`

const StyledDivInput = styled.div`
  border: 1px solid #e1e5ea;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  height: 39px;
`

type Props = {
  unit: Unit
  setUnit: (unit: Unit) => void
  label: string
  error?: string
  type: "number"
  value?: number
  setValue: (value: number | undefined) => void
  min?: number
  max?: number
  className?: string
}

export const UnitPickerInput = ({
  type,
  label,
  error,
  min,
  max,
  value,
  setValue,
  unit,
  setUnit,
  className,
}: Props) => (
  <StyledLabel data-error={!!error} className={className}>
    <h6>{label}</h6>
    <ErrorText>{error}</ErrorText>

    <StyledDivInput>
      <UnstyledInput
        type={type}
        min={min}
        max={max}
        // Note: value could be 0 and it is a valid input
        value={value !== undefined ? value : ""}
        onChange={(e) => {
          setValue(e.target.value !== "" ? +e.target.value : undefined)
        }}
      />
      <UnitPicker unit={unit} onChange={setUnit} />
    </StyledDivInput>
  </StyledLabel>
)
