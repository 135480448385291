/* eslint-disable  @typescript-eslint/no-explicit-any */
export function clog(...args: any): void {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log(...args)
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function cErr(...args: any): void {
  // Send error to Application monitoring platform
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.error(...args)
  }
}
