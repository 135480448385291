import { Dispatch, ReactNode } from "react"
import { Customer } from "types/customer"
import { AthenaInvoice } from "@abios/abios-ts-sdk"
import { StyledTable } from "../List.styles"
import { Pagination } from "./Pagination"
import TableRow from "./TableRow"

type Props = {
  headings: ReactNode
  customersList: Customer[]
  pagination: number
  setPagination: Dispatch<React.SetStateAction<number>>
  invoicesDic: Map<string, AthenaInvoice[]>
  onCustomerChange: (customer: Customer) => Promise<void>
}

export const CustomerList = ({
  headings,
  customersList,
  pagination,
  setPagination,
  invoicesDic,
  onCustomerChange,
}: Props) => {
  const rows = customersList
    .slice(0, pagination)
    .map((customer) => (
      <TableRow
        key={customer.id}
        customer={customer}
        invoices={invoicesDic.get(customer.id)}
        onSelect={onCustomerChange}
      />
    ))

  return (
    <>
      <StyledTable headings={headings} rows={rows} />
      <Pagination
        items={customersList}
        value={pagination}
        onChange={setPagination}
      />
    </>
  )
}
