import { ReactNode } from "react"
import { GAMEID } from "@abios/abios-ts-sdk"
import { StyledButton } from "./GameItem.styles"

type Props = {
  gameTitle: string
  gameID: GAMEID
  onSelect: (value: string, gameID: number) => void
  children: ReactNode
}

export const GameItem = ({ gameTitle, gameID, onSelect, children }: Props) => (
  <StyledButton onClick={() => onSelect(gameTitle, gameID)}>
    {children}
  </StyledButton>
)
