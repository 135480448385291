import styled from "styled-components"

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Section = styled.section`
  padding-top: 24px;

  &:not(:first-of-type) {
    padding-top: 32px;
  }
`

export const Aside = styled.aside`
  margin: -24px 32px 0 0;
  width: 280px;
  position: sticky;
  top: 1.5rem;
`

export const Span = styled.span`
  color: var(--text-secondary);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.32px;
  align-self: flex-end;
`

export const H1 = styled.h1`
  font-weight: 700;
  line-height: 40px;
  word-wrap: break-word;
  position: relative;
  // Leave the space for badge
  max-width: calc(100% - 75px);

  span {
    bottom: 8px;
    height: 20px;
    margin-left: 18px;
    padding: 0 8px;
    position: absolute;
  }
`

export const StyledDl = styled.dl`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;
`

export const StyledDt = styled.dt`
  color: var(--text-secondary);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  grid-column: 1;
  letter-spacing: 0.16px;

  &:not(:last-child) {
    padding-bottom: 10px;
  }
`

export const StyledDd = styled.dd`
  color: var(--text-primary);
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  grid-column: 2;

  &[data-id="true"] {
    font-family: var(--font-family-mono);
    font-weight: 500;
  }
`
