import LoadingContainer from "jsx/components/LoadingContainer"
import { useAtlasStore, useOddsStore } from "pages/trading/stores"
import { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import BetBuilderAPI from "services/betbuilder"
import { cErr } from "utils/debug"
import { BetBox } from "./BetBox"
import {
  BetContiner,
  BetPlacementContainer,
  StyledBetButton,
  StyledBetError,
  StyledComboBetContainer,
  StyledContainer,
  StyledStickyConatiner,
  StyledDivider,
} from "./BetBuilder.styles"
import { createBetBuilderObjects } from "./utils/CreateBetBuilderObjects"
import { TAB } from "../../types/tab"

export const BetBuilder = () => {
  const { matches } = useAtlasStore()
  const { selectedMarkets, markets } = useOddsStore()

  const [searchParams] = useSearchParams()
  const [comboOdds, setComboOdds] = useState<string>("")
  const [isLoadingOdds, setLoadingOdds] = useState<boolean>(false)
  const [hasCombinationError, setCombinationError] = useState<boolean>(false)

  const hasBetBuilderAccess = localStorage.getItem("cDemo")
  const tab = searchParams.get("tab")

  useEffect(() => {
    const controller = new AbortController()

    const getBetOdds = async () => {
      setCombinationError(false)
      // If the selected selections are below 2 we dont make a request
      // for the bet builder
      if (Object.keys(selectedMarkets).length <= 1) return
      if (!hasBetBuilderAccess && tab !== TAB.BETBUILDER) return

      const betMatches = createBetBuilderObjects(markets, selectedMarkets)

      try {
        setLoadingOdds(true)
        const response = await BetBuilderAPI.post(
          "/combination",
          {
            matches: betMatches,
          },
          {
            signal: controller.signal,
          },
        )
        if (response.data.odds === -1.0) {
          setCombinationError(true)
          setComboOdds("N/A")
        } else {
          setComboOdds(response.data.odds)
        }
      } catch (err) {
        cErr(err)
      } finally {
        setLoadingOdds(false)
      }
    }

    getBetOdds()
    // eslint-disable-next-line consistent-return
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarkets])

  const hasBets = useMemo(
    () => Object.keys(selectedMarkets).length,
    [selectedMarkets],
  )

  const getSeriesIdFromMatchId = (matchId: number) => {
    return matches[matchId].series.id
  }

  const groupSelectedMarketsBySeries = () => {
    const groupedSelections: { [key: number]: string[] } = {}

    Object.keys(selectedMarkets).forEach((marketId) => {
      const market = markets[Number(marketId)]

      const seriesId =
        market.fixture.type === "series"
          ? market.fixture.id
          : getSeriesIdFromMatchId(market.fixture.id)

      if (Object.prototype.hasOwnProperty.call(groupedSelections, seriesId)) {
        groupedSelections[seriesId] = [...groupedSelections[seriesId], marketId]
      } else {
        groupedSelections[seriesId] = [marketId]
      }
    })

    return groupedSelections
  }

  const getBets = () => {
    const groupedBets = groupSelectedMarketsBySeries()

    return Object.entries(groupedBets).map(([key, marketIds]) => (
      <BetBox key={key} seriesId={key} betMarketIds={marketIds} />
    ))
  }

  // Function checks whether the length is 1, if so we fetch the odds for
  // the selected market otherwise we use the odds from the selected selection
  const getBetSlipOdds = () => {
    const selectedMarketKeys = Object.keys(selectedMarkets)

    if (selectedMarketKeys.length === 1) {
      const singleMarketKey = Number(selectedMarketKeys[0])
      const singleMarket = markets[singleMarketKey]
      const singleSelectionKey = selectedMarkets[singleMarketKey]

      if (!singleMarket) {
        // TODO
        // If market is not found for some reason we have to handle this
        return ""
      }

      const singleSelection = singleMarket.selections.find(
        (selection) => selection.id === singleSelectionKey,
      )

      if (!singleSelection) {
        // TODO
        // If selection is not found for some reason we have to handle this
        return ""
      }

      // Return with 2 decimals to visual please the odds
      return Number(singleSelection.odds).toFixed(2)
    }

    return comboOdds
  }

  const comboBets = !isLoadingOdds ? (
    <p>{getBetSlipOdds()}</p>
  ) : (
    <LoadingContainer circleSize={24} />
  )

  const combinationError = hasCombinationError && (
    <StyledBetError>
      Sorry, the selected outcomes cannot be combined.
    </StyledBetError>
  )

  // Temporary solution for ICE
  if (hasBets && tab === TAB.BETBUILDER)
    return (
      <StyledContainer>
        <StyledStickyConatiner>
          <h3>Bet Slip</h3>
          <StyledDivider />
          <BetContiner>{getBets()}</BetContiner>
          {combinationError}
          <StyledDivider />
          <BetPlacementContainer>
            <StyledComboBetContainer>{comboBets}</StyledComboBetContainer>
            <StyledBetButton disabled>Place Bet</StyledBetButton>
          </BetPlacementContainer>
        </StyledStickyConatiner>
      </StyledContainer>
    )

  return null
}
