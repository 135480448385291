import styled from "styled-components"

export const StyledSubmitButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  background: var(--primary-accent);
  color: var(--text-white);
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.25px;
  outline: none;
  padding: 0.625rem 1.125rem;
  user-select: none;
`

export const StyledAbortButton = styled.button`
  cursor: pointer;

  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e3e8ee;
`
