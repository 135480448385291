import { useContext, useEffect, useState } from "react"
import { Navigate, Outlet } from "react-router-dom"

import { useAuthStore } from "stores"
import athenaCustomer from "services/athenaCustomer"
import { cErr } from "utils/debug"
import { UserContext } from "./user/UserContext"
import { CustomerContext } from "./customer/CustomerContext"

import LoadingContainer from "./components/LoadingContainer"

interface Props {
  redirectTo?: string
}

const PrivateRoute = ({ redirectTo = "/login" }: Props) => {
  const { isAuthed } = useAuthStore()
  const user = useContext(UserContext)
  const customer = useContext(CustomerContext)

  const [isLoadingCustomer, setLoadingCustomer] = useState<boolean>(true)
  const [shouldSignAgreement, setShouldSignAgreement] = useState<boolean>(false)
  const [customerID, setCustomerID] = useState<string>("")

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        setLoadingCustomer(true)
        const { data } = await athenaCustomer.get("")

        if (data.customers[0].trial_agreement_status === "unsigned") {
          setCustomerID(data.customers[0].id)
          setShouldSignAgreement(true)
        }
      } catch (e) {
        cErr(e)
      } finally {
        setLoadingCustomer(false)
      }
    }

    fetchCustomer()
  }, [])

  if (user.isFetching || customer.isFetching || isLoadingCustomer)
    return <LoadingContainer height="calc(100% - 127px)" />

  if (!isAuthed) return <Navigate to={{ pathname: redirectTo }} />

  if (shouldSignAgreement) {
    return <Navigate to={{ pathname: `/agreement/${customerID}` }} />
  }

  return <Outlet />
}

export default PrivateRoute
