import { create } from "zustand"
import { createTrackedSelector } from "react-tracked"

import { Payload } from "types/websocket/Message"
import { State } from "./State"
import { getTeamsFromAPI } from "./GetTeamsFromAPI"
import { getSeriesByIds } from "./GetSeriesByIds"
import { patchSeries } from "./PatchSeries"
import { fetchTournamentsByIds } from "./FetchTournamentsByIds"
import { getRostersByIds } from "./GetRostersByIds"
import { getMatchIdsFromSeries } from "./GetMatchIdsFromSeries"
import { getMatchesByIds } from "./GetMatchesByIds"
import { getMatchOrderById } from "./GetMatchOrderById"
import { getTournamentsByIds } from "./GetTournamentsByIds"
import { clear } from "./clear"
import { setPlayersByIds } from "./Player/setPlayersByIds"

const useStore = create<State>((set, get) => ({
  matches: {},
  rosters: {},
  series: {},
  teams: {},
  tournaments: {},
  players: {},

  /*
    Methods
  */

  // Clear store
  clear: () => clear(set),

  // Team
  getTeamsFromAPI: (teamIds) => getTeamsFromAPI(set, get, teamIds),

  // Tournament
  getTournamentsByIds: (ids) => getTournamentsByIds(get, ids),
  fetchTournamentsByIds: (tournamentIds) =>
    fetchTournamentsByIds(set, get, tournamentIds),

  // Match
  getMatchesByIds: (matchIds) => getMatchesByIds(set, get, matchIds),
  getMatchOrderById: (matchId) => getMatchOrderById(get, matchId),

  // Series
  getSeriesByIds: (seriesIds) => getSeriesByIds(set, get, seriesIds),
  getMatchIdsFromSeries: (seriesId) => getMatchIdsFromSeries(get, seriesId),
  patchSeries: (payload: Payload) => patchSeries(get, set, payload),

  // Rosters
  getRostersByIds: (rosterIds) => getRostersByIds(set, get, rosterIds),

  // Players
  setPlayersByIds: (playerIds) => setPlayersByIds(set, get, playerIds),
}))

export const useAtlasStore = createTrackedSelector(useStore)
