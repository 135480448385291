import {
  StyledContent,
  StyledLiveText,
  StyledDateDiv,
  StyledTimeText,
  StyledEndedText,
  StyledDateText,
  StyledLiveFigure,
} from "./SeriesDate.styles"

type Props = {
  start: string | null
  end: string | null
}

const timeFormat = new Intl.DateTimeFormat([], {
  hour: "2-digit",
  minute: "2-digit",
})
const dayFormat = new Intl.DateTimeFormat([], {
  month: "short",
  day: "numeric",
})

export const SeriesDate = ({ start, end }: Props) => {
  let isLive = false
  let hasEnded = false
  let startTime = ""
  let startDay = ""

  if (start) {
    const timeNow = new Date()
    const seriesStartTime = new Date(start)

    if (end) {
      hasEnded = true
    } else if (timeNow > seriesStartTime) {
      isLive = true
    } else {
      startTime = timeFormat.format(seriesStartTime)
      startDay = dayFormat.format(seriesStartTime)

      // Check if the series starts today
      if (
        timeNow.toLocaleDateString() === seriesStartTime.toLocaleDateString()
      ) {
        startDay = "Today"
      }
    }
  }

  let innerContent: JSX.Element | null = null

  if (isLive) {
    innerContent = (
      <StyledLiveFigure>
        <StyledLiveText>Live</StyledLiveText>
      </StyledLiveFigure>
    )
  } else if (hasEnded) {
    innerContent = <StyledEndedText>ENDED</StyledEndedText>
  } else if (startTime !== "") {
    innerContent = (
      <StyledDateDiv>
        <StyledTimeText>{startTime}</StyledTimeText>
        {startDay && <StyledDateText>{startDay}</StyledDateText>}
      </StyledDateDiv>
    )
  }

  return <StyledContent>{innerContent}</StyledContent>
}
