import styled, { css } from "styled-components"

type StyledTextProps = {
  isSuccess: boolean
}

const StyledText = styled.p<StyledTextProps>`
  font-size: 13px;
  font-weight: 600;
  margin-right: 12px;

  ${({ isSuccess }) =>
    isSuccess
      ? css`
          color: #5ad887;
        `
      : css`
          color: #f06e5f;
        `}
`

export type FormResponse = {
  status: number | undefined
  message: string | undefined
}

type Props = {
  response: FormResponse
}

export const FormMessage = ({ response }: Props) => {
  const isSuccess = response.status === 204

  return <StyledText isSuccess={isSuccess}>{response.message}</StyledText>
}
