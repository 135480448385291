import React, { useState, useEffect } from "react"
import styled from "styled-components"

import API from "../utils/api"
import Table from "./../components/Table"
import * as Currency from "../helpers/Currency"
import LoadingContainer from "../components/LoadingContainer"
import ResponseMsg from "./components/ResponseMsgContainer"

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  height: 55px;
`
const Container = styled.div`
  background-color: ${(props) => props.background || "inherit"};
  border: 1px solid var(--border-default);
  border-radius: 6px;
  display: ${(props) => props.display || "block"};
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height || "auto"};
  width: 100%;
`

const PaymentPlan = () => {
  let packageTableItem = [],
    packageItems = []
  let containerBackground = "#fff",
    containerHeight = "150px",
    containerDisplay = "flex"
  const [isFetching, setIsFetching] = useState(true)
  const [subscription, setSubscription] = useState(null)
  const [errorMsg, setErrorMsg] = useState("")

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      try {
        let data = await API.get(`v1/customer/subscription`)
        if (data.items) {
          if (data.extras) {
            data.items = [...data.items, ...data.extras]
          }
          data.items.sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          })
        }
        if (!didCancel) setSubscription(data)
      } catch (err) {
        if (!didCancel) setErrorMsg(err.message)
      }
      if (!didCancel) setIsFetching(false)
    }

    fetchData()
    return () => {
      didCancel = true
    }
  }, [])

  if (
    subscription !== null &&
    Array.isArray(subscription.items) &&
    subscription.items.length > 0
  ) {
    packageTableItem = (item, i) => {
      return (
        <li key={i} className="table-row">
          <div className="table-cell package_name">
            {item.package_name}{" "}
            {item.trial ? (
              <span className="package-trial-box">Trial</span>
            ) : null}
          </div>
          <div className="table-cell quantity">{item.quantity}</div>
          <div className="table-cell unit_price">{item.unit_price}</div>
          <div
            className="table-cell total"
            text-line-trough={item.trial ? "true" : "false"}>
            {item.total}
          </div>
        </li>
      )
    }

    packageItems = subscription.items.map((plan) => {
      let q = ""

      if (plan.game_ids) q = plan.game_ids.length
      else q = plan.quantity

      return {
        package_name: plan.name,
        quantity: q,
        currency: subscription.currency,
        unit_price: Currency.Formatter(subscription.currency, plan.unit_price),
        total: Currency.Formatter(subscription.currency, plan.amount),
        trial: plan.trial,
      }
    })
  }

  if (!isFetching && !errorMsg && packageItems.length > 0) {
    containerBackground = "transparent"
    containerHeight = "auto"
    containerDisplay = "block"
  }

  return (
    <section id="paymentPlanSection">
      <SectionHeader>
        <h2>Payment Plan</h2>
      </SectionHeader>

      <Container
        background={containerBackground}
        display={containerDisplay}
        height={containerHeight}>
        {isFetching ? (
          <LoadingContainer />
        ) : errorMsg ? (
          <ResponseMsg msg={errorMsg} />
        ) : packageItems.length === 0 ? (
          <ResponseMsg msg="No payment plan found" />
        ) : (
          <>
            <Table
              isFetching={isFetching}
              columns={[
                { key: "package_name", name: "Description", sortable: false },
                { key: "quantity", name: "Quantity", sortable: false },
                { key: "unit_price", name: "Unit price", sortable: false },
                { key: "total", name: "Amount", sortable: false },
              ]}
              items={packageItems}
              listItem={packageTableItem}
              pageSize={20}
              pageCurrent={0}
              tableFooter="plans"
              enableSorting={false}
              tableShowingCountView={false}
            />

            {subscription ? (
              <div className="payment-plan-overview">
                <div className="flex-column subPeriod">
                  <p className="subscription-period">
                    Subscription Period: Monthly
                  </p>
                </div>

                <div className="flex-column subFigures">
                  <h6 className="subtotal">Subtotal:</h6>
                  <h6
                    className="discount"
                    style={{
                      display: subscription.discount ? "block" : "none",
                    }}>
                    Discount:
                  </h6>
                  <h6 className="tax">Tax ({subscription.taxed.rate} %):</h6>
                  <h6 className="total">Total:</h6>
                </div>

                <div className="flex-column subNumbers">
                  <h6 className="subtotal">
                    {Currency.Formatter(
                      subscription.currency,
                      subscription.amount,
                    )}
                  </h6>
                  <h6
                    className="discount"
                    style={{
                      display: subscription.discount ? "block" : "none",
                    }}>
                    {Currency.Formatter(
                      subscription.currency,
                      subscription.discount,
                    )}
                  </h6>
                  <h6 className="tax">
                    {Currency.Formatter(
                      subscription.currency,
                      subscription.taxed.amount,
                    )}
                  </h6>
                  <h6 className="total">
                    {Currency.Formatter(
                      subscription.currency,
                      subscription.amount_charged,
                    )}
                  </h6>
                </div>
              </div>
            ) : null}
          </>
        )}
      </Container>
    </section>
  )
}

export default React.memo(PaymentPlan)
