import LoadingContainer from "jsx/components/LoadingContainer"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { useGameStore } from "stores/games"
import { WidgetGames } from "types/widget_namespace"
import { GAMEID } from "@abios/abios-ts-sdk"
import { getLifecycleState } from "../utils/getLifecycleState"
import { getSeriesTitle } from "../utils/getSeriesTitle"
import { usePreviewSeries } from "../utils/usePreviewSeries"
import { DropdownWrapper } from "./components/DropdownWrapper"
import { GameItem } from "./components/GameItem"
import { NoWidgetFallback } from "./components/NoWidgetFallback"
import { SeriesItem } from "./components/SeriesItem"
import {
  DropdownContainer,
  StyledTitle,
  WidgetPreviewContainer,
} from "./WidgetPreview.styles"
import { WidgetSide } from "./WidgetSide"

const sortCollator = new Intl.Collator("en", {
  numeric: true,
  sensitivity: "base",
})

type Props = {
  readonly slug: string
  readonly namespace: string
  games: WidgetGames[]
}

export const WidgetPreview = ({ slug, namespace, games }: Props) => {
  const { id } = useParams()
  const { games: gameStore } = useGameStore()

  const [selectedGameID, setSelectedGameID] = useState<GAMEID | undefined>(
    games[0]?.id,
  )
  const [selectedSeriesID, setSelectedSeriesID] = useState<number>(0)
  // Lifecycle
  const [selectedSeriesLC, setSelectedSeriesLC] = useState<string>("")

  // Dropdown logic
  const [gameValue, setGameValue] = useState<string>("")
  const [seriesValue, setSeriesValue] = useState<string>("")

  const { series, isLoading } = usePreviewSeries(id, selectedGameID)

  // Set the first available game
  useEffect(() => {
    if (games.length) setGameValue(gameStore[games[0].id].title)
  }, [games, gameStore])

  const setGameSelect = (value: string, gameID: GAMEID) => {
    if (gameID !== selectedGameID) {
      series.splice(0, series.length)
      setSeriesValue("")
      setSelectedSeriesLC("")
    }
    setGameValue(value)
    setSelectedGameID(gameID)
  }

  const setSeriesSelect = (
    seriesID: number,
    lifecycle: string,
    title: string,
  ) => {
    setSelectedSeriesID(seriesID)
    setSelectedSeriesLC(lifecycle)
    setSeriesValue(title)
  }
  // When we receive series of a game, load the first available
  useEffect(() => {
    if (series.length > 0) {
      const { id: seriesID, lifecycle } = series[0]

      setSeriesSelect(seriesID, lifecycle, getSeriesTitle(series[0]))
    }
  }, [series])

  const shouldShowWidget = () => {
    return (
      series.length > 0 && selectedSeriesID !== 0 && selectedSeriesLC !== ""
    )
  }

  // Get widget games
  const widgetGames = games.map((g) => gameStore[g.id])
  const availableGamesJSX = widgetGames
    .sort((a, b) => sortCollator.compare(a.title, b.title))
    .map((game) => (
      <GameItem
        key={game.id}
        gameTitle={gameStore[game.id].title}
        gameID={game.id}
        onSelect={setGameSelect}>
        {gameStore[game.id].title}
      </GameItem>
    ))

  const availableSeries = series
    .sort((a, b) => sortCollator.compare(a.start, b.start))
    .map((serie) => (
      <SeriesItem key={serie.id} serie={serie} onSelect={setSeriesSelect} />
    ))

  const widgetPreviewJSX = shouldShowWidget() ? (
    <WidgetSide
      slug={slug}
      namespace={namespace}
      seriesId={selectedSeriesID}
      lifecycle={getLifecycleState(selectedSeriesLC)}
    />
  ) : (
    <NoWidgetFallback />
  )

  const widgetPreview = !isLoading ? widgetPreviewJSX : <LoadingContainer />

  return (
    <WidgetPreviewContainer>
      <StyledTitle>Preview</StyledTitle>

      <DropdownContainer>
        <DropdownWrapper title="Games" value={gameValue}>
          {availableGamesJSX}
        </DropdownWrapper>

        <DropdownWrapper title="Series" value={seriesValue}>
          {availableSeries}
        </DropdownWrapper>
      </DropdownContainer>

      {widgetPreview}
    </WidgetPreviewContainer>
  )
}
