import { Game } from "types/game"
import { StoreApi } from "zustand"
import { State } from "./State"

// Add games to store
export const add = (set: StoreApi<State>["setState"], list: Game[]) => {
  const games: { [key: number]: Game } = {}
  list.forEach((g) => {
    games[g.id] = g
  })
  set({ games })
}
