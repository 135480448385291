import styled from "styled-components"

export const StyledContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  // Fixed width so it will not change based on different format of time
  width: 80px;
`

export const StyledDateDiv = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledTimeText = styled.p`
  font-weight: 600;
  color: #484858;
  font-size: 0.875rem;
  line-height: 1.4rem;
  letter-spacing: 0.24px;
  text-align: left;
`

export const StyledDateText = styled.p`
  color: rgb(96, 104, 112);
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.1rem;
  text-align: left;
`

export const StyledEndedText = styled.p`
  color: rgba(96, 104, 112, 0.8);
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
`

export const StyledLiveFigure = styled.figure`
  background-color: #ff7675;
  border-radius: 24px;
  padding: 6px 12px;
`

export const StyledLiveText = styled.p`
  color: #fff;
  letter-spacing: 0.5px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
`
