import { useState, useEffect } from "react"
import { StyledIframe } from "./WidgetData.styles"

type Props = {
  readonly id: string
  readonly urlPath: string
}

export const Widget = ({ id, urlPath }: Props) => {
  const url = `${process.env.REACT_APP_WIDGET_URL}/${urlPath}`
  const [height, setHeight] = useState("0px")

  useEffect(() => {
    const handleIframeHeight = (e: {
      origin: string
      data: { name: string; height: number }
    }) => {
      if (e.data.name === id) {
        const abiosIframe = document.getElementById(e.data.name)
        if (abiosIframe) {
          setHeight(`${e.data.height}px`)
        }
      }
    }

    window.addEventListener("message", handleIframeHeight, false)
    return () => {
      window.removeEventListener("message", handleIframeHeight, false)
    }
  }, [id])

  return (
    <StyledIframe id={id} name={id} scrolling="no" src={url} height={height} />
  )
}
