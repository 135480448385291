import { AthenaClient } from "@abios/abios-ts-sdk"

export enum CLIENT_STATUS {
  ACTIVE,
  INACTIVE,
  WARNING,
}

export enum SCOPE {
  READ = "read",
}

export type Client3 = AthenaClient & {
  max_concurrent_hermes_connections: number
  scope: SCOPE
}

// Response back from Athena
export type Client3APIResponse = {
  clients: Client3[]
}
