import { Market, Player } from "@abios/abios-ts-sdk"

export const sortMarketsOnContext =
  (sorter: Intl.Collator, players: { [key: number]: Player }) =>
  ({ context: firstCtx }: Market, { context: secondCtx }: Market) => {
    // If the market doesn't contain the context just keep the order
    if (!firstCtx || !secondCtx) return 0

    const firstCtxOfValue =
      firstCtx.line || firstCtx.round_number || firstCtx.index || 0

    const secondCtxOfValue =
      secondCtx.line || secondCtx.round_number || secondCtx.index || 0

    if (
      firstCtx.line &&
      secondCtx.line &&
      firstCtx.player &&
      secondCtx.player
    ) {
      const firstName = players[firstCtx.player.id]?.nick_name || ""
      const secondName = players[secondCtx.player.id]?.nick_name || ""

      return sorter.compare(
        `${firstName} ${firstCtx.line}`,
        `${secondName} ${secondCtx.line}`,
      )
    }

    return firstCtxOfValue - secondCtxOfValue
  }
