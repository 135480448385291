import axios from "axios"
import { BAD_REQUEST } from "../../constants/common"

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API}/`,
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
  timeout: 15000,
})

instance.interceptors.request.use(
  (config) => {
    let authStorage = localStorage.getItem("auth_data")
    if (authStorage) {
      config.headers["Authorization"] = `Bearer ${JSON.parse(authStorage)}`
    }
    // Check if x-customer exists in localStorage
    let xcustomer = localStorage.getItem("xcustomer")
    if (xcustomer) {
      let x = JSON.parse(xcustomer)
      if (x.id) config.headers["X-Customer"] = `${x.id}`
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  },
)

instance.interceptors.response.use(
  (resp) => {
    if (resp.status === 200) {
      return resp.data
    }
    return resp
  },
  (err) => {
    let errorObj = {
      status: null,
      message: "",
    }

    if (err && err.response) {
      errorObj["status"] = err.response.status

      // User is unauthorized
      if (err.response.status === 401) {
        localStorage.clear()
        window.location.href = "/login"
        return
      }

      if (err.response.data && err.response.data.message) {
        // Error message from API
        errorObj["message"] = err.response.data.message
      } else {
        errorObj["message"] = BAD_REQUEST
      }
    } else {
      // Network error
      errorObj["message"] =
        "There is a problem connecting to Abios. Please try again later."
    }
    return Promise.reject(errorObj)
  },
)

export default instance
