import { useOnClickOutside } from "hooks"
import { SyntheticEvent, useCallback, useRef, useState } from "react"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { Customer } from "types/customer"
import { useAuthStore } from "stores"
import { Dots } from "./Dots"
import {
  StyledOption,
  StyledLi,
  StyledUl,
  StyledButton,
} from "./Options.styles"

type Props = {
  customer: Customer
  onSelect: (customer: Customer) => Promise<void>
}

const List = ({ customer, onSelect }: Props) => {
  const navigate = useNavigate()
  const { getTradingToken } = useAuthStore()

  const onMouseDown = (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const onNavigate = useCallback(
    async (url: string) => {
      await onSelect(customer)
      navigate(url)
    },
    [onSelect, navigate, customer],
  )

  const checkTradingAccessAndNav = async () => {
    onNavigate("/api-clients")
    await getTradingToken()
  }

  return (
    <StyledUl onClick={onMouseDown} onMouseDown={onMouseDown}>
      <StyledLi>
        <Link to={`/admin/customers/${customer.id}/edit`}>Edit customer</Link>
      </StyledLi>

      <StyledLi>
        <StyledButton type="button" onClick={checkTradingAccessAndNav}>
          Manage V3 clients
        </StyledButton>
      </StyledLi>

      <StyledLi>
        <StyledButton type="button" onClick={() => onNavigate("/widget")}>
          Manage widgets
        </StyledButton>
      </StyledLi>

      <StyledLi>
        <StyledButton type="button" onClick={() => onNavigate("/billing")}>
          Manage billing
        </StyledButton>
      </StyledLi>
    </StyledUl>
  )
}

const Options = ({ customer, onSelect }: Props) => {
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)

  useOnClickOutside(ref, () => setOpen(false))

  const onClick = useCallback(() => {
    setOpen(!isOpen)
  }, [isOpen, setOpen])

  return (
    <StyledOption ref={ref} className={isOpen ? "open" : ""} onClick={onClick}>
      <Dots />

      {isOpen && <List customer={customer} onSelect={onSelect} />}
    </StyledOption>
  )
}

export default Options
