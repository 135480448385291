import React from "react"
import styled from "styled-components"

import PageLoader from "./PageLoader"

const Page = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
`
const Content = styled.div`
  max-width: 1280px;
  margin: 30px 30px 0;
  min-height: calc(100% - 30px);
  width: 100%;
`
const H3 = styled.h3`
  color: #484858;
`
const ErrorMiddle = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: inherit;
`

const PageContent = (props) => (
  <Page>
    <Content>
      {props.isLoading ? (
        <PageLoader />
      ) : props.errorMsg ? (
        <ErrorMiddle>
          <H3>{props.errorMsg}</H3>
        </ErrorMiddle>
      ) : (
        props.children
      )}
    </Content>
  </Page>
)

export default PageContent
