import LoadingContainer from "jsx/components/LoadingContainer"
import { NoDataCard } from "atoms/noDataCard"
import { WidgetNamespace } from "types/widget_namespace"
import { useWidgetNamespace } from "./components/utils/widgetnamespaces"
import { WidgetContainer } from "./components/WidgetContainer"
import { StyledContent } from "./WidgetList.styles"

const sortCollator = new Intl.Collator("en", {
  numeric: true,
  sensitivity: "base",
})

export const WidgetList = () => {
  const { widgetNamespaces, isLoading } = useWidgetNamespace()

  const namespaces = widgetNamespaces
    .sort((a: WidgetNamespace, b: WidgetNamespace) =>
      sortCollator.compare(a.slug, b.slug),
    )
    .map((namespace) => (
      <WidgetContainer
        key={+namespace.customer.id}
        widgetNamespace={namespace}
      />
    ))

  const content =
    widgetNamespaces.length > 0 ? (
      namespaces
    ) : (
      <NoDataCard
        title="No widget namespaces found"
        subText="It seems like there isn't a widget namespace."
      />
    )

  if (isLoading) return <LoadingContainer />

  return (
    <StyledContent>
      <h1>Widget Namespaces</h1>
      {content}
    </StyledContent>
  )
}
