import { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import axios from "axios"
import oddsAPI from "services/odds"
import { useOddsStore, useAtlasStore } from "pages/trading/stores"
import { useAuthStore } from "stores"
import LoadingContainer from "jsx/components/LoadingContainer"
import { cErr } from "utils/debug"
import { INFO_PAGE_TYPE } from "@abios/smorgasbord-components"
import PageContent from "jsx/components/PageContent"
import {
  MARKET_TYPE,
  MAX_MARKET_PAGES,
  Market,
  PAGE_SIZE,
  FIXTURE_TYPE,
  MARKET_STATE,
} from "@abios/abios-ts-sdk"
import { ListView } from "./ListView"
import { StyledErrorPage, StyledLoadingContainer } from "./ListOverview.styles"

export const ALLOWED_STATES_LIST = [MARKET_STATE.OPEN, MARKET_STATE.SUSPENDED]
// Fetch all data from the API that the list view need
export const ListOverview = () => {
  const [hasFetched, setHasFetched] = useState(false)
  const [hasError, setError] = useState<boolean>(false)
  const { addList } = useOddsStore()
  const { getSeriesByIds } = useAtlasStore()
  const { hasTradingAccess } = useAuthStore()

  useEffect(() => {
    let didCancel = false
    const source = axios.CancelToken.source()

    const fetchMarketsByTypeAndFixture = async (
      marketType: MARKET_TYPE,
      fixtureType: FIXTURE_TYPE,
    ) => {
      const fetchedMarkets: Market[] = []
      let skip = 0

      for (let i = 0; i < MAX_MARKET_PAGES; i += 1) {
        const url = `markets?order=id-desc&filter=type=${marketType},fixture.type=${fixtureType},state<={${ALLOWED_STATES_LIST.join()}}&skip=${skip}`

        // eslint-disable-next-line no-await-in-loop
        const resp = await oddsAPI.get<Market[]>(url, {
          cancelToken: source.token,
        })

        if (resp?.data) {
          fetchedMarkets.push(...resp.data)

          if (resp.data.length < PAGE_SIZE) break

          skip += resp.data.length
        } else {
          break
        }
      }

      return fetchedMarkets
    }

    // get odds markets
    const getMarkets = async () => {
      let markets: Market[] = []

      try {
        const [
          seriesWinnerMarkets,
          seriesWinnerThreeWay,
          matchWinnerMarkets,
          matchThreeWayMarkets,
        ] = await Promise.all([
          fetchMarketsByTypeAndFixture(
            MARKET_TYPE.SERIES_WINNER,
            FIXTURE_TYPE.SERIES,
          ),
          fetchMarketsByTypeAndFixture(
            MARKET_TYPE.SERIES_WINNER_THREE_WAY,
            FIXTURE_TYPE.SERIES,
          ),
          fetchMarketsByTypeAndFixture(
            MARKET_TYPE.MATCH_WINNER,
            FIXTURE_TYPE.MATCH,
          ),
          fetchMarketsByTypeAndFixture(
            MARKET_TYPE.MATCH_WINNER_THREE_WAY,
            FIXTURE_TYPE.MATCH,
          ),
        ])

        markets = [
          ...seriesWinnerMarkets,
          ...seriesWinnerThreeWay,
          ...matchWinnerMarkets,
          ...matchThreeWayMarkets,
        ]
      } catch (e) {
        cErr("unable to get markets from odds API", e)
        setError(true)
      } finally {
        setHasFetched(true)
      }

      if (!didCancel) {
        addList(markets)
      }
    }

    getMarkets()
    return () => {
      didCancel = true
      source.cancel()
    }
  }, [addList, getSeriesByIds])

  if (!hasTradingAccess) {
    return <Navigate to="/" />
  }

  if (!hasFetched) {
    return (
      <StyledLoadingContainer>
        <LoadingContainer
          background="transparent"
          height="calc(100% - 132px)"
        />
      </StyledLoadingContainer>
    )
  }

  if (hasError) {
    return (
      <PageContent>
        <StyledErrorPage
          title=""
          text="Your browser was unable to fetch the list of markets at this time. Contact your account manager if this problem persists."
          type={INFO_PAGE_TYPE.ERROR}
        />
      </PageContent>
    )
  }

  return <ListView />
}
