import styled from "styled-components"

export const Styledheader = styled.header`
  padding-bottom: 12px;
`

export const StyledH5 = styled.h5`
  color: var(--text-secondary);
  font-size: 13px;
  font-weight: 500;
`
