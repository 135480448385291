import { CrossIconNew, KeyIcon, LoadingIcon } from "atoms/icons"
import { useState, Dispatch, SetStateAction } from "react"
import {
  StyledButton,
  StyledErrorText,
  StyledIcon,
  StyledInput,
  StyledRow,
  StyledSubtitle,
  StyledTitle,
} from "../Modal.styles"

type Props = {
  title: string
  text: string
  isLoading: boolean
  submitButtonText?: string
  initialText: string
  onClose: () => void
  onSubmit: (
    keyInput: string,
    initialName: string,
    setHasError: Dispatch<SetStateAction<boolean>>,
    setErrorText: Dispatch<SetStateAction<string>>,
  ) => void
}

export const CreateModal = ({
  title,
  text,
  isLoading,
  submitButtonText,
  initialText,
  onClose,
  onSubmit,
}: Props) => {
  const [keyInput, setKeyInput] = useState<string>(initialText ?? "")
  const [hasError, setHasError] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string>("")

  return (
    <>
      <StyledRow data-align="between">
        <StyledIcon>
          <KeyIcon size="14" color="var(--blue-600)" />
        </StyledIcon>
        <button type="button" onClick={onClose}>
          <CrossIconNew color="var(--gray-500)" size="14" />
        </button>
      </StyledRow>

      <StyledRow data-direction="column">
        <StyledTitle>{title}</StyledTitle>
        <StyledSubtitle>{text}</StyledSubtitle>
      </StyledRow>

      <StyledRow data-direction="column">
        <StyledInput
          placeholder="Key name"
          value={keyInput}
          onChange={(event) => setKeyInput(event.target.value)}
          data-error={hasError}
          autoFocus
        />
        <StyledErrorText>{errorText}</StyledErrorText>
      </StyledRow>

      <StyledRow data-gap="1">
        <StyledButton data-type="normal" onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton
          onClick={() =>
            onSubmit(keyInput, initialText, setHasError, setErrorText)
          }
          disabled={isLoading}>
          {isLoading ? (
            <LoadingIcon size="20" color="white" />
          ) : (
            submitButtonText ?? "Create"
          )}
        </StyledButton>
      </StyledRow>
    </>
  )
}
