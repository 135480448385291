import { AxiosRequestConfig } from "axios"
import {
  createContext,
  useEffect,
  FC,
  useState,
  ReactNode,
  useMemo,
  useCallback,
} from "react"
import AthenaAPI from "services/athena"
import { ProductAPIResponse } from "types/product"
import { PackageResp } from "types/package"
import { STATUS } from "hooks/usefetch/types"
import { cErr } from "utils/debug"
import { ENDPOINTS_V1 } from "services/athenaApiEndpoints"
import type { PushChannelsResp, PushChannel } from "types/push_channels"
import { AthenaPackage, AthenaProduct, GAMEID } from "@abios/abios-ts-sdk"

type ResourceState = {
  products: AthenaProduct[]
  pushChannels: PushChannel[]
  packages: AthenaPackage[]
}

type Props = {
  v3Resources: ResourceState
  status: STATUS
}

type PropsV3Resources = {
  children: ReactNode
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const V3ResourcesContext = createContext<Props>({
  v3Resources: {} as ResourceState,
  status: STATUS.FETCHING,
})

type ActionProps = {
  getProducts: () => AthenaProduct[]
  getProductGamesById: (id: string) => GAMEID[]
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const V3ResourcesActions = createContext<ActionProps>({} as ActionProps)

export const V3ResourcesProvider: FC<PropsV3Resources> = ({ children }) => {
  const [status, setStatus] = useState<STATUS>(STATUS.INIT)
  const [resources, setResources] = useState<ResourceState>({
    products: [],
    pushChannels: [],
    packages: [],
  })

  const contextValues = useMemo(
    () => ({ status, v3Resources: resources }),
    [status, resources],
  )

  const getProducts = useCallback(() => {
    return resources.products
  }, [resources.products])

  const getProductGamesById = useCallback(
    (id: string) => {
      return resources.packages
        .filter((pkg) => pkg.product.id === id)
        .map((pkg) => pkg.game.id)
    },
    [resources.packages],
  )

  const actionValues = useMemo(
    () => ({ getProducts, getProductGamesById }),
    [getProducts, getProductGamesById],
  )

  useEffect(() => {
    const abortController = new AbortController()

    async function fetchData() {
      try {
        const reqConfig = {
          signal: abortController.signal,
        } as AxiosRequestConfig

        const [productsResp, pushChannelsResp, pkgResp] = await Promise.all([
          AthenaAPI.get<ProductAPIResponse>(ENDPOINTS_V1.PRODUCT, reqConfig),
          AthenaAPI.get<PushChannelsResp>(ENDPOINTS_V1.PUSH_CHANNEL, reqConfig),
          AthenaAPI.get<PackageResp>(ENDPOINTS_V1.PACKAGE, reqConfig),
        ])

        if (!abortController.signal.aborted) {
          setResources({
            products: productsResp.data.products,
            pushChannels: pushChannelsResp.data.push_channels,
            packages: pkgResp.data.packages,
          } as ResourceState)
          setStatus(STATUS.FETCHED)
        }
      } catch (err) {
        if (!abortController.signal.aborted) {
          setStatus(STATUS.ERROR)
          cErr("unable to fetch v3 resources from athena.", err)
        }
      }
    }

    fetchData()
    return () => {
      abortController.abort()
    }
  }, [])

  // TODO: Add loading animation?
  if (status === STATUS.FETCHING) return null

  return (
    <V3ResourcesContext.Provider value={contextValues}>
      <V3ResourcesActions.Provider value={actionValues}>
        {children}
      </V3ResourcesActions.Provider>
    </V3ResourcesContext.Provider>
  )
}
