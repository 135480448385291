import { useCallback, useState } from "react"

type Rule<T> = (value: T) => string | undefined

export const required =
  <T>(err: string): Rule<T> =>
  (value: T) =>
    !value ? err : undefined

export const requiredNumber =
  <T>(err: string): Rule<T> =>
  (value: T) =>
    typeof value === "number" ? undefined : err

export type SchemableForm<T> = Record<string, T[keyof T]>
export type Schema<T extends SchemableForm<T>> = {
  [K in keyof T]?: Rule<T[K]>
}
export type SchemaErrors<T extends SchemableForm<T>> = {
  [K in keyof T]?: string
}
export type Validation<T extends SchemableForm<T>> = (values: {
  [K in keyof T]?: T[K]
}) => SchemaErrors<T>
export type FormValues<T extends SchemableForm<T>> = { [K in keyof T]?: T[K] }

/**
 * createSchema creates a function for validating input. Should be used with useValidation hook.
 * @returns a function for validating an input object. The function returns an object
 *  similar to the input object, with error messages as values.
 * @example
 * const [errors, validate] = createSchema({
    name: required("Name is required"),
    historical: required("Historical access is required"),
  })
  const values = {
    name: "Name",
    historical: ""
  }
  validate(values)
  assert(errors, {
    historical: "Historical access is required"
  })
 */
export const createSchema =
  <T extends SchemableForm<T>>(schema: Schema<T>): Validation<T> =>
  (values: FormValues<T>): SchemaErrors<T> =>
    Object.entries(schema)
      .map(([k, rule]) => [k, rule(values[k])] as [string, string | undefined])
      .filter(([, value]) => value !== undefined)
      .reduce(
        (a, [k, v]) => ({
          ...a,
          [k]: v,
        }),
        {},
      )

/**
 * useValidation provides an error object and a validation function
 * @param validation a `Validation` type function which validates an input object.
 * @returns [errorState, validateFunction]
 * @example
 * const schema = createSchema({
    name: required("Name is required"),
    historical: required("Historical access is required"),
  })
  const [errors, validate] = useValidation(schema)
  const values = {
    name: "Name",
    historical: ""
  }
  validate(values)
  assert(errors, {
    historical: "Historical access is required"
  })
 */
export const useValidation = <T extends SchemableForm<T>>(
  validation: Validation<T>,
): [
  SchemaErrors<T>,
  (values: { [K in keyof T]?: T[K] }) => SchemaErrors<T>,
] => {
  const [errors, setErrors] = useState<SchemaErrors<T>>({})

  const validate = useCallback(
    (values: FormValues<T>) => {
      const err = validation(values)
      setErrors(err)
      return err
    },
    [setErrors, validation],
  )

  return [errors, validate]
}
