import styled from "styled-components"

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`
export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-top: 2rem;
`
