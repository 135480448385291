import { ChevronIcon } from "atoms/icons"
import { useRef } from "react"
import {
  Market,
  Player,
  Team,
  marketTypeTitles,
  useToggle,
} from "@abios/abios-ts-sdk"
import { useAtlasStore } from "pages/trading/stores"
import { SelectionHeader } from "./SelectionHeader"
import {
  StyledBogger,
  StyledContainer,
  StyledHeader,
} from "./MarketTemplate.styles"
import { SelectionBuilder } from "./SelectionBuilder"
import { sortMarketsOnContext } from "../utils/SortMarketsOnContext"

// isPlayer is a flag shows if left and right is player or team
// ESoccer game only has player

type Props =
  | {
      markets: Market[]
      leftCompetitor: Team
      rightCompetitor: Team
      isPlayer: false
    }
  | {
      markets: Market[]
      leftCompetitor: Player
      rightCompetitor: Player
      isPlayer: true
    }

const sorter = new Intl.Collator()

export const MarketTemplate = ({
  markets,
  leftCompetitor,
  rightCompetitor,
  isPlayer,
}: Props) => {
  const { isActive, open, close } = useToggle(true)
  const { players } = useAtlasStore()

  const collapseOuter = useRef<HTMLDivElement>(null)
  const collapseInner = useRef<HTMLDivElement>(null)

  const marketType = markets[0].type

  const setHeightRefs = () => {
    if (!collapseOuter.current || !collapseInner.current) return

    collapseOuter.current.style.height = `${collapseInner.current.clientHeight}px`
    if (collapseOuter.current.clientHeight) {
      collapseOuter.current.style.height = `${0}px`
      close()
    } else {
      open()
    }
  }

  const selections = markets
    .sort(sortMarketsOnContext(sorter, players))
    .map((market) => (
      <SelectionBuilder
        key={market.id}
        market={market}
        leftCompetitor={leftCompetitor}
        rightCompetitor={rightCompetitor}
      />
    ))

  // If there are no competitors or we don't support the market type, don't show anything
  if (!leftCompetitor || !rightCompetitor || !marketTypeTitles[marketType])
    return null

  return (
    <StyledContainer>
      <StyledHeader data-isopen={isActive} onClick={setHeightRefs}>
        <h2>{marketTypeTitles[marketType]}</h2>
        <ChevronIcon size="12" color="var(--text-white)" />
      </StyledHeader>

      <StyledBogger ref={collapseOuter}>
        <div ref={collapseInner}>
          {isPlayer ? (
            <SelectionHeader
              marketType={marketType}
              left={leftCompetitor}
              right={rightCompetitor}
              isPlayer
            />
          ) : (
            <SelectionHeader
              marketType={marketType}
              left={leftCompetitor}
              right={rightCompetitor}
              isPlayer={false}
            />
          )}

          <div>{selections}</div>
        </div>
      </StyledBogger>
    </StyledContainer>
  )
}
