/* eslint-disable @typescript-eslint/naming-convention */
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios"
import { getAthenaSecret } from "utils/localStorage"

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BET_BUILDER_API}/api/`,
  headers: {
    "Content-Type": "application/json",
  },
})

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const conf = { ...config }

    if (!conf.headers) {
      conf.headers = {} as AxiosRequestHeaders
    }

    const athenaId = getAthenaSecret()
    if (athenaId) {
      conf.headers["Abios-Secret"] = athenaId
    }
    return conf
  },
  (err) => {
    Promise.reject(err)
  },
)

export default instance
