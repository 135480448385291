import styled from "styled-components"

import { IndexTable } from "@abios/smorgasbord-components"

export const StyledContent = styled.div`
  margin: 0 auto;
  max-width: 80rem;
  min-height: calc(100vh - 3.75rem);
  padding: 3.75rem 1.25rem 0;
  width: calc(100% - 2.5rem);
`

export const StyledTable = styled(IndexTable)``

export const StyledTableHeader = styled(IndexTable.Header)``
