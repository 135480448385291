import moment from "moment"
import { StoreApi } from "zustand"
import jwtDecode from "jwt-decode"

import { TokenObject } from "types/Token"
import { TOKEN_STORAGE_NAME, State } from "./State"

// Check if the user has a valid token
export const checkIsAuthed = (set: StoreApi<State>["setState"]) => {
  const tokenData = localStorage.getItem(TOKEN_STORAGE_NAME)

  if (tokenData) {
    const tokenDecoded: TokenObject = jwtDecode(tokenData)

    // Check if expire time has passed
    if (moment.utc().unix() < tokenDecoded.exp) {
      set({ isAuthed: true })
      return
    }
  }

  set({ isAuthed: false })
}
