export enum CUSTOMER_AGREEMENT_STATUS {
  SIGNED = "signed",
  UNSIGNED = "unsigned",
  NO_TRIAL_AGREEMENT = "no_trial_agreement",
}

export type TrialAgreementFields = {
  company: {
    name: string
    number: string
    registered_address: {
      address_line_1: string
      address_line_2: string | null
      zip_code: string
      city: string
      country: string
    }
  }
}

export type CreateCustomerPayload = {
  name: string
  active_until: string | null
  stripe_id: string | null
  account_managers: {
    user: {
      id: string
    }
  }[]
  trial_agreement_fields?: TrialAgreementFields
}

export type CreateCustomerType = {
  customerName: string
  activeUntil: string
  billingMethod: string
  stripedId: string
  accountManagers: { id: string; name: string }[]
  fullCompanyName: string
  companyNumber: string
  country: string
  address1: string
  address2: string
  zipcode: string
  city: string
}

export type CreateCustomerErrorType = {
  customerName: string
  activeUntil: string
  stripedId: string
  accountManagers: string
  fullCompanyName: string
  companyNumber: string
  country: string
  address1: string
  address2: string
  zipcode: string
  city: string
}

export const emptyCustomer: CreateCustomerType = {
  customerName: "",
  activeUntil: "",
  billingMethod: "Stripe",
  stripedId: "",
  accountManagers: [],
  fullCompanyName: "",
  companyNumber: "",
  country: "",
  address1: "",
  address2: "",
  zipcode: "",
  city: "",
}

export const emptyCustomerError: CreateCustomerErrorType = {
  customerName: "",
  activeUntil: "",
  stripedId: "",
  accountManagers: "",
  fullCompanyName: "",
  companyNumber: "",
  country: "",
  address1: "",
  address2: "",
  zipcode: "",
  city: "",
}
