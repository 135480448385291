import React from "react"
import { Outlet, Route } from "react-router-dom"
import { ListOverview } from "./list"
import { Single } from "./single"
import { GetResources } from "./Resources"
import { OddsSubscriber } from "./Subscriber"
import { OddsRouteMiddleware } from "pages/trading/OddsRouteMiddleware"

const OddsRoutes = () => (
  <OddsRouteMiddleware>
    <OddsSubscriber />
    <GetResources />

    <Outlet />
  </OddsRouteMiddleware>
)

export default OddsRoutes
