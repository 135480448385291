function Formatter(currency, value) {
  if (value === null || !Number.isInteger(value)) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency ? currency.toUpperCase() : "EUR",
    }).format(Number(0))
  }

  if (!currency) {
    return "invalid-currency"
  }

  value = value.toString().replace(/(\d{2})$/, ".$1")

  value = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency.toUpperCase(),
  }).format(Number(value))

  return value
}

// Returns a symbol for a given currency
function Symbol(value) {
  let curr = ""
  if (!value || typeof value !== "string") {
    return curr
  }
  value = value.toLowerCase()
  switch (value) {
    case "sek":
      curr = "SEK"
      break
    case "eur":
      curr = "€"
      break
    default:
  }
  return curr
}

export { Formatter, Symbol }
