import { create } from "zustand"
import { createTrackedSelector } from "react-tracked"

import { ApiStoreTypes } from "./ApiStoreTypes"
import { fetchPushChannelCategories } from "./fetchPushChannelCategories"

const useStore = create<ApiStoreTypes>((set) => ({
  pushChannelCategories: [],

  // Methods
  fetchPushChannelCategories: () => fetchPushChannelCategories(set),
}))

export const useTrackedApiStore = createTrackedSelector(useStore)
