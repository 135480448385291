import styled from "styled-components"

import { KeysList } from "./Keys"
import { PushChannels } from "./PushChannels"

const Main = styled.main`
  flex: 1;
  margin-left: 32px;
  padding-bottom: 24px;
  width: 100%;
`

export const MainContent = () => (
  <Main>
    <KeysList />
    <PushChannels />
  </Main>
)
