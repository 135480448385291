import LoadingContainer from "jsx/components/LoadingContainer"
import { useParams } from "react-router"
import { useWidgets } from "../utils/widgets"
import { WidgetContent } from "./WidgetContent"
import { WidgetPreview } from "./WidgetPreview"
import { StyledContainer, StyledWrapper } from "./WidgetView.styles"

export const WidgetView = () => {
  const { id } = useParams()
  const { widgets, isLoading } = useWidgets(`?widget=${id}`)
  const widgetData = widgets[0]

  if (isLoading) {
    return <LoadingContainer />
  }

  return (
    <StyledWrapper>
      <StyledContainer>
        <WidgetContent widget={widgetData} />
        <WidgetPreview
          slug={widgetData.slug}
          namespace={widgetData.widget_namespace.slug}
          games={widgetData.games}
        />
      </StyledContainer>
    </StyledWrapper>
  )
}
