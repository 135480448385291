import {
  ALERT_TYPE,
  Button,
  BUTTON_SIZE,
  CrossCloseIcon,
  Dropdown,
  Input,
  INPUT_TYPE,
  Tag,
  TAG_SIZE,
} from "@abios/smorgasbord-components"
import { StyledAlert } from "../atoms/StyledAlert.styles"
import {
  ContentRow,
  DropdownValueText,
  Paragraph,
  StyledCheckbox,
  StyledDatePicker,
  StyledDropdownItem,
  Title,
} from "../create/CreateCustomer.styles"
import { useUpdateCustomer } from "../hooks/useUpdateCustomer"
import { getFormattedAccountManagers } from "../utils/getFormattedAccountManagers"
import {
  ContentWrapper,
  TagButton,
  UpdateCustomerContainer,
} from "./UpdateCustomer.styles"

export const UpdateCustomer = () => {
  const {
    managers,
    hasFetchError,
    hasStripe,
    isFetching,
    hasPatchError,
    isSendingPatch,
    onSelectManager,
    onRemoveManager,
    currentCustomer,
    onCustomerUpdate,
    validationErrors,
    hasTrailAgreement,
    setCustomerDetails,
    hasSuccessfulPatch,
  } = useUpdateCustomer()

  const formattedAccountManagers = getFormattedAccountManagers(managers)

  const getManagerItems = formattedAccountManagers
    .filter(
      (manager) =>
        !currentCustomer.accountManagers.find((m) => m.id === manager.user.id),
    )
    .map((manager) => (
      <StyledDropdownItem
        key={manager.user.id}
        onClick={() => onSelectManager(manager.user.id, manager.user.name)}>
        {manager.user.name}
      </StyledDropdownItem>
    ))

  const getSelectedManagerValues = currentCustomer.accountManagers.map((m) => (
    <Tag size={TAG_SIZE.S} key={m.id}>
      <>
        {m.name}
        <TagButton type="button" onClick={() => onRemoveManager(m.id)}>
          <CrossCloseIcon size="14" color="var(--text-secondary)" />
        </TagButton>
      </>
    </Tag>
  ))

  if (isFetching) {
    return null
  }

  const patchErrorContent = (
    <ContentRow mBottom={2.5}>
      <StyledAlert type={ALERT_TYPE.ERROR}>
        An unexpected problem occurred while trying to update the customer
        information. Please try again soon, and if the problem persists contact
        the developer team.
      </StyledAlert>
    </ContentRow>
  )

  const formContent = (
    <>
      <ContentRow>
        <Input
          title="Customer Name"
          value={currentCustomer.customerName}
          onChange={(e) => setCustomerDetails("customerName", e.target.value)}
          hint={validationErrors.customerName}
          warned={!!validationErrors.customerName}
        />
        <StyledDatePicker
          title="Terminate (Optional)"
          type={INPUT_TYPE.DATE}
          value={currentCustomer.activeUntil}
          onChange={(e) => setCustomerDetails("activeUntil", e.target.value)}
        />
      </ContentRow>

      <ContentRow mTop={2.5}>
        <Dropdown
          title="Billing Method"
          inputHidden
          selectedValue={
            <DropdownValueText>
              {currentCustomer.billingMethod}
            </DropdownValueText>
          }>
          <StyledDropdownItem
            onClick={() => setCustomerDetails("billingMethod", "Stripe")}>
            Stripe
          </StyledDropdownItem>
          <StyledDropdownItem
            onClick={() => setCustomerDetails("billingMethod", "Other")}>
            Other
          </StyledDropdownItem>
        </Dropdown>

        {hasStripe && (
          <Input
            title="Stripe ID"
            value={currentCustomer.stripedId}
            onChange={(e) => setCustomerDetails("stripedId", e.target.value)}
            hint={validationErrors.stripedId}
            warned={!!validationErrors.stripedId}
          />
        )}
      </ContentRow>

      <ContentRow mTop={2.5}>
        <Dropdown
          title="Account Managers"
          inputHidden
          hint={validationErrors.accountManagers}
          warned={!!validationErrors.accountManagers}
          selectedValue={getSelectedManagerValues}>
          {getManagerItems}
        </Dropdown>
      </ContentRow>

      <ContentRow mTop={2} mBottom={2}>
        <StyledCheckbox checked={hasTrailAgreement} onToggle={() => {}} />
        <Paragraph>Require Trial Access Agreement</Paragraph>
      </ContentRow>
      {hasTrailAgreement && (
        <>
          <Title>Company Details</Title>

          <ContentRow>
            <Input
              title="Full Company Name"
              value={currentCustomer.fullCompanyName}
              onChange={() => {}}
              disabled
            />
            <Input
              title="Company Number"
              value={currentCustomer.companyNumber}
              onChange={(e) =>
                setCustomerDetails("companyNumber", e.target.value)
              }
              disabled
            />
          </ContentRow>

          <ContentRow mTop={2.5}>
            <Input
              title="Country"
              value={currentCustomer.country}
              onChange={(e) => setCustomerDetails("country", e.target.value)}
              disabled
            />
          </ContentRow>

          <ContentRow mTop={2.5}>
            <Input
              title="Address Line 1"
              value={currentCustomer.address1}
              onChange={(e) => setCustomerDetails("address1", e.target.value)}
              disabled
            />
            <Input
              title="Address Line 2 (Optional)"
              value={currentCustomer.address2}
              onChange={(e) => setCustomerDetails("address2", e.target.value)}
              disabled
            />
          </ContentRow>

          <ContentRow mTop={2.5} mBottom={2.5}>
            <Input
              title="Zipcode"
              value={currentCustomer.zipcode}
              onChange={(e) => setCustomerDetails("zipcode", e.target.value)}
              disabled
            />
            <Input
              title="City"
              value={currentCustomer.city}
              onChange={(e) => setCustomerDetails("city", e.target.value)}
              disabled
            />
          </ContentRow>
        </>
      )}

      <ContentRow>
        <Button size={BUTTON_SIZE.XS} onClick={() => onCustomerUpdate()}>
          Update
        </Button>
      </ContentRow>
    </>
  )

  const errorContent = (
    <StyledAlert type={ALERT_TYPE.ERROR}>
      An unexpected problem occurred while trying to fetch the customer
      information. Please try again soon, and if the problem persists contact
      the developer team.
    </StyledAlert>
  )

  const patchSuccessfulContent = (
    <ContentRow mBottom={2.5}>
      <StyledAlert title="Success!" type={ALERT_TYPE.SUCCESS}>
        The customer has successfully been updated with the new information.
      </StyledAlert>
    </ContentRow>
  )

  return (
    <UpdateCustomerContainer>
      <ContentWrapper>
        {!isSendingPatch && hasSuccessfulPatch && patchSuccessfulContent}
        <Title>Update Customer</Title>
        {!isSendingPatch && hasPatchError && patchErrorContent}
        {!hasFetchError ? formContent : errorContent}
      </ContentWrapper>
    </UpdateCustomerContainer>
  )
}
