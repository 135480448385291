import { create } from "zustand"
import { createTrackedSelector } from "react-tracked"

import { State } from "./State"
import { add } from "./Add"
import { getById } from "./GetById"

const useStore = create<State>((set, get) => ({
  games: {},
  // Fetches games from the API
  addGames: (list) => add(set, list),
  // Get game by single game id
  getById: (id) => getById(get, id),
}))

export const useGameStore = createTrackedSelector(useStore)
