import { DeleteIcon } from "atoms/icons"
import { PenIcon } from "atoms/icons/PenIcon"
import { useOnClickOutside } from "hooks"
import {
  SyntheticEvent,
  useCallback,
  useRef,
  useState,
  MouseEvent,
} from "react"
import { AthenaKey } from "@abios/abios-ts-sdk"
import { Dots } from "../../../customers/components/Dots"
import {
  StyledOption,
  StyledLi,
  StyledUl,
} from "../../../customers/components/Options.styles"
import { useModalStore } from "../store/useModalStore"

type Props = {
  clientKey: AthenaKey
}

type ListProps = Props & {
  closeMenu: () => void
}

const List = ({ clientKey, closeMenu }: ListProps) => {
  const { setIsDeleteModalOpen, setIsEditModalOpen, setTargetId } =
    useModalStore()

  const onMouseDown = (event: SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <StyledUl onClick={onMouseDown} onMouseDown={onMouseDown}>
      <StyledLi
        onClick={() => {
          setIsEditModalOpen(true)
          setTargetId(clientKey.id)
          closeMenu()
        }}>
        <PenIcon />
        <p>Edit key</p>
      </StyledLi>
      <StyledLi
        onClick={() => {
          setIsDeleteModalOpen(true)
          setTargetId(clientKey.id)
          closeMenu()
        }}>
        <DeleteIcon size="14" />
        <p>Delete key</p>
      </StyledLi>
    </StyledUl>
  )
}

export const KeyOptions = ({ clientKey }: Props) => {
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)

  useOnClickOutside(ref, () => setOpen(false))

  const onClick = useCallback(
    (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      e.stopPropagation()
      setOpen(!isOpen)
    },
    [isOpen, setOpen],
  )

  return (
    <StyledOption
      ref={ref}
      className={isOpen ? "open" : ""}
      onClick={(e) => onClick(e)}>
      <Dots />

      {isOpen && (
        <List clientKey={clientKey} closeMenu={() => setOpen(false)} />
      )}
    </StyledOption>
  )
}
