import { StoreApi } from "zustand"

import { State } from "./State"

export const getMatchOrderById = (
  get: StoreApi<State>["getState"],
  matchId: number,
) => {
  const match = get().matches[matchId]
  if (match === undefined) {
    return undefined
  }
  return match.order
}
