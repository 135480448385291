import { createGlobalStyle } from "styled-components"

/* eslint-disable-next-line @typescript-eslint/naming-convention */
export const GlobalStyles = createGlobalStyle`

  //--------------------------------------------------------------
  //  1.0 - General
  //--------------------------------------------------------------
  :root {
    font-size: clamp(0.875rem, calc(0.875rem + ((1vw - 0.2rem) * 0.2083)), 1rem);
    min-height: 0vw;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;

  }

  body {
    -webkit-font-smoothing: antialiased;

    font-family:    var(--font-family);
    font-weight:    var(--text-font-weight);

    text-rendering: geometricPrecision;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    min-height: fit-content;

    color:          var(--text-heading);
    font-size:      var(--text-lg);
    font-family:    var(--heading-font-family);
    font-weight:    var(600);
  }

  h1 {
    font-size:      var(--text-2xl);
  }

  h2 {
    font-size:      var(--text-lg);
    color: var(--text-primary);
  }

  h3 {
    font-size:      var(--text-md);
  }

  h4 {
    font-size:      var(--text-sm);
  }

  h5 {
    font-size:      var(--text-xs);
  }

  h6 {
    font-size:      var(--text-2xs);
  }

  figure {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  button {
    outline: none;
  }

`
