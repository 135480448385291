import { orderBy } from "lodash"
import { IMAGE_FALLBACK } from "constants/image"
import type { Game } from "types/game"
import { sortGamesByActive } from "pages/api/utils/sortGames"
import { Header } from "./atoms/Header"
import { GameFigure } from "../atoms/GameFigure"
import { H2, Package, Games } from "./Resources.styles"

export type GameResource = Game & {
  isActive: boolean // Whether the v3 client has access to the game or not
}

export type Resource = {
  id: string // product id
  title: string // product title
  games: { [key: number]: GameResource }
}

type Props = {
  clientResources: Resource[]
  title?: string
}

export const Resources = ({ clientResources, title }: Props) => {
  // Order packages by title
  const clientPkgsSorted = orderBy(clientResources, ["title"], ["asc"])

  const packageResources = clientPkgsSorted.map((p) => {
    // Sort package games by active and in order by title
    const pkgGames = sortGamesByActive(p.games)

    const gameFigures = pkgGames.map((game) => {
      // Get game image
      const gIndex = game.images.findIndex((g) => g.type === "circle")
      const imgUrl = game.images[gIndex]?.url || IMAGE_FALLBACK

      return (
        <GameFigure
          key={game.abbreviation}
          title={game.title}
          iconUrl={imgUrl}
          isActive={game.isActive}
        />
      )
    })

    return (
      <Package key={p.title}>
        <Header title={p.title} />

        <Games>{gameFigures}</Games>
      </Package>
    )
  })

  return (
    <div>
      <H2> {title ?? "API Access"} </H2>

      {packageResources}
    </div>
  )
}
