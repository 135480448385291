type Props = {
  size?: string
  color?: string
}

export const ChevronIcon = ({ size = "14", color = "black" }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 8 14" fill="none">
      <path
        d="M7 13L1 7L7 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
