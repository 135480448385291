import { removeAthenaSecret } from "utils/localStorage"
import { StoreApi } from "zustand"
import { TOKEN_STORAGE_NAME, State } from "./State"

export const SignOut = (set: StoreApi<State>["setState"]) => {
  localStorage.removeItem(TOKEN_STORAGE_NAME)
  localStorage.removeItem("xcustomer")

  removeAthenaSecret()

  set({ isAuthed: false, hasTradingAccess: false })
}
