import styled from "styled-components"

// SubClause
export const AgreementSubClauseNumber = styled.p`
  font-size: var(--text-font-size-sm);
  font-weight: bold;
`

export const AgreementSubClauseText = styled.p`
  font-size: var(--text-font-size-sm);
`

export const AgreementSubClauseContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const AgreementSubClauseWrapper = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

// Clause
export const AgreementClauseContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
`

export const AgreementClauseText = styled.p`
  font-size: var(--text-font-size-md);
`

export const AgreementClauseSectionBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

// Notice
export const AgreementNoticeSection = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`
