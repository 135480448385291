import React, { useEffect, useContext, useState, createContext } from "react"
import { UserContext } from "../user/UserContext"
import { removeAthenaSecret } from "utils/localStorage"
import { useAuthStore } from "stores"
import { useAtlasStore, useOddsStore } from "pages/trading/stores"
import LoadingContainer from "jsx/components/LoadingContainer"

export const XCustomerContext = createContext()

export const XCustomerProvider = ({ children }) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const user = useContext(UserContext)
  const [customer, setCustomer] = useState("")
  const { getTradingToken } = useAuthStore()
  const oddsStore = useOddsStore()
  const atlasStore = useAtlasStore()

  useEffect(() => {
    const loadRes = async () => {
      // Fetch new trading access token
      await getTradingToken()

      if (user.data && user.data.superuser) {
        // Check if xcustomer exists in localStorage
        let xcustomerItem = localStorage.getItem("xcustomer")
        if (xcustomerItem) {
          setCustomer(JSON.parse(xcustomerItem))
        }
      }
      setHasLoaded(true)
    }

    loadRes()
  }, [user.data])

  const Clear = () => {
    localStorage.removeItem("xcustomer")
    // Remove athena secret for trading access
    removeAthenaSecret()
    // Clear odds store
    oddsStore.clear()
    // Clear atlas store
    atlasStore.clear()
    setCustomer("")
    // Fetch new trading access token
    getTradingToken()
  }

  async function SetCustomer(data) {
    if (data) {
      localStorage.setItem("xcustomer", JSON.stringify(data))
      // Remove athena token for trading access
      removeAthenaSecret()
      // Clear odds store
      oddsStore.clear()
      // Clear atlas store
      atlasStore.clear()
      setCustomer(data)
    }
  }

  const Change = (data) => {
    if (data) SetCustomer(data)
    else Clear()
  }

  if (!hasLoaded) {
    return <LoadingContainer />
  }

  return (
    <XCustomerContext.Provider value={{ Change, customer, SetCustomer, Clear }}>
      {children}
    </XCustomerContext.Provider>
  )
}
