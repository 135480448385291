export const getClientHistoricalAccess = (hoursBack: number | null): string => {
  if (hoursBack === null) {
    return "Unlimited"
  }
  if (hoursBack === 0) {
    return "None"
  }
  if (hoursBack < 24) {
    return hoursBack === 1 ? "1 hour" : `${hoursBack} hours`
  }
  let days = Math.floor(hoursBack / 24)
  if (days >= 365) {
    let year = Math.floor(days / 365)
    const remainder = days % 365
    if (remainder > 365 / 2) {
      year += 1
    }
    return year === 1 ? "1 year" : `${year} years`
  }
  const remainder = hoursBack % 24
  if (remainder > 12) {
    days += 1
  }
  return days === 1 ? "1 day" : `${days} days`
}
