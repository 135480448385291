type Props = {
  size?: string
  color?: string
}

export const PenIcon = ({ size = "14", color = "black" }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none">
      <path
        d="M17 9L13 5M1.49997 20.5L4.88434 20.124C5.29783 20.078 5.50457 20.055 5.69782 19.9925C5.86926 19.937 6.03242 19.8586 6.18286 19.7594C6.35242 19.6475 6.49951 19.5005 6.7937 19.2063L20 6C21.1046 4.89543 21.1046 3.10457 20 2C18.8954 0.895428 17.1046 0.895427 16 2L2.7937 15.2063C2.49952 15.5005 2.35242 15.6475 2.24061 15.8171C2.1414 15.9676 2.06298 16.1307 2.00748 16.3022C1.94493 16.4954 1.92195 16.7021 1.87601 17.1156L1.49997 20.5Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
