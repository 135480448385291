import axios from "axios"
import { StoreApi } from "zustand"

import { PAGE_SIZE, Roster } from "@abios/abios-ts-sdk"
import { State } from "./State"
import { generateFilterIdsRequests } from "./Utils"

export const getRostersByIds = async (
  set: StoreApi<State>["setState"],
  get: StoreApi<State>["getState"],
  rosterIds: number[],
) => {
  const { rosters } = get()
  const rostersList: Roster[] = []

  // check if series exist in state
  const seriesIdsLength = rosterIds.length
  for (let i = seriesIdsLength - 1; i >= 0; i -= 1) {
    if (rosters[rosterIds[i]] !== undefined) {
      rostersList.push(rosters[rosterIds[i]])
      rosterIds.splice(i, 1)
    }
  }

  if (rosterIds.length === 0) {
    // We have all the series that we need
    return rostersList
  }

  // Fetch series from Atlas API

  const url = `v3/rosters?filter=id<=`
  const requests = generateFilterIdsRequests(url, PAGE_SIZE, rosterIds)

  const newRosters: { [key: number]: Roster } = {}
  try {
    const resp = await axios.all(requests)

    if (Array.isArray(resp)) {
      const respLength = resp.length
      for (let i = 0; i < respLength; i += 1) {
        if (resp[i].data && Array.isArray(resp[i].data)) {
          const respDataLength = resp[i].data.length
          for (let j = 0; j < respDataLength; j += 1) {
            newRosters[resp[i].data[j].id] = resp[i].data[j]

            // Add series to list that we return back
            rostersList.push(resp[i].data[j])
          }
        }
      }
    }
  } catch (e) {
    throw new Error(e)
  }

  // Update store with all the new fetched series
  set({ rosters: { ...rosters, ...newRosters } })

  return rostersList
}
