import styled from "styled-components"

export const StyledStatusGrid = styled.div`
  display: grid;
  grid-template-columns: 14px 1fr;
  grid-gap: 12px;
`

export const StyledFigure = styled.figure`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const P = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledText = styled(P)`
  color: var(--text-primary);

  &[data-date="true"] {
    color: var(--text-secondary);
  }
`

export const StyledTextSub = styled(P)`
  color: var(--text-secondary);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.2px;

  &[data-id="true"] {
    font-family: var(--font-family-mono);
  }
`
