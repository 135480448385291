import { ExpandIcon } from "atoms/expandIcon"
import { useToggle } from "hooks/useToggle"
import { ReactNode, useCallback, useEffect, useState } from "react"
import { InputLabel, Input } from "./FilterCreated.styles"
import { FilterRowContent } from "./FilterRow"

export type CreatedParams<T> = {
  to: T
  from: T
}

// yyyy-MM-dd
type Date = string
type DateValue = Date | undefined

export type FilterCreatedInterval = CreatedParams<Date>
export type FilterCreatedValue = CreatedParams<DateValue>

const EMPTY_DATE: FilterCreatedValue = {
  from: undefined,
  to: undefined,
}

type Props = {
  header: ReactNode
  interval: FilterCreatedInterval
  value: FilterCreatedValue
  onChange: (v: FilterCreatedValue) => void
}

export const FilterCreated = ({ header, interval, value, onChange }: Props) => {
  const {
    isActive: isOpen,
    close: onClose,
    toggle: toggleOpen,
  } = useToggle(Object.values(value).some((v) => v != null) || false)
  const [active, setActive] = useState<FilterCreatedValue>(value)

  const onFilterRowButtonClick = useCallback(() => {
    if (isOpen) {
      onChange(EMPTY_DATE)
    } else {
      onChange(active)
    }

    toggleOpen()
  }, [isOpen, toggleOpen, onChange, active])

  const { from, to } = active

  const setValue = useCallback(
    (v: Partial<FilterCreatedValue>) =>
      onChange({
        to,
        from,
        ...v,
      }),
    [onChange, to, from],
  )

  useEffect(() => {
    // On close group, don't clear values
    if (isOpen) setActive(value)

    // On clear
    if (!value) onClose()
  }, [isOpen, value, setActive, onClose])

  const { from: min, to: max } = interval

  const content = isOpen && (
    <>
      <InputLabel>Before</InputLabel>
      <Input
        min={min}
        max={max}
        type="date"
        name="created-to"
        value={to || ""}
        onChange={(v) => setValue({ to: v.target.value })}
      />
      <InputLabel>After</InputLabel>
      <Input
        min={min}
        max={max}
        type="date"
        name="created-from"
        value={from || ""}
        onChange={(v) => setValue({ from: v.target.value })}
      />
    </>
  )

  return (
    <FilterRowContent>
      <button type="button" onClick={onFilterRowButtonClick}>
        {header}
        <ExpandIcon isActive={isOpen} />
      </button>
      {content}
    </FilterRowContent>
  )
}
