import { CrossIconNew } from "atoms/icons"
import {
  NoWidgetFallbackContainer,
  WidgetFallbackIcon,
} from "./NoWidgetFallback.styles"

export const NoWidgetFallback = () => {
  return (
    <NoWidgetFallbackContainer>
      <WidgetFallbackIcon>
        <CrossIconNew color="var(--red-300)" size="18" />
      </WidgetFallbackIcon>
      <h3>Oops!</h3>
      <h5>There are no series available at this time</h5>
    </NoWidgetFallbackContainer>
  )
}
