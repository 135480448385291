import styled from "styled-components"
import { animated } from "react-spring"
// eslint-disable-next-line import/no-extraneous-dependencies
import { TriangleFill } from "@styled-icons/bootstrap/TriangleFill"

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
`

export const StyledText = styled.p`
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  line-height: 16px;
  letter-spacing: 0.6px;
`

export const StyledIconContent = styled(animated.div)`
  position: absolute;
  transform: translateX(-100%);
  > * {
    display: flex;
  }
`

type TriangleIconProps = {
  isHigher: boolean
  hasPrevOdds: boolean
}

export const StyledTriangleIcon = styled(TriangleFill)<TriangleIconProps>`
  color: ${({ isHigher }) => (isHigher ? "#69d2a0" : "#ff7a79")};
  transform: ${({ isHigher }) => (isHigher ? "#rotate(0)" : "rotate(180deg)")};
  opacity: ${({ hasPrevOdds }) => (hasPrevOdds ? "1" : "0")};
`
