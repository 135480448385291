import { Unit } from "../UnitPicker/UnitPicker"

export type Package = {
  game: number
  products: Record<string, boolean>
}

export type FormState = {
  name: string
  historical?: number
  unit: Unit
  perSecond?: number
  burst?: number
  concurrent?: number
  activeUntil?: Date
  packages: Package[]
}

/**
 * STATE_ACTION is an action type for formReducer
 */
export enum STATE_ACTION {
  /**
   * Set any value of string or number, used for field such as `name`, `historical`, `perSecond`, `burst`, `concurrent`, `queueDepth`
   */
  SET_VALUE = "SET_VALUE",
  /**
   * Set the date value activeUntil
   */
  SET_ACTIVE_UNTIL = "SET_ACTIVE_UNTIL",
  /**
   * Set the value of `unit`, which is used with the `historical` value
   */
  SET_UNIT = "SET_UNIT",
  DELETE_GAME = "DELETE_GAME",
  ADD_GAME = "ADD_GAME",
  TOGGLE_PRODUCT = "TOGGLE_PRODUCT",
}

/**
 * KeysOfType<T, V> will result in the fields of T which has type V.
 * Used for formReducer action SET_VALUE in order to force the right type depending on what field is being updated.
 */
type KeysOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V ? P : never]: T[P]
}

export type StateAction =
  | {
      type: STATE_ACTION.SET_VALUE
      field: "name"
      value: string
    }
  | {
      type: STATE_ACTION.SET_VALUE
      field: KeysOfType<FormState, number | undefined>
      value: number | undefined
    }
  | {
      type: STATE_ACTION.SET_UNIT
      value: Unit
    }
  | {
      type: STATE_ACTION.SET_ACTIVE_UNTIL
      value: Date | undefined
    }
  | {
      type: STATE_ACTION.DELETE_GAME
      game: number
    }
  | {
      type: STATE_ACTION.ADD_GAME
      game: number
    }
  | {
      type: STATE_ACTION.TOGGLE_PRODUCT
      game: number
      product: string
    }

export const formReducer = (state: FormState, action: StateAction) => {
  switch (action.type) {
    case STATE_ACTION.SET_VALUE:
      return {
        ...state,
        [action.field]: action.value,
      }

    case STATE_ACTION.SET_UNIT:
      return {
        ...state,
        unit: action.value,
      }

    case STATE_ACTION.SET_ACTIVE_UNTIL:
      return {
        ...state,
        activeUntil: action.value,
      }

    case STATE_ACTION.TOGGLE_PRODUCT: {
      const products =
        state.packages.find(({ game }) => game === action.game)?.products || {}

      return {
        ...state,
        packages: [
          ...state.packages.filter(({ game }) => game !== action.game),
          {
            game: action.game,
            products: {
              ...products,
              [action.product]: !products[action.product],
            },
          },
        ],
      }
    }

    case STATE_ACTION.DELETE_GAME:
      return {
        ...state,
        packages: state.packages.filter(({ game }) => game !== action.game),
      }

    case STATE_ACTION.ADD_GAME:
      return {
        ...state,
        packages: [
          ...state.packages,
          {
            game: action.game,
            products: {},
          },
        ],
      }

    default:
      return state
  }
}
