import styled, { css } from "styled-components"

const CELL_STYLING = css`
  border-bottom: 1px solid #e1e5ea;
  padding: 0.8rem;
`

export const Tr = styled.tr`
  white-space: nowrap;
`

export const Th = styled.th`
  ${CELL_STYLING}

  color: #67768E;
  font-size: 12px;
  font-weight: 500;
  padding-top: 0;
`

export const Td = styled.td`
  ${CELL_STYLING}
`

const GAME_ROW = css`
  display: flex;
  align-items: center;
  gap: 1rem;

  > img {
    border-radius: 100%;
    width: 24px;
    height: 24px;
    user-select: none;
  }

  > span {
    font-size: 12px;
    color: #404650;
    font-weight: 500;
  }
`

export const GameTd = styled(Td)`
  > div {
    ${GAME_ROW}
  }
`

export const PermissionsTd = styled(Td)`
  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 10px;
    color: var(--text-inactive);

    > button {
      border-radius: 16px;
      background-color: var(--inactive-accent);
      padding: 0.5rem 0.7rem;
      font-size: 11px;
      font-weight: 500;

      &.active {
        background-color: var(--primary-accent);
        color: var(--text-white);
      }

      &:not(.active):hover {
        background-color: var(--resource-color-light);
        color: var(--text-white);
      }
    }
  }
`

export const CrossTd = styled(Td)`
  width: 1px;
`

export const Table = styled.table`
  width: 100%;
  text-align: left;
  margin-top: 1rem;
`
