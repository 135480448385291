import axios from "axios"
import { Widget } from "types/widget_namespace"
import AthenaAPI from "services/athena"
import { useEffect, useState } from "react"

const requestWidgets = async (
  signal: AbortSignal,
  urlParam = "",
): Promise<Widget[]> => {
  const data = await AthenaAPI.get(`v1/widget${urlParam}`, { signal })
  const { widgets } = data.data
  return widgets
}

export const useWidgets = (
  urlParam: string,
): {
  widgets: Widget[]
  isLoading: boolean
  error?: string
} => {
  const [widgets, setWidgets] = useState<Widget[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>()

  useEffect(() => {
    const controller = new AbortController()

    const getWidgets = async () => {
      setIsLoading(true)
      try {
        setWidgets(await requestWidgets(controller.signal, urlParam))
        setError(undefined)
      } catch (err) {
        if (axios.isCancel(err)) {
          return
        }
        setError(err)
      } finally {
        setIsLoading(false)
      }
    }

    getWidgets()
    return () => {
      controller.abort()
    }
  }, [urlParam])

  return {
    widgets,
    isLoading,
    error,
  }
}
