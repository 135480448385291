export const Logo = () => (
  <svg
    width="80"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M3.266.104c1.13 0 1.97.583 2.52 1.75L4.1 2.75c-.266-.543-.595-.815-.988-.815a.68.68 0 00-.48.186.621.621 0 00-.2.475c0 .339.393.674 1.178 1.006 1.082.466 1.81.895 2.185 1.287.374.393.562.922.562 1.587a2.78 2.78 0 01-.943 2.138c-.61.544-1.347.816-2.211.816-1.48 0-2.53-.722-3.145-2.166l1.74-.807c.241.423.426.692.553.807.247.23.544.344.888.344.689 0 1.033-.314 1.033-.942 0-.363-.266-.701-.798-1.015-.205-.103-.41-.203-.616-.3a54.13 54.13 0 01-.625-.298c-.592-.29-1.01-.58-1.251-.87C.674 3.813.52 3.34.52 2.758c0-.767.263-1.401.788-1.903.538-.501 1.19-.752 1.958-.752z"
        id="a"
      />
      <path
        d="M4.804.104c1.318 0 2.435.45 3.354 1.35.906.9 1.36 2.006 1.36 3.318 0 1.323-.457 2.432-1.37 3.326-.918.888-2.045 1.332-3.38 1.332-1.323 0-2.432-.453-3.326-1.36C.548 7.178.1 6.054.1 4.7c0-1.276.457-2.36 1.37-3.255C2.38.551 3.492.104 4.803.104zm0 1.84c-.779 0-1.41.26-1.894.78-.483.519-.725 1.19-.725 2.011 0 .883.236 1.58.707 2.094.483.52 1.12.78 1.912.78.798 0 1.435-.257 1.913-.771.477-.514.716-1.2.716-2.057 0-.858-.239-1.544-.716-2.058-.484-.52-1.121-.78-1.913-.78z"
        id="c"
      />
      <path
        d="M2.397 5.07v8.828H.357V5.07h2.04zM1.373.085c.368 0 .682.13.942.39.26.253.39.565.39.933 0 .369-.13.683-.39.943-.253.26-.565.39-.933.39-.369 0-.683-.13-.943-.39-.26-.26-.39-.577-.39-.952 0-.356.13-.664.39-.924s.571-.39.934-.39z"
        id="e"
      />
      <path
        d="M2.1.032v7.505c.816-.846 1.74-1.27 2.774-1.27 1.19 0 2.18.445 2.972 1.333.792.882 1.188 1.982 1.188 3.3 0 1.359-.4 2.483-1.197 3.37-.791.883-1.791 1.324-3 1.324-1.02 0-1.933-.393-2.737-1.178v.924H.06V.032H2.1zm2.411 8.076c-.75 0-1.36.262-1.83.788-.466.52-.699 1.197-.699 2.03 0 .859.23 1.544.69 2.058.458.526 1.063.788 1.812.788.707 0 1.293-.262 1.758-.788.472-.532.707-1.196.707-1.994 0-.846-.23-1.535-.689-2.067-.465-.543-1.048-.815-1.749-.815z"
        id="g"
      />
      <path
        d="M6.925 0l6.68 14.873h-2.312l-1.468-3.399H3.834l-1.559 3.4H0L6.925 0zm-.037 4.731L4.713 9.49h4.25L6.889 4.73z"
        id="i"
      />
      <path
        id="k"
        d="M10.884 0l10.499 23.975h-6.621L13.552 21 0 23.974v-.016z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(72.787 10.492)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path fill="#25272C" mask="url(#b)" d="M.055 9.432h6.31V.1H.056z" />
      </g>
      <g transform="translate(61.508 10.492)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path fill="#25272C" mask="url(#d)" d="M.097 9.432h9.427V.1H.097z" />
      </g>
      <g transform="translate(56.656 5.77)">
        <mask id="f" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <path fill="#25272C" mask="url(#f)" d="M.04 13.902h2.675V.084H.039z" />
      </g>
      <g transform="translate(45.377 4.328)">
        <mask id="h" fill="#fff">
          <use xlinkHref="#g" />
        </mask>
        <path fill="#25272C" mask="url(#h)" d="M.05 15.596h8.986V.031H.05z" />
      </g>
      <g transform="translate(29.861 4.795)">
        <mask id="j" fill="#fff">
          <use xlinkHref="#i" />
        </mask>
        <path
          fill="#25272C"
          mask="url(#j)"
          d="M-.015 14.877h13.629V-.01H-.015z"
        />
      </g>
      <g transform="translate(.008 .025)">
        <mask id="l" fill="#fff">
          <use xlinkHref="#k" />
        </mask>
        <path fill="#25272C" mask="url(#l)" d="M0 23.983h21.388V0H0z" />
      </g>
    </g>
  </svg>
)
