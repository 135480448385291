import { Dropdown } from "@abios/smorgasbord-components"
import styled from "styled-components"

export const StyledDropdown = styled(Dropdown)`
  width: 100%;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.5rem 0;
  color: var(--text-secondary);
  & p {
    height: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > div > div > div:first-child {
    overflow: hidden;
  }
`

export const StyledDropContent = styled.div`
  padding: 0.75rem 0.5rem;
  display: flex;
  flex-direction: column;
  background: var(--bg);
  border-radius: var(--border-radius-3);
`

export const StyledDropdownWrapper = styled.div`
  width: 100%;
  min-width: calc(50% - 1rem);

  & > p {
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: var(--text-secondary);
    overflow: hidden;
  }
`
