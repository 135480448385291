import React, { useEffect } from "react"
import styled from "styled-components"
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom"
import ReactGA from "react-ga"

import { GlobalStyles } from "styles/GlobalStyles"
import { Tokens } from "styles/Tokens"
import { Theme } from "styles/Theme"

import { Client3Overview } from "pages/api/single"

import { AuthProvider } from "AuthProvider"
import { UserProvider } from "./jsx/user/UserContext"
import { LoadResources } from "Resources"

import { CustomerProvider } from "./jsx/customer/CustomerContext"
import { XCustomerProvider } from "./jsx/contexts/XCustomer"
import { TrailAgreement } from "./pages/agreement/TrailAgreement"

import PrivateRoute from "./jsx/PrivateRoute"
import AdminRoute from "./jsx/AdminRoute"

import Customer from "./jsx/customer/Index"
import { Sidebar } from "./jsx/components/Sidebar"
import Billing from "./jsx/billing/index"
import ConfirmSCA from "./jsx/billing/ConfirmSCA"
import { Login } from "./jsx/auth/Login"
import UpdatePassword from "./jsx/auth/UpdatePassword"

import UsersList from "jsx/users/list/Index"
import TradingRoutes from "pages/trading/Routes"

import CustomersList from "./pages/customers/List"

import "@smorgasbord/tokens"
import "@smorgasbord/themes"
import "./main.css"
import { ApiList } from "pages/api/list"
import { ApiCreate } from "pages/api/create"
import { ApiEdit } from "pages/api/edit"
import { ListOverview } from "pages/trading/list"
import { Single } from "pages/trading/single"
import { WidgetList } from "pages/widgets/"
import { WidgetView } from "pages/widgets/components/single/WidgetView"
import { CreateCustomer } from "pages/customers/create/CreateCustomer"
import { UpdateCustomer } from "pages/customers/update/UpdateCustomer"

const PageWrapper = styled.div`
  display: flex;
`

// Load Google Analytics in production only
let isGaAnalyticsLoaded = false
if (process.env.REACT_APP_ENV === "production") {
  const gaAnalytics = process.env.REACT_APP_GA_ANALYTICS
  if (gaAnalytics && gaAnalytics !== undefined) {
    ReactGA.initialize(gaAnalytics)
    isGaAnalyticsLoaded = true
  } else {
    console.error("Missing environment variable REACT_APP_GA_ANALYTICS")
  }
}

function usePageViews() {
  const location = useLocation()
  useEffect(() => {
    if (isGaAnalyticsLoaded) ReactGA.send(["pageview", location.pathname])
  }, [location])
}

const AppTemplate = () => {
  return (
    <AuthProvider>
      <CustomerProvider>
        <LoadResources>
          <UserProvider>
            <XCustomerProvider>
              <PageWrapper>
                <Sidebar />

                <Customer>
                  <Outlet />
                </Customer>
              </PageWrapper>
            </XCustomerProvider>
          </UserProvider>
        </LoadResources>
      </CustomerProvider>
    </AuthProvider>
  )
}

const AdminCustomerProviderTemplate = () => {
  return (
    <AdminCustomerProvider>
      <Outlet />
    </AdminCustomerProvider>
  )
}

const DEFAULT_PATH = "/api-clients"

const App = () => {
  usePageViews()

  const badURLPaths = ["/index.html", "/index", "/index.htm"]
  if (badURLPaths.indexOf(window.location.pathname) > -1) {
    return <Navigate to="/" />
  }

  return (
    <>
      <Theme />
      <Tokens />
      <GlobalStyles />

      <Routes>
        <Route path="login" element={<Login></Login>} />
        <Route path="billing/confirm-sca" element={<ConfirmSCA />} />
        <Route path="update-password/:token" element={<UpdatePassword />} />
        <Route path="agreement/:id" element={<TrailAgreement />} />

        <Route element={<AppTemplate />}>
          <Route element={<PrivateRoute />}>
            <Route exact path="" element={<Navigate to={DEFAULT_PATH} />} />

            <Route path="billing" element={<Billing />} />

            <Route path="api-clients">
              <Route exact path="" element={<ApiList />} />
              <Route path="create" element={<ApiCreate />} />
              <Route path=":clientId" element={<Client3Overview />} />
              <Route path=":clientId/edit" element={<ApiEdit />} />
            </Route>

            <Route path="widget">
              <Route exact path="" element={<WidgetList />} />
              <Route exact path=":id" element={<WidgetView />} />
            </Route>

            <Route path="admin/customers">
              <Route exact path="" element={<CustomersList />} />
              <Route path=":tab" element={<CustomersList />} />
              <Route path="create" element={<CreateCustomer />} />
              <Route path=":id/edit" element={<UpdateCustomer />} />
            </Route>

            <Route path="users" element={<UsersList />} />
            <Route path="trading" element={<TradingRoutes />}>
              <Route exact path="" element={<ListOverview />} />

              <Route path=":seriesId" element={<Single />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  )
}

export default App
