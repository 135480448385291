import { AccessAgreementDetails } from "../types/accessAgreementForm"

const lengthValidation = (value: string) => {
  return value.trim().length
}

export const accessAgreementValidation = (
  formDetails: AccessAgreementDetails,
) => {
  if (!lengthValidation(formDetails.recipientName)) {
    return {
      error: true,
      key: "recipientName",
      message: "A recipient name is required.",
    }
  }

  if (!lengthValidation(formDetails.recipientAddress)) {
    return {
      error: true,
      key: "recipientAddress",
      message: "A recipient address is required.",
    }
  }

  if (!lengthValidation(formDetails.recipientEmail)) {
    return {
      error: true,
      key: "recipientEmail",
      message: "A recipient email is required.",
    }
  }

  if (!lengthValidation(formDetails.signerCompany)) {
    return {
      error: true,
      key: "signerCompany",
      message: "A recipient company name is required.",
    }
  }

  if (!lengthValidation(formDetails.signerName)) {
    return {
      error: true,
      key: "signerName",
      message: "A signer name is required.",
    }
  }

  if (!lengthValidation(formDetails.signerRole)) {
    return {
      error: true,
      key: "signerRole",
      message: "A signer role is required.",
    }
  }

  if (!lengthValidation(formDetails.signerEmail)) {
    return {
      error: true,
      key: "signerEmail",
      message: "A signer email is required.",
    }
  }

  return {
    error: false,
    key: "",
  }
}
