import { AthenaKey } from "@abios/abios-ts-sdk"
import AthenaAPI from "services/athena"

export const createClient3Key = async (
  id: string,
  keyName: string,
): Promise<AthenaKey> => {
  const resp = await AthenaAPI.post(`v1/client3/${id}/key3`, { name: keyName })
  return resp.data.key
}
