import React, { useContext } from "react"

import { BillingProvider } from "./sections/billing/Contexts"
import { CustomerContext } from "../customer/CustomerContext"

import BillingInformation from "./sections/billing/Index"
import PaymentPlan from "./PaymentPlan"
import PaymentMethods from "./sections/paymentmethods/PaymentMethods"
import Invoices from "./Invoices"

const Billing = () => {
  let stripe = false
  const customer = useContext(CustomerContext)

  if (customer.data && customer.data.stripe) {
    stripe = true
  }

  /*
  {customer.data && customer.data.payment_source === "stripe-auto" ? (
    <PaymentPlan />
  ) : null}
  */

  return (
    <div id="billing-page">
      <main>
        {stripe ? (
          <section id="BillingSection">
            <div className="flex-container">
              <BillingProvider>
                <BillingInformation />
                <PaymentMethods />
              </BillingProvider>
            </div>
          </section>
        ) : null}

        {stripe ? <Invoices /> : null}
      </main>
    </div>
  )
}

export default Billing
