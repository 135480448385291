import styled from "styled-components"

export const UpdateCustomerContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 2rem;

  display: flex;
  justify-content: center;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 800px;
`
export const TagButton = styled.button`
  padding: 0;
  display: flex;
  justify-content: center;
`
