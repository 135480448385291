import {
  Badge,
  BADGE_STATUS,
  IndexTable,
  ROW_SIZE,
} from "@abios/smorgasbord-components"

import { Customer } from "types/customer"
import { CLIENT_STATUS } from "types/client3"
import { ClientStatus } from "molecules/ClientStatus/ClientStatus"
import { useMemo } from "react"
import { AthenaInvoice } from "@abios/abios-ts-sdk"
import Options from "./Options"
import { getCreatedAtDate } from "./date"
import { useAccountManagers } from "./utils/account-manager"
import {
  StyledRow,
  StyledTextLight,
  StyledText,
  StyledTextSub,
} from "./TableRow.styles"
import { getTerminateDate } from "../../api/utils/getTerminateDate"
import { getClientStatus } from "../../api/utils/getClientStatus"
import { getOverduePayments } from "./utils/invoices"

export const HEADERS = [
  "ID",
  "Customer",
  "Status",
  "Account Manager",
  "Created",
  "",
  "",
]

type Props = {
  customer: Customer
  invoices: AthenaInvoice[] | undefined
  onSelect: (customer: Customer) => Promise<void>
}

const TableRow = ({ customer, invoices, onSelect }: Props) => {
  const [accountManagers, isLoadingAccountManagers] = useAccountManagers()

  const dateActiveUntil =
    customer.active_until !== null ? new Date(customer.active_until) : null
  const customerStatus = getClientStatus(dateActiveUntil)

  // Get client status
  let statusText = "Active"
  let statusSubText = "Until Terminated"
  if (customerStatus === CLIENT_STATUS.WARNING) {
    statusText = "Expiring"
    statusSubText = `On ${getTerminateDate(dateActiveUntil)}`
  } else if (customerStatus === CLIENT_STATUS.INACTIVE) {
    statusText = "Inactive"
    statusSubText = `Since ${getTerminateDate(dateActiveUntil)}`
  }

  // avoid the method being invoked every time switching the tab
  const overduePaymentAmount = useMemo(() => {
    const overduePayments = getOverduePayments(invoices)
    return overduePayments.length
  }, [invoices])

  const isPluralNum =
    overduePaymentAmount <= 1 ? "Unpaid Invoice" : "Unpaid Invoices"

  const numOverduePayments =
    overduePaymentAmount > 9 ? "+9" : `${overduePaymentAmount} `

  const badgeText = `${numOverduePayments} ${isPluralNum}`

  return (
    <StyledRow
      as={IndexTable.Row}
      id={customer.id}
      key={customer.id}
      size={ROW_SIZE.lg}>
      <IndexTable.Cell>
        <StyledTextLight data-id="true">{customer.id}</StyledTextLight>
      </IndexTable.Cell>

      <IndexTable.Cell>
        <StyledText>{customer.internal_name}</StyledText>
        <StyledTextSub>{customer.external_name}</StyledTextSub>
      </IndexTable.Cell>

      <IndexTable.Cell>
        <ClientStatus
          status={customerStatus}
          statusText={statusText}
          statusSubText={statusSubText}
        />
      </IndexTable.Cell>

      <IndexTable.Cell>
        <StyledTextLight>
          {!isLoadingAccountManagers &&
            customer.account_managers
              .map(({ user }) => accountManagers[user.id].label)
              .join(", ")}
        </StyledTextLight>
      </IndexTable.Cell>

      <IndexTable.Cell>
        <StyledTextLight>{getCreatedAtDate(customer)}</StyledTextLight>
      </IndexTable.Cell>

      <IndexTable.Cell>
        {overduePaymentAmount ? (
          <Badge className="badge_styles" status={BADGE_STATUS.ERROR}>
            {badgeText}
          </Badge>
        ) : (
          <span />
        )}
      </IndexTable.Cell>

      <IndexTable.Cell>
        <Options customer={customer} onSelect={onSelect} />
      </IndexTable.Cell>
    </StyledRow>
  )
}

export default TableRow
