import React from "react"

const BubbleSpinner = () => (
  <div className="loader">
    <div className="loading-circle">
      <div className="loading-child"></div>
      <div className="loading-child"></div>
      <div className="loading-child"></div>
      <div className="loading-child"></div>
      <div className="loading-child"></div>
      <div className="loading-child"></div>
      <div className="loading-child"></div>
      <div className="loading-child"></div>
      <div className="loading-child"></div>
      <div className="loading-child"></div>
      <div className="loading-child"></div>
      <div className="loading-child"></div>
    </div>
  </div>
)

export default BubbleSpinner
