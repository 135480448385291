import { StoreApi } from "zustand"
import { State } from "./State"

// Clear state
export const clear = (set: StoreApi<State>["setState"]) => {
  set({
    label: {},
    markets: {},
  })
}
