import { StoreApi } from "zustand"

import { Tournament } from "@abios/abios-ts-sdk"
import { State } from "./State"

export const getTournamentsByIds = (
  get: StoreApi<State>["getState"],
  tournamentIds: number[],
) => {
  const { tournaments } = get()
  const tourneys: Tournament[] = []

  // check if tournament exist in state
  const tournamentIdsLength = tournamentIds.length
  for (let i = tournamentIdsLength - 1; i >= 0; i -= 1) {
    if (tournaments[tournamentIds[i]] !== undefined) {
      tourneys.push(tournaments[tournamentIds[i]])
    }
  }

  return tourneys
}
