import cloneDeep from "lodash/cloneDeep"
import { StoreApi } from "zustand"
import { State } from "../State"

export const onRemoveBet = (
  set: StoreApi<State>["setState"],
  get: StoreApi<State>["getState"],
  marketId: number,
) => {
  const { selectedMarkets } = get()
  const selectedMarketsCloned = cloneDeep(selectedMarkets)

  delete selectedMarketsCloned[marketId]

  set({ selectedMarkets: { ...selectedMarketsCloned } })

  if (!Object.keys(selectedMarketsCloned).length) {
    set({ selectedMatchOrder: 0 })
  }
}
