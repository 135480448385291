import type { Market as MarketType } from "@abios/abios-ts-sdk"

export const GroupMarketsByType = (markets: MarketType[]) => {
  const groupedMarkets: Record<string, MarketType[]> = {}

  markets.forEach((market) => {
    const { type } = market

    // eslint-disable-next-line no-prototype-builtins
    if (groupedMarkets.hasOwnProperty(type)) {
      groupedMarkets[type].push(market)
    } else {
      groupedMarkets[type] = [market]
    }
  })

  return groupedMarkets
}
