import chunk from "lodash/chunk"

import atlasAPI, { GlobalRetryConfig } from "services/atlas"

export const generateFilterIdsRequests = (
  url: string,
  chunkSize: number,
  ids: number[],
) => {
  const requests = []
  const idsChunked = chunk(ids, chunkSize)

  const idsChunkedLength = idsChunked.length
  for (let i = 0; i < idsChunkedLength; i += 1) {
    requests.push(
      atlasAPI.get(`${url}{${idsChunked[i].toString()}}`, GlobalRetryConfig),
    )
  }

  return requests
}
