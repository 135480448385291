import { AgreementNoticeSection } from "../atoms/AgreementAtoms.styles"
import { AgreementNoticeText } from "../atoms/AgreementNoticeText.styles"
import { AccessAgreementClauses } from "../organisms/AccessAgreementClauses"
import { AccessAgreementParties } from "../organisms/AccessAgreementParties"
import { AccessAgreementTemplate as AccessAgreementTemplateType } from "../types/accessAgreementResponse"

type Props = {
  template: AccessAgreementTemplateType
}

export const AccessAgreementTemplate = ({ template }: Props) => {
  if (!template.parties) return null

  const renderNotices = template.notice.map((notice) => (
    <AgreementNoticeText key={notice}>{notice}</AgreementNoticeText>
  ))

  return (
    <>
      <AccessAgreementParties parties={template.parties} />

      <AgreementNoticeSection>{renderNotices}</AgreementNoticeSection>

      <AccessAgreementClauses clauses={template.clauses} />
    </>
  )
}
