import { Widget } from "pages/trading/single/atoms/Widget"
import { LIFECYCLE } from "@abios/abios-ts-sdk"
import { StyledSidebar } from "./WidgetSide.style"

type Props = {
  readonly namespace: string
  readonly slug: string
  readonly seriesId: number
  readonly lifecycle: LIFECYCLE
}

export const WidgetSide = ({ namespace, slug, seriesId, lifecycle }: Props) => {
  const widgetURL = `${namespace}/${slug}/${seriesId}`

  // Show live and stream widget
  if (lifecycle === LIFECYCLE.LIVE) {
    return (
      <StyledSidebar>
        <Widget id="widget-live" urlPath={widgetURL} />
      </StyledSidebar>
    )
  }

  // Show pre-game stats widget
  return (
    <StyledSidebar>
      <Widget id="widget-pregame" urlPath={widgetURL} />
    </StyledSidebar>
  )
}
