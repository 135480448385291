import styled from "styled-components"

export const FilterRowContent = styled.div`
  border-bottom: 1px solid var(--border-default);

  button {
    width: 100%;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 1rem;
    font-weight: 600;

    input {
      margin-right: 0.625rem;
    }
  }
`

export const FilterRowElement = styled.div`
  border-top: 1px solid var(--border-default);

  label {
    user-select: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    padding: 0.625rem 1rem 0.625rem 1rem;
    background-color: #f7fafc;

    input {
      margin-right: 0.625rem;
    }
  }
`

export const StyledButtonTitleContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
