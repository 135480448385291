import styled from "styled-components"

export const Input = styled.input`
  border: none;
  width: 100%;
  display: block;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
  padding: 0.625rem 0.79rem 0.625rem 0.925rem;
  padding-top: 0;
  outline: none;
`

export const InputLabel = styled.p`
  border-top: 1px solid var(--border-default);
  padding: 0.625rem 0.79rem 0rem 0.925rem;
  color: var(--text-secondary);
`
