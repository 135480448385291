import styled from "styled-components"
// eslint-disable-next-line import/no-extraneous-dependencies
import { ArrowIosDownwardOutline } from "@styled-icons/evaicons-outline"

export const StyledContent = styled.section`
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid var(--border-default);

  :not(:first-child) {
    margin-bottom: 24px;
  }
`
export const StyledHeader = styled.header`
  align-items: center;
  border-bottom: 1px solid var(--border-default);
  padding: 24px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  div {
    display: flex;
    flex-direction: row;
  }
`

type ArrowDownProps = {
  deg: string
}
export const StyledArrowDown = styled(ArrowIosDownwardOutline)<ArrowDownProps>`
  color: #949da4;
  cursor: pointer;
  display: block;
  height: 24px;
  margin-left: 12px;
  width: 28px;
  transform: rotate(${(props) => props.deg});
  transition-duration: 450ms;
`

export const StyledSeries = styled.div`
  padding: 8px 0;
`

export const StyledSection = styled.section`
  overflow: hidden;
  transition: height 550ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
`

export const StyledAlignText = styled.div`
  align-items: center;
  display: flex;
`
