import { FC, ReactNode } from "react"
import { StyledBadge, StyledText } from "./Badge.styles"
import { STATUS } from "./Types"

type Props = {
  children: ReactNode
  readonly status: STATUS
}

export const Badge: FC<Props> = ({ status, children }) => (
  <StyledBadge status={status}>
    <StyledText>{children}</StyledText>
  </StyledBadge>
)
