import { useContext, useReducer, useState } from "react"
import { useNavigate } from "react-router-dom"

import { Client3 } from "types/client3"
import { V3ResourcesProvider } from "contexts/v3Resources"
import { XCustomerContext } from "jsx/contexts/XCustomer"

import { AthenaCreateClientPayload } from "@abios/abios-ts-sdk"
import { CreateHeader } from "./Header"
import { ResourceAccess } from "./ResourceAccess"
import { PreviewClient } from "../single/sidebar/PreviewClient"
import { UNITS } from "./UnitPicker/UnitPicker"

import { useResourceAccess } from "./util/useResourceAccess"
import {
  createSchema,
  required,
  requiredNumber,
  useValidation,
} from "./util/validation"
import { FormState, formReducer, STATE_ACTION } from "./util/formReducer"

import {
  ErrorableInputBasis,
  LeftSideWrapper,
  StyledContent,
  StyledContentLeft,
  StyledContentRight,
  StyledCreate,
  StyledError,
  StyledForm,
  StyledRow,
  UnitPickerInputBasis,
  PreviewText,
} from "./Create.styles"
import {
  getClientFromForm,
  getClientInputObjectFromForm,
  getFormFromClient,
} from "./util/convert"
import { createClient } from "./util/createClient"
import { getClientStore } from "../list/store/getClientStore"

const newClient: FormState = {
  name: "",
  historical: 48,
  unit: UNITS.hours,
  perSecond: 5,
  burst: 5,
  concurrent: 20,
  activeUntil: undefined,
  packages: [],
}

const schema = createSchema<FormState>({
  name: required("Name is required"),
  perSecond: required("Request rate per second is required"),
  unit: required("Unit is required"),
  burst: required("Burst is required"),
  concurrent: requiredNumber("Concurrent connections is required"),
  packages: required("Packages are required"),
})

type Props = {
  readonly title: string

  client?: Client3
  /**
   * onSubmit returns an error message if there was an error
   */
  onSubmit: (client: AthenaCreateClientPayload) => Promise<string | null>
  onAbort: () => void
  submitBtnText: string
}

export const CreateApiClient = ({
  title,
  client: originalClient,
  onSubmit,
  onAbort,
  submitBtnText,
}: Props) => {
  const [state, dispatch] = useReducer(
    formReducer,
    originalClient ? getFormFromClient(originalClient) : newClient,
  )
  const [errors, validate] = useValidation(schema)
  const [serverError, setServerError] = useState<string>()
  const resources = useResourceAccess(state.packages)
  const client: Client3 = getClientFromForm(state)

  const onSubmitForm = async () => {
    // There is one or more form errors
    if (Object.values(validate(state)).length > 0) {
      return
    }

    const clientInput = getClientInputObjectFromForm(state)
    const activeUntilChange = clientInput.active_until ?? null
    const activeUntilObj = { active_until: activeUntilChange }

    const err = await onSubmit({ ...clientInput, ...activeUntilObj })
    if (err) {
      setServerError(err)
    }
  }

  return (
    <StyledCreate>
      <CreateHeader
        title={title}
        submitBtnText={submitBtnText}
        onAbort={onAbort}
        onSubmit={onSubmitForm}
      />
      <StyledContent>
        <StyledContentLeft>
          <StyledForm>
            {serverError && <StyledError>{serverError}</StyledError>}
            <h2>General information</h2>
            <StyledRow>
              <ErrorableInputBasis
                basis={33.3}
                label="Client name"
                type="text"
                error={errors.name}
                value={state.name}
                onChange={(e) =>
                  dispatch({
                    type: STATE_ACTION.SET_VALUE,
                    field: "name",
                    value: e.target.value,
                  })
                }
              />
              <UnitPickerInputBasis
                basis={33.3}
                label="Historical access"
                error={errors.historical}
                type="number"
                min={0}
                value={state.historical}
                unit={state.unit}
                setValue={(value) =>
                  dispatch({
                    type: STATE_ACTION.SET_VALUE,
                    field: "historical",
                    value,
                  })
                }
                setUnit={(unit) =>
                  dispatch({
                    type: STATE_ACTION.SET_UNIT,
                    value: unit,
                  })
                }
              />
              <ErrorableInputBasis
                basis={33.3}
                label="Active until"
                error={errors.activeUntil}
                type="date"
                min={0}
                value={state.activeUntil}
                onChange={(e) =>
                  dispatch({
                    type: STATE_ACTION.SET_ACTIVE_UNTIL,
                    value: e.target.value
                      ? new Date(e.target.value)
                      : undefined,
                  })
                }
              />
            </StyledRow>
            <h3>Request rates</h3>
            <StyledRow>
              <ErrorableInputBasis
                label="Per second"
                error={errors.perSecond}
                type="number"
                min={0}
                value={state.perSecond}
                onChange={(e) =>
                  dispatch({
                    type: STATE_ACTION.SET_VALUE,
                    field: "perSecond",
                    value: e.target.value ? +e.target.value : undefined,
                  })
                }
              />
              <ErrorableInputBasis
                label="Burst"
                error={errors.burst}
                type="number"
                min={0}
                value={state.burst}
                onChange={(e) =>
                  dispatch({
                    type: STATE_ACTION.SET_VALUE,
                    field: "burst",
                    value: e.target.value ? +e.target.value : undefined,
                  })
                }
              />
            </StyledRow>

            <h3>Hermes configuration</h3>
            <StyledRow>
              <ErrorableInputBasis
                label="Concurrent connections"
                error={errors.concurrent}
                type="number"
                min={0}
                value={state.concurrent}
                onChange={(e) =>
                  dispatch({
                    type: STATE_ACTION.SET_VALUE,
                    field: "concurrent",
                    value: e.target.value ? +e.target.value : undefined,
                  })
                }
              />
            </StyledRow>

            <h3>Resource access</h3>
            <ResourceAccess state={state} dispatch={dispatch} />
          </StyledForm>
        </StyledContentLeft>
        <StyledContentRight>
          <LeftSideWrapper>
            <PreviewText>Preview</PreviewText>
            <PreviewClient client={client} resources={resources} hideBadge />
          </LeftSideWrapper>
        </StyledContentRight>
      </StyledContent>
    </StyledCreate>
  )
}

export const ApiCreate = () => {
  const navigate = useNavigate()
  const xcustomer = useContext(XCustomerContext)
  const { addClient } = getClientStore()

  const onSubmit = async (client: AthenaCreateClientPayload) => {
    if (!xcustomer) return null

    const { data, error } = await createClient(
      client,
      xcustomer.customer.public_id,
    )
    if (error) return error.message
    if (data) {
      addClient(data.client)
      navigate(`/api-clients/${data.client.id}`)
    }

    return null
  }

  const onAbort = () => navigate("/api-clients")

  return (
    <V3ResourcesProvider>
      <CreateApiClient
        title="Create API Client"
        submitBtnText="Create"
        onSubmit={onSubmit}
        onAbort={onAbort}
      />
    </V3ResourcesProvider>
  )
}
