import {
  AthenaAccountManager,
  AthenaListAccountManagersResponse,
} from "@abios/abios-ts-sdk"
import { useEffect, useState } from "react"
import AthenaAPI from "services/athena"
import { ENDPOINTS_V1 } from "services/athenaApiEndpoints"
import { cErr } from "utils/debug"

const fetchAccountManagers = async () => {
  const resp = await AthenaAPI.get<AthenaListAccountManagersResponse>(
    ENDPOINTS_V1.ACCOUNT_MANAGER,
    {
      params: {
        deleted: false,
      },
    },
  )

  return resp.data
}

export const useFetchAccountManagers = () => {
  const [managers, setManagers] = useState<AthenaAccountManager[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const getAccountManagers = async () => {
      if (isLoading) return

      try {
        setIsLoading(true)
        const fetchedManagers = await fetchAccountManagers()

        setManagers(fetchedManagers.account_managers)
      } catch (err) {
        cErr(err)
      } finally {
        setIsLoading(false)
      }
    }

    getAccountManagers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    managers,
    isLoading,
  }
}
