import { Badge, STATUS } from "atoms/badge"
import { CUSTOMER_STATUS } from "types/customer"

const statusInfo: { [key: number]: [STATUS, string] } = {
  [CUSTOMER_STATUS.ACTIVE]: [STATUS.SUCCESS, "Active"],
  [CUSTOMER_STATUS.NO_CLIENTS]: [STATUS.SUCCESS, "No clients"],

  [CUSTOMER_STATUS.OVERDUE]: [STATUS.WARNING, "Expiring"],
  [CUSTOMER_STATUS.UNAUTHORIZED]: [STATUS.WARNING, "Unauthorized"],

  [CUSTOMER_STATUS.INACTIVE]: [STATUS.DEFAULT, "Inactive"],
}

export const getStatusInfo = (status: number): [STATUS, string] =>
  statusInfo[status] || [STATUS.DEFAULT, ""]

const StatusBadge = ({ status }: { status: CUSTOMER_STATUS }) => {
  const [statusValue, text] = getStatusInfo(status)
  return <Badge status={statusValue}>{text}</Badge>
}

export default StatusBadge
