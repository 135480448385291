import { StoreApi } from "zustand"

import { State } from "./State"

export const getMatchIdsFromSeries = (
  get: StoreApi<State>["getState"],
  seriesId: number,
): number[] => {
  const { series } = get()

  const serie = series[seriesId]
  if (serie === undefined) {
    return []
  }

  return serie.matches.map((m) => m.id)
}
