import { useCallback, useEffect } from "react"
import { Customer, CUSTOMER_STATUS } from "types/customer"
import { FilterCategory, FilterCategoryOption } from "./FilterCategory"
import {
  FilterCreated,
  FilterCreatedInterval,
  FilterCreatedValue,
} from "./FilterCreated"
import { serverDateToISO } from "../utils/filter"
import {
  FilterBox,
  FilterBoxButton,
  FilterBoxFooter,
  FilterBoxHeader,
} from "./FilterContent.styles"
import { useTrackedFilterStore } from "../utils/useTrackedFilterStore"
import StatusBadge from "../StatusBadge"
import { FilterAccountManager } from "./FilterAccountManager"

const sum = (a: number, v: number): number => a + v

type Props = {
  customers: Customer[]
  /**
   * setActive will report the amount of active filters used.
   * This callback is optional as it is not required for normal functionality.
   */
  setActive?: (active: number) => void
  show: boolean
}

export const FilterContent = ({ customers, setActive, show }: Props) => {
  const {
    managers,
    setManagers,
    statuses,
    setStatuses,
    createdFrom,
    setCreatedFrom,
    createdTo,
    setCreatedTo,
    setStatusDate,
    setActiveDate,
  } = useTrackedFilterStore()

  const onClear = () => {
    setManagers([])
    setStatuses([])
    setCreatedFrom(undefined)
    setCreatedTo(undefined)
  }

  // The amount of active filters
  useEffect(
    () =>
      setActive?.(
        [
          managers.length,
          statuses.length,
          createdFrom ? 1 : 0,
          createdTo ? 1 : 0,
        ].reduce<number>(sum, 0),
      ),
    [setActive, managers, statuses, createdFrom, createdTo],
  )

  const setCreated = useCallback(
    ({ from, to }: FilterCreatedValue) => {
      setCreatedFrom(from)
      setCreatedTo(to)
    },
    [setCreatedFrom, setCreatedTo],
  )

  const statusOptions: CUSTOMER_STATUS[] = [
    CUSTOMER_STATUS.ACTIVE,
    CUSTOMER_STATUS.INACTIVE,
    CUSTOMER_STATUS.OVERDUE,
  ]
  const getStatusOptions: FilterCategoryOption<number>[] = statusOptions.map(
    (status) => ({
      label: <StatusBadge status={status} />,
      value: status,
    }),
  )

  const dates = customers.map((v) => v.created).sort()
  const createdInterval: FilterCreatedInterval = {
    from: serverDateToISO(dates[0]),
    to: serverDateToISO(dates[dates.length - 1]),
  }

  const created = {
    from: createdFrom,
    to: createdTo,
  }

  return (
    <FilterBox hidden={!show}>
      <FilterBoxHeader>Filter Customers</FilterBoxHeader>
      <div>
        <FilterAccountManager managers={managers} setManagers={setManagers} />
        <FilterCategory
          header="Status"
          options={getStatusOptions}
          values={statuses}
          setStatusDate={setStatusDate}
          setActiveDate={setActiveDate}
          onChange={setStatuses}
        />
        <FilterCreated
          header="Created"
          interval={createdInterval}
          value={created}
          onChange={setCreated}
        />
      </div>
      <FilterBoxFooter>
        <FilterBoxButton onClick={onClear}>Clear</FilterBoxButton>
      </FilterBoxFooter>
    </FilterBox>
  )
}
